/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useAnswerCategories_feedback = {
    readonly userAnswers: ReadonlyArray<{
        readonly category: {
            readonly id: string;
        };
        readonly answers: ReadonlyArray<{
            readonly id: string;
        }>;
    }>;
    readonly " $refType": "useAnswerCategories_feedback";
};
export type useAnswerCategories_feedback$data = useAnswerCategories_feedback;
export type useAnswerCategories_feedback$key = {
    readonly " $data"?: useAnswerCategories_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useAnswerCategories_feedback">;
};



const node: ReaderInlineDataFragment = {
    "kind": "InlineDataFragment",
    "name": "useAnswerCategories_feedback"
} as any;
(node as any).hash = '8149ce97a41f020bb74099601b650b3f';
export default node;

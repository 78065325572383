/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RankingMenuPaginationQueryVariables = {
    count: number;
    cursor?: string | null | undefined;
};
export type RankingMenuPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"RankingMenu_rankings">;
};
export type RankingMenuPaginationQuery = {
    readonly response: RankingMenuPaginationQueryResponse;
    readonly variables: RankingMenuPaginationQueryVariables;
};



/*
query RankingMenuPaginationQuery(
  $count: Int!
  $cursor: String
) {
  ...RankingMenu_rankings_1G22uz
}

fragment RankingItem_item on RankingPlayer {
  rank
  rating
  user {
    __typename
    id
    isMe
    fullNameForDuelling
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
}

fragment RankingMenu_rankings_1G22uz on Query {
  duelRankings(first: $count, after: $cursor) {
    pageInfo {
      total
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...RankingItem_item
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RankingMenuPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "RankingMenu_rankings"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RankingMenuPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RankingPlayerConnection",
                    "kind": "LinkedField",
                    "name": "duelRankings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "total",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RankingPlayerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RankingPlayer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rank",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rating",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "isMe",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "fullNameForDuelling",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "profileImageForDuelling",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasAnonymousDuellingEnabled",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "RankingMenu_duelRankings",
                    "kind": "LinkedHandle",
                    "name": "duelRankings"
                }
            ]
        },
        "params": {
            "cacheID": "0af5de7be06d9ee3f798e36c45634659",
            "id": null,
            "metadata": {},
            "name": "RankingMenuPaginationQuery",
            "operationKind": "query",
            "text": "query RankingMenuPaginationQuery(\n  $count: Int!\n  $cursor: String\n) {\n  ...RankingMenu_rankings_1G22uz\n}\n\nfragment RankingItem_item on RankingPlayer {\n  rank\n  rating\n  user {\n    __typename\n    id\n    isMe\n    fullNameForDuelling\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n}\n\nfragment RankingMenu_rankings_1G22uz on Query {\n  duelRankings(first: $count, after: $cursor) {\n    pageInfo {\n      total\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...RankingItem_item\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'cedfa45bfcbfd0a3238466899437d31f';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ActiveDuelsListPaginationQuery from "./ActiveDuelsListPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type ActiveDuelsList_duels = {
    readonly myDuels: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly status: DuelStatus;
                readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_duel" | "DuelFinalizingPopup_item" | "PlayDuelRound_duel">;
            };
        }>;
    } | null;
    readonly " $refType": "ActiveDuelsList_duels";
};
export type ActiveDuelsList_duels$data = ActiveDuelsList_duels;
export type ActiveDuelsList_duels$key = {
    readonly " $data"?: ActiveDuelsList_duels$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActiveDuelsList_duels">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "myDuels"
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "count"
            },
            {
                "kind": "RootArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": ActiveDuelsListPaginationQuery
            }
        },
        "name": "ActiveDuelsList_duels",
        "selections": [
            {
                "alias": "myDuels",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "statusFilter",
                        "value": "ONLY_ACTIVE"
                    }
                ],
                "concreteType": "DuelConnection",
                "kind": "LinkedField",
                "name": "__ActiveDuelsList_myDuels_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DuelEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Duel",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "id",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "status",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ActiveDuelSlot_duel"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "DuelFinalizingPopup_item"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "PlayDuelRound_duel"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "kind": "ClientExtension",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                            }
                        ]
                    }
                ],
                "storageKey": "__ActiveDuelsList_myDuels_connection(statusFilter:\"ONLY_ACTIVE\")"
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '45d5315dc1bd00c65b4c8696269aa978';
export default node;

import { ComponentPropsWithoutRef, forwardRef, ReactElement, Ref } from 'react'

import styles from './TextualSliderInput.scss'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { Slider, SliderMark, SliderThumb, SliderTrack } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { DirectionAwareIcon } from './DirectionAwareIcon'

interface SliderInputProps {
  disabled?: boolean
  label?: string
  min: number
  max: number
  onChange?(event: Event, value: number | number[], activeThumb: number): void
  step?: number
  value: number
  valueText: string
}

type ThumbComponentProps = ComponentPropsWithoutRef<typeof SliderThumb>

function ThumbComponent(props: ThumbComponentProps) {
  const { children, ...other } = props
  return (
    <SliderThumb {...other} className={styles.thumb}>
      {children}
      <DirectionAwareIcon
        className={styles.icon}
        ltrIcon={faArrowLeft}
        rtlIcon={faArrowRight}
      />
      <span className={styles.pipe}>|</span>
      <DirectionAwareIcon
        className={styles.icon}
        ltrIcon={faArrowRight}
        rtlIcon={faArrowLeft}
      />
    </SliderThumb>
  )
}

type MarkComponentProps = ComponentPropsWithoutRef<typeof SliderMark>

function MarkComponent(props: MarkComponentProps) {
  const { children, ...other } = props
  return (
    <SliderMark {...other} className={styles.mark}>
      {children}
    </SliderMark>
  )
}

type TrackComponentProps = ComponentPropsWithoutRef<typeof SliderTrack>

function TrackComponent(props: TrackComponentProps) {
  const { children, ...other } = props
  return (
    <SliderTrack {...other} className={styles.track}>
      {children}
    </SliderTrack>
  )
}

export const TextualSliderInput = forwardRef(function SliderInput(
  props: SliderInputProps,
  ref: Ref<HTMLInputElement>
): ReactElement {
  return (
    <StyledEngineProvider injectFirst>
      <Slider
        aria-label={props.label}
        aria-valuetext={props.valueText}
        className={styles.slider}
        components={{
          Thumb: ThumbComponent,
          Mark: MarkComponent,
          Track: TrackComponent,
        }}
        disabled={props.disabled}
        marks={true}
        max={props.max}
        min={props.min}
        onChange={props.onChange}
        ref={ref}
        step={1}
        value={props.value}
        valueLabelDisplay='off'
      />
    </StyledEngineProvider>
  )
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type OnboardingItemPageContinueToPowerAppMutationVariables = {};
export type OnboardingItemPageContinueToPowerAppMutationResponse = {
    readonly continueToPowerApp: {
        readonly id: string;
        readonly isOnboarding: boolean;
    };
};
export type OnboardingItemPageContinueToPowerAppMutationRawResponse = {
    readonly continueToPowerApp: {
        readonly id: string;
        readonly isOnboarding: boolean;
    };
};
export type OnboardingItemPageContinueToPowerAppMutation = {
    readonly response: OnboardingItemPageContinueToPowerAppMutationResponse;
    readonly variables: OnboardingItemPageContinueToPowerAppMutationVariables;
    readonly rawResponse: OnboardingItemPageContinueToPowerAppMutationRawResponse;
};



/*
mutation OnboardingItemPageContinueToPowerAppMutation {
  continueToPowerApp {
    id
    isOnboarding
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "continueToPowerApp",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOnboarding",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "OnboardingItemPageContinueToPowerAppMutation",
            "selections": (v0 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "OnboardingItemPageContinueToPowerAppMutation",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "6532aed7892f69e942a806d8a1777994",
            "id": null,
            "metadata": {},
            "name": "OnboardingItemPageContinueToPowerAppMutation",
            "operationKind": "mutation",
            "text": "mutation OnboardingItemPageContinueToPowerAppMutation {\n  continueToPowerApp {\n    id\n    isOnboarding\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bc94a955e0548755134f1fafc0e8710c';
export default node;

import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { ScoreIndicator_scoreInfo$key } from '../../generated/ScoreIndicator_scoreInfo.graphql'

import styles from './ScoreIndicator.scss'

interface ScoreIndicatorProps {
  scoreInfo: ScoreIndicator_scoreInfo$key
}

export function ScoreIndicator(props: ScoreIndicatorProps): ReactElement {
  const { t } = useTranslation()
  const scoreInfo = useFragment(
    graphql`
      fragment ScoreIndicator_scoreInfo on ScoreInfoResponse {
        totalScore
      }
    `,
    props.scoreInfo
  )

  return (
    <div className={styles.container}>
      <div
        className={styles.progressIndicator}
        style={{ width: `${scoreInfo.totalScore}%` }}
      />

      <Trans
        t={t}
        i18nKey='learn.percentageUpToDate'
        count={scoreInfo.totalScore}
      >
        <span className={styles.percentage}>
          {{ count: scoreInfo.totalScore }}%
        </span>
        up-to-date
      </Trans>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type WellDoneOverlayMeQueryVariables = {};
export type WellDoneOverlayMeQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly profileImage: string | null;
        readonly fullName: string;
    } | null;
};
export type WellDoneOverlayMeQuery = {
    readonly response: WellDoneOverlayMeQueryResponse;
    readonly variables: WellDoneOverlayMeQueryVariables;
};



/*
query WellDoneOverlayMeQuery {
  me {
    id
    profileImage
    fullName
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImage",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "WellDoneOverlayMeQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "WellDoneOverlayMeQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "14c964f507731f4d27c58ca894c264fb",
            "id": null,
            "metadata": {},
            "name": "WellDoneOverlayMeQuery",
            "operationKind": "query",
            "text": "query WellDoneOverlayMeQuery {\n  me {\n    id\n    profileImage\n    fullName\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '00bd436876c38c2d8b3bfed94644ce19';
export default node;

const translations = {
  popup: {
    confirm: {
      delete: 'Delete',
      keep: 'Keep',
      cancelDuelInviteTitle: 'Delete duel invitation',
      cancelDuelInviteMessage:
        'Are you sure you want to delete the duel invitation?',
    },
    comeBackLater: {
      heading: 'Come back later',
      explanation1:
        'You have completed all items currently available to you. Please come back later to continue your onboarding.',
      explanation2: 'Your next update will be available on:',
      buttonText: 'Go back to my overview',
    },
    finishedOnboarding: {
      proceedHeading: 'Continuous learning in the PowerApp',
      stayHeading: 'OnFire onboarding completed',
      proceedExplanation1:
        'Congratulations! You have completed your onboarding in Onfire. You can now continue to the PowerApp, the place where we continuously learn in our organization.',
      proceedExplanation2:
        'Would you like to continue to the PowerApp or stay in OnFire for a while? You can always continue from the main menu.',
      stayExplanation:
        'Congratulations, you have completed your onboarding in Onfire.',
      proceedToPowerAppButtonText: 'To the PowerApp',
      stayInOnfireButtonText: 'Stay in OnFire',
      closeButtonText: 'Close',
    },
    privacyPolicy: {
      heading: 'Privacy policy',
      explanation1: 'By using this app you agree to our privacy policy.',
      buttonText: 'Accept',
    },
  },
  header: {
    'welcome-message': 'Hi {{name}}!',
    welcome: 'Welcome.',
    welcomeBack: 'Welcome back.',
  },
  learn: {
    instruction: 'Select the topics that you want to practice.',
    practiceSelection: 'Practice this selection',
    practiceAll: 'Practice all themes',
    selectAll: 'Select all',
    deselectAll: 'Clear selection',
    wellDone: 'Well done!',
    anotherStepForwardKnowledge: 'Another step forward!',
    anotherStepForwardSubjective:
      'Your awareness has increased on this subject!',
    instructionLinear:
      'Select a specific moment where you want to see more information',
    instructionTimeline:
      'Select a specific phase where you want to see more information',
    endOfTimeline: 'End of timeline',
    switchToPowerApp: 'Continue with continuous learning in the PowerApp',
    congratsFinished: "Congratulations, you're done!",
    toThePowerapp: 'To the PowerApp',
    availableFrom: 'Available from',
    available: 'Available',
    availabilityUnknown: 'Unknown',
    startedAt: 'Started:',
    completedAt: 'Completed:',
    percentageUpToDate: '<0>{{count}}%</0> up-to-date',
    topicCount: '{{count}} topic',
    topicCount_plural: '{{count}} topics',
    knowledge: 'Knowledge',
    awareness: 'Awareness',
    poll: 'Poll',
  },
  duels: {
    title: 'Duel rating: {{score}}',
    readyToDuel: 'Are you ready to duel?',
    roundsOverview: 'Overview of the duel rounds',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Previous page',
      doneText: 'Brainsnack completed',
      browseNext: 'Next page',
      challengingText: 'Read this information',
      completedText: 'Remember this information',
    },
    brainItem: {
      correct: 'Correct',
      incorrect: 'Incorrect',
      almostCorrect: 'Almost correct',
      completed: 'You completed a question',
      doneText: 'Done',
      openItem: 'Start',
      closeItem: 'Close',
      topics: 'Topics',
      nextItem: 'Continue practicing',
      nextOnboardingItem: 'Continue to next item',
      showProgress: 'Show your progress',
    },
    order: {
      questionTitle: 'Order',
      helpText: 'Drag the answers in order.',
      correct: '{{num}} of {{max}} correct',
      challengingText: 'Drag the answers in order',
      completedText: 'You already answered this question',
      pollSubmittedFeedback: 'Your answer has been saved',
    },
    match: {
      questionTitle: 'Match',
      helpText: 'Drag the answers to the correct spot.',
      correct: '{{num}} of {{max}} correct.',
      showCorrectAnswer: 'Show correct answer',
      showingCorrectAnswer: 'View the correct answers.',
      showMyAnswer: 'Show my answer',
      challengingText: 'Drag the answers to the correct spot',
      completedText: 'You already answered this question',
      pollSubmittedFeedback: 'Your answer has been saved',
    },
    slider: {
      doneText: 'Done',
      questionTitle: 'Slider',
      helpText: 'Drag the slider.',
      challengingText: 'Make an estimate',
      completedText: 'You already answered this question',
      averageAnswer: 'Average answer',
    },
    poll: {
      questionTitle: 'Poll',
      resultinfo: 'This is what others have answered',
      doneText: 'You answered a poll question',
      helpText: 'Choose one answer',
      challengingText: 'Give your opinion',
      completedText: 'You have given your opinion.',
    },
    notification: {
      title: 'Notification',
      pauze: 'Can I continue?',
      continueanyway: 'Continue anyway',
      doneText: 'Notification read',
      continueLearningText:
        "You are practicing with extra questions. You'll receive less points for these because you are already up-to-date.",
      panelbuttonlabel: 'Stop extra practice',
    },
    multipleselect: {
      correct: '{{num}} of {{max}} correct',
      incorrect: ', {{incorrect}} incorrect',
      doneText: 'Question completed',
      questionTitle: 'Question',
      helpText: 'Choose one or more answers',
      challengingText: 'Answer the question',
      completedText: 'You already answered this question',
    },
    multiplechoice: {
      doneText: 'Question completed',
      questionTitle: 'Question',
      helpText: 'Choose one answer',
      challengingText: 'Answer the question',
      completedText: 'You already answered this question',
    },
    duelItem: {
      duel: 'Duel',
      draw: 'Draw',
      invite: 'Duel challenge',
      acceptDuel: 'Accept duel',
      declineDuel: 'Reject duel',
      yourTurn: 'Your turn',
      opponentTurn: "Opponent's turn",
      youWon: 'You win!',
      youLost: 'You lose',
      cancelled: 'The duel is canceled',
      closeItem: 'Close',
      showDetails: 'Round details',
      playItem: 'Play round {{roundnumber}}',
      roundNumber: 'Round {{number}}',
      duelAccepted: 'You accepted a duel invite from {{name}}.',
      duelDeclined: 'You rejected a duel invite from {{name}}.',
      duelStarted: 'Let the duel with {{name}} begin. Good luck!',
      duelRequest: 'New duel challenge!',
      duelRequestText: '{{name}} challenges you to a duel',
      duelInfoInvitation: 'You are challenged for a duel.',
      duelInfoMain:
        "A short knowlegde battle in 5 rounds. Win by answering fast and correctly. Win the most rounds and you win the duel and increase your rating. Note there's a 72-hour limit to play a round.",
      viewInvitation: 'View invite',
      versusLabel: 'VS',
      started: 'Start!',
      doneText: 'Duel completed',
      tooLate: 'Out of time!',
      tooLateTurn: 'Too late',
      practiceWhileWaiting: 'Practice while waiting',
      practice: 'Keep practicing',
      yourName: '{{name}} (You)',
      subheading: {
        waitForOpponent: 'Wait for your opponent to respond',
        yourTurn: 'Are you ready to roll?',
        timeOutOpponent: 'Your opponent took to long to respond',
        timeOutPlayer: 'You took too long to respond',
        timeOutBoth: 'You both took too long to respond',
        wellDone: 'Well done, keep it up!',
        betterNextTime: 'Better luck next time!',
      },
    },
    topicPin: {
      title: 'Pin topic',
      pinenabled:
        'You have pinned {{- topiclabel}} and only see items associated with this topic. The items associated with this topic are repeated more often so that you can practice them. Click the pinned topic again to include questions relating to other topics.',
      pindisabled:
        'The topic is no longer pinned. From now on, you will see items for all topics again.',
      continue: 'Continue',
      dontshowagain: "Don't show again",
      selectTopics: 'Select topics',
      selectTopicsPractice: 'Select the topics you want to practice with.',
    },
    topicInfoHeader: 'Topics',
    challengingTextStarted: 'Continue where you left off',
  },
  login: {
    succesful: 'Login successful.',
  },
  menu: {
    menu: 'Menu',
    duel: 'Duel',
    duels: 'Duels',
    duelsNotAvailable: 'Duelling is not available for you yet.',
    duelsNotAvailableExplanation:
      'Do you want to play duels with colleagues? Request this with your {{appName}} administrator.',
    duelsNotEnabled:
      'Do you want to player duels with colleagues? Enable the option to duel below.',
    duelsNotEnabledDisclaimer:
      'Your photo and name will be visible for your colleagues.',
    enableDuelling: 'Enable duelling with colleagues!',
    newDuel: 'New duel',
    activeDuels: 'Active duels',
    duelVersus: 'VS',
    ranking: 'Ranking',
    completedDuels: 'Completed',
    rating: 'Duel rating',
    profile: 'Profile',
    autoDuelLabel: 'Automatic duels',
    anonymousDuelLabel: 'Hide my name when duelling',
    duellingEnabledLabel: 'Participate in duels',
    'help-and-settings': 'Help & Settings',
    challengeplayer: 'Challenge a colleague',
    pickplayer: 'Pick a colleague',
    randomplayer: "I'm feeling lucky",
    select: 'Select',
    settings: 'Settings',
    practice: 'Practice',
    duelWaiting: 'Looking for opponent...',
    quickDuelFailed: 'You already have pending invites',
    aboutDuels: {
      heading: 'What is a duel invite?',
      explanation1: 'You have been challenged to a duel.',
      explanation2:
        'A duel is a short knowledge against a colleague in 5 rounds. You win a round if you answer the question correctly more quickly than your colleague. If you win more rounds than your opponent, you win the duel, and move up in the rankings.',
      attention: 'Note:',
      explanation3: 'Play every round within 7 days!',
    },
    leaveAlert: {
      title: 'Are you sure?',
      text: '<p>Are you sure you want to stop practicing?</p><p>Your progress has been saved.</p>',
      confirm: 'Stop',
      continue: 'Continue practicing',
    },
    onboarding: 'overview',
    timeline: 'timeline',
  },
  ranking: {
    title: 'Duel ranking',
    subtitle:
      'This rating shows your duel rank in relation to your colleagues.',
    yourPosition: 'Your position ({{rank}})',
    topPositions: 'Top positions',
    loadMore: 'Load more',
    empty: 'There is no ranking yet. Challenge a colleague to a duel!',
    notRanked: 'Finish a duel and earn a spot in the rankings!',
  },
  searchFeedback: {
    title: 'Search colleague',
    oneResult: '{{amount}} colleague found.',
    searchMessage: '{{amount}} colleagues found.',
    overflowMessage:
      'Only the {{amount}} best matching search results are shown.',
  },
  searchDuel: {
    title: 'Search opponent',
    rankingposition: 'Ranking position {{rank}}',
    noduelpossible: 'Duel not possible',
    duelstillactive: 'Already an active duel',
    invitationsentondate: 'Invitation sent on {{date}}',
    searchPlaceholder: 'Search a name',
    emptyList: 'Unfortunately no-one is available. Try again later.',
    emptyListSearch: 'There are no search results.',
    randomOpponent:
      "We're looking for your next opponent. You can close this screen, we will let you know when your duel is ready.",
  },
  activeDuels: {
    title: 'Play an available duel.',
    subtitle: '{{num}}/{{max}} duels are active',
    emptyListNew: 'You do not have any duels yet. Start a new one!',
    emptyListCompleted:
      'You have finished all your duels, well done! Reward yourself with a cup of coffee.',
    status: {
      draw: 'Draw',
      expired: 'Expired',
      lost: 'Lost',
      invited: 'Invited',
      waiting: 'Waiting',
      won: 'Won',
      yourturn: 'Play',
    },
  },
  completedDuels: {
    title: 'View your completed duels.',
    empty: "You don't have any completed duels yet. Finish a duel first!",
  },
  error: {
    internetdown: 'Lost connection to the internet!',
    maintenance: {
      title: 'Maintenance',
      subtitle:
        "The PowerApp is currently being upgraded. We're back in a couple of minutes.",
    },
    versionConflict: {
      title: 'New version',
      text: 'There is a new version available of this application. To ensure everyone is running the newest version we will update you to the newest version now.',
      refresh: 'Go to the newest version',
    },
    refreshButton: 'Click to refresh',
    internetup: 'Connected to the internet.',
    timeoutajax: 'The server request has timed out.',
    404: 'Server not found (HTTP 404).',
    500: 'Internal server error (HTTP 500).',
    unknown: 'Unknown error',
    tryReconnect: 'Attempting to reconnect...',
    alreadyAnswered: 'Item already answered.',
    serverError:
      'An error has occurred when contacting the server. Click to try again, or reload the app if the issue persists.',
    retry: 'Try again',
    unavailableOffline: {
      title: 'No connection',
      subtitle: 'There is currently no connection',
      explanation:
        'An internet connection is required to use the app. There is no connection at this time. Check if you can reconnect to the internet or try again later.',
    },
  },
  common: {
    CheckAnswer: 'Check answer',
    CheckAnswers: 'Check answers',
    Emailaddress: 'Email address',
    Name: 'Name',
    Checking: 'Checking...',
    Answer: 'Save my answer',
    Answers: 'Save my answers',
    Close: 'Close',
    browsePrevious: 'Previous',
    browseNext: 'Next',
    Accept: 'Accept',
    'Log out': 'Log out',
    Help: 'Help',
    Hello: 'Hi',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    CorrectNotChosen: 'Would have been correct',
    Round: 'Round',
    Version: 'Version',
    selected: 'Selected',
    'No Rating': 'Not rated yet',
    'No Rank': 'No ranking',
    NoUserSelection: 'No users found',
    monthNames: 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec',
    or: 'or',
    on: 'on',
    off: 'off',
    back: 'Back',
    matchQuestions: {
      emptyCategoryPlaceholderText: 'Drop an answer here',
      itemDropCanceled: 'Item drag and drop canceled',
      notOverADraggableArea: 'You are currently not over a droppable area',
      itemLiftedInPositionX:
        'You have lifted the item in position {{sourceIndex}}',
      itemMovedToPositionX:
        'You have moved the item to position {{destinationIndex}}',
      itemMovedToOtherDroppableArea:
        'You have moved the item from position {{sourceIndex}} in list {{sourceArea}} to list {{destinationArea}} in position {{destinationIndex}}',
      startingDroppableAreaName: 'stack with answers',
    },
  },
  profile: {
    title: 'Profile',
    subtitle: 'Adjust your profile here.',
    pictureEdit: 'View or edit your picture.',
    'file-submit': 'Upload',
    'picture-submit': 'Use this crop',
    pictureCancel: 'Cancel',
    pictureConfirmDelete:
      'Are you sure you want to delete your profile picture?',
    'picture-nofileselected': 'You did not select a file.',
    'picture-nofile': 'There is no file.',
    'picture-invalidsize':
      'This image is too large. The maximum width or height cannot exceed 2600 pixels.',
    'picture-notimage': 'The file you selected does not appear to be an image.',
    passwordEdit: 'Change password',
    'picture-saved': 'Your profile picture is saved!',
    passwordTitle: 'Change password',
    passwordText:
      'Change your password by entering your current password and 2 times your new password. The new password should be at least 6 characters long.',
    'password-old': 'Current password',
    'password-new': 'New password',
    'password-repeat': 'Confirm new password',
    'password-submit': 'Save new password',
    'password-oldpasswordmismatch': 'Your current password was incorrect',
    'password-newpasswordmismatch': 'The confirmed password did not match.',
    'password-passwordtooshort':
      'The new password should be at least 6 characters long.',
    'password-passwordsaved': 'Your password is saved!',
    profilePictureTitle: 'Profile picture',
    profilePictureText: 'This photo is visible for your colleagues.',
    generalDataTitle: 'Personal information',
    generalDataText: 'You cannot change all personal data by yourself.',
    profilePageTitle: 'Manage your profile.',
    noEditDataText: 'You cannot change this data.',
    profileLanguagePreference: 'Language preference',
    profileLanguageChoice: 'Language choice',
    notificationsTitle: 'Notifications',
    emailNotifications: 'Email notifications',
    pushNotifications: 'Push notifications',
  },
  auth: {
    header: {
      login: 'Log in',
      expired: 'Link expired',
      remind: "Can't remember your password?",
      create: 'Activate your account',
      reset: 'Update your password',
      welcome: 'Welcome!',
    },
    field: {
      email: 'Email address',
      password: 'Password',
      oldPassword: 'Current password',
      newPassword: 'New password',
      newRepeatPassword: 'Repeat new password',
      acceptPrivacyPolicy: 'I accept the <2>privacy policy</2>.',
      viewPrivacyPolicy: 'View the privacy policy.',
      rememberMe: 'Remember me',
    },
    action: {
      itemcompleted: "You've completed an item",
      updateprofile: 'Update profile',
      sendLink: 'Send me a password reset link',
      backToLogin: 'I want to log in',
      sendActivateLink: 'Activate account',
      alreadyActivatedLogin:
        'I have already activated the account and want to log in',
      savePassword: 'Save my new password',
      newPassword: 'Your new password',
      repeatNewPassword: 'Repeat your new password',
      newPasswordPolicyIntro: 'Password strength:',
    },
    selfActivate: {
      header: 'Activate your account',
    },
    logintext: 'Log in',
    remindText:
      "No problem. We'll send you an email so you can set a new password.",
    resetText: 'You can set your new password here.',
    createText:
      "Welcome! Please create a new password and then you're ready to log in.",
    passwords: {
      user: "Sorry, We can't find a user with that e-mail address.",
      token: 'This password reset token is invalid.',
      sent: 'An email with a reset-link has been sent.',
      reset: 'Password has been reset!',
      forgot: "I don't know my password",
      passwordExpiredTitle: 'Password expired',
      submitPasswordExpiredForm: 'Save new password',
      passwordExpiredText:
        'Your password is older than {{days}} days and is expired. You will need to set a new password using the form below, after that you can continue learning in the PowerApp',
      passwordCompleteSsoTitle: 'Complete your account',
      passwordCompleteSsoText:
        'Set a password for your {{app}} account. You will need to log in with your email address and this password when logging in via {{idpText}} is not available.',
    },
  },
  faq: {
    header: 'Frequently asked questions',
    q1: 'The PowerApp is a micro learning app, what does that mean?',
    a1: 'Micro learning means that it is more efficient to learn in short sessions if your aim is to retain more knowledge over time.',
    q2: 'Why is my score sometimes lower than I expected?',
    a2: 'Your score decreases because over time you forget what you have learned.',
    q3: 'When does my score decrease?',
    a3: 'When you have answered questions incorrectly or are inactive for a while.',
    q4: 'Why do the same items repeat?',
    a4: 'Because spaced repetition is the most effective way to learn.',
    q5: 'What is a duel?',
    a5: 'A 5 question knowledge quiz in which you compete against a colleague.',
    q6: 'Why do I have to wait for my turn when playing a duel?',
    a6: 'You have to wait for your colleague to finish his or her turn',
    q7: 'Where can I find more information and answers to my questions?',
    a7: 'The button below will take you to our website.',
    toTheFaq: 'To the FAQ',
    toTheWebsite: 'To the website',
    onBoarding: {
      q1: 'What do I use the OnFire app for?',
      a1: 'As a convenient collection point for all information and tasks.',
      q2: 'Why do I have to wait for new information?',
      a2: "Not everything is relevant now, so we won't overwhelm you.",
      q3: 'Where can I find more information?',
      a3: 'The button below will take you to our website.',
    },
  },
}

export default translations

export type Translations = typeof translations

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NotificationType = "NO_CONTENT" | "UP_TO_DATE";
export type NotificationView_item = {
    readonly label: string;
    readonly message: string;
    readonly notificationType: NotificationType;
    readonly " $refType": "NotificationView_item";
};
export type NotificationView_item$data = NotificationView_item;
export type NotificationView_item$key = {
    readonly " $data"?: NotificationView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NotificationView_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationView_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notificationType",
            "storageKey": null
        }
    ],
    "type": "NotificationStreamItem",
    "abstractKey": null
} as any;
(node as any).hash = '2ad0a307db89bbd30ef17effaa342432';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MatchView_feedback = ({
    readonly __typename: "MatchQuestionCompletionData";
    readonly correct: boolean | null;
    readonly answerCategories: ReadonlyArray<{
        readonly category: {
            readonly id: string;
        };
        readonly answers: ReadonlyArray<{
            readonly id: string;
        }>;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"useAnswerCategories_feedback">;
} | {
    readonly __typename: "BrainSnackCompletionData" | "GenericQuestionCompletionData" | "PollQuestionCompletionData" | "SliderQuestionCompletionData";
}) & {
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_feedback">;
    readonly " $refType": "MatchView_feedback";
};
export type MatchView_feedback$data = MatchView_feedback;
export type MatchView_feedback$key = {
    readonly " $data"?: MatchView_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MatchView_feedback">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AnswerCategory",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": (v0 /*: any*/),
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "concreteType": "AnswerOption",
            "kind": "LinkedField",
            "name": "answers",
            "plural": true,
            "selections": (v0 /*: any*/),
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MatchView_feedback",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_feedback"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "correct",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerCategoryCompletionData",
                        "kind": "LinkedField",
                        "name": "answerCategories",
                        "plural": true,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "kind": "InlineDataFragmentSpread",
                        "name": "useAnswerCategories_feedback",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AnswerCategoryCompletionData",
                                "kind": "LinkedField",
                                "name": "userAnswers",
                                "plural": true,
                                "selections": (v1 /*: any*/),
                                "storageKey": null
                            }
                        ]
                    }
                ],
                "type": "MatchQuestionCompletionData",
                "abstractKey": null
            }
        ],
        "type": "BrainItemCompletionData",
        "abstractKey": "__isBrainItemCompletionData"
    } as any;
})();
(node as any).hash = '20c6282feb9c210e6e29a6e6eb115365';
export default node;

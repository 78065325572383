/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TimeLineFavoriteMutationVariables = {
    id: string;
    favorited: boolean;
};
export type TimeLineFavoriteMutationResponse = {
    readonly favoriteOnboardingItem: {
        readonly id: string;
        readonly favorited: boolean;
    };
};
export type TimeLineFavoriteMutationRawResponse = {
    readonly favoriteOnboardingItem: {
        readonly id: string;
        readonly favorited: boolean;
    };
};
export type TimeLineFavoriteMutation = {
    readonly response: TimeLineFavoriteMutationResponse;
    readonly variables: TimeLineFavoriteMutationVariables;
    readonly rawResponse: TimeLineFavoriteMutationRawResponse;
};



/*
mutation TimeLineFavoriteMutation(
  $id: ID!
  $favorited: Boolean!
) {
  favoriteOnboardingItem(id: $id, favorite: $favorited) {
    id
    favorited
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "favorited"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "favorite",
                    "variableName": "favorited"
                },
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "OnboardingItem",
            "kind": "LinkedField",
            "name": "favoriteOnboardingItem",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "favorited",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeLineFavoriteMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "TimeLineFavoriteMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "ca4f74ed634b2662bf34aaad1209b2b7",
            "id": null,
            "metadata": {},
            "name": "TimeLineFavoriteMutation",
            "operationKind": "mutation",
            "text": "mutation TimeLineFavoriteMutation(\n  $id: ID!\n  $favorited: Boolean!\n) {\n  favoriteOnboardingItem(id: $id, favorite: $favorited) {\n    id\n    favorited\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '954620ea704da4547b0a4592cc21c7c6';
export default node;

import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faSync } from '@fortawesome/pro-regular-svg-icons/faSync'
import { faTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from 'relay-hooks'
import { useEnvironment } from '../../App'

import { Header } from '../../containers/Header'
import { Page } from '../../containers/Page'
import { MainMenuChangeLanguageMutation } from '../../generated/MainMenuChangeLanguageMutation.graphql'
import { MainMenuChangePasswordMutation } from '../../generated/MainMenuChangePasswordMutation.graphql'
import { MainMenuToggleDuelPreferenceMutation } from '../../generated/MainMenuToggleDuelPreferenceMutation.graphql'
import { MainMenuToggleNotificationsMutation } from '../../generated/MainMenuToggleNotificationsMutation.graphql'
import { MainMenuUserQuery } from '../../generated/MainMenuUserQuery.graphql'

import { useStores } from '../../stores'
import { Severity } from '../../stores/authStore'
import { classNames } from '../../utils/classNames'
import {
  Language,
  WithLanguages,
  withLanguages,
} from '../../utils/hocs/withLanguages'
import { AuthAlert } from '../auth/AuthAlert'
import { Avatar, AvatarContext } from '../common/Avatar'
import { EditableAvatar } from '../common/EditableAvatar'
import { FaqItem } from '../common/FaqItem'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { PasswordInput } from '../common/PasswordInput'
import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'
import { SelectSingle } from '../common/SelectSingle'
import { MainMenuItem } from '../profile/MainMenuItem'

import styles from './MainMenu.scss'
import { OnlineMenu } from '../../containers/OnlineMenu'

export const MainMenu = withLanguages(
  observer(function MainMenu(
    props: RouteComponentProps & WithLanguages
  ): ReactElement {
    const { t, i18n } = useTranslation()
    const { authStore, commonStore } = useStores()
    const environment = useEnvironment()

    const { isLoading, ...user } = useQuery<MainMenuUserQuery>(graphql`
      query MainMenuUserQuery {
        me {
          companyUserId
          companyUserIdString
          email
          emailNotificationsEnabled
          firstName
          fullName
          hasAutoDuellingEnabled
          hasDuellingAvailable
          hasDuellingEnabled
          hasAnonymousDuellingEnabled
          id
          isOnboarding
          loginType
          profileImage
          pushNotificationsEnabled
        }
      }
    `)

    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [canSubmitPassword, setCanSubmitPassword] = useState(false)
    const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(
      user.data?.me?.pushNotificationsEnabled || false
    )
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
      user.data?.me?.emailNotificationsEnabled || false
    )

    const [changePassword] =
      useMutation<MainMenuChangePasswordMutation>(graphql`
        mutation MainMenuChangePasswordMutation(
          $oldPassword: String!
          $password: String!
          $passwordConfirmation: String!
        ) {
          changePassword(
            oldPassword: $oldPassword
            password: $password
            passwordConfirmation: $passwordConfirmation
          ) {
            message
            success
          }
        }
      `)

    const [changeLanguage] =
      useMutation<MainMenuChangeLanguageMutation>(graphql`
        mutation MainMenuChangeLanguageMutation($language: String!) {
          changeLanguage(language: $language) {
            __typename
          }
        }
      `)

    const [toggleNotifications] =
      useMutation<MainMenuToggleNotificationsMutation>(graphql`
        mutation MainMenuToggleNotificationsMutation(
          $type: AppNotificationType!
          $enabled: Boolean!
        ) @raw_response_type {
          toggleNotifications(type: $type, enabled: $enabled) {
            id
            emailNotificationsEnabled
            pushNotificationsEnabled
          }
        }
      `)

    const [toggleDuelPreference] =
      useMutation<MainMenuToggleDuelPreferenceMutation>(graphql`
        mutation MainMenuToggleDuelPreferenceMutation(
          $preference: DuelPreference!
          $enabled: Boolean!
        ) @raw_response_type {
          toggleDuelPreference(preference: $preference, enabled: $enabled) {
            id
            hasDuellingEnabled
            hasAutoDuellingEnabled
            hasAnonymousDuellingEnabled
          }
        }
      `)

    const setLanguage = useCallback(
      (language: Language): void => {
        i18n.changeLanguage(language.code).then(() => {
          commonStore.setLanguage(language.code)

          // Save to the server, and reload the page afterwards.
          changeLanguage({ variables: { language: language.code } }).catch() // Now what
        })
      },
      [changeLanguage, commonStore, i18n]
    )

    const saveAutoDuelSetting = useCallback(
      (enabled: boolean) => {
        toggleDuelPreference({
          optimisticResponse: {
            toggleDuelPreference: {
              hasAutoDuellingEnabled: enabled,
              hasAnonymousDuellingEnabled:
                user.data?.me?.hasAnonymousDuellingEnabled || false,
              hasDuellingEnabled: user.data?.me?.hasDuellingEnabled || true,
              id: user.data?.me?.id || '',
            },
          },
          variables: { preference: 'AUTO_DUELLING', enabled },
        }).catch((error) => {
          // TODO: Need to handle this error.
          console.log(error)
        })
      },
      [
        toggleDuelPreference,
        user.data?.me?.hasAnonymousDuellingEnabled,
        user.data?.me?.hasDuellingEnabled,
        user.data?.me?.id,
      ]
    )
    const saveDuellingEnabledSetting = useCallback(
      (enabled: boolean) => {
        toggleDuelPreference({
          optimisticResponse: {
            toggleDuelPreference: {
              hasAutoDuellingEnabled:
                user.data?.me?.hasAutoDuellingEnabled || false,
              hasAnonymousDuellingEnabled:
                user.data?.me?.hasAnonymousDuellingEnabled || false,
              hasDuellingEnabled: enabled,
              id: user.data?.me?.id || '',
            },
          },
          variables: { preference: 'DUELLING_ENABLED', enabled },
        }).catch((error) => {
          // TODO: Need to handle this error.
          console.log(error)
        })
      },
      [
        toggleDuelPreference,
        user.data?.me?.hasAnonymousDuellingEnabled,
        user.data?.me?.hasAutoDuellingEnabled,
        user.data?.me?.id,
      ]
    )
    const saveAnonymousDuellingSetting = useCallback(
      (enabled: boolean) => {
        toggleDuelPreference({
          optimisticResponse: {
            toggleDuelPreference: {
              hasAnonymousDuellingEnabled: enabled,
              hasAutoDuellingEnabled:
                user.data?.me?.hasAutoDuellingEnabled || false,
              hasDuellingEnabled: user.data?.me?.hasDuellingEnabled || true,
              id: user.data?.me?.id || '',
            },
          },
          variables: { preference: 'ANONYMOUS_DUELLING', enabled },
        }).catch((error) => {
          // TODO: Need to handle this error.
          console.log(error)
        })
      },
      [
        toggleDuelPreference,
        user.data?.me?.hasAutoDuellingEnabled,
        user.data?.me?.hasDuellingEnabled,
        user.data?.me?.id,
      ]
    )
    const savePushNotificationsEnabled = useCallback(
      (enabled: boolean) => {
        const oldValue = pushNotificationsEnabled
        setPushNotificationsEnabled(enabled)

        toggleNotifications({
          optimisticResponse: {
            toggleNotifications: {
              emailNotificationsEnabled,
              id: user.data?.me?.id || '',
              pushNotificationsEnabled: enabled,
            },
          },
          variables: { type: 'PUSH_NOTIFICATIONS', enabled },
        })
          .then((response) => {
            // Set it again in case the user is spamming the toggle.
            setPushNotificationsEnabled(
              response.toggleNotifications.pushNotificationsEnabled
            )
          })
          .catch((error) => {
            // TODO: Need to handle this error.
            setPushNotificationsEnabled(oldValue)
            console.error(error)
          })
      },
      [
        emailNotificationsEnabled,
        pushNotificationsEnabled,
        toggleNotifications,
        user.data?.me?.id,
      ]
    )

    const saveEmailNotificationsEnabled = useCallback(
      (enabled: boolean) => {
        const oldValue = emailNotificationsEnabled
        setEmailNotificationsEnabled(enabled)

        toggleNotifications({
          optimisticResponse: {
            toggleNotifications: {
              emailNotificationsEnabled: enabled,
              id: user.data?.me?.id || '',
              pushNotificationsEnabled,
            },
          },
          variables: { type: 'EMAIL', enabled },
        })
          .then((response) => {
            // Set it again in case the user is spamming the toggle.
            setEmailNotificationsEnabled(
              response.toggleNotifications.emailNotificationsEnabled
            )
          })
          .catch((error) => {
            // TODO: Need to handle this error.
            setEmailNotificationsEnabled(oldValue)
            console.error(error)
          })
      },
      [
        emailNotificationsEnabled,
        pushNotificationsEnabled,
        toggleNotifications,
        user.data?.me?.id,
      ]
    )

    const submitNewPassword = useCallback(
      (event: SyntheticEvent): void => {
        event.preventDefault()
        if (!canSubmitPassword) {
          return
        }

        changePassword({
          variables: {
            oldPassword,
            password,
            passwordConfirmation,
          },
        }).then((response) => {
          authStore.setAuthMessage(
            response.changePassword.message,
            response.changePassword.success ? Severity.SUCCESS : Severity.ERROR
          )
        })
      },
      [
        authStore,
        canSubmitPassword,
        changePassword,
        oldPassword,
        password,
        passwordConfirmation,
      ]
    )

    const supportUrl = user.data?.me?.isOnboarding
      ? environment.onFireSupportUrl
      : environment.supportUrl
    const { authMessage, authMessageSeverity } = authStore

    const faqItems = user.data?.me?.isOnboarding ? [1, 2, 3] : [1, 2, 5]
    const faqLanguageKey = user.data?.me?.isOnboarding
      ? 'faq.onBoarding'
      : 'faq'

    useEffect(() => {
      if (!isLoading && user.data?.me) {
        setEmailNotificationsEnabled(user.data.me.emailNotificationsEnabled)
        setPushNotificationsEnabled(user.data.me.pushNotificationsEnabled)
      }
    }, [isLoading, user.data?.me])

    return (
      <>
        <Header wide>
          <div className={styles.header}>
            <div className={styles.welcome}>
              <div className={styles.welcomeName}>
                {t('header.welcome-message', {
                  name: user.data?.me?.firstName || '',
                })}
              </div>
              <div className={styles.welcomeInstruction}>
                {t('profile.subtitle')}
              </div>
            </div>
            <EditableAvatar>
              {user.data?.me && (
                <Avatar
                  filename={user.data.me.profileImage}
                  name={user.data.me.fullName}
                  userId={user.data.me.id}
                  context={AvatarContext.default}
                />
              )}

              <div className={styles.avatarAttachment}>
                <FontAwesomeIcon icon={faCamera} />
              </div>
            </EditableAvatar>
          </div>
        </Header>
        <OnlineMenu>
          <Page narrow>
            <p className={styles.instruction}>{t('profile.generalDataText')}</p>
            <div className={styles.menu}>
              <MainMenuItem
                type='popup'
                icon={faUser}
                title={t('profile.generalDataTitle')}
              >
                <div className={styles.popupList}>
                  <div className={styles.personal}>
                    <b>{t('common.Name')}</b>
                    <br />
                    {user.data?.me?.fullName}
                  </div>
                  <div className={styles.personal}>
                    <b>{user.data?.me?.companyUserIdString}</b>
                    <br />
                    {user.data?.me?.companyUserId}
                  </div>
                  <div className={styles.personal}>
                    <b>{t('common.Emailaddress')}</b>
                    <br />
                    {user.data?.me?.email}
                  </div>
                </div>
              </MainMenuItem>

              <SelectSingle
                className={styles.languageSelector}
                itemClass={styles.languageSelectList}
                icon={faGlobe}
                title={t('profile.language_' + i18n.language)}
                items={props.languages}
                onSelect={setLanguage}
                renderItem={(language, onSelect) => (
                  <li
                    className={classNames({
                      [styles.selected]: language.code === i18n.language,
                    })}
                    key={language.code}
                    onClick={() => onSelect(language)}
                    onKeyPress={(event) =>
                      event.key === 'Enter' && onSelect(language)
                    }
                    tabIndex={0}
                  >
                    {language.name}
                  </li>
                )}
              />

              {user.data?.me?.loginType !== 'SAML' && (
                <MainMenuItem
                  type='popup'
                  icon={faLock}
                  title={t('profile.passwordTitle')}
                >
                  <div className={styles.popupList}>
                    <AuthAlert />

                    {!authMessage ||
                    (authMessage &&
                      authMessageSeverity !== Severity.SUCCESS) ? (
                      <form
                        action='#'
                        method='post'
                        onSubmit={submitNewPassword}
                      >
                        <input
                          autoComplete='username'
                          readOnly
                          style={{ display: 'none' }}
                          type='text'
                          value={user.data?.me?.email}
                        />

                        <PasswordInput
                          email={user.data?.me?.email}
                          withOldPassword={true}
                          onOldPasswordChanged={setOldPassword}
                          onPasswordChanged={setPassword}
                          onPasswordConfirmationChanged={
                            setPasswordConfirmation
                          }
                          onPasswordsValidated={setCanSubmitPassword}
                        />

                        <div className={styles.textCenter}>
                          <PrimaryButton
                            type='submit'
                            id='submitPassword'
                            disabled={!canSubmitPassword}
                          >
                            {t('auth.action.savePassword')}
                          </PrimaryButton>
                        </div>
                      </form>
                    ) : (
                      ''
                    )}
                  </div>
                </MainMenuItem>
              )}

              <MainMenuItem
                type='popup'
                icon={faQuestionCircle}
                title={t('faq.header')}
              >
                <div className={styles.popupList}>
                  {faqItems.map((i) => (
                    <FaqItem
                      key={i}
                      q={t(faqLanguageKey + '.q' + i)}
                      a={t(faqLanguageKey + '.a' + i)}
                    />
                  ))}

                  <div className={styles.button}>
                    <SecondaryButton
                      component='a'
                      href={supportUrl}
                      tabIndex={0}
                      target='_blank'
                    >
                      {t('faq.toTheFaq')}
                    </SecondaryButton>
                  </div>
                </div>
              </MainMenuItem>
            </div>

            {isLoading && <LoadingIndicator />}

            {!isLoading && (
              <>
                <h2 className={styles.menuHeader}>
                  {t('profile.notificationsTitle')}
                </h2>
                <div className={styles.menu}>
                  <MainMenuItem
                    currentValue={pushNotificationsEnabled}
                    handleChange={savePushNotificationsEnabled}
                    icon={faBell}
                    title={t('profile.pushNotifications')}
                    type='checkbox'
                  />
                  <MainMenuItem
                    currentValue={emailNotificationsEnabled}
                    handleChange={saveEmailNotificationsEnabled}
                    icon={faEnvelope}
                    title={t('profile.emailNotifications')}
                    type='checkbox'
                  />
                </div>
              </>
            )}
            {user.data?.me && !user.data?.me.isOnboarding && (
              <>
                <h2 className={styles.menuHeader}>{t('menu.duels')}</h2>
                <div className={styles.menu}>
                  {user.data?.me?.hasDuellingAvailable ? (
                    <>
                      <MainMenuItem
                        currentValue={user.data.me.hasDuellingEnabled}
                        handleChange={saveDuellingEnabledSetting}
                        icon={faTrophy}
                        title={t('menu.duellingEnabledLabel')}
                        type='checkbox'
                      />

                      {environment.anonymousDuelling === 'USER' && (
                        <MainMenuItem
                          currentValue={
                            user.data.me.hasAnonymousDuellingEnabled
                          }
                          disabled={!user.data.me.hasDuellingEnabled}
                          handleChange={saveAnonymousDuellingSetting}
                          icon={faLock}
                          title={t('menu.anonymousDuelLabel')}
                          type='checkbox'
                        />
                      )}

                      <MainMenuItem
                        currentValue={user.data.me.hasAutoDuellingEnabled}
                        disabled={!user.data.me.hasDuellingEnabled}
                        handleChange={saveAutoDuelSetting}
                        icon={faSync}
                        title={t('menu.autoDuelLabel')}
                        type='checkbox'
                      />
                    </>
                  ) : (
                    <p>
                      {t('menu.duelsNotAvailableExplanation', {
                        appName: environment.pageTitle,
                      })}
                    </p>
                  )}
                </div>
              </>
            )}

            <div className={styles.button}>
              <SecondaryButton component={Link} to='/logout' tabIndex={0}>
                {t('common.Log out')}
              </SecondaryButton>
            </div>
          </Page>
        </OnlineMenu>
      </>
    )
  })
)

import { action, IObservableArray, makeObservable, observable } from 'mobx'

import { WebService } from '../services/WebService'

export class LearnStore {
  @observable
  public selectedTopicCategoryIndex: number

  @observable
  public topicPin: IObservableArray<string>

  public webservice: WebService

  public constructor(webservice: WebService) {
    this.webservice = webservice

    this.selectedTopicCategoryIndex = 0
    this.topicPin = observable([])

    makeObservable(this)
  }

  @action
  public setTopicPin(topic: string[]): void {
    this.topicPin = observable(topic)
  }

  @action
  public pinTopic = (selectedTopic: string): void => {
    if (this.topicPin.indexOf(selectedTopic) < 0) {
      this.topicPin.push(selectedTopic)
    }
  }

  @action
  public unpinTopic = (selectedTopic: string): void => {
    const pinned = this.topicPin.find((topic) => topic === selectedTopic)
    if (pinned) {
      this.topicPin.remove(pinned)
    }
  }

  @action
  public initializeTopicPin(scoreInfo: string[]): void {
    this.topicPin = observable(
      this.topicPin.filter((pinnedTopic) =>
        scoreInfo.find((topic) => topic === pinnedTopic)
      )
    )

    if (this.topicPin.length === 0) {
      this.topicPin = observable(scoreInfo)
    }
  }

  @action
  public setTopicCategory(topicCategory: number): void {
    this.selectedTopicCategoryIndex = topicCategory
  }
}

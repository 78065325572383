/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelRequestStatus = "INVITED" | "WAITING";
export type ActiveDuelSlot_request = {
    readonly opponent: {
        readonly rating: number;
        readonly user: {
            readonly id: string;
            readonly nameForDuelling: string;
            readonly profileImageForDuelling: string | null;
            readonly hasAnonymousDuellingEnabled: boolean;
        };
    } | null;
    readonly status: DuelRequestStatus;
    readonly time: string;
    readonly " $refType": "ActiveDuelSlot_request";
};
export type ActiveDuelSlot_request$data = ActiveDuelSlot_request;
export type ActiveDuelSlot_request$key = {
    readonly " $data"?: ActiveDuelSlot_request$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_request">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveDuelSlot_request",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "opponent",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rating",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasAnonymousDuellingEnabled",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
        }
    ],
    "type": "DuelRequest",
    "abstractKey": null
} as any;
(node as any).hash = '02a2fafb3b071955bdc6503ed70e9db0';
export default node;

const translations = {
  popup: {
    confirm: {
      delete: 'Eliminar',
      keep: 'Cancelar',
      cancelDuelInviteTitle: 'Eliminar?',
      cancelDuelInviteMessage: 'Você tem certeza?',
    },
  },
  header: {
    'welcome-message': 'Olá, {{name}}!',
    welcome: 'Bem-vindo.',
    welcomeBack: 'Bem-vindo de volta.',
  },
  learn: {
    instruction: 'Selecione os tópicos que você deseja jogar.',
    practiceSelection: 'Pratique essa seleção',
    selectAll: 'Selecionar tudo',
    deselectAll: 'Remover seleção',
    wellDone: 'Parabéns!',
  },
  duels: {
    title: 'Avaliação do desafio: {{score}}',
    readyToDuel: 'Pronto para o desafio?',
    roundsOverview: 'Visão geral das rodadas de desafio',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Página anterior',
      doneText: 'Brainsnack concluído',
      browseNext: 'Próxima página',
    },
    brainItem: {
      correct: 'Correta',
      incorrect: 'Incorreta',
      completed: 'Você respondeu uma pergunta',
      doneText: 'Pronto',
      openItem: 'Início',
      closeItem: 'Fechar',
      topics: 'Tópicos',
      nextItem: 'Continuar praticando',
      showProgress: 'Mostre o seu progresso',
    },
    order: {
      questionTitle: 'Ordem',
      helpText: 'Arraste as respostas na ordem correta',
      correct: '{{num}} de {{max}} correto',
    },
    match: {
      questionTitle: 'Correspondência',
      showingCorrectAnswer: 'Ver as respostas corretas.',
      helpText: 'Arraste as respostas para o local correto',
      correct: '{{num}} de {{max}} correto',
      showCorrectAnswer: 'Mostrar resposta correta',
      showMyAnswer: 'Mostrar minha resposta',
    },
    slider: {
      doneText: 'Feito',
      questionTitle: 'Controle deslizante',
      helpText: 'Arraste o controle deslizante para a posição correta',
    },
    poll: {
      questionTitle: 'Pesquisa',
      resultinfo: 'This is what others have answered',
      doneText: 'Pesquisa concluída',
      helpText: 'Escolha uma resposta',
    },
    notification: {
      doneText: 'Notificação lida',
      title: 'Notificação',
      pauze: 'Posso continuar?',
      continueanyway: 'Continuar mesmo assim',
      continueLearningText:
        'Você está praticando com perguntas adicionais. Como você já está atualizado, receberá menos pontos por essas perguntas.',
      panelbuttonlabel: 'Parar a prática adicional',
    },
    multipleselect: {
      correct: '{{num}} de {{max}} corretas',
      incorrect: ', {{incorrect}} incoretta(s)',
      doneText: 'Pergunta concluída',
      questionTitle: 'Pergunta',
      helpText: 'Escolha uma ou mais respostas',
    },
    multiplechoice: {
      doneText: 'Pergunta concluída',
      questionTitle: 'Pergunta',
      helpText: 'Escolha uma resposta',
    },
    duelItem: {
      duel: 'Desafio',
      draw: 'Empate',
      invite: 'Solicitar desafio',
      acceptDuel: 'Aceitar desafio',
      declineDuel: 'Rejeitar desafio',
      yourTurn: 'Sua vez',
      opponentTurn: 'A vez do oponente',
      youWon: 'Você ganhou!',
      youLost: 'Você perdeu',
      cancelled: 'Desafio cancelado',
      closeItem: 'Fechar',
      showDetails: 'Detalhes da rodada',
      playItem: 'Rodada {{roundnumber}}',
      duelAccepted: 'Você aceitou o desafio de {{name}}.',
      duelDeclined: 'Você rejeitou o desafio de {{name}}.',
      duelStarted: 'Que comece o desafio com  {{name}}. Boa sorte!',
      duelInfoInvitation: 'Você foi desafiado.',
      duelInfoMain:
        'Uma curta batalha de conhecimento em 5 rodadas. Vença ao responder rápido e corretamente. Ganhe o maior número de rodadas, vença o desafio e aumente sua classificação. Observe que há um limite de 72 horas para jogar uma partida.',
      viewInvitation: 'Ver convite',
      versusLabel: 'vs',
      started: 'Iniciar!',
      doneText: 'Desafio concluído',
      tooLate: 'Tempo esgotado!',
      practiceWhileWaiting: 'Pratique enquanto espera',
      practice: 'Continue praticando',
      subheading: {
        waitForOpponent: 'Aguarde o seu oponente responder',
        yourTurn: 'Você está pronto?',
        timeOutOpponent: 'Seu oponente levou muito tempo para responder',
        timeOutPlayer: 'Você demorou muito para responder',
        timeOutBoth: 'Vocês dois demoraram muito para responder',
        wellDone: 'Muito bem! Continue assim!',
        betterNextTime: 'Mais sorte da próxima vez',
      },
      roundNumber: 'Rodada {{number}}',
      duelRequest: 'Novo desafio!',
      duelRequestText: '{{name}} desafiou você para um desafio',
      tooLateTurn: 'Tarde demais',
    },
    topicPin: {
      title: 'Salvar tópico',
      pinenabled:
        'Você salvou {{- topiclabel}} e só está vendo itens associados a esse tópico. Os itens associados a esse tópico são repetidos com mais frequência para que você possa praticá-los. Clique no tópico salvo novamente para incluir perguntas relacionadas a outros tópicos.',
      pindisabled:
        'O tópico não está mais salvo. A partir de agora, você verá itens de todos os tópicos novamente.',
      continue: 'Continuar',
      dontshowagain: 'Não mostrar novamente',
      selectTopics: 'Selecionar tópicos',
      selectTopicsPractice:
        'Selecione os tópicos com os quais você deseja praticar.',
    },
    topicInfoHeader: 'Tópicos',
  },
  login: {
    succesful: 'Acesso efetuado.',
  },
  menu: {
    menu: 'Menu',
    duel: 'Desafio',
    duels: 'Desafios',
    newDuel: 'Novo desafio',
    activeDuels: 'Desafios ativos',
    duelVersus: 'VS',
    ranking: 'Classificação do desafio',
    rating: 'Avaliação do desafio',
    profile: 'Perfil',
    autoDuelLabel: 'Desafios automáticos',
    'help-and-settings': 'Ajuda e configuração',
    challengeplayer: 'Desafie um colega',
    pickplayer: 'Escolha um colega',
    randomplayer: 'Estou com sorte',
    duelWaiting: 'Procurando adversário...',
    quickDuelFailed: 'Você já tem convites pendentes',
    duelsNotAvailable: 'O desafio ainda não está disponível para você.',
    duelsNotAvailableExplanation:
      'Deseja jogar desafios com colegas? Solicite isso ao seu supervisor.',
    duelsNotEnabled:
      'Deseja jogar desafios com colegas? Habilite a opção de desafio abaixo.',
    duelsNotEnabledDisclaimer:
      'Sua foto e nome ficarão visíveis para seus colegas.',
    enableDuelling: 'Habilite o desafio com colegas!',
    duellingEnabledLabel: 'Participar de desafios',
    completedDuels: 'Concluído',
    select: 'Selecionar',
    settings: 'Configurações',
    practice: 'Praticar',
    aboutDuels: {
      heading: 'O que é um convite para desafio?',
      explanation1: 'Você foi desafiado.',
      explanation2:
        'Um duelo é um questionário de conhecimento breve contra um colega em 5 rodadas. Você vence uma rodada se responder à pergunta corretamente mais rapidamente que seu colega. Se vencer mais rodadas que seu oponente, você vence o desafio e sobe na classificação.',
      attention: 'Observação:',
      explanation3: 'Jogue cada rodada em 7 dias!',
    },
    leaveAlert: {
      title: 'Você tem certeza?',
      text: '<p>Tem certeza de que deseja parar de praticar?</p><p>Seu progresso foi salvo.</p>',
      confirm: 'Parar',
      continue: 'Continuar praticando',
    },
  },
  ranking: {
    title: 'Classificação do desafio',
    subtitle:
      'Essa lista mostra sua classificação em relação aos seus colegas.',
    loadMore: 'Carregar mais',
    yourPosition: 'Sua posição ({{rank}})',
    topPositions: 'Primeiras posições',
    empty: 'Não há classificações, comece um novo desafio!',
    notRanked: 'Conclua um duelo para colocar na classificação!',
  },
  searchFeedback: {
    title: 'Procurar colega',
    oneResult: '{{amount}} colega encontrado.',
    searchMessage: '{{amount}} colegas encontrados.',
    overflowMessage:
      'Somente os {{amount}} melhores resultados de pesquisa são exibidos.',
  },
  searchDuel: {
    title: 'Procurar adversário',
    rankingposition: 'Ordem classificatória {{rank}}',
    noduelpossible: 'Não é possível realizar o desafio',
    duelstillactive: 'Desafio já em andamento',
    invitationsentondate: 'Convite enviado em {{date}}',
    searchPlaceholder: 'Procurar nome',
    randomOpponent:
      'Estamos procurando seu próximo oponente. Pode fechar esta tela, informaremos quando seu desafio estiver pronto.',
    emptyListSearch: 'Não há resultados de pesquisa. ',
    emptyList:
      'Infelizmente, não há ninguém disponível.<br>Tente novamente mais tarde. ',
  },
  activeDuels: {
    title: 'Jogar um desafio disponível.',
    subtitle: '{{num}}/{{max}} desafios estão ativos',
    status: {
      draw: 'Empate',
      lost: 'Perdeu',
      won: 'Venceu',
      expired: 'Expirado',
      invited: 'Convidado',
      waiting: 'Aguardando',
      yourturn: 'Jogar',
    },
    emptyListNew: 'Não há desafios, comece um novo desafio!',
    emptyListCompleted:
      'Todos os duelos concluídos, parabéns!<br>Tome um xícara de café como recompensa. ',
  },
  completedDuels: {
    title: 'Ver seus desafios concluídos.',
    empty: 'Você ainda não terminou nenhum desafio.<br>Comece um novo desafio!',
  },
  error: {
    internetdown: 'Perdida a conexão com a internet!',
    versionConflict: {
      title: 'Nova versão',
      text: 'Há uma nova versão deste aplicativo. Para garantir que todos usem a versão mais recente, atualizaremos você para a versão mais recente agora. ',
      refresh: 'Ir para a nova versão ',
    },
    refreshButton: 'Clique para atualizar',
    internetup: 'Conectado à internet.',
    timeoutajax: 'Esgotado o tempo de conexão com o servidor.',
    404: 'Servidor não encontrado (HTTP 404).',
    500: 'Erro interno do servidor (HTTP 500).',
    unknown: 'Erro desconhecido',
    tryReconnect: 'Tentativa de reconexão…',
    alreadyAnswered: 'Item já respondido.',
    unavailableOffline: {
      title: 'Sem conexão',
      subtitle: 'Não há conexão no momento',
      explanation:
        'É necessária uma conexão com a internet para usar o aplicativo. Não há conexão com o servidor no momento. Verifique se você pode se reconectar à Internet ou tente novamente mais tarde. ',
    },
  },
  common: {
    CheckAnswers: 'Verificar respostas',
    Answer: 'Salvar minha resposta',
    Hello: 'Olá',
    back: 'Voltar',
    CheckAnswer: 'Verificar resposta',
    Emailaddress: 'E-mail',
    Name: 'Nome',
    Checking: 'Verificando…',
    Close: 'Fechar',
    browsePrevious: 'Anterior',
    browseNext: 'Próximo',
    Accept: 'Aceitar',
    'Log out': 'Sair',
    Help: 'Ajuda',
    Correct: 'Correta',
    Incorrect: 'Incorreta',
    CorrectNotChosen: 'Teria sido correto',
    Round: 'Rodada',
    Version: 'Versão',
    selected: 'Selecionada',
    'No Rating': 'Nenhuma avaliação ainda',
    'No Rank': 'Nenhuma classificação',
    NoUserSelection: 'Usuários não encontrados',
    monthNames: 'Jan Fev Mar Abr Maio Jun Jul Ago Set Out Nov Dez',
    or: 'ou',
    on: 'ligado',
    off: 'desligado',
  },
  profile: {
    title: 'Perfil',
    subtitle: 'Ajuste seu perfil aqui.',
    pictureEdit: 'Ver ou editar sua foto.',
    'picture-submit': 'Usar este corte',
    pictureConfirmDelete:
      'Tem certeza de que deseja excluir sua foto de perfil?',
    profilePageTitle: 'Gerenciar seu perfil.',
    noEditDataText: 'Não é possível alterar esses dados.',
    'file-submit': 'Carregar',
    pictureCancel: 'Cancelar',
    'picture-nofileselected': 'Você não selecionou um arquivo.',
    'picture-nofile': 'Não há nenhum arquivo.',
    'picture-invalidsize':
      'A imagem é muito grande. Não pode exceder 2600 pixels.',
    'picture-notimage': 'O arquivo selecionado não parece ser uma imagem.',
    passwordEdit: 'Alterar senha',
    'picture-saved': 'Sua foto de perfil está salva!',
    passwordTitle: 'Alterar senha',
    passwordText:
      'Altere sua senha digitando a senha atual e duas vezes a nova senha. A nova senha deve ter pelo menos 6 caracteres.',
    'password-old': 'Senha atual',
    'password-new': 'Nova senha',
    'password-repeat': 'Confirmar nova senha',
    'password-submit': 'Salvar nova senha',
    'password-oldpasswordmismatch': 'A senha atual está incorreta',
    'password-newpasswordmismatch': 'A senha confirmada não corresponde.',
    'password-passwordtooshort':
      'A nova senha deve ter pelo menos 6 caracteres.',
    'password-passwordsaved': 'Senha salva!',
    profilePictureTitle: 'Foto do perfil',
    profilePictureText: 'Esta foto está visível para os seus colegas.',
    generalDataTitle: 'Dados pessoais',
    generalDataText:
      'Você não pode alterar todos os dados pessoais por conta própria.',
    profileLanguagePreference: 'Preferência de idioma',
    profileLanguageChoice: 'Escolha de idioma',
  },
  auth: {
    header: {
      login: 'Entrar',
      expired: 'Link inválido',
      remind: 'Esqueceu sua senha?',
      create: 'Ativar conta',
      reset: 'Atualizar senha',
      welcome: 'Bem-vindo!',
    },
    field: {
      email: 'E-mail',
      password: 'Senha',
      oldPassword: 'Senha atual',
      newPassword: 'Nova senha',
      newRepeatPassword: 'Repetir nova senha',
      rememberMe: 'Lembrar de mim',
    },
    action: {
      itemcompleted: 'Item concluído',
      updateprofile: 'Atualizar perfil',
      sendLink: 'Enviar link para redefinir senha',
      backToLogin: 'Eu quero fazer login',
      sendActivateLink: '﻿Ativar conta',
      alreadyActivatedLogin: 'Já ativei a conta e quero fazer login',
      savePassword: 'Salvar nova senha',
      newPassword: 'Sua nova senha',
      repeatNewPassword: 'Repetir nova senha',
      newPasswordPolicyIntro: 'Força da senha',
    },
    selfActivate: {
      header: 'Ativar conta',
    },
    logintext: 'Entrar',
    remindText:
      'Sem problema. Enviaremos um e-mail para você poder definir uma nova senha.',
    resetText: 'Definir a nova senha aqui.',
    createText: 'Bem-vindo! Crie uma nova senha e estará pronto para acessar.',
    passwords: {
      user: 'Não foi possível encontrar o usuário com esse endereço de e-mail.',
      token: 'O token de reinicialização de senha é inválido.',
      sent: ' Enviamos um e-mail com um link para redefinir sua senha.',
      reset: 'Senha reinicializada!',
      forgot: 'Não sei minha senha',
      passwordExpiredTitle: 'Senha expirada',
      submitPasswordExpiredForm: 'Salvar nova senha',
      passwordExpiredText:
        'A senha tem mais de {{days}} dias e está expirada. É necessário definir uma nova senha usando o formulário abaixo. Depois disso, você poderá continuar seu aprendizado no PowerApp',
    },
  },
  faq: {
    header: 'Perguntas frequentes',
    q1: 'O PowerApp é um aplicativo de microaprendizagem. O que isso significa?',
    a1: 'Microaprendizado significa que é mais eficiente aprender em sessões curtas se o seu objetivo for reter mais conhecimento ao longo do tempo.',
    q2: 'Por que minha pontuação às vezes é mais baixa do que eu esperava?',
    a2: 'Sua pontuação diminui porque você esquece o que aprendeu com o tempo.',
    q3: 'Quando minha pontuação diminui?',
    a3: 'Quando você responde a perguntas incorretamente ou fica inativo por um tempo.',
    q4: 'Por que os mesmos itens se repetem?',
    a4: 'Porque a repetição espaçada é a forma mais eficaz de aprender.',
    q5: 'O que é um desafio?',
    a5: 'Um questionário de conhecimento de 5 perguntas em que você compete com um colega.',
    q6: 'Por que tenho que esperar a minha vez para jogar um desafio?',
    a6: 'Você precisa esperar que seu ou sua colega termine sua vez',
    q7: 'Onde posso encontrar mais informações e respostas às minhas perguntas?',
    a7: 'Clique no botão abaixo para acessar o nosso site.',
    toTheFaq: 'Para as Perguntas frequentes',
    toTheWebsite: 'Ao site',
  },
}

export default translations

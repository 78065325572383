const translation = {
  popup: {
    confirm: {
      delete: 'Törles',
      keep: 'Megszünteti',
      cancelDuelInviteTitle: 'Törles?',
      cancelDuelInviteMessage: 'Biztosan ezt szeretnéd?',
    },
  },
  header: {
    'welcome-message': 'Kedves {{name}}!',
    welcome: 'Üdvözöljük.',
    welcomeBack: 'Örülünk, hogy visszatért.',
  },
  learn: {
    instruction: 'Válassza ki azokat a témákat, amelyekben játszani szeretne.',
    practiceSelection: 'A kiválasztás gyakorlása',
    selectAll: 'Összes kiválasztása',
    deselectAll: 'Kijelölés eltávolítása',
    wellDone: 'Kiváló munka!',
  },
  duels: {
    title: 'Párbaj pontszáma: {{score}}',
    readyToDuel: 'Készen áll a párbajra?',
    roundsOverview: 'A párbajkörök áttekintése',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Előző oldal',
      doneText: 'Brainsnack befejeződött',
      browseNext: 'Következő oldal',
    },
    brainItem: {
      correct: 'Helyes',
      incorrect: 'Hamis',
      completed: 'A kérdést megválaszolta',
      doneText: 'Kész',
      openItem: 'Kezdet',
      closeItem: 'Bezárás',
      topics: 'Témák',
      nextItem: 'Gyakorlás folytatása',
      showProgress: 'Eredmények megmutatása',
    },
    order: {
      questionTitle: 'Sorrend',
      helpText: 'Helyezze a válaszokat a megfelelő sorrendbe',
      correct: 'Helyes{{num}} és {{max}}',
    },
    match: {
      questionTitle: 'Mérkőzés',
      showingCorrectAnswer: 'A helyes válaszok megtekintése.',
      helpText: 'Helyezze át a válaszokat a megfelelő helyre',
      correct: 'Helyes{{num}} és {{max}}',
      showCorrectAnswer: 'A helyes válasz kimutatása',
      showMyAnswer: 'A válaszom kimutatása',
    },
    slider: {
      doneText: 'Kész',
      questionTitle: 'Csúszka',
      helpText: 'Mozgassa a csúszkát a megfelelő pozícióba',
    },
    poll: {
      questionTitle: 'Kérdőív',
      resultinfo: 'Ezek a többiek válaszai',
      doneText: 'Megválaszolta a kérdőív kérdését',
      helpText: 'Válasszon egy választ!',
    },
    notification: {
      title: 'Értesítés',
      pauze: 'Folytathatok?',
      continueanyway: 'Folytatás annak ellenére',
      doneText: 'Értesítés elolvasva',
      continueLearningText:
        'Ön kiegészítő kérdésekkel gyakorol. Ezékért  kevesebb pontot kap, mert már elérte a szükséges számot',
      panelbuttonlabel: 'A kiegészítő gyakorlatok megszüntetése',
    },
    multipleselect: {
      correct: 'Helyes {{num}} és{{max}}',
      incorrect: ',helytelen {{incorrect}}',
      doneText: 'A kérdés lezárult',
      questionTitle: 'Kérdés',
      helpText: 'Válasszon egyet vagy több választ!',
    },
    multiplechoice: {
      doneText: 'A kérdés lezárult',
      questionTitle: 'Kérdés',
      helpText: 'Válasszon egy választ!',
    },
    duelItem: {
      duel: 'Párbaj',
      draw: 'Egyenlőség',
      invite: 'Kihívás a párbajra',
      acceptDuel: 'A párbaj elfogadása',
      declineDuel: 'A párbaj elutasítása',
      yourTurn: 'Önnek kell cselekednie',
      opponentTurn: 'Az ellenfél sorában',
      youWon: 'Megnyerte!',
      youLost: 'Elveszítette!',
      cancelled: 'A párbajt lefújták',
      closeItem: 'Lezárás',
      showDetails: 'Részletek a menetről',
      playItem: 'Játsszon menetet! {{roundnumber}}',
      duelAccepted:
        'Elfogadta a párbajra való meghívót {{name}} névű játékostól.',
      duelDeclined:
        'Elutasította a párbajra való meghívót {{name}} névű játékostól.',
      duelStarted:
        'A  {{name}}  névű játékossal való párbajnak most kell elkezdődnie. Sok sikert!',
      duelInfoInvitation: 'Ön kihívást kapott egy párbajra.',
      duelInfoMain:
        'Gyors harc a tudásért 5 menetben. Nyerje a gyors és helyes válaszaival. Nyerje a legtöbb menetete, nyerje meg a párbajt és növelje a pontszámait. Vegye figyelembe, hogy 72 órás limittel rendelkezik, ami a menetet illeti.',
      viewInvitation: 'A meghívó megtekintése',
      versusLabel: 'ellen',
      started: 'Start!',
      doneText: 'A párbajnak vége van',
      tooLate: 'Az idő lejárt!',
      practiceWhileWaiting: 'Gyakoroljon, amíg várnia kell',
      practice: 'Folytassa a gyakorlást!',
      subheading: {
        waitForOpponent: 'Megvárja az ellenfél válaszát!',
        yourTurn: 'Ön készen áll?',
        timeOutOpponent:
          'На Вашия опонент беше необходимо прекалено дълго време, за да отговори',
        timeOutPlayer: 'Túl sok idő múlt el míg válaszolt',
        timeOutBoth: 'Mindkettőnek túl so időt vett igénybe  válaszolnia',
        wellDone: 'Jó munka, folytassa a jó munkát!',
        betterNextTime: 'Sok sikert a következő alkalommal!',
      },
      roundNumber: '{{Number}}. kör',
      duelRequest: 'Új párbaj kihívás!',
      duelRequestText: '{{name}} kihívta egy párbajra',
      tooLateTurn: 'Túl késő',
    },
    topicPin: {
      title: 'Csatolja a témát!',
      pinenabled:
        'Ön csatolta a {{- topiclabel}} fájlt, és csak a témához kapcsolódó elemeket látja. A témához kapcsolódó elemeket gyakrabban megismételjük, hogy gyakorolhassuk őket. Kattintson ismét a csatolt témára, ha más témákkal kapcsolatos kérdéseket szeretne felvenni.',
      pindisabled:
        'A téma már nincs csatolva. Mostantól kezdve újra látni fogja az összes téma elemét.',
      continue: 'Folytatás ',
      dontshowagain: 'Ne mutassa ezt ismét!',
      selectTopics: 'Téma kiválasztása',
      selectTopicsPractice: 'Válassza ki a témát, amelyet gyakorolni szeretne.',
    },
    topicInfoHeader: 'Témák',
  },
  login: {
    succesful: 'Sikeres belépés',
  },
  menu: {
    menu: 'Menü',
    duel: 'Párbaj',
    duels: 'Párbajok',
    newDuel: 'Új párbaj',
    activeDuels: 'Aktív párbajok',
    duelVersus: 'ELLEN',
    ranking: 'Rangsor',
    rating: 'A párbaj  értekelése',
    profile: 'Profil',
    autoDuelLabel: 'Automatikus párbajok',
    'help-and-settings': 'Segítség és beállítások',
    challengeplayer: 'Hívja ki a kollégáját!',
    pickplayer: 'Válasszon kollégát!',
    randomplayer: 'Szerencsésnek érzem magam',
    duelWaiting: 'Ellenfél keresése ...',
    quickDuelFailed: 'Már van függőben lévő meghívásai',
    duelsNotAvailable: 'A párbaj még nem elérhető.',
    duelsNotAvailableExplanation:
      'Szeretne párbajozni a kollégáival? Ez esetben kérje a {{appName}} rendszergazdájának segítségét!',
    duelsNotEnabled:
      'Szeretne párbajokat játszani a munkatársaival? Engedélyezze a párbaj opciót alább.',
    duelsNotEnabledDisclaimer:
      'A fotója és neve a munkatársak számára látható lesz.',
    enableDuelling: 'Engedélyezze a párbajt a munkatársakkal!',
    duellingEnabledLabel: 'Részvétel párbajokban',
    completedDuels: 'Befejezve',
    select: 'Kiválasztás',
    settings: 'Beállítások',
    practice: 'Gyakorlás',
    aboutDuels: {
      heading: 'Mi a párbaj meghívó?',
      explanation1: 'Önt párbajra hívták',
      explanation2:
        'A párbaj egy rövid, 5 körből álló kihívás egy munkatárssal. Akkor nyeri meg a kört, ha a kérdésre az ellenfelednél gyorsabban ad helyes választ. Ha több kört nyer, mint az ellenfele, megnyeria párbajt, és előrelép a rangsorban.',
      attention: 'Megjegyzés:',
      explanation3: 'Játssza le az egyes köröket 7 nap alatt!',
    },
    leaveAlert: {
      title: 'Biztos benne?',
      text: '<p>Biztos benne, hogy le szeretné állítani a gyakorlást?</p><p>Az előrehaladást elmentettük.</p>',
      confirm: 'Leállítás',
      continue: 'Gyakorlás folytatása',
    },
  },
  ranking: {
    title: 'A párbajok rangsora',
    subtitle:
      'Ez az értékelés megmutatja rangját a párbajban, összehasonlítva a kollégai eredményeihez',
    loadMore: 'Töltsön többet',
    yourPosition: 'Az Ön pozíciója ({{rank}})',
    topPositions: 'Felső pozíciók',
    empty: 'Nincs rangsor, indítson el párbajt!',
    notRanked: 'A rangsorba azután kerül be, ha már befejezett egy párbajt!',
  },
  searchFeedback: {
    title: 'Kolléga keresése',
    oneResult: 'Talált {{amount}} kolléga.',
    searchMessage: 'Talált {{amount}} kollégák.',
    overflowMessage: 'Csak a (z) {{amount}} keresési eredmények jelennek meg.',
  },
  searchDuel: {
    title: 'Ellenfél keresése ...',
    rankingposition: 'Pozició a rangsorban {{rank}}',
    noduelpossible: 'A párbaj nem lehetséges',
    duelstillactive: 'A párbaj már aktív',
    invitationsentondate: 'A Meghívó elküldve: {{date}}',
    searchPlaceholder: 'Keresés név szerint',
    randomOpponent:
      'Keressük a következő ellenfelet. Bezárhatja ezt a képernyőt, értesítjük, amikor a párbaj készen áll.',
    emptyListSearch: 'Nincs keresési eredmény. ',
    emptyList: 'Sajnos senki sem elérhető. Próbálja újra később. ',
  },
  activeDuels: {
    title: 'Részvétel egy elérhető párbajban.',
    subtitle: '{{num}}/{{max}} párbajok aktívak',
    status: {
      draw: 'Egyenlőség',
      lost: 'Elveszett',
      won: 'Megnyert',
      expired: 'Lejárt',
      invited: 'Meghívott',
      waiting: 'Várakozik',
      yourturn: 'Játék',
    },
    emptyListNew: 'Nincs párbaj, indítson újat!',
    emptyListCompleted:
      'Összes párbaj befejezve, kiváló munka! Jutalomként igyon egy kávét. ',
  },
  completedDuels: {
    title: 'Befejezett párbajok megtekintése.',
    empty: 'Még nem fejezett be egy párbajt sem. Indítson újat!',
  },
  error: {
    404: 'A szerver nem található (HTTP 404).',
    500: 'Belső szerverhiba (HTTP 500).',
    internetdown: 'Elveszett internetkapcsolat!',
    versionConflict: {
      title: 'Új változat',
      text: 'Ez az alkalmazás új változata. Annak érdekében, hogy mindenki a legújabb változatot használja, most frissítjük az alkalmazást a legújabb változatra. ',
      refresh: 'Tovább a legújabb változathoz',
    },
    refreshButton: 'Kattintson a frissítéshez',
    internetup: 'Van internet kapcsolat.',
    timeoutajax: 'A szerver várakozási ideje lejárt',
    unknown: 'Ismeretlen hiba',
    tryReconnect: 'Újracsatlakozás kísérlete ...',
    alreadyAnswered: 'Ez az elem már válaszolt.',
    unavailableOffline: {
      title: 'Nincs kapcsolat',
      subtitle: 'Pillanatnyilag nincs kapcsolat',
      explanation:
        'Az alkalmazás használatához internetkapcsolat szükséges. Pillanatnyilag nincs kapcsolat. Próbáljon meg újrakapcsolódni, vagy próbálja újra később. ',
    },
  },
  common: {
    CheckAnswers: 'Válaszok ellenőrzése',
    Answer: 'Válasz elmentése',
    Hello: 'Üdv',
    back: 'Vissza',
    CheckAnswer: 'Válasz ellenőrzése',
    Emailaddress: 'E-mail cím',
    Name: 'Név',
    Checking: 'Ellenőrzés...',
    Close: 'Zárás',
    browsePrevious: 'Előző',
    browseNext: 'Következő',
    Accept: 'Elfogadás',
    'Log out': 'Kilépés',
    Help: 'Segítség',
    Correct: 'Helyes',
    Incorrect: 'Hamis',
    CorrectNotChosen: 'Helyes lett volna',
    Round: 'Forduló',
    Version: 'Változat',
    selected: 'Kiválasztott',
    'No Rating': 'Még nincs értékelés',
    'No Rank': 'Nincs rangsor',
    NoUserSelection: 'Nincsenek megtalált felhasználók',
    monthNames: 'Jan Feb Már Apr Mai Jun Jul Aug Szep Okt Nov Dez',
    or: 'vagy',
    on: 'bekapcsolás',
    off: 'kikapcsolás',
  },
  profile: {
    title: 'Profil',
    subtitle: 'Profil módosítása.',
    pictureEdit: 'Kép megtekintése vagy szerkesztése.',
    'picture-submit': 'Használja ezt a kivágást',
    pictureConfirmDelete: 'Biztosan törölni szeretné a profilképet?',
    profilePageTitle: 'Profil kezelése.',
    noEditDataText: 'Ezt az adatot nem módosíthatja.',
    'file-submit': 'Feltöltés',
    pictureCancel: 'Visszavonás',
    'picture-nofileselected': 'Nem választott ki fájlt.',
    'picture-nofile': 'Nincs fájl.',
    'picture-invalidsize':
      'A kép túl nagy. A maximális szélesség vagy magasság nem haladhatja meg a 2600 pixelt.',
    'picture-notimage': 'Az Öntől kiválasztott fájl nem kép',
    passwordEdit: 'Jelszó megváltoztatása',
    'picture-saved': 'Profilfotóját mentettük!',
    passwordTitle: 'Jelszó megváltoztatása',
    passwordText:
      'Változtassa meg a jelszavát az aktuális jelszó és az új jelszó kétszer történő beírásával. Az új jelszónak legalább 6 karakter hosszúnak kell lennie.',
    'password-old': 'Jelenlegi jelszó',
    'password-new': 'Új jelszó',
    'password-repeat': 'Az új jelszó megerősítése',
    'password-submit': 'Az új jelszó mentése',
    'password-oldpasswordmismatch': 'A jelenlegi jelszó helytelen',
    'password-newpasswordmismatch': 'A megerősített jelszó nem egyezik.',
    'password-passwordtooshort':
      'Az új jelszónak legalább 6 karakter hosszúnak kell lennie.',
    'password-passwordsaved': 'A jelszava le van mentve',
    profilePictureTitle: 'Profilkép',
    profilePictureText: 'A kollégái láthatják ezt a fényképet.',
    generalDataTitle: 'Személyes információ',
    generalDataText: 'Saját maga nem módosíthatja az összes személyes adatot.',
    profileLanguagePreference: 'Nyelvi preferenciák',
    profileLanguageChoice: 'Nyelvválasztás',
  },
  auth: {
    header: {
      login: 'Bejelentkezés',
      expired: 'A hivatkozás lejárt',
      remind: 'Nem emlékszik a jelszóra?',
      create: 'Fiók aktiválása',
      reset: 'Jelszó frissítése',
      welcome: 'Üdvözöljük!',
    },
    field: {
      email: 'E-mail cím',
      password: 'Jelszó',
      oldPassword: 'Aktuális jelszó',
      newPassword: 'Új jelszó',
      newRepeatPassword: 'Új jelszó ismét',
      rememberMe: 'Emlékezzen rám',
    },
    action: {
      itemcompleted: 'Befejezett egy elemet',
      updateprofile: 'Profil frissítése',
      sendLink: 'Küldjön egy jelszó visszaállítási hivatkozást',
      backToLogin: 'Szeretnék bejelentkezni',
      sendActivateLink: 'Fiók aktiválása',
      alreadyActivatedLogin:
        'Már aktiváltam a fiókot és szeretnék bejelentkezni',
      savePassword: 'Új jelszó elmentése',
      newPassword: 'Új jelszó',
      repeatNewPassword: 'Ismételje meg az új jelszót',
      newPasswordPolicyIntro: 'Jelszó erőssége',
    },
    selfActivate: {
      header: 'A profil aktiválása',
    },
    logintext: 'Bejelentkezés',
    remindText:
      'Semmi gond. Küldünk egy e-mailt, amellyel beállíthatja az új jelszót.',
    resetText: 'Itt állíthatja be az új jelszót.',
    createText:
      'Üdvözöljük! Kérjük, hozzon létre új jelszót, ezt követően bejelentkezhet.',
    passwords: {
      user: 'Elnézést, nem találunk felhasználót ezzel az e-mail címmel.',
      token: 'Ez a jelszó visszaállítási token érvénytelen.',
      sent: ' E-mailben elküldtük a visszaállítási hivatkozást.',
      reset: 'A jelszó visszaállítva!',
      forgot: 'Nem tudom a jelszót',
      passwordExpiredTitle: 'A jelszó lejárt',
      submitPasswordExpiredForm: 'Új jelszó elmentése',
      passwordExpiredText:
        'A jelszó régebbi mint {{days}} nap és lejárt. Új jelszót kell beállítani az alábbi űrlap segítségével, majd folytathatja a tanulást a PowerApp alkalmazásban',
    },
  },
  faq: {
    header: 'Gyakran feltett kérdések',
    q1: 'A PowerApp egy mikro-tanulási alkalmazás, ez mit jelent?',
    a1: 'A mikro-tanulás azt jelenti, hogy rövid munkamenetekben hatékonyabb a tanulás, ha a cél a tudás hosszabb ideig történő megőrzése.',
    q2: 'Miért alacsonyabb néha a pontszámom a vártnál?',
    a2: 'A pontszám azért csökken, mert idővel elfelejtjük a korábban megtanultakat.',
    q3: 'Mikor csökken a pontszámom?',
    a3: 'Akkor csökken a pontszám, ha hibásan válaszolt a kérdésekre, vagy egy ideig nem voltaktív.',
    q4: 'Miért ismétlődnek ugyanazok az elemek?',
    a4: 'Mert az időközönkénti ismétlés a tanulás leghatékonyabb módja.',
    q5: 'Mi a párbaj?',
    a5: 'Egy 5 kérdésből álló tudáspróba, amelyben egy munkatárs ellen játszik.',
    q6: 'Miért kell várnom a soromra a párbaj közben?',
    a6: 'Meg kell várni, amíg a munkatárs befejezi a saját körét',
    q7: 'Hol találok további információt és válaszokat a kérdéseimre?',
    a7: 'Az alábbi gombra kattintva megtekintheti a weboldalunkat.',
    toTheFaq: 'A GYIK-hez',
    toTheWebsite: 'A weboldalhoz',
  },
}

export default translation

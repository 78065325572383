import { Children, PropsWithChildren, ReactElement } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { AnswerCategory_category$key } from '../../../../generated/AnswerCategory_category.graphql'

import { classNames } from '../../../../utils/classNames'
import { BrainItemState } from '../../../../utils/hooks/useBrainItems'

import styles from './AnswerCategory.scss'
import { useTranslation } from 'react-i18next'

export interface AnswerCategoryProps {
  brainItemState: BrainItemState
  category: AnswerCategory_category$key
  itemCount: number
  numberBubbleClassName: string
  tabindex: number
}

export function AnswerCategory(
  props: PropsWithChildren<AnswerCategoryProps>
): ReactElement {
  const { t } = useTranslation()

  const category = useFragment(
    graphql`
      fragment AnswerCategory_category on AnswerCategory {
        id
        text(language: $language)
      }
    `,
    props.category
  )

  return (
    <Droppable droppableId={category.id}>
      {(provided, snapshot) => (
        <div className={styles.targetSpacing}>
          <div
            className={classNames(styles.answerCategory, {
              [styles.highlighted]: snapshot.isDraggingOver,
              [styles.withFeedback]:
                props.brainItemState >= BrainItemState.CHECKING,
            })}
            tabIndex={props.tabindex}
            data-category={category.id}
            id={'answer-category-' + category.id}
            role='listbox'
          >
            <div className={styles.heading}>
              <div
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: category.text,
                }}
              />
            </div>

            <div
              className={styles.dropTarget}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {Children.count(props.children) === 0 && (
                <span
                  className={classNames(styles.emptyAnswerCategoryPlaceholder)}
                >
                  {t('common.matchQuestions.emptyCategoryPlaceholderText')}
                </span>
              )}

              {props.children}

              {provided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TimeLineQueryVariables = {
    language: string;
};
export type TimeLineQueryResponse = {
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"LearnHeader_user">;
    } | null;
    readonly topicCategories: ReadonlyArray<{
        readonly id: string;
        readonly description: string;
        readonly availableFrom: string;
        readonly progress: number;
        readonly items: ReadonlyArray<{
            readonly id: string;
            readonly favorited: boolean;
            readonly completedAt: string | null;
            readonly " $fragmentRefs": FragmentRefs<"TimeLineItem_item">;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"TimeLineItem_topicCategory">;
    }>;
};
export type TimeLineQuery = {
    readonly response: TimeLineQueryResponse;
    readonly variables: TimeLineQueryVariables;
};



/*
query TimeLineQuery(
  $language: String!
) {
  me {
    ...LearnHeader_user
    id
  }
  topicCategories {
    id
    description(language: $language)
    availableFrom
    progress
    ...TimeLineItem_topicCategory
    items {
      id
      favorited
      completedAt
      ...TimeLineItem_item
    }
  }
}

fragment Asset_asset on Asset {
  translation(language: $language) {
    mime
    url
    id
  }
}

fragment LearnHeader_user on AuthenticatedUser {
  id
  firstLogin
  firstName
  fullName
  profileImage
}

fragment TimeLineItem_item on OnboardingItem {
  id
  brainItem {
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
  completedAt
  favorited
  introAsset {
    ...Asset_asset
    id
  }
  startedAt
  summaryText(language: $language)
}

fragment TimeLineItem_topicCategory on TopicCategory {
  availableFrom
  unlocked
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v3 = {
        "alias": null,
        "args": (v2 /*: any*/),
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "availableFrom",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progress",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "favorited",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "completedAt",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TimeLineQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LearnHeader_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicCategory",
                    "kind": "LinkedField",
                    "name": "topicCategories",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "OnboardingItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "TimeLineItem_item"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TimeLineItem_topicCategory"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TimeLineQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstLogin",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicCategory",
                    "kind": "LinkedField",
                    "name": "topicCategories",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unlocked",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "OnboardingItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "brainItem",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v1 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "introAsset",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": (v2 /*: any*/),
                                            "concreteType": "AssetTranslation",
                                            "kind": "LinkedField",
                                            "name": "translation",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "mime",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "url",
                                                    "storageKey": null
                                                },
                                                (v1 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startedAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "kind": "ScalarField",
                                    "name": "summaryText",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "610b1ff2eb16962ef119dec8a95ec9da",
            "id": null,
            "metadata": {},
            "name": "TimeLineQuery",
            "operationKind": "query",
            "text": "query TimeLineQuery(\n  $language: String!\n) {\n  me {\n    ...LearnHeader_user\n    id\n  }\n  topicCategories {\n    id\n    description(language: $language)\n    availableFrom\n    progress\n    ...TimeLineItem_topicCategory\n    items {\n      id\n      favorited\n      completedAt\n      ...TimeLineItem_item\n    }\n  }\n}\n\nfragment Asset_asset on Asset {\n  translation(language: $language) {\n    mime\n    url\n    id\n  }\n}\n\nfragment LearnHeader_user on AuthenticatedUser {\n  id\n  firstLogin\n  firstName\n  fullName\n  profileImage\n}\n\nfragment TimeLineItem_item on OnboardingItem {\n  id\n  brainItem {\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  completedAt\n  favorited\n  introAsset {\n    ...Asset_asset\n    id\n  }\n  startedAt\n  summaryText(language: $language)\n}\n\nfragment TimeLineItem_topicCategory on TopicCategory {\n  availableFrom\n  unlocked\n}\n"
        }
    } as any;
})();
(node as any).hash = '4035417f157095c7ca5fa53b8424fa7d';
export default node;

import { Fragment, ReactElement } from 'react'

import styles from './BGParticleAnimation.scss'

export function BGParticleAnimation(): ReactElement {
  const nrOfParticles = 12
  const particles = []

  for (let i = 1; i <= nrOfParticles; i++) {
    particles.push(
      <Fragment key={i}>
        <div className={styles['p' + i]} />
        <div className={styles['p2_' + i]} />
      </Fragment>
    )
  }

  return <div className={styles.bgAnimationContainer}>{particles}</div>
}

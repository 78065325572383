/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActiveDuelRequestsListPaginationQueryVariables = {
    count: number;
    cursor?: string | null | undefined;
};
export type ActiveDuelRequestsListPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ActiveDuelRequestsList_duelRequests">;
};
export type ActiveDuelRequestsListPaginationQuery = {
    readonly response: ActiveDuelRequestsListPaginationQueryResponse;
    readonly variables: ActiveDuelRequestsListPaginationQueryVariables;
};



/*
query ActiveDuelRequestsListPaginationQuery(
  $count: Int!
  $cursor: String
) {
  ...ActiveDuelRequestsList_duelRequests
}

fragment ActiveDuelRequestsList_duelRequests on Query {
  myDuelRequests(first: $count, after: $cursor) {
    edges {
      node {
        id
        status
        ...ActiveDuelSlot_request
        ...DuelRequestPopup_item
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ActiveDuelSlot_request on DuelRequest {
  opponent {
    __typename
    rating
    user {
      __typename
      id
      nameForDuelling
      profileImageForDuelling
      hasAnonymousDuellingEnabled
    }
    id
  }
  status
  time
}

fragment DuelRequestPopup_item on DuelRequest {
  id
  challenger {
    __typename
    ...DuelVersusHeader_right
    id
  }
  opponent {
    __typename
    ...DuelVersusHeader_left
    id
  }
}

fragment DuelVersusHeader_left on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}

fragment DuelVersusHeader_right on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameForDuelling",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImageForDuelling",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAnonymousDuellingEnabled",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMe",
        "storageKey": null
    } as any, v8 = {
        "kind": "TypeDiscriminator",
        "abstractKey": "__isPlayer"
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
    } as any, v10 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timedOut",
                "storageKey": null
            }
        ],
        "type": "DuelPlayer",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActiveDuelRequestsListPaginationQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ActiveDuelRequestsList_duelRequests"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActiveDuelRequestsListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DuelRequestConnection",
                    "kind": "LinkedField",
                    "name": "myDuelRequests",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DuelRequestEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DuelRequest",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "opponent",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "rating",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/),
                                                (v8 /*: any*/),
                                                (v9 /*: any*/),
                                                (v10 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "time",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "challenger",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v8 /*: any*/),
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/),
                                                (v10 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "kind": "ClientExtension",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__id",
                                    "storageKey": null
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ActiveDuelRequestsList_myDuelRequests",
                    "kind": "LinkedHandle",
                    "name": "myDuelRequests"
                }
            ]
        },
        "params": {
            "cacheID": "c98c2fe0f80438313c15c149181743af",
            "id": null,
            "metadata": {},
            "name": "ActiveDuelRequestsListPaginationQuery",
            "operationKind": "query",
            "text": "query ActiveDuelRequestsListPaginationQuery(\n  $count: Int!\n  $cursor: String\n) {\n  ...ActiveDuelRequestsList_duelRequests\n}\n\nfragment ActiveDuelRequestsList_duelRequests on Query {\n  myDuelRequests(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        status\n        ...ActiveDuelSlot_request\n        ...DuelRequestPopup_item\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ActiveDuelSlot_request on DuelRequest {\n  opponent {\n    __typename\n    rating\n    user {\n      __typename\n      id\n      nameForDuelling\n      profileImageForDuelling\n      hasAnonymousDuellingEnabled\n    }\n    id\n  }\n  status\n  time\n}\n\nfragment DuelRequestPopup_item on DuelRequest {\n  id\n  challenger {\n    __typename\n    ...DuelVersusHeader_right\n    id\n  }\n  opponent {\n    __typename\n    ...DuelVersusHeader_left\n    id\n  }\n}\n\nfragment DuelVersusHeader_left on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n\nfragment DuelVersusHeader_right on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '493e850efd9420843c92f6341b703c83';
export default node;

import { useCallback, useState } from 'react'

type ID = string
type AddAnswer = (id: ID) => void
type RemoveAnswer = (id: ID) => void
type SetSelected = (id: ID | ID[]) => void

export function useSelectedAnswers(
  initialState: ID[] = []
): [ReadonlyArray<ID>, AddAnswer, RemoveAnswer, SetSelected] {
  const [selected, setSelected] = useState<ID[]>(initialState)

  const addAnswer = useCallback(
    (id: ID): void => {
      if (!selected.includes(id)) {
        setSelected([...selected, id])
      }
    },
    [selected]
  )
  const removeAnswer = useCallback(
    (id: ID): void => {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id))
      }
    },
    [selected]
  )
  const setSelectedAnswers = useCallback((id: ID | ID[]): void => {
    setSelected(Array.isArray(id) ? id : [id])
  }, [])

  return [selected, addAnswer, removeAnswer, setSelectedAnswers]
}

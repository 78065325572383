import { observer } from 'mobx-react'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useMutation } from 'relay-hooks'

import { Header } from '../../containers/Header'
import { Page } from '../../containers/Page'
import { DuelCompleteRoundMutation } from '../../generated/DuelCompleteRoundMutation.graphql'
import { DuelStartRoundMutation } from '../../generated/DuelStartRoundMutation.graphql'
import { BrainItemState, useBrainItems } from '../../utils/hooks/useBrainItems'

import { Fullscreen } from '../common/Fullscreen'
import { GraphQlError } from '../common/GraphQlError'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { BrainItemHeader } from '../learn/BrainItemHeader'
import { ItemHeader } from '../learn/ItemHeader'
import { QuestionView } from '../learn/questions/QuestionView'
import { BrainItemCompletionInput } from '../learn/StreamItem'
import { WellDoneOverlay } from '../learn/WellDoneOverlay'

import styles from './Duel.scss'
import { DuelTimer } from './DuelTimer'
import { DuelVersusHeader } from './DuelVersusHeader'

export interface DuelProps {
  duel: string
  onBackClicked?(): void
}

export const Duel = observer(function Duel(props: DuelProps): ReactElement {
  const { onBackClicked } = props
  const [startTime] = useState(new Date())
  const [wellDoneOverlay, showWellDoneOverlay] = useState(false)
  const { i18n } = useTranslation()

  const [brainItemState, setBrainItemState] = useBrainItems()

  const [startDuelRound, result] = useMutation<DuelStartRoundMutation>(
    graphql`
      mutation DuelStartRoundMutation($id: ID!, $language: String!) {
        startDuelRound(duelId: $id) {
          penalty
          round
          players {
            ...DuelVersusHeader_left
            ...DuelVersusHeader_right
          }
          question {
            __typename
            ... on Node {
              id
            }
            ...BrainItemHeader_brainItem
            ...QuestionView_item
          }
        }
      }
    `,
    {
      onCompleted() {
        setBrainItemState(BrainItemState.READY)
      },
      variables: {
        id: props.duel,
        language: i18n.language,
      },
    }
  )
  const [completeDuelRound, completionResult] =
    useMutation<DuelCompleteRoundMutation>(graphql`
      mutation DuelCompleteRoundMutation(
        $id: ID!
        $brainItemId: ID!
        $data: BrainItemCompletionInput!
        $language: String!
      ) {
        completeDuelRound(id: $id, brainItemId: $brainItemId, data: $data) {
          brainItemCompletionData {
            ...QuestionView_feedback
            ...WellDoneOverlay_feedback
            scoreChange {
              __typename
            }
            ... on GenericQuestionCompletionData {
              correct
            }
            ... on MatchQuestionCompletionData {
              correct
            }
            ... on SliderQuestionCompletionData {
              correct
            }
          }
          duel {
            # These ensure the active duels page gets updated.
            ...ActiveDuelSlot_duel
            ...DuelFinalizingPopup_item
            ...PlayDuelRound_duel
          }
        }
      }
    `)

  const submitQuestion = useCallback(
    (data: BrainItemCompletionInput): void => {
      setBrainItemState(BrainItemState.CHECKING)
      completeDuelRound({
        variables: {
          id: props.duel,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          brainItemId: result.data!.startDuelRound.question.id!,
          data: {
            ...data,
            duration:
              new Date().getTime() -
              startTime.getTime() +
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              result.data!.startDuelRound.penalty * 1000,
          },
          language: i18n.language,
        },
      }).then(() => {
        setBrainItemState(BrainItemState.FEEDBACK)
      })
    },
    [
      setBrainItemState,
      completeDuelRound,
      props.duel,
      result.data,
      startTime,
      i18n.language,
    ]
  )
  const closeDuel = useCallback((): void => {
    onBackClicked && onBackClicked()
  }, [onBackClicked])

  const feedback =
    completionResult.data?.completeDuelRound.brainItemCompletionData ?? null
  const onClose = useCallback((): void => {
    if (
      feedback &&
      'correct' in feedback &&
      feedback.correct &&
      feedback.scoreChange &&
      !wellDoneOverlay
    ) {
      showWellDoneOverlay(true)
    } else {
      closeDuel()
    }
  }, [feedback, wellDoneOverlay, closeDuel])

  // On opening, start the duel round, thereby retrieving the details of the
  // question etc.
  useEffect((): void => {
    if (!result.data && !result.loading && !result.error) {
      startDuelRound()
    }
  }, [result.data, result.loading, result.error, startDuelRound])

  return (
    <Fullscreen>
      <>
        <Header duel streamItem>
          {result.data && (
            <DuelVersusHeader
              className={styles.extraPadding}
              left={result.data.startDuelRound.players[0]}
              right={result.data.startDuelRound.players[1]}
              round={result.data.startDuelRound.round}
              type='ACTIVE'
            >
              <DuelTimer
                paused={brainItemState >= BrainItemState.CHECKING}
                penalty={result.data.startDuelRound?.penalty || 0}
              />
            </DuelVersusHeader>
          )}
        </Header>

        <Page>
          {!result.error && result.loading && <LoadingIndicator />}

          {result.data ? (
            <BrainItemHeader brainItem={result.data.startDuelRound.question} />
          ) : (
            <ItemHeader />
          )}

          {result.data && (
            <QuestionView
              brainItemState={brainItemState}
              feedback={feedback}
              inDuel={true}
              item={result.data.startDuelRound.question}
              onClose={onClose}
              onSubmit={submitQuestion}
              setBrainItemState={setBrainItemState}
            />
          )}

          {!result.data && result.error && (
            <GraphQlError error={result.error} retry={startDuelRound} />
          )}
        </Page>

        {wellDoneOverlay && feedback && (
          <WellDoneOverlay feedback={feedback} onClose={onClose} />
        )}
      </>
    </Fullscreen>
  )
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SelectTopicScoreInfoQueryVariables = {
    language: string;
};
export type SelectTopicScoreInfoQueryResponse = {
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"LearnHeader_user">;
    } | null;
    readonly scoreInfo: {
        readonly totalScore: number;
        readonly topicCategories: ReadonlyArray<{
            readonly topics: ReadonlyArray<{
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"SelectableTopic_topic">;
            }>;
            readonly " $fragmentRefs": FragmentRefs<"TopicCategorySelector_topicCategories">;
        }>;
        readonly topics: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"SelectableTopic_topic">;
        }>;
    };
};
export type SelectTopicScoreInfoQuery = {
    readonly response: SelectTopicScoreInfoQueryResponse;
    readonly variables: SelectTopicScoreInfoQueryVariables;
};



/*
query SelectTopicScoreInfoQuery(
  $language: String!
) {
  me {
    ...LearnHeader_user
    id
  }
  scoreInfo {
    totalScore
    topicCategories {
      ...TopicCategorySelector_topicCategories
      topics {
        id
        ...SelectableTopic_topic
      }
      id
    }
    topics {
      id
      ...SelectableTopic_topic
    }
  }
}

fragment LearnHeader_user on AuthenticatedUser {
  id
  firstLogin
  firstName
  fullName
  profileImage
}

fragment SelectableTopic_topic on TopicWithScore {
  name(language: $language)
  percentage
  topicIcon
}

fragment TopicCategorySelector_topicCategories on TopicCategoryWithScore {
  id
  description(language: $language)
  percentage
  topics {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalScore",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "TopicWithScore",
        "kind": "LinkedField",
        "name": "topics",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectableTopic_topic"
            }
        ],
        "storageKey": null
    } as any, v4 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "TopicWithScore",
        "kind": "LinkedField",
        "name": "topics",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": (v4 /*: any*/),
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            (v5 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicIcon",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SelectTopicScoreInfoQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LearnHeader_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScoreInfoResponse",
                    "kind": "LinkedField",
                    "name": "scoreInfo",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TopicCategoryWithScore",
                            "kind": "LinkedField",
                            "name": "topicCategories",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "TopicCategorySelector_topicCategories"
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SelectTopicScoreInfoQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstLogin",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScoreInfoResponse",
                    "kind": "LinkedField",
                    "name": "scoreInfo",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TopicCategoryWithScore",
                            "kind": "LinkedField",
                            "name": "topicCategories",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                },
                                (v5 /*: any*/),
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "fcc8ac301369e0cc252ced335f2290c9",
            "id": null,
            "metadata": {},
            "name": "SelectTopicScoreInfoQuery",
            "operationKind": "query",
            "text": "query SelectTopicScoreInfoQuery(\n  $language: String!\n) {\n  me {\n    ...LearnHeader_user\n    id\n  }\n  scoreInfo {\n    totalScore\n    topicCategories {\n      ...TopicCategorySelector_topicCategories\n      topics {\n        id\n        ...SelectableTopic_topic\n      }\n      id\n    }\n    topics {\n      id\n      ...SelectableTopic_topic\n    }\n  }\n}\n\nfragment LearnHeader_user on AuthenticatedUser {\n  id\n  firstLogin\n  firstName\n  fullName\n  profileImage\n}\n\nfragment SelectableTopic_topic on TopicWithScore {\n  name(language: $language)\n  percentage\n  topicIcon\n}\n\nfragment TopicCategorySelector_topicCategories on TopicCategoryWithScore {\n  id\n  description(language: $language)\n  percentage\n  topics {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a81d50ea85ac893f46a2eb76f6682b42';
export default node;

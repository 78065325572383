/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type CompletedDuelSlot_item = {
    readonly status: DuelStatus;
    readonly players: ReadonlyArray<{
        readonly rating: number;
        readonly timedOut: boolean;
        readonly user: {
            readonly id: string;
            readonly nameForDuelling: string;
            readonly isMe: boolean;
            readonly profileImageForDuelling: string | null;
            readonly hasAnonymousDuellingEnabled: boolean;
        };
        readonly rounds: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_left" | "DuelRoundsOverview_right">;
        }> | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_duel">;
    readonly " $refType": "CompletedDuelSlot_item";
};
export type CompletedDuelSlot_item$data = CompletedDuelSlot_item;
export type CompletedDuelSlot_item$key = {
    readonly " $data"?: CompletedDuelSlot_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CompletedDuelSlot_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompletedDuelSlot_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "DuelPlayer",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rating",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timedOut",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isMe",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasAnonymousDuellingEnabled",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelPlayerRound",
                    "kind": "LinkedField",
                    "name": "rounds",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_left"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_right"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DuelRoundsOverview_duel"
        }
    ],
    "type": "Duel",
    "abstractKey": null
} as any;
(node as any).hash = 'fc2ea4d7fbadbbe0b75b760174ef80d3';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CompletedDuelsQueryVariables = {};
export type CompletedDuelsQueryResponse = {
    readonly duelInfo: {
        readonly " $fragmentRefs": FragmentRefs<"DuelPage_duelInfo">;
    };
    readonly duels: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"CompletedDuelSlot_item">;
    }>;
};
export type CompletedDuelsQuery = {
    readonly response: CompletedDuelsQueryResponse;
    readonly variables: CompletedDuelsQueryVariables;
};



/*
query CompletedDuelsQuery {
  duelInfo {
    ...DuelPage_duelInfo
  }
  duels(statusFilter: ONLY_COMPLETED) {
    id
    ...CompletedDuelSlot_item
  }
}

fragment CompletedDuelSlot_item on Duel {
  status
  players {
    rating
    timedOut
    user {
      __typename
      id
      nameForDuelling
      isMe
      profileImageForDuelling
      hasAnonymousDuellingEnabled
    }
    rounds {
      ...DuelRoundsOverview_left
      ...DuelRoundsOverview_right
    }
    id
  }
  ...DuelRoundsOverview_duel
}

fragment DuelPage_duelInfo on UserDuelInfo {
  rank
  rating
}

fragment DuelRoundsOverview_duel on Duel {
  status
}

fragment DuelRoundsOverview_left on DuelPlayerRound {
  answerTime
  isCorrect
  roundNumber
}

fragment DuelRoundsOverview_right on DuelPlayerRound {
  answerTime
  isCorrect
  roundNumber
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "statusFilter",
            "value": "ONLY_COMPLETED"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "CompletedDuelsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserDuelInfo",
                    "kind": "LinkedField",
                    "name": "duelInfo",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelPage_duelInfo"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "Duel",
                    "kind": "LinkedField",
                    "name": "duels",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CompletedDuelSlot_item"
                        }
                    ],
                    "storageKey": "duels(statusFilter:\"ONLY_COMPLETED\")"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "CompletedDuelsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserDuelInfo",
                    "kind": "LinkedField",
                    "name": "duelInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rank",
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "Duel",
                    "kind": "LinkedField",
                    "name": "duels",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DuelPlayer",
                            "kind": "LinkedField",
                            "name": "players",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timedOut",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "nameForDuelling",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isMe",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "profileImageForDuelling",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasAnonymousDuellingEnabled",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DuelPlayerRound",
                                    "kind": "LinkedField",
                                    "name": "rounds",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "answerTime",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isCorrect",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "roundNumber",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "duels(statusFilter:\"ONLY_COMPLETED\")"
                }
            ]
        },
        "params": {
            "cacheID": "72e7a1df800f920d65cb6478a82fdb11",
            "id": null,
            "metadata": {},
            "name": "CompletedDuelsQuery",
            "operationKind": "query",
            "text": "query CompletedDuelsQuery {\n  duelInfo {\n    ...DuelPage_duelInfo\n  }\n  duels(statusFilter: ONLY_COMPLETED) {\n    id\n    ...CompletedDuelSlot_item\n  }\n}\n\nfragment CompletedDuelSlot_item on Duel {\n  status\n  players {\n    rating\n    timedOut\n    user {\n      __typename\n      id\n      nameForDuelling\n      isMe\n      profileImageForDuelling\n      hasAnonymousDuellingEnabled\n    }\n    rounds {\n      ...DuelRoundsOverview_left\n      ...DuelRoundsOverview_right\n    }\n    id\n  }\n  ...DuelRoundsOverview_duel\n}\n\nfragment DuelPage_duelInfo on UserDuelInfo {\n  rank\n  rating\n}\n\nfragment DuelRoundsOverview_duel on Duel {\n  status\n}\n\nfragment DuelRoundsOverview_left on DuelPlayerRound {\n  answerTime\n  isCorrect\n  roundNumber\n}\n\nfragment DuelRoundsOverview_right on DuelPlayerRound {\n  answerTime\n  isCorrect\n  roundNumber\n}\n"
        }
    } as any;
})();
(node as any).hash = '6b1962310925b908d9f56a03ab2e603f';
export default node;

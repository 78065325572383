/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ActiveDuelRequestsListDeletedSubscriptionVariables = {
    duelRequestConnections: Array<string>;
};
export type ActiveDuelRequestsListDeletedSubscriptionResponse = {
    readonly duelRequestDeleted: {
        readonly id: string;
    } | null;
};
export type ActiveDuelRequestsListDeletedSubscription = {
    readonly response: ActiveDuelRequestsListDeletedSubscriptionResponse;
    readonly variables: ActiveDuelRequestsListDeletedSubscriptionVariables;
};



/*
subscription ActiveDuelRequestsListDeletedSubscription {
  duelRequestDeleted {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "duelRequestConnections"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActiveDuelRequestsListDeletedSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelRequest",
                    "kind": "LinkedField",
                    "name": "duelRequestDeleted",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActiveDuelRequestsListDeletedSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelRequest",
                    "kind": "LinkedField",
                    "name": "duelRequestDeleted",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "deleteEdge",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "id",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "duelRequestConnections"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a0c06b3376c306e16d40ff2317a11e61",
            "id": null,
            "metadata": {},
            "name": "ActiveDuelRequestsListDeletedSubscription",
            "operationKind": "subscription",
            "text": "subscription ActiveDuelRequestsListDeletedSubscription {\n  duelRequestDeleted {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ec2bdb4fe347ba72a81d2ab43d295843';
export default node;

import { ReactElement, useCallback } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { Asset_asset$key } from '../../generated/Asset_asset.graphql'

import { url } from '../../utils/url'

import styles from './Asset.scss'

interface AssetProps {
  asset: Asset_asset$key
  className?: string

  onImageClicked?(imageUrl: string): void
}

export const Asset = function Asset(props: AssetProps): ReactElement {
  const { onImageClicked } = props

  const asset = useFragment(
    graphql`
      fragment Asset_asset on Asset {
        translation(language: $language) {
          mime
          url
        }
      }
    `,
    props.asset
  )

  const onImageClickedCallback = useCallback(() => {
    if (onImageClicked) {
      onImageClicked(asset.translation.url)
    }
  }, [onImageClicked, asset.translation.url])

  switch (asset.translation.mime) {
    case 'video/youtube':
      return (
        <iframe
          allow='fullscreen'
          allowFullScreen
          className={styles.videoAsset}
          src={asset.translation.url}
          width='100%'
        />
      )
    case 'video/vimeo':
      return (
        <iframe
          allow='fullscreen'
          allowFullScreen
          className={styles.videoAsset}
          src={asset.translation.url}
          width='100%'
        />
      )
    case 'video/mp4':
    case 'video/webm':
    case 'video/3gp':
      return (
        <video className={styles.videoAsset} width='100%' controls={true}>
          <source src={asset.translation.url} type={asset.translation.mime} />
          Your browser does not support HTML5 video.
        </video>
      )

    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
      return (
        <img
          className={props.className}
          onClick={onImageClickedCallback}
          src={url(asset.translation.url)}
          width='100%'
        />
      )

    default:
      return <></>
  }
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TimeLineItem_topicCategory = {
    readonly availableFrom: string;
    readonly unlocked: boolean;
    readonly " $refType": "TimeLineItem_topicCategory";
};
export type TimeLineItem_topicCategory$data = TimeLineItem_topicCategory;
export type TimeLineItem_topicCategory$key = {
    readonly " $data"?: TimeLineItem_topicCategory$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TimeLineItem_topicCategory">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeLineItem_topicCategory",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableFrom",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unlocked",
            "storageKey": null
        }
    ],
    "type": "TopicCategory",
    "abstractKey": null
} as any;
(node as any).hash = '7a2dcfc67d227d4f4ec933f0148ecc6a';
export default node;

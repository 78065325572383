// import { faHeadSideBrain } from '@fortawesome/pro-light-svg-icons/faHeadSideBrain'
// import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
// import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion'
// import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap'
// import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { BrainItemType_item$key } from '../../generated/BrainItemType_item.graphql'
// import { never } from '../../utils/assert'
import { useFragment } from 'relay-hooks'
import { faBrain } from '@fortawesome/pro-light-svg-icons/faBrain'
import styles from './BrainItemType.scss'

interface BrainItemTypeProps {
  brainItem: BrainItemType_item$key
  withoutIcon?: boolean
}

export function BrainItemType(props: BrainItemTypeProps): ReactElement {
  const { t } = useTranslation()
  const brainItem = useFragment(
    graphql`
      fragment BrainItemType_item on BrainItem {
        __typename
        ... on HasQuestionType {
          questionType
        }
      }
    `,
    props.brainItem
  )
  let brainItemType = <></>
  if (brainItem) {
    if (
      brainItem.__typename === 'BrainSnack' ||
      brainItem.questionType === 'KNOWLEDGE'
    ) {
      brainItemType = (
        <span className={styles.typeIndicator}>
          <FontAwesomeIcon icon={faBrain} /> {t('learn.knowledge')}
        </span>
      )
    } else if (brainItem.questionType === 'AWARENESS') {
      brainItemType = (
        <span className={styles.typeIndicator}>
          <AwarenessIcon /> {t('learn.awareness')}
        </span>
      )
    } else if (brainItem.questionType === 'POLL') {
      brainItemType = (
        <span className={styles.typeIndicator}>
          <AwarenessIcon /> {t('learn.poll')}
        </span>
      )
    }
  }
  return <>{brainItemType}</>
}

// Note that if we put the .svg in a separate file, fill='currentColor' does not work.
function AwarenessIcon(): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.525'
      height='13.738'
      viewBox='0 0 13.525 13.738'
    >
      <g transform='translate(-3.965)'>
        <g transform='translate(5.934 5.223)'>
          <path
            d='M78.707,198.18a4,4,0,0,1-.4-3.027.405.405,0,1,0-.783-.209,4.807,4.807,0,0,0,.484,3.643.405.405,0,0,0,.7-.407Z'
            transform='translate(-77.361 -194.643)'
            fill='currentColor'
          />
        </g>
        <g transform='translate(3.965)'>
          <path
            d='M14.958,1.487a6.763,6.763,0,0,0-9.976,8.845A.405.405,0,0,0,5.67,9.9,5.953,5.953,0,0,1,10.727.81a5.887,5.887,0,0,1,3.723,1.308.405.405,0,1,0,.507-.632Z'
            transform='translate(-3.965)'
            fill='currentColor'
          />
        </g>
        <g transform='translate(6.101 4.008)'>
          <path
            d='M89.815,154.377a2.754,2.754,0,1,0-3.22,0,4.634,4.634,0,0,0-3.016,4.337.405.405,0,0,0,.405.405h8.442a.405.405,0,0,0,.405-.405A4.634,4.634,0,0,0,89.815,154.377Zm-3.554-2.233a1.944,1.944,0,1,1,1.944,1.944A1.946,1.946,0,0,1,86.262,152.144Zm-1.851,6.165a3.816,3.816,0,0,1,7.589,0Z'
            transform='translate(-83.579 -149.39)'
            fill='currentColor'
          />
        </g>
        <g transform='translate(6.908 1.969)'>
          <path
            d='M117.476,73.395a4.775,4.775,0,0,0-3.729,1.782.405.405,0,1,0,.63.509,3.983,3.983,0,0,1,6.546,4.5.405.405,0,1,0,.7.407,4.794,4.794,0,0,0-4.147-7.2Z'
            transform='translate(-113.657 -73.395)'
            fill='currentColor'
          />
        </g>
        <g transform='translate(15.399 2.53)'>
          <path
            d='M430.828,94.452a.405.405,0,1,0-.647.488,5.956,5.956,0,0,1,.3,6.724.405.405,0,1,0,.688.428,6.766,6.766,0,0,0-.345-7.64Z'
            transform='translate(-430.1 -94.291)'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StreamItem_feedback = {
    readonly " $fragmentRefs": FragmentRefs<"QuestionView_feedback">;
    readonly " $refType": "StreamItem_feedback";
};
export type StreamItem_feedback$data = StreamItem_feedback;
export type StreamItem_feedback$key = {
    readonly " $data"?: StreamItem_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StreamItem_feedback">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamItem_feedback",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestionView_feedback"
        }
    ],
    "type": "BrainItemCompletionData",
    "abstractKey": "__isBrainItemCompletionData"
} as any;
(node as any).hash = '5f70ffc8efaedd64358db6b56e7557b0';
export default node;

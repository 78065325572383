/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RankingItem_user = {
    readonly id: string;
    readonly isMe: boolean;
    readonly fullNameForDuelling: string;
    readonly profileImageForDuelling: string | null;
    readonly hasAnonymousDuellingEnabled: boolean;
    readonly " $refType": "RankingItem_user";
};
export type RankingItem_user$data = RankingItem_user;
export type RankingItem_user$key = {
    readonly " $data"?: RankingItem_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RankingItem_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingItem_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMe",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullNameForDuelling",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImageForDuelling",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAnonymousDuellingEnabled",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": "__isUser"
} as any;
(node as any).hash = 'e2ce0ac4e2142c84739fda7fcfc0fd4d';
export default node;

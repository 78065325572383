import { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { MatchView_feedback$key } from '../../../generated/MatchView_feedback.graphql'
import {
  MatchView_item,
  MatchView_item$key,
} from '../../../generated/MatchView_item.graphql'

import { assert } from '../../../utils/assert'
import { useEnterKeyHandler } from '../../../utils/handleEnterKey'
import {
  MATCHVIEW_LISTID,
  useAnswerCategories,
} from '../../../utils/hooks/useAnswerCategories'
import {
  BrainItemProps,
  BrainItemState,
} from '../../../utils/hooks/useBrainItems'

import { PrimaryButton } from '../../common/PrimaryButton'
import { FeedbackDrawer } from '../FeedbackDrawer'
import { BrainItemCompletionInput } from '../StreamItem'

import { AnswerCategory } from './elements/AnswerCategory'
import {
  AnswerOption,
  AnswerOptionFeedback,
  AnswerOptionProps,
  AnswerOptionViewType,
} from './elements/AnswerOption'
import { DraggableAnswerOption } from './elements/DraggableAnswerOption'
import { Question } from './elements/Question'
import { QuestionInstruction } from './elements/QuestionInstruction'
import styles from './MatchView.scss'
import { QuestionHandlers } from './QuestionView'

export interface MatchViewProps {
  feedback: MatchView_feedback$key | null
  item: MatchView_item$key
}

export function MatchView(
  props: MatchViewProps & BrainItemProps & QuestionHandlers
): ReactElement {
  const { t, i18n } = useTranslation()

  const item = useFragment(
    graphql`
      fragment MatchView_item on MatchQuestion {
        ...useAnswerCategories_question
        id
        answerCategories {
          id
          text(language: $language)
          ...AnswerCategory_category
        }
        answerOptions {
          id
          ...AnswerOption_answer
        }
        questionType
        text(language: $language)
        ...FeedbackDrawer_brainItem
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment MatchView_feedback on BrainItemCompletionData {
        __typename
        ...FeedbackDrawer_feedback
        ... on MatchQuestionCompletionData {
          correct
          answerCategories {
            category {
              id
            }
            answers {
              id
            }
          }
          ...useAnswerCategories_feedback
        }
      }
    `,
    props.feedback
  )

  const [matchCategoryAnswers, moveAnswerToCategory] = useAnswerCategories(
    item,
    feedback?.__typename === 'MatchQuestionCompletionData' ? feedback : null
  )

  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false)

  const answerData =
    props.brainItemState > BrainItemState.CHECKING ? feedback : undefined

  const availableAnswerOptions = (matchCategoryAnswers[MATCHVIEW_LISTID] || [])
    .map((id) => item.answerOptions.find((answer) => answer.id === id))
    .filter(
      (answer): answer is MatchView_item['answerOptions'][0] =>
        answer !== undefined
    )

  const onButtonClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()
      if (props.brainItemState === BrainItemState.READY) {
        const answerCategories: BrainItemCompletionInput['answerCategories'] =
          item.answerCategories.map((category) => {
            return {
              id: category.id,
              answerOptions: (matchCategoryAnswers[category.id] || []).slice(),
            }
          })

        props.onSubmit({
          answerCategories,
        })
      }
    },
    [matchCategoryAnswers, item.answerCategories, props]
  )
  const buttonEnterHandler = useEnterKeyHandler(onButtonClicked)

  const toggleShowCorrectAnswer = useCallback(
    (event: SyntheticEvent): void => {
      setShowCorrectAnswers(!showCorrectAnswers)

      event.preventDefault()
    },
    [showCorrectAnswers]
  )
  const toggleShowCorrectAnswerKeyPress = useEnterKeyHandler(
    toggleShowCorrectAnswer
  )

  const onDragStart = useCallback(
    (start: DragStart, provided: ResponderProvided) => {
      provided.announce(
        t('common.matchQuestions.itemLiftedInPositionX', {
          sourceIndex: start.source.index,
        })
      )
    },
    [t]
  )

  const onDragUpdate = useCallback(
    (update: DragUpdate, provided: ResponderProvided) => {
      let message = ''
      if (!update.destination) {
        // No destination
        message = t('common.matchQuestions.notOverADraggableArea')
      } else if (update.source.droppableId != update.destination.droppableId) {
        // Item moved to another droppable area
        const sourceCategory = item.answerCategories.find(
          (c) => c.id == update.source.droppableId
        )
        const destinationCategory = item.answerCategories.find(
          (c) => c.id == update.destination?.droppableId
        )
        message = t('common.matchQuestions.itemMovedToOtherDroppableArea', {
          sourceIndex: update.source.index,
          sourceArea: sourceCategory
            ? sourceCategory?.text.replace(/<[^>]+>/g, '')
            : t('common.matchQuestions.startingDroppableAreaName'),
          destinationIndex: update.source.index,
          destinationArea: destinationCategory?.text.replace(/<[^>]+>/g, ''),
        })
      } else {
        // Item moved within same droppable area
        message = t('common.matchQuestions.itemMovedToPositionX', {
          destinationIndex: update.destination.index,
        })
      }

      provided.announce(message)
    },
    [item.answerCategories, t]
  )

  const onDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination || result.reason === 'CANCEL') {
        // Item movement canceled
        provided.announce(t('common.matchQuestions.itemDropCanceled'))
        return
      }
      if (result.source.droppableId === result.destination.droppableId) {
        // Dropped on the same list, and we don't do anything with reordering.
        return false
      }

      const sourceCategory = item.answerCategories.find(
        (c) => c.id == result.source.droppableId
      )
      const destinationCategory = item.answerCategories.find(
        (c) => c.id == result.destination?.droppableId
      )

      if (!destinationCategory) {
        return
      }

      // Find the answer in the source.
      const sourceAnswers =
        matchCategoryAnswers[result.source.droppableId] || []
      const answer = sourceAnswers[result.source.index]

      provided.announce(
        t('common.matchQuestions.itemMovedToOtherDroppableArea', {
          sourceIndex: result.source.index,
          sourceArea: sourceCategory
            ? sourceCategory?.text.replace(/<[^>]+>/g, '')
            : t('common.matchQuestions.startingDroppableAreaName'),
          destinationIndex: result.source.index,
          destinationArea: destinationCategory?.text.replace(/<[^>]+>/g, ''),
        })
      )
      moveAnswerToCategory(destinationCategory.id, answer)
    },
    [item.answerCategories, matchCategoryAnswers, moveAnswerToCategory, t]
  )

  if (answerData && answerData.__typename !== 'MatchQuestionCompletionData') {
    throw new Error(
      'Invalid answer data for match question: ' + JSON.stringify(answerData)
    )
  }

  let answersCorrect = 0
  if (
    props.brainItemState === BrainItemState.FEEDBACK &&
    answerData?.answerCategories
  ) {
    answerData.answerCategories.forEach((answerCategory) => {
      const answered = matchCategoryAnswers[answerCategory.category.id] || []

      // Increment answers correct with the number of answers of this category
      // that we actually put in this category.
      answersCorrect += answerCategory.answers.filter((answer) =>
        answered.includes(answer.id)
      ).length
    })
  }

  const renderAnswerOption = (
    answer: MatchView_item['answerOptions'][0],
    index: number,
    draggable = true,
    categoryId?: string
  ): ReactElement => {
    let answerFeedback: AnswerOptionFeedback | undefined
    if (
      categoryId &&
      props.brainItemState === BrainItemState.FEEDBACK &&
      answerData
    ) {
      if (item.questionType !== 'KNOWLEDGE') {
        answerFeedback = AnswerOptionFeedback.POLL
      } else {
        assert(
          answerData.__typename === 'MatchQuestionCompletionData' &&
            answerData.answerCategories !== null
        )

        const correctAnswersForThisCategory = (
          answerData.answerCategories.find(
            (answerCategory) => answerCategory.category.id == categoryId
          )?.answers || []
        ).map((answerInCategory) => answerInCategory.id)
        answerFeedback = correctAnswersForThisCategory.includes(answer.id)
          ? AnswerOptionFeedback.CORRECT
          : AnswerOptionFeedback.INCORRECT
      }
    }

    const sharedProperties: Omit<AnswerOptionProps, 'ref' | 'type'> = {
      answer,
      checking: props.brainItemState === BrainItemState.CHECKING,
      className: categoryId ? undefined : styles.answerOption,
      disabled: !draggable || props.brainItemState > BrainItemState.READY,
      feedback: answerFeedback,
      key: answer.id,
      selected: false,
    }

    return draggable ? (
      <DraggableAnswerOption
        {...sharedProperties}
        answerId={answer.id}
        index={index}
      />
    ) : (
      <AnswerOption
        {...sharedProperties}
        type={
          item.questionType === 'KNOWLEDGE'
            ? AnswerOptionViewType.DRAGGABLE
            : AnswerOptionViewType.POLL
        }
      />
    )
  }

  const numberFormat = new Intl.NumberFormat(i18n.language)

  return (
    <div className={styles.question}>
      <Question text={item.text} />
      <QuestionInstruction
        text={
          item.questionType !== 'KNOWLEDGE'
            ? t('streamItem.match.challengingText')
            : answerData
            ? showCorrectAnswers
              ? t('streamItem.match.showingCorrectAnswer')
              : t('streamItem.match.correct', {
                  max: numberFormat.format(item.answerOptions.length),
                  num: numberFormat.format(answersCorrect),
                })
            : t('streamItem.match.helpText')
        }
      />

      <form aria-readonly={props.brainItemState !== BrainItemState.READY}>
        <DragDropContext
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          {availableAnswerOptions.length > 0 && (
            <div
              className={styles.answersStack}
              role='listbox'
              aria-label={t('streamItem.match.helpText')}
            >
              <Droppable droppableId={MATCHVIEW_LISTID} isDropDisabled={true}>
                {(provided) => (
                  <div
                    className={styles.answerOptions}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {availableAnswerOptions.map((answer, index) =>
                      renderAnswerOption(answer, index, index === 0)
                    )}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}

          {item.answerCategories.map((category) => {
            const items = (
              showCorrectAnswers &&
              answerData &&
              answerData.__typename === 'MatchQuestionCompletionData' &&
              answerData.answerCategories
                ? // When showing the correct answers, we resolve the IDs returned in
                  // the answer data to the answer options in the initial props.
                  (
                    answerData.answerCategories.find(
                      (answerCategory) =>
                        answerCategory.category.id === category.id
                    )?.answers || []
                  ).map((answer) => answer.id)
                : // Otherwise fetch what the user got from the state.
                  matchCategoryAnswers[category.id] || []
            ).map(
              (id) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item.answerOptions.find((answer) => answer.id == id)!
            )

            return (
              <AnswerCategory
                brainItemState={props.brainItemState}
                category={category}
                itemCount={items.length}
                numberBubbleClassName={styles.answerCategoryNumberBubble}
                key={category.id}
                tabindex={0}
              >
                {items.map((answer, index) =>
                  renderAnswerOption(answer, index, true, category.id)
                )}
              </AnswerCategory>
            )
          })}
        </DragDropContext>
      </form>

      {props.brainItemState < BrainItemState.FEEDBACK || !answerData ? (
        <div className={styles.buttonContainer}>
          <PrimaryButton
            disabled={
              (matchCategoryAnswers[MATCHVIEW_LISTID]?.length || 0) > 0 ||
              props.brainItemState === BrainItemState.CHECKING
            }
            onClick={onButtonClicked}
            onKeyPress={buttonEnterHandler}
          >
            {props.brainItemState === BrainItemState.READY &&
              (item.questionType === 'KNOWLEDGE'
                ? t('common.CheckAnswers')
                : t('common.Answer'))}
            {props.brainItemState === BrainItemState.CHECKING &&
              t('common.Checking')}
            {props.brainItemState === BrainItemState.FEEDBACK &&
              t('common.Close')}
          </PrimaryButton>
        </div>
      ) : (
        <FeedbackDrawer
          brainItem={item}
          brainItemState={props.brainItemState}
          feedback={answerData}
          heading={
            item.questionType !== 'KNOWLEDGE'
              ? t('streamItem.match.pollSubmittedFeedback')
              : answerData.correct
              ? t('streamItem.brainItem.correct')
              : props.inOnboarding
              ? t('streamItem.brainItem.almostCorrect')
              : t('streamItem.brainItem.incorrect')
          }
          inDuel={!!props.inDuel}
          inOnboarding={!!props.inOnboarding}
          onBoardingItemsLeft={!!props.onboardingItemsLeft}
          onClose={props.onClose}
        >
          {'correct' in answerData && answerData.correct === false && (
            <a
              className={styles.toggleCorrectAnswersLink}
              href='#'
              onClick={toggleShowCorrectAnswer}
              onKeyPress={toggleShowCorrectAnswerKeyPress}
              tabIndex={0}
            >
              {showCorrectAnswers
                ? t('streamItem.match.showMyAnswer')
                : t('streamItem.match.showCorrectAnswer')}
            </a>
          )}
        </FeedbackDrawer>
      )}
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useAnswerCategories_question = {
    readonly answerCategories: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly answerOptions: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "useAnswerCategories_question";
};
export type useAnswerCategories_question$data = useAnswerCategories_question;
export type useAnswerCategories_question$key = {
    readonly " $data"?: useAnswerCategories_question$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useAnswerCategories_question">;
};



const node: ReaderInlineDataFragment = {
    "kind": "InlineDataFragment",
    "name": "useAnswerCategories_question"
} as any;
(node as any).hash = '06ec84e88548df45024f38d4c2140f8e';
export default node;

import { useState } from 'react'

export const enum BrainItemState {
  READY,
  CHECKING,
  FEEDBACK,
  WELL_DONE,
  COMPLETED,
}

export interface BrainItemProps {
  brainItemState: BrainItemState
  setBrainItemState: Setter<BrainItemState>
}

type Setter<T> = (value: T) => void

export function useBrainItems(
  initialState = BrainItemState.READY
): [BrainItemState, Setter<BrainItemState>] {
  const [state, setState] = useState(initialState)

  return [state, setState]
}

import * as Sentry from '@sentry/browser'
import { observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router-dom'
import { Context, FormEvent, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EnvironmentContext, useEnvironment } from '../../../App'

import { AuthAlert } from '../../auth/AuthAlert'
import { AuthFooter } from '../../auth/AuthFooter'
import { AuthHeader } from '../../auth/AuthHeader'
import { Page } from '../../../containers/Page'
import { AcceptPrivacyPolicy } from '../../common/AcceptPrivacyPolicy'
import { PrimaryButton } from '../../common/PrimaryButton'
import { PasswordInput } from '../../common/PasswordInput'
import { useStores } from '../../../stores'

import styles from './AuthenticationPasswordCompleteSsoPage.scss'
import { useMutation, useRelayEnvironment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { AuthenticationPasswordCompleteSsoPageSetPasswordMutation } from '../../../generated/AuthenticationPasswordCompleteSsoPageSetPasswordMutation.graphql'
import { Severity } from '../../../stores/authStore'

interface AuthenticationPasswordCompleteSsoPageProps {
  idp: string
  email: string
  token: string
}

type SsoIdpConfiguration = (typeof EnvironmentContext extends Context<infer T>
  ? T
  : never)['singleSignOn']['idps'][0]

function getCorrectTranslation(
  translationOptions: SsoIdpConfiguration['displayName'],
  languages: string[]
): string {
  for (const language of languages) {
    const translation = translationOptions.find(
      (name) => name.language === language
    )
    if (translation) {
      return translation.value
    }
  }

  return translationOptions[0].value
}

export const AuthenticationPasswordCompleteSsoPage = observer(
  function AuthenticationPasswordCompleteSsoPage(
    props: RouteComponentProps<AuthenticationPasswordCompleteSsoPageProps>
  ): ReactElement {
    const {
      pageTitle: appName,
      language: defaultLanguage,
      singleSignOn,
    } = useEnvironment()
    const { authStore, commonStore } = useStores()
    const { i18n, t } = useTranslation()
    const relayEnvironment = useRelayEnvironment()

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [passwordsValidated, setPasswordsValidated] = useState(false)
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)

    const [submitNewPassword] =
      useMutation<AuthenticationPasswordCompleteSsoPageSetPasswordMutation>(graphql`
        mutation AuthenticationPasswordCompleteSsoPageSetPasswordMutation(
          $email: String!
          $token: String!
          $password: String!
          $passwordConfirmation: String!
          $deviceName: String!
          $requestAccessToken: Boolean!
        ) {
          completeHybridSso(
            email: $email
            token: $token
            password: $password
            passwordConfirmation: $passwordConfirmation
          ) {
            message
            success
            result {
              accessToken(deviceName: $deviceName)
                @include(if: $requestAccessToken)
              user {
                __typename
                id
                email
                language
              }
            }
          }
        }
      `)

    const onSubmit = useCallback(
      (event: FormEvent): void => {
        authStore.setAuthLoading(true)
        event.preventDefault()

        submitNewPassword({
          variables: {
            email: decodeURIComponent(props.match.params.email),
            token: props.match.params.token,
            deviceName: navigator.userAgent,
            requestAccessToken: !!process.env.WEBPACK_DEV_SERVER,
            password,
            passwordConfirmation,
          },
        })
          .then(({ completeHybridSso: response }) => {
            if (!response.success && response.message) {
              authStore.setAuthMessage(response.message, Severity.ERROR)
            }

            if (response.result) {
              authStore.attemptLoginSuccess(response.result.accessToken)

              commonStore.setLanguage(response.result.user.language)

              const id = response.result.user.id
              Sentry.setUser({
                email: response.result.user.email,
                id,
              })

              relayEnvironment.commitUpdate((store) => {
                const record = store.get(id)
                if (record) {
                  store.getRoot().setLinkedRecord(record, 'me')
                }
              })
            }

            if (response.success) {
              props.history.push('/')
            }
          })
          .finally(() => authStore.setAuthLoading(false))
      },
      [
        authStore,
        commonStore,
        password,
        passwordConfirmation,
        props.history,
        props.match.params.email,
        props.match.params.token,
        relayEnvironment,
        submitNewPassword,
      ]
    )

    // Find the relevant IdP.
    const idp = singleSignOn.idps.find(
      (item) => item.name === props.match.params.idp
    )
    if (!idp) {
      // Now what? Go back to the login screen I guess.
      props.history.push('/login')
      return <></>
    }

    return (
      <>
        <AuthHeader />

        <Page hideWatermark narrow>
          <div className={styles.formGroup + ' ' + styles.formHeader}>
            <div className={styles.head}>
              {t('auth.passwords.passwordCompleteSsoTitle')}
            </div>
            <div className={styles.sub}>
              {idp.setPasswordInstruction
                ? getCorrectTranslation(idp.setPasswordInstruction, [
                    i18n.language,
                    defaultLanguage,
                  ])
                : t('auth.passwords.passwordCompleteSsoText', {
                    app: appName,
                    idpText: getCorrectTranslation(idp.displayName, [
                      i18n.language,
                      defaultLanguage,
                    ]),
                  })}
            </div>
          </div>

          <form
            action='#'
            method='post'
            className={styles.formContainer}
            onSubmit={onSubmit}
          >
            <AuthAlert />

            <PasswordInput
              email={props.match.params.email}
              token={props.match.params.token}
              onPasswordChanged={setPassword}
              onPasswordConfirmationChanged={setPasswordConfirmation}
              onPasswordsValidated={setPasswordsValidated}
            />

            <AcceptPrivacyPolicy
              privacyPolicyAccepted={privacyPolicyAccepted}
              change={setPrivacyPolicyAccepted}
            />

            <div className={styles.formFooter}>
              <div>
                <PrimaryButton
                  disabled={
                    authStore.authLoading ||
                    !passwordsValidated ||
                    !privacyPolicyAccepted
                  }
                  type='submit'
                  className={styles.primaryButton}
                >
                  {t('auth.action.savePassword')}
                </PrimaryButton>
              </div>
            </div>
          </form>
        </Page>

        <AuthFooter />
      </>
    )
  }
)

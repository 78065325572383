import { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { TextItemView_pages$key } from '../../../generated/TextItemView_pages.graphql'
import { BrainItemProps } from '../../../utils/hooks/useBrainItems'
import styles from '../questions/Question.scss'
import { TextItemButton } from './TextItemButton'

import { TextItemPage } from './TextItemPage'

export const enum ButtonType {
  Close,
  Accept,
  ContinueAnyway,
}

export type BrainSnackViewProps = BrainItemProps & {
  buttonType: ButtonType
  heading?: string
  inOnboarding?: boolean
  onboardingItemsLeft?: boolean
  onClose(event: SyntheticEvent): void
  pages: TextItemView_pages$key
}

export function TextItemView(props: BrainSnackViewProps): ReactElement {
  const isPolicy = props.buttonType === ButtonType.Accept
  const [closeDisabled, setCloseDisabled] = useState(isPolicy)

  useEffect(() => {
    if (closeDisabled) {
      setTimeout((): void => {
        setCloseDisabled(false)
      }, 3000)
    }
  })

  const pages = useFragment(
    graphql`
      fragment TextItemView_pages on BrainSnackPage @relay(plural: true) {
        ...TextItemPage_page
      }
    `,
    props.pages
  )

  return (
    <article>
      {props.heading && <h4>{props.heading}</h4>}

      {pages.map(
        (page, index): ReactElement => (
          <TextItemPage index={index} key={index} page={page} />
        )
      )}

      <div className={styles.buttonContainer}>
        <TextItemButton
          closeDisabled={closeDisabled}
          brainItemState={props.brainItemState}
          onClick={props.onClose}
          buttonType={props.buttonType}
          inOnboarding={props.inOnboarding}
          onboardingItemsLeft={props.onboardingItemsLeft}
        />
      </div>
    </article>
  )
}

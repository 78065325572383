/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type FeedbackDrawer_feedback = ({
    readonly __typename: "GenericQuestionCompletionData";
    readonly correct: boolean | null;
} | {
    readonly __typename: "MatchQuestionCompletionData";
    readonly correct: boolean | null;
} | {
    readonly __typename: "SliderQuestionCompletionData";
    readonly correct: boolean | null;
} | {
    readonly __typename: "BrainSnackCompletionData" | "PollQuestionCompletionData";
}) & {
    readonly feedbackText: string | null;
    readonly scoreChange: {
        readonly __typename: string;
    } | null;
    readonly " $refType": "FeedbackDrawer_feedback";
};
export type FeedbackDrawer_feedback$data = FeedbackDrawer_feedback;
export type FeedbackDrawer_feedback$key = {
    readonly " $data"?: FeedbackDrawer_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_feedback">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "correct",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "FeedbackDrawer_feedback",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "language",
                        "variableName": "language"
                    }
                ],
                "kind": "ScalarField",
                "name": "feedbackText",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScoreChange",
                "kind": "LinkedField",
                "name": "scoreChange",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v1 /*: any*/),
                "type": "GenericQuestionCompletionData",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v1 /*: any*/),
                "type": "MatchQuestionCompletionData",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v1 /*: any*/),
                "type": "SliderQuestionCompletionData",
                "abstractKey": null
            }
        ],
        "type": "BrainItemCompletionData",
        "abstractKey": "__isBrainItemCompletionData"
    } as any;
})();
(node as any).hash = 'd0fd84f3aa3682bf5725a7bbd84886f6';
export default node;

import { observer } from 'mobx-react'
import { PropsWithChildren, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { RouteComponentProps } from 'react-router'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import { Avatar, AvatarContext } from '../../components/common/Avatar'
import { EditableAvatar } from '../../components/common/EditableAvatar'
import { NumberBubble } from '../../components/common/NumberBubble'
import { Tab } from '../../components/common/Tab'
import { DuelPage_duelInfo$key } from '../../generated/DuelPage_duelInfo.graphql'
import { DuelPageUserQuery } from '../../generated/DuelPageUserQuery.graphql'
import { useStores } from '../../stores'
import { Header } from '../Header'
import { Page } from '../Page'
import { TabContainer } from '../TabContainer'

import styles from './DuelPage.scss'

export const DuelPage = observer(function DuelPage(
  props: PropsWithChildren<
    RouteComponentProps & { duelInfo?: DuelPage_duelInfo$key | null }
  >
): ReactElement {
  const { commonStore } = useStores()
  const { t, i18n } = useTranslation()

  const user = useQuery<DuelPageUserQuery>(graphql`
    query DuelPageUserQuery {
      me {
        id
        fullName
        hasDuellingAvailable
        hasDuellingEnabled
        profileImage
      }
    }
  `)
  const duelInfo = useFragment(
    graphql`
      fragment DuelPage_duelInfo on UserDuelInfo {
        rank
        rating
      }
    `,
    props.duelInfo || null
  )

  useEffect((): void => {
    if (
      user.data?.me &&
      (!user.data?.me.hasDuellingAvailable || !user.data?.me.hasDuellingEnabled)
    ) {
      if (!commonStore.hasPopup('duelling-not-available')) {
        commonStore.openPopup({ type: 'duelling-not-available' })
      }

      props.history.goBack()
    }
  }, [user, commonStore, props.history])

  return (
    <>
      <Header wide>
        <div className={styles.duelHeader}>
          <div className={styles.info}>
            <div className={styles.duelScore}>
              {t('duels.title', {
                score: duelInfo?.rating
                  ? Intl.NumberFormat(i18n.language).format(duelInfo.rating)
                  : '',
              })}
            </div>
            <div className={styles.motivational}>{t('duels.readyToDuel')}</div>
          </div>
          <EditableAvatar>
            {user.data?.me ? (
              <Avatar
                filename={user.data?.me.profileImage}
                name={user.data?.me.fullName}
                userId={user.data?.me.id}
                context={AvatarContext.default}
              />
            ) : (
              <Avatar
                filename={null}
                name={''}
                userId={''}
                context={AvatarContext.default}
              />
            )}

            <NumberBubble
              className={styles.avatarRanking}
              sup={duelInfo?.rank ? '#' : undefined}
              text={duelInfo?.rank || '?'}
              scaling
            />
          </EditableAvatar>
        </div>
      </Header>

      <TabContainer>
        <Tab to='/duels' label={t('menu.duels')} />
        <Tab to='/duels/ranking' label={t('menu.ranking')} />
        <Tab to='/duels/completed' label={t('menu.completedDuels')} />
      </TabContainer>

      <Page>{props.children}</Page>
    </>
  )
})

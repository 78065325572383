import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { CompletedDuelSlot_item$key } from '../../generated/CompletedDuelSlot_item.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { Avatar, AvatarContext } from '../common/Avatar'

import styles from './CompletedDuelSlot.scss'
import { DuelRoundsOverview } from './DuelRoundsOverview'

export interface CompletedDuelSlotProps {
  item: CompletedDuelSlot_item$key | null
}

export function CompletedDuelSlot(props: CompletedDuelSlotProps): ReactElement {
  const { t, i18n } = useTranslation()
  const [expanded, toggleExpanded] = useReducer((previous) => !previous, false)
  const toggleExpandedKeyPressHandler = useEnterKeyHandler(toggleExpanded)

  const item = useFragment(
    graphql`
      fragment CompletedDuelSlot_item on Duel {
        status
        players {
          rating
          timedOut
          user {
            id
            nameForDuelling
            isMe
            profileImageForDuelling
            hasAnonymousDuellingEnabled
          }
          rounds {
            ...DuelRoundsOverview_left
            ...DuelRoundsOverview_right
          }
        }
        ...DuelRoundsOverview_duel
      }
    `,
    props.item
  )

  const left = item ? item.players.find((player) => !player.user.isMe) : null
  const right = item ? item.players.find((player) => player.user.isMe) : null

  if ((!left || !right) && item) {
    // TODO: Should we report this somehow?
    return <></>
  }

  return (
    <div
      className={classNames(
        styles.completedDuelSlotOuter,
        styles[item ? item.status.toLowerCase() : 'won'],
        {
          [styles.expanded]: expanded,
          [styles.placeholder]: !item,
        }
      )}
    >
      <div
        className={styles.completedDuelSlot}
        onClick={item ? toggleExpanded : undefined}
        onKeyPress={item ? toggleExpandedKeyPressHandler : undefined}
        role='listitem'
        tabIndex={item ? 0 : undefined}
      >
        <div className={styles.summary}>
          {left ? (
            <Avatar
              filename={left.user.profileImageForDuelling}
              name={left.user.nameForDuelling}
              className={styles.avatar}
              userId={left.user.id}
              context={AvatarContext.duelling}
              hasAnonymousDuellingEnabled={
                left.user.hasAnonymousDuellingEnabled
              }
            />
          ) : (
            <span className={styles.avatar} />
          )}

          <div className={styles.horizontal}>
            <div className={styles.responsive}>
              <span className={styles.name}>
                {left ? left.user.nameForDuelling : t('common.Name')}
              </span>
              <span className={styles.rating}>
                <FontAwesomeIcon icon={faStar} />{' '}
                {Intl.NumberFormat(i18n.language).format(
                  left ? left.rating : 0
                )}
              </span>
            </div>

            <div className={styles.responsive}>
              <div className={styles.status}>
                {t(
                  'activeDuels.status.' +
                    (item ? item.status.toLowerCase() : 'won')
                )}
              </div>
            </div>
          </div>
        </div>

        {expanded && left && right && (
          <div className={styles.rounds}>
            <DuelRoundsOverview
              left={left.rounds}
              leftTimedOut={left.timedOut}
              right={right.rounds}
              rightTimedOut={right.timedOut}
              duel={item}
            />
          </div>
        )}
      </div>

      <div className={styles.attachment} onClick={toggleExpanded}>
        {item && (
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        )}
      </div>
    </div>
  )
}

import { observer } from 'mobx-react'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
import { useFragment } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { useEnvironment } from '../../App'
import { RoutingGuestRoute_user$key } from '../../generated/RoutingGuestRoute_user.graphql'
import { RoutingPrivateRoute_user$key } from '../../generated/RoutingPrivateRoute_user.graphql'
import { RoutingUserQuery } from '../../generated/RoutingUserQuery.graphql'

import { useStores } from '../../stores'
import { CommonStore } from '../../stores/commonStore'
import { classNames } from '../../utils/classNames'
import { AuthenticationCmsRedirector } from '../pages/auth/AuthenticationCmsRedirector'
import { AuthenticationForgotPasswordPage } from '../pages/auth/AuthenticationForgotPasswordPage'
import { AuthenticationLoggedOutPage } from '../pages/auth/AuthenticationLoggedOutPage'
import { AuthenticationLoginPage } from '../pages/auth/AuthenticationLoginPage'
import { AuthenticationLogoutPage } from '../pages/auth/AuthenticationLogoutPage'
import { AuthenticationPasswordCompleteSsoPage } from '../pages/auth/AuthenticationPasswordCompleteSsoPage'
import { AuthenticationPasswordExpiredPage } from '../pages/auth/AuthenticationPasswordExpiredPage'

import { SelfActivatePage } from '../pages/auth/SelfActivatePage'
import { SetPassword, SetPasswordAction } from '../pages/auth/SetPassword'
import { ActiveDuels } from '../pages/duels/ActiveDuels'
import { CompletedDuels } from '../pages/duels/CompletedDuels'
import { RankingMenu } from '../pages/duels/RankingMenu'
import { SelectTopic } from '../pages/learn/SelectTopic'
import { Stream } from '../pages/learn/Stream'
import { MainMenu } from '../pages/MainMenu'
import { OnboardingItemPage } from '../pages/onboarding/OnboardingItemPage'
import { TimeLine } from '../pages/onboarding/TimeLine'
import { TopicCategories } from '../pages/onboarding/TopicCategories'

import { ErrorBoundary } from './ErrorBoundary'
import { Navigationbar } from './NavigationBar'
import { Popup } from './Popup'
import styles from './Routing.scss'

type GuestRouteProps<Path extends string> = Omit<RouteProps<Path>, 'render'> &
  Required<Pick<RouteProps<Path>, 'render'>> & {
    forceLogout?: boolean
    user?: RoutingGuestRoute_user$key | null
  }

type PrivateRouteProps<Path extends string> = Omit<RouteProps<Path>, 'render'> &
  Required<Pick<RouteProps<Path>, 'render'>> & {
    onboarding?: boolean
    powerapp?: boolean
    user?: RoutingPrivateRoute_user$key | null
  }

// The reason this codeblock is reused with a minor tweak is simple
// Like the PrivateRoute but the user check is reversed to continue with logging in
// The purpose is to build the function component that redirect guests to another page than users.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function GuestRoute<Path extends string = string>(
  routeProps: GuestRouteProps<Path>
): ReactElement {
  const { render, forceLogout, ...rest } = routeProps
  const { authStore, commonStore } = useStores()

  const user = useFragment(
    graphql`
      fragment RoutingGuestRoute_user on AuthenticatedUser {
        loginType
      }
    `,
    routeProps.user || null
  )

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !forceLogout) {
          let url = '/'
          if (commonStore.postAuthRedirect) {
            url = commonStore.postAuthRedirect

            commonStore.setPostAuthRedirect(undefined)
          }

          return <Redirect to={url} />
        }

        if (user && forceLogout) {
          authStore.logout(user.loginType)
        }

        return render(props)
      }}
    />
  )
}

function PrivateRoute<Path extends string = string>(
  routeProps: PrivateRouteProps<Path>
): ReactElement {
  const { render, ...rest } = routeProps
  const { commonStore } = useStores()

  const user = useFragment(
    graphql`
      fragment RoutingPrivateRoute_user on AuthenticatedUser {
        isOnboarding
      }
    `,
    routeProps.user || null
  )

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          (routeProps.onboarding && user && !user.isOnboarding) ||
          (routeProps.powerapp && user && user.isOnboarding)
        ) {
          return <Redirect to='/' />
        }

        if (user) {
          const target = commonStore.postAuthRedirect
          if (target) {
            commonStore.setPostAuthRedirect(undefined)
            return <Redirect to={target} />
          }

          return render(props)
        }

        commonStore.setPostAuthRedirect(
          props.location.pathname + props.location.search
        )

        return <Redirect to='/login' />
      }}
    />
  )
}

export const Routing = observer(function Routing(): ReactElement {
  const { singleSignOn } = useEnvironment()
  const { commonStore } = useStores()
  const [privacyPolicyPopupShown, setPrivacyPolicyPopupShown] = useState(false)

  const getUserConfirmation = useCallback(
    (_, callback): void => {
      commonStore.openPopup({ type: 'confirm-stop-practicing', callback })
    },
    [commonStore]
  )

  const user = useQuery<RoutingUserQuery>(graphql`
    query RoutingUserQuery {
      me {
        firstLogin
        idp
        isOnboarding
        loginType
        ...NavigationBar_user
        ...RoutingGuestRoute_user
        ...RoutingPrivateRoute_user
      }
    }
  `)

  useEffect(() => {
    if (
      !privacyPolicyPopupShown &&
      user.data?.me &&
      user.data.me.firstLogin &&
      user.data.me.loginType === 'SAML' &&
      !singleSignOn.idps.find((idp) => idp.name === user.data?.me?.idp)
        ?.isHybrid
    ) {
      commonStore.openPopup({
        type: 'privacy-policy',
      })
      setPrivacyPolicyPopupShown(true)
    }
  }, [user, commonStore, privacyPolicyPopupShown, singleSignOn.idps])

  const me = user.data?.me
  const showNavBar = me && !commonStore.navBarHidden
  const popup = commonStore.popups.find((p) =>
    CommonStore.canShowPopup(p, user.data?.me)
  )

  useEffect(() => {
    // Set commonStore to initialized as soon as user data is loaded.
    if (user.data) {
      commonStore.setAppInitialized(true)
    }
  }, [commonStore, user.data])

  // Prevent flash of guest route when logged in
  if (!user.data) {
    return <></>
  }

  return (
    <ErrorBoundary>
      <Router basename='/app' getUserConfirmation={getUserConfirmation}>
        <div
          className={classNames({
            [styles.routerWithNavigationBar]: showNavBar,
            [styles.routerWithItemNavigationBar]:
              commonStore.itemNavigationBarShown,
            [styles.onFire]: user.data?.me?.isOnboarding,
          })}
        >
          {showNavBar && <Navigationbar user={me} />}

          {popup && (
            <Popup
              {...popup}
              key={
                // Force re-render when popup changes.
                popup.type + ('popupKey' in popup ? popup.popupKey : '')
              }
            />
          )}

          <div className={styles.route}>
            <Switch>
              <GuestRoute
                exact
                path='/login'
                render={(props) => <AuthenticationLoginPage {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                path='/login/cms'
                render={(props) => <AuthenticationCmsRedirector {...props} />}
                user={user.data?.me}
              />

              <Route
                exact
                path='/logout'
                render={() => <AuthenticationLogoutPage />}
              />

              <Route
                exact
                path='/logged-out'
                render={() => <AuthenticationLoggedOutPage />}
              />

              <GuestRoute
                exact
                path='/password/remind'
                render={(props) => (
                  <AuthenticationForgotPasswordPage {...props} />
                )}
                user={user.data?.me}
              />

              <GuestRoute
                exact
                path='/password/expired/:email/:days/:token'
                render={(props) => (
                  <AuthenticationPasswordExpiredPage {...props} />
                )}
                user={user.data?.me}
              />

              <GuestRoute
                exact
                path='/password/complete-sso/:idp/:email/:token'
                forceLogout
                render={(props) => (
                  <AuthenticationPasswordCompleteSsoPage {...props} />
                )}
                user={user.data?.me}
              />

              <GuestRoute
                exact
                path='/password/accept-invitation/:token/:email?/:language?'
                forceLogout
                render={(props) => (
                  <SetPassword
                    action={SetPasswordAction.Activation}
                    {...props}
                  />
                )}
                user={user.data?.me}
              />

              <GuestRoute
                exact
                path='/password/self-activate'
                forceLogout
                render={() => <SelfActivatePage />}
                user={user.data?.me}
              />

              <GuestRoute
                exact
                path='/password/reset/:token/:email?/:language?'
                forceLogout
                render={(props) => (
                  <SetPassword
                    action={SetPasswordAction.PasswordReset}
                    {...props}
                  />
                )}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                path='/'
                render={(props) =>
                  user.data?.me &&
                  (user.data?.me?.isOnboarding ? (
                    <TopicCategories {...props} />
                  ) : (
                    <SelectTopic {...props} />
                  ))
                }
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                onboarding
                path='/onboarding/:refId?'
                render={(props) => <TimeLine {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                onboarding
                path='/onboarding/item/:itemId?'
                render={(props) => <OnboardingItemPage {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                powerapp
                path='/practice'
                render={() => <Stream />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                powerapp
                path='/duels'
                render={(props) => <ActiveDuels {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                powerapp
                path='/duels/ranking'
                render={(props) => <RankingMenu {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                powerapp
                path='/duels/active'
                render={(props) => <ActiveDuels {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                powerapp
                path='/duels/completed'
                render={(props) => <CompletedDuels {...props} />}
                user={user.data?.me}
              />

              <PrivateRoute
                exact
                path='/settings'
                render={(props) => <MainMenu {...props} />}
                user={user.data?.me}
              />
            </Switch>
          </div>
        </div>
      </Router>
    </ErrorBoundary>
  )
})

import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { graphql } from 'react-relay'
import { readInlineData } from 'relay-runtime'
import {
  useTimeline_timeline,
  useTimeline_timeline$key,
} from '../../generated/useTimeline_timeline.graphql'

type TopicCategory = {
  readonly id: string
  readonly items: ReadonlyArray<unknown>
} & useTimeline_timeline$key

type TopicCategories = ReadonlyArray<TopicCategory>

interface TimelineProps<T extends TopicCategories> {
  canNavigateBackwards: boolean
  canNavigateForwards: boolean
  item: T[0]['items'][0] | undefined
  navigateBackwards(): void
  navigateForwards(): void
  nextLockedTopicCategoryId: string | undefined
  nextLockedItemAvailableFrom: string
  startTime: number
}

export function useTimeline<T extends TopicCategories>(
  timeline?: T,
  initialItemId?: string
): TimelineProps<T> {
  // Fetch the fragment data we need for the timeline.
  const timelineInternal = useMemo(
    () =>
      timeline?.map((category) =>
        readInlineData(
          graphql`
            fragment useTimeline_timeline on TopicCategory @inline {
              availableFrom
              id
              unlocked
              items {
                brainItem {
                  ... on Node {
                    id
                  }
                }
              }
            }
          `,
          category
        )
      ) || [],
    [timeline]
  )

  // Keep track of the index of the current item, and the associated start time.
  const startTime = useRef(new Date().getTime())
  const [indexSet, setIndexSet] = useState(false)
  const [itemIndex, setItemIndex] = useReducer(
    (_: number, newIndex: number) => {
      startTime.current = new Date().getTime()

      return newIndex
    },
    0
  )

  // When retrieving the timeline for the first time, look at the item ID in the
  // URL, and derive the correct item index.
  useEffect(() => {
    if (!indexSet && timeline && initialItemId) {
      const desiredItemId = decodeURIComponent(initialItemId)
      const index = timelineInternal
        .filter((category) => category.unlocked)
        .flatMap((category) => category.items)
        .findIndex((item) => item.brainItem.id === desiredItemId)

      if (index >= 0) {
        setItemIndex(index)
        setIndexSet(true)
      }
    }
  }, [indexSet, initialItemId, timeline, timelineInternal])

  // Get the item data from the current item index, and also set whether navigating
  // forwards or backwards is possible.
  const [item, setItem] = useState<T[0]['items'][0] | undefined>(undefined)
  const [validPreviousIndex, setValidPreviousIndex] = useState(
    itemIndex > 0 ? itemIndex - 1 : undefined
  )
  const [canNavigateBackwards, setCanNavigateBackwards] = useState(
    itemIndex > 0
  )
  const [validNextIndex, setValidNextIndex] = useState<number | undefined>(
    undefined
  )
  const [canNavigateForwards, setCanNavigateForwards] = useState(false)
  const [nextLockedTopicCategory, setNextLockedTopicCategory] = useState<
    useTimeline_timeline | undefined
  >(undefined)

  // Pick the current item from the timeline, but only after selecting the correct index (if an initial ID is given).
  useEffect((): void => {
    if (timeline && (indexSet || !initialItemId)) {
      // Map the nested categories & items to a linear timeline, pick the item, and the item from the input.
      const internalTimelineItems = timelineInternal
        .filter((category) => category.unlocked)
        .flatMap((category, categoryIndex) =>
          category.items.map((itemInternal, index) => ({
            ...itemInternal,
            categoryIndex,
            itemIndex: index,
          }))
        )
      const internalItem = internalTimelineItems[itemIndex]
      setItem(
        timeline[internalItem.categoryIndex].items[internalItem.itemIndex]
      )

      const validPreviousIndex_: number | undefined =
        itemIndex > 0 ? itemIndex - 1 : undefined

      const validNextIndex_: number | undefined =
        internalTimelineItems.length - 1 > itemIndex ? itemIndex + 1 : undefined

      if (validNextIndex_ === undefined) {
        const lockedTopicCategories = timelineInternal.filter(
          (category) => !category.unlocked
        )
        if (lockedTopicCategories.length > 0) {
          setNextLockedTopicCategory(lockedTopicCategories[0])
        }
      }

      // Update state variables.
      setValidPreviousIndex(validPreviousIndex_)
      setValidNextIndex(validNextIndex_)
      setCanNavigateBackwards(validPreviousIndex_ !== undefined)
      setCanNavigateForwards(validNextIndex_ !== undefined)
    }
  }, [indexSet, initialItemId, itemIndex, timeline, timelineInternal])

  // Handlers for navigating backwards and forwards.
  const navigateBackwards = useCallback((): void => {
    if (validPreviousIndex !== undefined) {
      setItemIndex(validPreviousIndex)
    }
  }, [validPreviousIndex])
  const navigateForwards = useCallback((): void => {
    if (validNextIndex !== undefined) {
      setItemIndex(validNextIndex)
    }
  }, [validNextIndex])

  return {
    item,
    canNavigateBackwards,
    canNavigateForwards,
    navigateBackwards,
    navigateForwards,
    nextLockedTopicCategoryId: nextLockedTopicCategory?.id,
    nextLockedItemAvailableFrom: nextLockedTopicCategory?.availableFrom ?? '',
    startTime: startTime.current,
  }
}

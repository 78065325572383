import { Dispatch, ReactElement, useEffect } from 'react'
import { usePaginationFragment } from 'react-relay'
import { graphql } from 'relay-hooks'
import { ActiveDuelsList_duels$key } from '../../generated/ActiveDuelsList_duels.graphql'
import { ActiveDuelsListPaginationQuery } from '../../generated/ActiveDuelsListPaginationQuery.graphql'
import { PlayDuelRound_duel$key } from '../../generated/PlayDuelRound_duel.graphql'
import { ConnectionAction } from '../../utils/hooks/useDuelConnections'
import {
  ActiveDuelsPopup,
  ActiveDuelsPopupAction,
  FINALIZING_STATUSES,
} from '../pages/duels/ActiveDuels'
import { ActiveDuelSlot } from './ActiveDuelSlot'

interface ActiveDuelsListProps {
  localPopups: ActiveDuelsPopup[]
  duelsConnectionIds: string[]
  duels: ActiveDuelsList_duels$key
  toDuel(duel: PlayDuelRound_duel$key): void
  updateDuelConnections: Dispatch<ConnectionAction>
  updateLocalPopups: Dispatch<ActiveDuelsPopupAction>
}

export function ActiveDuelsList(props: ActiveDuelsListProps): ReactElement {
  const {
    duelsConnectionIds,
    localPopups,
    updateDuelConnections,
    updateLocalPopups,
  } = props
  const { data } = usePaginationFragment<
    ActiveDuelsListPaginationQuery,
    ActiveDuelsList_duels$key
  >(
    graphql`
      fragment ActiveDuelsList_duels on Query
      @refetchable(queryName: "ActiveDuelsListPaginationQuery") {
        myDuels(statusFilter: ONLY_ACTIVE, first: $count, after: $cursor)
          @connection(key: "ActiveDuelsList_myDuels") {
          __id
          edges {
            node {
              id
              status
              ...ActiveDuelSlot_duel
              ...DuelFinalizingPopup_item
              ...PlayDuelRound_duel
            }
          }
        }
      }
    `,
    props.duels
  )

  useEffect(() => {
    if (!data.myDuels?.__id) {
      return
    }

    const connection = data.myDuels.__id
    updateDuelConnections({ type: 'add', connection })
  }, [data.myDuels?.__id, updateDuelConnections])

  useEffect(() => {
    if (data?.myDuels) {
      data.myDuels.edges
        .map((edge) => edge.node)
        .filter((duel) => FINALIZING_STATUSES.includes(duel.status))
        .filter(
          (item) =>
            !localPopups.find(
              (popup) =>
                popup.type === 'duel-finalizing' && item.id === popup.popupKey
            )
        )
        .forEach((item) => {
          updateLocalPopups({
            type: 'push',
            popup: {
              type: 'duel-finalizing',
              duelsConnectionIds,
              onClose: () => updateLocalPopups({ type: 'pop' }),
              item,
              popupKey: item.id,
            },
          })
        })
    }
  }, [data.myDuels, duelsConnectionIds, localPopups, updateLocalPopups])

  return (
    <>
      {data.myDuels?.edges
        .map((edge) => edge.node)
        .filter((duel) => !FINALIZING_STATUSES.includes(duel.status))
        .map(
          (duel): ReactElement => (
            <ActiveDuelSlot
              key={duel.id}
              duel={duel}
              request={null}
              onClick={(event): void => {
                event.preventDefault()

                if (duel.status === 'YOURTURN') {
                  props.toDuel(duel)
                }
              }}
            />
          )
        )}
    </>
  )
}

import { PropsWithChildren, ReactElement } from 'react'

import styles from './TabContainer.scss'

interface TabContainerProps {
  wide?: boolean
}

export function TabContainer(
  props: PropsWithChildren<TabContainerProps>
): ReactElement {
  return (
    <div className={styles.tabContainer}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div
            className={
              props.wide
                ? styles.tabContainerContentWide
                : styles.tabContainerContent
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

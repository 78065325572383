/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BrainItemHeader_brainItem = ({
    readonly __typename: "BrainSnack";
    readonly pages: ReadonlyArray<{
        readonly asset: {
            readonly translation: {
                readonly mime: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"Asset_asset">;
        } | null;
    }>;
} | {
    readonly __typename: "MatchQuestion" | "MultipleChoiceQuestion" | "MultipleSelectQuestion" | "OrderQuestion" | "SliderQuestion";
}) & {
    readonly asset?: {
        readonly translation: {
            readonly mime: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"Asset_asset">;
    } | null | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TopicIndicator_item">;
    readonly " $refType": "BrainItemHeader_brainItem";
};
export type BrainItemHeader_brainItem$data = BrainItemHeader_brainItem;
export type BrainItemHeader_brainItem$key = {
    readonly " $data"?: BrainItemHeader_brainItem$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BrainItemHeader_brainItem">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "language",
                            "variableName": "language"
                        }
                    ],
                    "concreteType": "AssetTranslation",
                    "kind": "LinkedField",
                    "name": "translation",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mime",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Asset_asset"
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "BrainItemHeader_brainItem",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TopicIndicator_item"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrainSnackPage",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": true,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "BrainSnack",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v0 /*: any*/),
                "type": "HasAsset",
                "abstractKey": "__isHasAsset"
            }
        ],
        "type": "BrainItem",
        "abstractKey": "__isBrainItem"
    } as any;
})();
(node as any).hash = '37e13df031ff5df47caabf8d85f4640b';
export default node;

import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons/faChevronCircleRight'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../stores'
import { classNames } from '../../utils/classNames'
import { DirectionAwareIcon } from '../common/DirectionAwareIcon'

import styles from './OnboardingItemNavigationBar.scss'

interface OnboardingItemNavigationBarProps {
  canNavigateBackwards: boolean
  canNavigateForwards: boolean

  onBack(): void
  onForward(): void
}

export function OnboardingItemNavigationBar(
  props: OnboardingItemNavigationBarProps
): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    commonStore.setItemNavigationBarShown(true)

    return () => {
      commonStore.setItemNavigationBarShown(false)
    }
  }, [commonStore])

  return (
    <div className={styles.navigationBar}>
      {props.canNavigateBackwards && (
        <a className={styles.menuItem} onClick={props.onBack}>
          <DirectionAwareIcon
            ltrIcon={faChevronCircleLeft}
            rtlIcon={faChevronCircleRight}
          />
          <span className={styles.menuItemLabel}>
            {t('common.browsePrevious')}
          </span>
        </a>
      )}
      <a
        className={classNames(styles.menuItem, styles.nextButton)}
        onClick={props.onForward}
      >
        {props.canNavigateForwards ? (
          <>
            <span className={styles.menuItemLabel}>
              {t('common.browseNext')}
            </span>
            <DirectionAwareIcon
              ltrIcon={faChevronCircleRight}
              rtlIcon={faChevronCircleLeft}
            />
          </>
        ) : (
          <>
            <span className={styles.menuItemLabel}>{t('common.Close')}</span>
            <FontAwesomeIcon icon={faTimesCircle} />
          </>
        )}
      </a>
    </div>
  )
}

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faPoll } from '@fortawesome/pro-solid-svg-icons/faPoll'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { classNames } from '../../../../utils/classNames'
import { TextualSliderInput } from '../../../common/TextualSliderInput'
import { AnswerOptionFeedback } from './AnswerOption'

import styles from './TextualSlider.scss'

const CONTAINER_PADDING = 50

export interface SliderProps {
  disabled?: boolean
  feedback?: AnswerOptionFeedback
  label: string
  min: number
  max: number
  onChange?: (value: number) => void
  sliderContainer: RefObject<HTMLElement>
  step: number
  value: number
  values: readonly string[]
  valueToText(value: number): string
}

export function TextualSlider(props: SliderProps): ReactElement {
  const slider = useRef<HTMLInputElement>(null)
  const label = useRef<HTMLLabelElement>(null)
  const [offset, setOffset] = useState(0)

  useEffect((): void => {
    // Calculate and update the label position.
    const valuePercentage = props.value / (props.values.length - 1)
    const labelWidth = label.current ? label.current.offsetWidth : 300
    const trackWidth = props.sliderContainer.current
      ? props.sliderContainer.current.offsetWidth - CONTAINER_PADDING
      : 0

    const position =
      trackWidth * valuePercentage + CONTAINER_PADDING / 2 - labelWidth / 2

    // Make sure the text stays within the box and doesn't overflow.
    setOffset(
      Math.min(
        Math.max(position, 1),
        trackWidth - labelWidth + CONTAINER_PADDING / 2 + 5
      )
    )
  }, [props.value, props.sliderContainer, props.values.length, label])

  const onSliderValueChanged = useCallback(
    (_event: Event, value: number | number[]): void => {
      if (Array.isArray(value) || isNaN(value)) {
        return
      }

      if (props.value !== value) {
        setOffset(0)
        props.onChange && props.onChange(value)
      }
    },
    [props]
  )

  const firstTextValue = props.values[0]
  const lastTextValue = props.values[props.values.length - 1]

  return (
    <div
      className={classNames(styles.sliderContainer, {
        [styles.disabled]: props.disabled,
        [styles.correct]: props.feedback === AnswerOptionFeedback.CORRECT,
        [styles.incorrect]: props.feedback === AnswerOptionFeedback.INCORRECT,
      })}
    >
      <div className={styles.sliderBox}>
        <label
          ref={label}
          className={styles.sliderLabel}
          style={{
            marginInlineStart: `${offset}px`,
            visibility: offset !== 0 ? 'visible' : 'hidden',
          }}
          aria-hidden={true}
        >
          {props.valueToText(props.value)}
        </label>
        {props.disabled && typeof props.feedback === 'undefined' && (
          <div className={styles.loadingIndicator}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.CORRECT && (
          <div className={styles.correctIndicator}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.SUBMITTED && (
          <div className={styles.pollIndicator}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.INCORRECT && (
          <div className={styles.incorrectIndicator}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.POLL && (
          <div className={styles.pollIndicator}>
            <FontAwesomeIcon icon={faPoll} />
          </div>
        )}

        <TextualSliderInput
          disabled={props.disabled}
          label={props.label}
          max={props.max}
          min={props.min}
          onChange={onSliderValueChanged}
          ref={slider}
          step={props.step}
          value={props.value}
          valueText={props.valueToText(props.value)}
        />
        <div className={styles.textContainer}>
          <div className={styles.sliderTextLabel} aria-label={firstTextValue}>
            {firstTextValue}
          </div>
          <div
            className={classNames(styles.sliderTextLabel, styles.right)}
            aria-label={lastTextValue}
          >
            {lastTextValue}
          </div>
        </div>
      </div>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AuthenticationPasswordExpiredPageSetPasswordMutationVariables = {
    email: string;
    token: string;
    password: string;
    passwordConfirmation: string;
};
export type AuthenticationPasswordExpiredPageSetPasswordMutationResponse = {
    readonly setPassword: {
        readonly message: string;
        readonly success: boolean;
    };
};
export type AuthenticationPasswordExpiredPageSetPasswordMutation = {
    readonly response: AuthenticationPasswordExpiredPageSetPasswordMutationResponse;
    readonly variables: AuthenticationPasswordExpiredPageSetPasswordMutationVariables;
};



/*
mutation AuthenticationPasswordExpiredPageSetPasswordMutation(
  $email: String!
  $token: String!
  $password: String!
  $passwordConfirmation: String!
) {
  setPassword(email: $email, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
    message
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "email"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "password"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "passwordConfirmation"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "token"
    } as any, v4 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Variable",
                    "name": "password",
                    "variableName": "password"
                },
                {
                    "kind": "Variable",
                    "name": "passwordConfirmation",
                    "variableName": "passwordConfirmation"
                },
                {
                    "kind": "Variable",
                    "name": "token",
                    "variableName": "token"
                }
            ],
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "setPassword",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthenticationPasswordExpiredPageSetPasswordMutation",
            "selections": (v4 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AuthenticationPasswordExpiredPageSetPasswordMutation",
            "selections": (v4 /*: any*/)
        },
        "params": {
            "cacheID": "524cf4d36e57adeb98256a0985810a81",
            "id": null,
            "metadata": {},
            "name": "AuthenticationPasswordExpiredPageSetPasswordMutation",
            "operationKind": "mutation",
            "text": "mutation AuthenticationPasswordExpiredPageSetPasswordMutation(\n  $email: String!\n  $token: String!\n  $password: String!\n  $passwordConfirmation: String!\n) {\n  setPassword(email: $email, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {\n    message\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '382a7ea22d917f8bfb2c836b45f605a6';
export default node;

import { observer } from 'mobx-react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'

import defaultBrainSnackImage from '../../../../../images/default-stream-items/brainsnack.jpg'
import { StreamItemHeader_streamItem$key } from '../../generated/StreamItemHeader_streamItem.graphql'
import { never } from '../../utils/assert'
import { BrainItemHeader } from './BrainItemHeader'
import { ItemHeader } from './ItemHeader'

interface StreamItemHeaderProps {
  streamItem: StreamItemHeader_streamItem$key
}

export const StreamItemHeader = observer(function StreamItemHeader(
  props: StreamItemHeaderProps
): ReactElement {
  const { t } = useTranslation()

  const streamItem = useFragment(
    graphql`
      fragment StreamItemHeader_streamItem on StreamItem {
        __typename
        ... on NotificationBrainSnackStreamItem {
          brainSnack {
            ...BrainItemHeader_brainItem
          }
        }
        ... on NotificationStreamItem {
          __typename
        }
        ... on BrainItemStreamItem {
          brainItem {
            ...BrainItemHeader_brainItem
          }
        }
      }
    `,
    props.streamItem
  )

  let asset: ReactElement | undefined
  if (streamItem && streamItem.__typename) {
    switch (streamItem.__typename) {
      case 'NotificationBrainSnackStreamItem':
        if (streamItem.brainSnack) {
          return <BrainItemHeader brainItem={streamItem.brainSnack} />
        }

      // eslint-disable-next-line no-fallthrough
      case 'NotificationStreamItem':
        asset = (
          <img
            alt={t('streamItem.notification.title')}
            src={defaultBrainSnackImage}
          />
        )
        break

      case 'BrainItemStreamItem':
        return <BrainItemHeader brainItem={streamItem.brainItem} />

      default:
        never(streamItem, 'Invalid item for stream item header')
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return asset ? <ItemHeader>{() => asset!}</ItemHeader> : <ItemHeader />
})

import { ReactElement, useEffect, useMemo, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface DuelTimerProps {
  paused: boolean
  penalty: number
}

export function DuelTimer(props: DuelTimerProps): ReactElement {
  const { i18n } = useTranslation()
  const interval = useRef<number | null>(null)
  const numberFormat = useMemo<Intl.NumberFormat>(
    () =>
      new Intl.NumberFormat(i18n.language, {
        minimumIntegerDigits: 2,
      }),
    [i18n.language]
  )
  const [time, setTime] = useReducer((previous) => previous + 1, 0)

  useEffect(() => {
    if (props.paused) {
      return
    }

    interval.current = window.setInterval(() => {
      setTime()
    }, 1000)

    return () => {
      if (interval.current) {
        window.clearInterval(interval.current)
      }
    }
  }, [props.paused, setTime])

  const totalSeconds = time + props.penalty
  const seconds = totalSeconds % 60
  const minutes = Math.floor(totalSeconds / 60)

  // It seems silly to use a fragment here, but it gets around type errors on
  // just returning a string.
  return (
    <>
      {[numberFormat.format(minutes), numberFormat.format(seconds)].join(':')}
    </>
  )
}

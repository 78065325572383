/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ActiveDuelRequestsListPaginationQuery from "./ActiveDuelRequestsListPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type DuelRequestStatus = "INVITED" | "WAITING";
export type ActiveDuelRequestsList_duelRequests = {
    readonly myDuelRequests: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly status: DuelRequestStatus;
                readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_request" | "DuelRequestPopup_item">;
            };
        }>;
    } | null;
    readonly " $refType": "ActiveDuelRequestsList_duelRequests";
};
export type ActiveDuelRequestsList_duelRequests$data = ActiveDuelRequestsList_duelRequests;
export type ActiveDuelRequestsList_duelRequests$key = {
    readonly " $data"?: ActiveDuelRequestsList_duelRequests$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActiveDuelRequestsList_duelRequests">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "myDuelRequests"
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "count"
            },
            {
                "kind": "RootArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": ActiveDuelRequestsListPaginationQuery
            }
        },
        "name": "ActiveDuelRequestsList_duelRequests",
        "selections": [
            {
                "alias": "myDuelRequests",
                "args": null,
                "concreteType": "DuelRequestConnection",
                "kind": "LinkedField",
                "name": "__ActiveDuelRequestsList_myDuelRequests_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DuelRequestEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "DuelRequest",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "id",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "status",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ActiveDuelSlot_request"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "DuelRequestPopup_item"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "kind": "ClientExtension",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                            }
                        ]
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '493e850efd9420843c92f6341b703c83';
export default node;

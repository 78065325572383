const translations = {
  direction: 'ltr',
  popup: {
    confirm: {
      delete: 'Odstranit',
      keep: 'Zrušit',
      cancelDuelInviteTitle: 'Odstranit?',
      cancelDuelInviteMessage: 'Jste si jist?',
    },
  },
  header: {
    'welcome-message': 'Ahoj {{name}}!',
    welcome: 'Vítej.',
    welcomeBack: 'Vítej zpět.',
  },
  learn: {
    instruction: 'Vyber témata, která si chceš hrát.',
    practiceSelection: 'Procvič si tento výběr',
    selectAll: 'Vybrat vše',
    deselectAll: 'Odebrat výběr',
    wellDone: 'Výborně!',
  },
  duels: {
    title: 'Hodnocení v duelu: {{score}}',
    readyToDuel: 'Jsi připraven/a na duel?',
    roundsOverview: 'Přehled kol duelu',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Wellnessovka',
      browsePrevious: 'Předchozí stránka',
      doneText: 'Wellnessovka byla dokončena',
      browseNext: 'Další strana',
    },
    brainItem: {
      correct: 'Správně ',
      incorrect: 'Nesprávně',
      completed: 'Dokončil/a jsi otázku',
      doneText: 'Hotovo',
      openItem: 'Start',
      closeItem: 'Zavřít',
      topics: 'Témat',
      nextItem: 'Pokračovat v procvičování',
      showProgress: 'Ukaž svůj pokrok',
    },
    order: {
      questionTitle: 'Pořadí',
      helpText: 'Přetáhněte odpovědi ve správném pořadí',
      correct: '{{num}} z {{max}} správné',
    },
    match: {
      questionTitle: 'Shoda',
      showingCorrectAnswer: 'Zobraz si správné odpovědi.',
      helpText: 'Přetáhněte odpovědi na správné místo',
      correct: '{{num}} z {{max}} správné',
      showCorrectAnswer: 'Zobrazit správnou odpověď',
      showMyAnswer: 'Zobrazit odpověď',
    },
    slider: {
      doneText: 'Hotovo',
      questionTitle: 'Posuvník',
      helpText: 'Přetáhněte jezdec do správné polohy',
    },
    poll: {
      questionTitle: 'Hlasování',
      resultinfo: 'To je to, co odpověděli ostatní',
      doneText: 'Odpověděl jsi na otázku týkající se dotazování',
      helpText: 'Vyber jednu správnou odpověď',
    },
    notification: {
      title: 'Oznámení',
      pauze: 'Mohu pokračovat?',
      continueanyway: 'Přesto pokračovat',
      doneText: 'Čtení oznámení',
      continueLearningText:
        'Procvičuješ se na extra otázkách. Bacha, získáš méně bodů, protože jsi v režimu up-to-date.',
      panelbuttonlabel: 'Zastavení dalšího postupu',
    },
    multipleselect: {
      correct: '{{num}} z {{max}} správných',
      incorrect: ', {{incorrect}} nesprávně',
      doneText: 'Otázka byla dokončena',
      questionTitle: 'Otázka',
      helpText: 'Vyber jednu nebo více odpovědí',
    },
    multiplechoice: {
      doneText: 'Otázka byla dokončena',
      questionTitle: 'Otázka',
      helpText: 'Vyber jednu odpověď',
    },
    duelItem: {
      duel: 'Duel',
      draw: 'Remíza',
      invite: 'Výzva k duelu!',
      acceptDuel: 'Přijmout duel!',
      declineDuel: 'Odmítnout duel',
      yourTurn: 'Tvůj tah',
      opponentTurn: 'Otoč na soupeře',
      youWon: 'Vyhráváš!',
      youLost: 'Prohráváš',
      cancelled: 'Duel je zrušen',
      closeItem: 'Zavřít',
      showDetails: 'Detaily okolo..',
      playItem: 'Zahraj si {{roundnumber}}',
      duelAccepted: 'Přijal/a jsi pozvánku od {{name}}.',
      duelDeclined: 'Odmítl/a jsi pozvánku od {{name}}.',
      duelStarted: 'Nechť duel s {{name}} začne. Hodně štěstí!',
      duelInfoInvitation: 'Jsi vyzván/a k duelu.',
      duelInfoMain:
        'A je tu krátká znalostí bitva o 5ti kolech. Vyhraj rychle a hlavně správně. Dej nejvíc kol, vyhraj duel a zvyš si tak své hodnocení. Dej bacha, máš na to jen 72 hodin.',
      viewInvitation: 'Zobrazení pozvánky',
      versusLabel: 'vs',
      started: 'Start!',
      doneText: 'Duel byl dokončen',
      tooLate: 'Nestihnuls!',
      practiceWhileWaiting: 'Praxe při čekání',
      practice: 'Pokračuj ve cvičení',
      subheading: {
        waitForOpponent: 'Počkejte na to, aby váš soupeř reagoval',
        yourTurn: 'Jste připraveni se vrátit?',
        timeOutOpponent: 'Váš oponent vzal dlouhou odpověď',
        timeOutPlayer: 'Trval jste příliš dlouho na to, abyste odpověděli',
        timeOutBoth:
          'Obě jste potřebovali příliš dlouho na to, abyste odpověděli',
        wellDone: 'Dobře, držte to!',
        betterNextTime: 'Příště štěstí!',
      },
      roundNumber: 'Kolo {{number}}',
      duelRequest: 'Nová duelová výzva!',
      duelRequestText: 'Uživatel {{name}} tě vyzývá k duelu',
      tooLateTurn: 'Příliš pozdě',
    },
    topicPin: {
      title: 'Přidat téma',
      pinenabled:
        'Zadal/a jsi {{- topiclabel}} a viděl/a jsi tak pouze položky spojené s tímto tématem. Položky s daným tématem se opakují častěji, tak abys je mohl praktikovat. Chceš-li zahrnout i dotazy z jiných témat, klikni znovu na připnutá témata.',
      pindisabled:
        'Téma již není aktivní. Od teď se zobrazí položky pro všechny témata.',
      continue: 'Pokračovat',
      dontshowagain: 'Nezobrazovat znovu',
      selectTopics: 'Vyber témata',
      selectTopicsPractice: 'Vyber témata, která chceš procvičovat.',
    },
    topicInfoHeader: 'Témata',
  },
  login: {
    succesful: 'Přihlášení bylo úspěšné.',
  },
  menu: {
    menu: 'Menu',
    duel: 'Duel',
    duels: 'Duely',
    newDuel: 'Nový duel',
    activeDuels: 'Aktivní duely',
    duelVersus: 'vs',
    ranking: 'Hodnocení',
    rating: 'Hodnocení duelu',
    profile: 'Profil',
    autoDuelLabel: 'Automatické duely',
    'help-and-settings': 'Nápověda & nastavení',
    challengeplayer: 'Vyzvat kolegu',
    pickplayer: 'Vybrat kolegu',
    randomplayer: 'Fíha, mám pocit štěstí',
    duelWaiting: 'Hledat soupeře…',
    quickDuelFailed: 'Už na tebe čekají nějaké pozvánky',
    duelsNotAvailable: 'Bohužel duely pro Tebe nejsou v tuto chvíli dostupné.',
    duelsNotAvailableExplanation:
      'Chceš vyzvat své kolegy? Obrať se na svého PowerApp Administrátora!',
    duelsNotEnabled:
      'Chceš hrát duely se svými kolegy? Zapni možnost účastnit se duelů níže.',
    duelsNotEnabledDisclaimer:
      'Tvoje fotografie a jméno budou viditelné tvým kolegům.',
    enableDuelling: 'Zapni účast na duelech s kolegy!',
    duellingEnabledLabel: 'Účastni se duelů',
    completedDuels: 'Dokončeno',
    select: 'Vybrat',
    settings: 'Nastavení',
    practice: 'Procvičení',
    aboutDuels: {
      heading: 'Co je pozvánka k duelu?',
      explanation1: 'Někdo tě vyzval k duelu.',
      explanation2:
        'Duel je krátký znalostní kvíz proti kolegovi, který trvá 5 kol. Kolo vyhráváš, pokud otázku zodpovíš správně a rychleji, než tvůj kolega. Pokud vyhraješ víc kol než tvůj oponent, vyhráváš duel a posouváš se nahoru v hodnocení.',
      attention: 'Poznámka:',
      explanation3: 'Odehraj každé kolo během 7 dnů!',
    },
    leaveAlert: {
      title: 'Víš to jistě?',
      text: '<p>Opravu chceš skončit s procvičováním?</p><p>Tvůj pokrok jsme uložili.</p>',
      confirm: 'Zastavit',
      continue: 'Pokračovat v procvičování',
    },
  },
  ranking: {
    title: 'Hodnocení duelu',
    subtitle: 'Toto hodnocení ti ukazuje, jak si stojíš vůči ostatním kolegům.',
    yourPosition: 'Tvoje pozice ({{rank}})',
    topPositions: 'TOP pozice',
    loadMore: 'Načíst další',
    empty: 'Zatím nemáš žádnou pozici v žebříčku, zahaj nový duel!',
    notRanked: 'Pokud se chceš umístit v žebříčku, dokonči duel!',
  },
  searchFeedback: {
    title: 'Najdi kolegu',
    oneResult: '{{amount}} kolega nalezen.',
    searchMessage: '{{amount}} kolegové byli nalezeni.',
    overflowMessage:
      'Jsou zobrazeny pouze ty nejlépe odpovídající výsledky vyhledávání {{amount}}.',
  },
  searchDuel: {
    title: 'Vyhledej soupeře',
    rankingposition: 'Ranking {{rank}}',
    noduelpossible: 'Duel není možný',
    duelstillactive: 'Ale..duel je již aktivní.',
    invitationsentondate: 'Pozvánka byla zaslána dne {{date}}',
    searchPlaceholder: 'Vyhledej jméno',
    randomOpponent:
      'Hledáme tvého dalšího oponenta. Tuto obrazovku můžeš zavřít, ozveme se ti, jakmile bude duel připravený.',
    emptyListSearch: 'Nebyly nalezeny žádné výsledky hledání. ',
    emptyList: 'Bohužel není nikdo k dispozici.<br>Zkus to znovu později. ',
  },
  activeDuels: {
    title: 'Hrát duel, který je k dispozici.',
    subtitle: '{{num}}/{{max}} duely jsou aktivní',
    status: {
      draw: 'Remíza',
      lost: 'Ztraceno',
      won: 'Vyhrál jsi',
      expired: 'Platnost vypršela',
      invited: 'Pozvání',
      waiting: 'Čekání',
      yourturn: 'Hrát',
    },
    emptyListNew: 'Žádný duel neprobíhá, zahaj nový duel!',
    emptyListCompleted:
      'Všechny duely byly dokončeny, dobrá práce!<br>Za odměnu si dej šálek kávy. ',
  },
  completedDuels: {
    title: 'Zobrazit tebou dokončené duely.',
    empty: 'Zatím jsi nedokončil/a žádné duely.<br>Zahaj nový duel!',
  },
  error: {
    internetdown: 'Ztratilo se nám připojení internetu!',
    versionConflict: {
      title: 'Nová verze',
      text: 'K dispozici je nová verze této aplikace. Abychom zajistili, že všichni mají nejnovější verzi, aktualizujeme nyní tvou aplikaci na nejnovější verzi. ',
      refresh: 'Přejít na nejnovější verzi ',
    },
    refreshButton: 'Klikni pro obnovení',
    internetup: 'Připojeno k internetu.',
    timeoutajax: 'Požadavek serveru vypršel.',
    404: 'Server nebyl nalezen (HTTP 404).',
    500: 'Interní chyba serveru (HTTP 500)',
    unknown: 'Neznámá chyba',
    tryReconnect: 'Pokouším se opětovně připojit..',
    alreadyAnswered: 'Položka byla již zodpovězena.',
    unavailableOffline: {
      title: 'Připojení není k dispozici',
      subtitle: 'Momentálně není k dispozici žádné připojení',
      explanation:
        'Pro použití aplikace je nutné připojení k internetu. Připojení k serveru není v tuto chvíli k dispozici. Zkontroluj, jestli se můžeš znovu připojit k internetu, nebo to zkus znovu později. ',
    },
  },
  common: {
    CheckAnswer: 'Zkontroluj odpověď',
    CheckAnswers: 'Zkontroluj odpovědi',
    Answer: 'Ulož mou odpověď',
    Hello: 'Ahoj',
    back: 'Zpět',
    Emailaddress: 'E-mailová adresa',
    Name: 'Jméno',
    Checking: 'Kontroluji…',
    Close: 'Uzavřít',
    browsePrevious: 'Předchozí',
    browseNext: 'Další',
    Accept: 'Akceptovat',
    'Log out': 'Odhlášení',
    Help: 'Pomoc',
    Correct: 'Správně ',
    Incorrect: 'Špatně',
    CorrectNotChosen: 'Bylo by správné',
    Round: 'Okruh',
    Version: 'Verze',
    selected: 'Vybráno',
    'No Rating': 'Nehodnoceno',
    'No Rank': 'Žádné hodnocení',
    NoUserSelection: 'Nebyl nalezen žádný uživatel',
    monthNames: 'Led Úno Bře Dub Kvě Čer Červc Srp Zář Říj Lis Pro',
    or: 'nebo',
    on: 'on',
    off: 'off',
  },
  profile: {
    title: 'Profil',
    subtitle: 'Svůj profil upravíš tady.',
    pictureEdit: 'Zobraz si nebo uprav svůj obrázek.',
    'picture-submit': 'Použít toto oříznutí',
    pictureConfirmDelete: 'Opravdu chceš odstranit svůj profilový obrázek?',
    profilePageTitle: 'Spravovat vlastní profil.',
    noEditDataText: 'Tyto údaje nemůžeš změnit.',
    'file-submit': 'Nahrát',
    pictureCancel: 'Zrušit',
    'picture-nofileselected': 'Nevybral jsi žádný soubor.',
    'picture-nofile': 'Není tu žádný soubor.',
    'picture-invalidsize':
      'Tento obrázek je mooc velký. Maximální šířka či výška nesmí přesáhnout 2600 pixels.',
    'picture-notimage': 'Vybraný obrázek se nezdá být obrázkem.',
    passwordEdit: 'Změna hesla',
    'picture-saved': 'Tvůj profilový obrázek je uložen!',
    passwordTitle: 'Změna hesla',
    passwordText:
      'Změň své heslo zadáním svého aktuálního hesla a následně 2x uveďte heslo nové. Nové heslo by mělo být v délce nejméně 6 znaků.',
    'password-old': 'Aktuální heslo',
    'password-new': 'Nové heslo',
    'password-repeat': 'Potvrď nové heslo',
    'password-submit': 'Uložit nové heslo',
    'password-oldpasswordmismatch': 'Ups, Tvé heslo nebylo zadáno správně.',
    'password-newpasswordmismatch': 'Potvrzené heslo neodpovídá první verzi.',
    'password-passwordtooshort': 'Nové heslo by mělo mít alespoň 6 znaků.',
    'password-passwordsaved': 'Tvoje heslo je uloženo!',
    profilePictureTitle: 'Profilový obrázek',
    profilePictureText: 'Tato fotografie je viditelná i Tvým kolegům.',
    generalDataTitle: 'Osobní informace',
    generalDataText: 'Všechny osobní údaje nemůžeš měnit sám/sama.',
    profileLanguagePreference: 'Preferovaný jazyk',
    profileLanguageChoice: 'Výběr jazyka',
  },
  auth: {
    header: {
      login: 'Přihlásit se',
      expired: 'Platnost odkazu vypršela',
      remind: 'Nepamatuješ si své heslo?',
      create: 'Aktivuj svůj účet',
      reset: 'Aktualizuj své heslo',
      welcome: 'Vítej!',
    },
    field: {
      email: 'E-mailová adresa',
      password: 'Heslo',
      oldPassword: 'Stávající heslo',
      newPassword: 'Nové heslo',
      newRepeatPassword: 'Nové heslo znovu',
      rememberMe: 'Zapamatujte si mě',
    },
    action: {
      itemcompleted: 'Dokončil/a jsi tuto položku',
      updateprofile: 'Aktualizovat profil',
      sendLink: 'Pošlete mi odkaz pro obnovu hesla e-mailem',
      backToLogin: 'Chci se přihlásit',
      sendActivateLink: 'Aktivovat účet',
      alreadyActivatedLogin: 'Účet mám již aktivovaný a chci se přihlásit',
      savePassword: 'Uložit mé nové heslo',
      newPassword: 'Tvoje nové heslo',
      repeatNewPassword: 'Tvoje nové heslo znovu',
      newPasswordPolicyIntro: 'Síla hesla',
    },
    selfActivate: {
      header: 'Aktivace tvého účtu',
    },
    logintext: 'Přihlásit se',
    remindText:
      'Žádný problém. Odeslali jsme ti e-mail pro nastavení nového hesla.',
    resetText: 'Nové heslo si můžeš nastavit tady.',
    createText: 'Vítej! Vytvoř si, prosím, heslo a pak se můžeš přihlásit.',
    passwords: {
      user: 'Je nám to líto, ale uživatele s touto e-mailovou adresou jsme nenašli.',
      token: 'Tento token pro obnovu hesla je neplatný.',
      sent: ' Odeslali jsme e-mail s odkazem pro obnovu hesla.',
      reset: 'Heslo bylo obnoveno!',
      forgot: 'Neznám své heslo',
      passwordExpiredTitle: 'Platnost hesla vypršela',
      submitPasswordExpiredForm: 'Uložit nové heslo',
      passwordExpiredText:
        'Tvoje heslo je starší než {{days}} a jeho platnost vypršela. Budeš si muset nastavit nové heslo pomocí formuláře níže a poté můžeš dál pokračovat v získávání znalostí v aplikaci PowerApp',
    },
  },
  faq: {
    header: 'Časté otázky',
    q1: 'Aplikace PowerApp je microlearningová aplikace – co to znamená?',
    a1: 'Microlearning znamená, že je efektivnější učit se v krátkých lekcích, pokud je tvým cílem uchovat si do budoucna více znalostí.',
    q2: 'Proč je moje skóre někdy nižší, než jsem čekal/a?',
    a2: 'Tvoje skóre se snižuje, protože v průběhu času zapomínáš to, co ses naučil/a.',
    q3: 'Kdy se moje skóre snižuje?',
    a3: 'Když odpovíš na otázku nesprávně nebo jsi nějakou dobu neaktivní.',
    q4: 'Proč se opakují stejné položky?',
    a4: 'Protože opakování s odstupem je nejefektivnější metodou učení se.',
    q5: 'Co je duel?',
    a5: 'Jde o znalostní kvíz o 5 otázkách, v kterém soutěžíš proti svému kolegovi.',
    q6: 'Proč musím čekat na své kolo, když hraji duel?',
    a6: 'Čekat musíš, než tvůj kolega dokončí své kolo',
    q7: 'Kde zjistím další informace a odpovědi na své otázky?',
    a7: 'Tlačítko níže tě přesměruje na naši webovou stránku.',
    toTheFaq: 'Přejít na časté otázky',
    toTheWebsite: 'Přejít na webovou stránku',
  },
}

export default translations

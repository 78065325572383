/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TextItemPage_page = {
    readonly text: string;
    readonly asset: {
        readonly " $fragmentRefs": FragmentRefs<"Asset_asset">;
    } | null;
    readonly " $refType": "TextItemPage_page";
};
export type TextItemPage_page$data = TextItemPage_page;
export type TextItemPage_page$key = {
    readonly " $data"?: TextItemPage_page$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TextItemPage_page">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TextItemPage_page",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Asset_asset"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "BrainSnackPage",
    "abstractKey": null
} as any;
(node as any).hash = 'b130a3bc4cb6718375687c43dd1bc215';
export default node;

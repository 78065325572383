import { ReactElement, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'

import { Fullscreen } from '../../common/Fullscreen'

export const AuthenticationCmsRedirector = function AuthenticationCmsRedirector(
  props: RouteComponentProps
): ReactElement {
  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    let to = params.get('to')

    // Strip domain name
    if (to) {
      to = to.replace(/^[^:]*:\/*/, '/')
    }

    location.href = to ?? '/app'
  }, [props.location.search])

  return <Fullscreen />
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TopicCategory_item = {
    readonly availableFrom: string;
    readonly completedAt: string | null;
    readonly description: string;
    readonly icon: string | null;
    readonly progress: number;
    readonly startedAt: string | null;
    readonly unlocked: boolean;
    readonly " $refType": "TopicCategory_item";
};
export type TopicCategory_item$data = TopicCategory_item;
export type TopicCategory_item$key = {
    readonly " $data"?: TopicCategory_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TopicCategory_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicCategory_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableFrom",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completedAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "progress",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startedAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unlocked",
            "storageKey": null
        }
    ],
    "type": "TopicCategory",
    "abstractKey": null
} as any;
(node as any).hash = '2aa4a73a9f4b106539cf69e553a7d6de';
export default node;

import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { Header } from '../../containers/Header'
import { WellDoneOverlay_feedback$key } from '../../generated/WellDoneOverlay_feedback.graphql'
import { WellDoneOverlayMeQuery } from '../../generated/WellDoneOverlayMeQuery.graphql'

import { useStores } from '../../stores'
import { classNames } from '../../utils/classNames'
import { Avatar, AvatarContext } from '../common/Avatar'
import { AvatarContainer } from '../common/AvatarContainer'
import { NumberBubble } from '../common/NumberBubble'
import { ScoreChart } from '../common/ScoreChart'
import { BGParticleAnimation } from '../common/BGParticleAnimation'

import styles from './WellDoneOverlay.scss'
import { TertiaryButton } from '../common/TertiaryButton'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

export interface WellDoneOverlayProps {
  feedback: WellDoneOverlay_feedback$key

  onClose(): void
}

export function WellDoneOverlay(props: WellDoneOverlayProps): ReactElement {
  const { onClose } = props
  const { learnStore } = useStores()
  const { i18n, t } = useTranslation()

  const [disappearing, setDisappearing] = useState(false)

  const currentUser = useQuery<WellDoneOverlayMeQuery>(
    graphql`
      query WellDoneOverlayMeQuery {
        me {
          id
          profileImage
          fullName
        }
      }
    `,
    {},
    // Try avoiding a network request if possible.
    { fetchPolicy: 'store-or-network' }
  )
  const { scoreChange, brainItem } = useFragment(
    graphql`
      fragment WellDoneOverlay_feedback on BrainItemCompletionData {
        scoreChange {
          from
          to
        }
        brainItem {
          ... on HasQuestionType {
            questionType
          }
        }
      }
    `,
    props.feedback
  )

  const subHeadingText =
    // Note that for POLL questions, the WellDoneOverlay is not triggered.
    brainItem && brainItem.questionType !== 'KNOWLEDGE'
      ? t('learn.anotherStepForwardSubjective')
      : t('learn.anotherStepForwardKnowledge')

  // 450 is the time before the content appears, 2500 is the time that it is shown
  const totalDuration = 450 + 2500

  const [scoreToShow, setScoreToShow] = useState(scoreChange?.from)

  const closePopup = useCallback(() => {
    setDisappearing(true)
    onClose()
  }, [onClose])
  const closePopupEnterKey = useEnterKeyHandler(closePopup)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setScoreToShow(scoreChange?.to)
    })

    return () => clearTimeout(timeout)
  }, [scoreChange?.to])

  useEffect(() => {
    document.body.classList.add(styles.noOverflow)

    const disappearingTimeout = setTimeout(() => {
      setDisappearing(true)
    }, totalDuration)
    const closeTimeout = setTimeout(onClose, totalDuration + 200)

    return () => {
      clearTimeout(disappearingTimeout)
      clearTimeout(closeTimeout)

      document.body.classList.remove(styles.noOverflow)
    }
  }, [learnStore, onClose, totalDuration])

  return (
    <div
      className={classNames(styles.overlay, {
        [styles.disappearing]: disappearing,
      })}
    >
      <BGParticleAnimation />
      <div className={styles.content}>
        <div className={styles.popupContainer}>
          <div className={styles.popup}>
            <Header duel wide>
              <div className={styles.popupHeaderContent}>
                <h1 className={styles.heading}>{t('learn.wellDone')}</h1>
                <p className={styles.subheading}>{subHeadingText}</p>

                <AvatarContainer className={styles.avatar}>
                  <ScoreChart
                    animateAtStart={false}
                    className={styles.avatarChart}
                    trackWidth={5}
                    chartWidth={14}
                    size={150 + 2 * 14}
                    value={scoreToShow ?? 0}
                  />

                  <Avatar
                    filename={currentUser.data?.me?.profileImage}
                    name={currentUser.data?.me?.fullName || ''}
                    userId={currentUser.data?.me?.id || ''}
                    context={AvatarContext.default}
                  />

                  {brainItem && brainItem.questionType === 'KNOWLEDGE' && (
                    <NumberBubble
                      className={styles.avatarAttachment}
                      scaling
                      text={Intl.NumberFormat(i18n.language, {
                        signDisplay:
                          scoreChange && scoreChange.to > scoreChange.from
                            ? 'always'
                            : 'auto',
                        style: 'percent',
                      }).format(
                        (scoreChange
                          ? scoreChange.to > scoreChange.from
                            ? scoreChange.to - scoreChange.from
                            : scoreChange.to
                          : 0) / 100
                      )}
                    />
                  )}
                </AvatarContainer>
              </div>
            </Header>
          </div>
          <TertiaryButton
            className={styles.attachment}
            aria-label={t('common.Close')}
            icon={true}
            onClick={closePopup}
            onKeyPress={closePopupEnterKey}
            tabIndex={0}
          >
            <FontAwesomeIcon icon={faTimes} />
          </TertiaryButton>
        </div>
      </div>
    </div>
  )
}

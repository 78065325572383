/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionType = "AWARENESS" | "KNOWLEDGE" | "POLL";
export type BrainItemType_item = {
    readonly __typename: "BrainSnack" | "MatchQuestion" | "MultipleChoiceQuestion" | "MultipleSelectQuestion" | "OrderQuestion" | "SliderQuestion";
    readonly questionType?: QuestionType | undefined;
    readonly " $refType": "BrainItemType_item";
};
export type BrainItemType_item$data = BrainItemType_item;
export type BrainItemType_item$key = {
    readonly " $data"?: BrainItemType_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BrainItemType_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainItemType_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
        },
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "questionType",
                    "storageKey": null
                }
            ],
            "type": "HasQuestionType",
            "abstractKey": "__isHasQuestionType"
        }
    ],
    "type": "BrainItem",
    "abstractKey": "__isBrainItem"
} as any;
(node as any).hash = '90c2f8c394ee6e994e1b0203f4b6b919';
export default node;

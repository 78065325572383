/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OnboardingItemPageItemView_item = {
    readonly __typename: "BrainSnack" | "MatchQuestion" | "MultipleChoiceQuestion" | "MultipleSelectQuestion" | "OrderQuestion" | "SliderQuestion";
    readonly " $fragmentRefs": FragmentRefs<"BrainSnackView_item" | "QuestionView_item">;
    readonly " $refType": "OnboardingItemPageItemView_item";
};
export type OnboardingItemPageItemView_item$data = OnboardingItemPageItemView_item;
export type OnboardingItemPageItemView_item$key = {
    readonly " $data"?: OnboardingItemPageItemView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OnboardingItemPageItemView_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingItemPageItemView_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BrainSnackView_item"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestionView_item"
        }
    ],
    "type": "BrainItem",
    "abstractKey": "__isBrainItem"
} as any;
(node as any).hash = '702b59f8e1b2f999ddaf1256329d2c90';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type ActiveDuelsNewDuelSubscriptionVariables = {
    duelConnections: Array<string>;
    duelRequestConnections: Array<string>;
};
export type ActiveDuelsNewDuelSubscriptionResponse = {
    readonly duelCreated: {
        readonly duelRequest: {
            readonly id: string;
        } | null;
        readonly duel: {
            readonly id: string;
            readonly status: DuelStatus;
            readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_duel" | "DuelFinalizingPopup_item" | "PlayDuelRound_duel">;
        };
    } | null;
};
export type ActiveDuelsNewDuelSubscription = {
    readonly response: ActiveDuelsNewDuelSubscriptionResponse;
    readonly variables: ActiveDuelsNewDuelSubscriptionVariables;
};



/*
subscription ActiveDuelsNewDuelSubscription {
  duelCreated {
    duelRequest {
      id
    }
    duel {
      id
      status
      ...ActiveDuelSlot_duel
      ...DuelFinalizingPopup_item
      ...PlayDuelRound_duel
    }
  }
}

fragment ActiveDuelSlot_duel on Duel {
  players {
    rating
    user {
      __typename
      id
      nameForDuelling
      isMe
      profileImageForDuelling
      hasAnonymousDuellingEnabled
    }
    id
  }
  status
  time
}

fragment DuelFinalizingPopup_item on Duel {
  id
  status
  players {
    timedOut
    rounds {
      ...DuelRoundsOverview_left
      ...DuelRoundsOverview_right
    }
    user {
      __typename
      isMe
      id
    }
    ...DuelVersusHeader_left
    ...DuelVersusHeader_right
    id
  }
  ...DuelRoundsOverview_duel
}

fragment DuelRoundsOverview_duel on Duel {
  status
}

fragment DuelRoundsOverview_left on DuelPlayerRound {
  answerTime
  isCorrect
  roundNumber
}

fragment DuelRoundsOverview_right on DuelPlayerRound {
  answerTime
  isCorrect
  roundNumber
}

fragment DuelVersusHeader_left on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}

fragment DuelVersusHeader_right on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}

fragment PlayDuelRound_duel on Duel {
  ownRoundNumber
  players {
    user {
      __typename
      isMe
      id
    }
    ...DuelVersusHeader_left
    ...DuelVersusHeader_right
    rounds {
      ...DuelRoundsOverview_left
      ...DuelRoundsOverview_right
    }
    id
  }
  ...DuelRoundsOverview_duel
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "duelConnections"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "duelRequestConnections"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActiveDuelsNewDuelSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelCreatedPayload",
                    "kind": "LinkedField",
                    "name": "duelCreated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DuelRequest",
                            "kind": "LinkedField",
                            "name": "duelRequest",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Duel",
                            "kind": "LinkedField",
                            "name": "duel",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ActiveDuelSlot_duel"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "DuelFinalizingPopup_item"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "PlayDuelRound_duel"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActiveDuelsNewDuelSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelCreatedPayload",
                    "kind": "LinkedField",
                    "name": "duelCreated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DuelRequest",
                            "kind": "LinkedField",
                            "name": "duelRequest",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "filters": null,
                                    "handle": "deleteEdge",
                                    "key": "",
                                    "kind": "ScalarHandle",
                                    "name": "id",
                                    "handleArgs": [
                                        {
                                            "kind": "Variable",
                                            "name": "connections",
                                            "variableName": "duelRequestConnections"
                                        }
                                    ]
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Duel",
                            "kind": "LinkedField",
                            "name": "duel",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DuelPlayer",
                                    "kind": "LinkedField",
                                    "name": "players",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rating",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "nameForDuelling",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "isMe",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "profileImageForDuelling",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasAnonymousDuellingEnabled",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timedOut",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DuelPlayerRound",
                                            "kind": "LinkedField",
                                            "name": "rounds",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "answerTime",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "isCorrect",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "roundNumber",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "rank",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "Player",
                                            "abstractKey": "__isPlayer"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "time",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "ownRoundNumber",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "appendNode",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "duel",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "duelConnections"
                                },
                                {
                                    "kind": "Literal",
                                    "name": "edgeTypeName",
                                    "value": "DuelEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d8c39d6b2188782a04d83cac8584b3d3",
            "id": null,
            "metadata": {},
            "name": "ActiveDuelsNewDuelSubscription",
            "operationKind": "subscription",
            "text": "subscription ActiveDuelsNewDuelSubscription {\n  duelCreated {\n    duelRequest {\n      id\n    }\n    duel {\n      id\n      status\n      ...ActiveDuelSlot_duel\n      ...DuelFinalizingPopup_item\n      ...PlayDuelRound_duel\n    }\n  }\n}\n\nfragment ActiveDuelSlot_duel on Duel {\n  players {\n    rating\n    user {\n      __typename\n      id\n      nameForDuelling\n      isMe\n      profileImageForDuelling\n      hasAnonymousDuellingEnabled\n    }\n    id\n  }\n  status\n  time\n}\n\nfragment DuelFinalizingPopup_item on Duel {\n  id\n  status\n  players {\n    timedOut\n    rounds {\n      ...DuelRoundsOverview_left\n      ...DuelRoundsOverview_right\n    }\n    user {\n      __typename\n      isMe\n      id\n    }\n    ...DuelVersusHeader_left\n    ...DuelVersusHeader_right\n    id\n  }\n  ...DuelRoundsOverview_duel\n}\n\nfragment DuelRoundsOverview_duel on Duel {\n  status\n}\n\nfragment DuelRoundsOverview_left on DuelPlayerRound {\n  answerTime\n  isCorrect\n  roundNumber\n}\n\nfragment DuelRoundsOverview_right on DuelPlayerRound {\n  answerTime\n  isCorrect\n  roundNumber\n}\n\nfragment DuelVersusHeader_left on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n\nfragment DuelVersusHeader_right on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n\nfragment PlayDuelRound_duel on Duel {\n  ownRoundNumber\n  players {\n    user {\n      __typename\n      isMe\n      id\n    }\n    ...DuelVersusHeader_left\n    ...DuelVersusHeader_right\n    rounds {\n      ...DuelRoundsOverview_left\n      ...DuelRoundsOverview_right\n    }\n    id\n  }\n  ...DuelRoundsOverview_duel\n}\n"
        }
    } as any;
})();
(node as any).hash = '517440d1e43b5a41516f8d4f329a5289';
export default node;

import { ReactElement, useEffect } from 'react'

import { useStores } from '../../stores'
import { ChildrenProps } from '../../utils/types'

export function Fullscreen({ children }: ChildrenProps): ReactElement {
  const { commonStore } = useStores()

  // Hide and show the navbar upon opening/closing the duel (i.e. fullscreen).
  useEffect(() => {
    commonStore.setNavBarHidden(true)

    return () => {
      commonStore.setNavBarHidden(false)
    }
  }, [commonStore])

  return <>{children}</>
}

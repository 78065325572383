/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceView_feedback = ({
    readonly __typename: "GenericQuestionCompletionData";
    readonly correct: boolean | null;
    readonly correctAnswers: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly responseCount: number | null;
    readonly responses: ReadonlyArray<{
        readonly timesChosen: number;
        readonly answerOption: {
            readonly id: string;
        };
    }> | null;
    readonly userAnswers: ReadonlyArray<{
        readonly id: string;
    }>;
} | {
    readonly __typename: "BrainSnackCompletionData" | "MatchQuestionCompletionData" | "PollQuestionCompletionData" | "SliderQuestionCompletionData";
}) & {
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_feedback">;
    readonly " $refType": "MultipleChoiceView_feedback";
};
export type MultipleChoiceView_feedback$data = MultipleChoiceView_feedback;
export type MultipleChoiceView_feedback$key = {
    readonly " $data"?: MultipleChoiceView_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MultipleChoiceView_feedback">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MultipleChoiceView_feedback",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_feedback"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "correct",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "correctAnswers",
                        "plural": true,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "responseCount",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PollResponse",
                        "kind": "LinkedField",
                        "name": "responses",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timesChosen",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AnswerOption",
                                "kind": "LinkedField",
                                "name": "answerOption",
                                "plural": false,
                                "selections": (v0 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "userAnswers",
                        "plural": true,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "GenericQuestionCompletionData",
                "abstractKey": null
            }
        ],
        "type": "BrainItemCompletionData",
        "abstractKey": "__isBrainItemCompletionData"
    } as any;
})();
(node as any).hash = 'c650f3886eff417639964c7bb2e90b90';
export default node;

import { ReactElement, SyntheticEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { MultipleChoiceView_feedback$key } from '../../../generated/MultipleChoiceView_feedback.graphql'

import { MultipleChoiceView_item$key } from '../../../generated/MultipleChoiceView_item.graphql'
import { assert, never } from '../../../utils/assert'
import { handleEnterKey } from '../../../utils/handleEnterKey'
import {
  BrainItemProps,
  BrainItemState,
} from '../../../utils/hooks/useBrainItems'
import { useSelectedAnswers } from '../../../utils/hooks/useSelectedAnswers'

import { PrimaryButton } from '../../common/PrimaryButton'
import { FeedbackDrawer } from '../FeedbackDrawer'

import {
  AnswerOption,
  AnswerOptionFeedback,
  AnswerOptionViewType,
} from './elements/AnswerOption'
import { Question } from './elements/Question'
import { QuestionInstruction } from './elements/QuestionInstruction'

import styles from './MultipleChoiceView.scss'
import { QuestionHandlers } from './QuestionView'

export type MultipleChoiceViewProps = BrainItemProps & {
  feedback: MultipleChoiceView_feedback$key | null
  item: MultipleChoiceView_item$key
}

export function MultipleChoiceView(
  props: MultipleChoiceViewProps & QuestionHandlers
): ReactElement {
  const { t } = useTranslation()

  const item = useFragment(
    graphql`
      fragment MultipleChoiceView_item on MultipleChoiceQuestion {
        id
        answerOptions {
          id
          ...AnswerOption_answer
        }
        questionType
        text(language: $language)
        ...FeedbackDrawer_brainItem
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment MultipleChoiceView_feedback on BrainItemCompletionData {
        __typename
        ...FeedbackDrawer_feedback
        ... on GenericQuestionCompletionData {
          correct
          correctAnswers {
            id
          }
          responseCount
          responses {
            timesChosen
            answerOption {
              id
            }
          }
          userAnswers {
            id
          }
        }
      }
    `,
    props.feedback
  )

  const [selectedAnswers, , , setSelectedAnswer] = useSelectedAnswers(
    feedback?.__typename === 'GenericQuestionCompletionData'
      ? feedback.userAnswers.map((answerOption) => answerOption.id)
      : []
  )

  const onAnswerClicked = useCallback(
    (answerId: string): void => {
      if (props.brainItemState >= BrainItemState.CHECKING) {
        return
      }

      if (selectedAnswers.includes(answerId)) {
        return
      }

      setSelectedAnswer(answerId)
    },
    [props.brainItemState, selectedAnswers, setSelectedAnswer]
  )

  const onButtonClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (props.brainItemState === BrainItemState.READY) {
        props.onSubmit({
          answerOptions: selectedAnswers.slice(),
        })
      }
    },
    [props, selectedAnswers]
  )

  const answerData =
    props.brainItemState > BrainItemState.CHECKING ? feedback : undefined

  if (answerData) {
    if (answerData.__typename !== 'GenericQuestionCompletionData') {
      throw new Error(
        'Invalid answer data for multiple choice question: ' +
          JSON.stringify(answerData)
      )
    }

    if (item.questionType === 'KNOWLEDGE') {
      if (answerData.correct === null || answerData.correctAnswers === null) {
        throw new Error(
          'Invalid answer data for non-poll multiple choice question: ' +
            JSON.stringify(answerData)
        )
      }
    } else if (
      item.questionType === 'POLL' &&
      (answerData.responseCount === null || answerData.responses === null)
    ) {
      throw new Error(
        'Invalid answer data for subjective multiple choice question: ' +
          JSON.stringify(answerData)
      )
    }
  }

  return (
    <div className={styles.question}>
      <Question text={item.text} />
      <QuestionInstruction text={t('streamItem.multiplechoice.helpText')} />

      <form
        role='listbox'
        aria-label={t('streamItem.multiplechoice.helpText')}
        aria-readonly={props.brainItemState !== BrainItemState.READY}
      >
        {item.answerOptions.map((answer) => {
          const selected = selectedAnswers.includes(answer.id)
          let answerFeedback: AnswerOptionFeedback | undefined
          let pollPercentage: number | undefined
          if (answerData) {
            switch (item.questionType) {
              case 'KNOWLEDGE':
                assert(
                  answerData.correctAnswers !== null &&
                    answerData.correct !== null
                )

                if (selected) {
                  answerFeedback = answerData.correct
                    ? AnswerOptionFeedback.CORRECT
                    : AnswerOptionFeedback.INCORRECT
                } else if (
                  answerData.correctAnswers.find(
                    (correctAnswer) => correctAnswer.id === answer.id
                  )
                ) {
                  answerFeedback = AnswerOptionFeedback.CORRECT
                }
                break

              case 'AWARENESS':
                answerFeedback = selected
                  ? AnswerOptionFeedback.SUBMITTED
                  : undefined
                break

              case 'POLL':
                assert(
                  answerData.responses !== null &&
                    answerData.responseCount !== null
                )

                {
                  answerFeedback = AnswerOptionFeedback.POLL
                  const response = answerData.responses.find(
                    (r) => r.answerOption.id === answer.id
                  )
                  if (response) {
                    pollPercentage = Math.round(
                      (response.timesChosen / answerData.responseCount) * 100
                    )
                  }
                }
                break

              default:
                never(
                  item.questionType,
                  `Invalid question type ${item.questionType}`
                )
            }
          }

          return (
            <AnswerOption
              answer={answer}
              checking={props.brainItemState === BrainItemState.CHECKING}
              disabled={props.brainItemState >= BrainItemState.CHECKING}
              feedback={answerFeedback}
              key={answer.id}
              selected={selected}
              type={
                item.questionType === 'KNOWLEDGE'
                  ? AnswerOptionViewType.MULTIPLE_CHOICE
                  : AnswerOptionViewType.POLL
              }
              onClick={(): void => onAnswerClicked(answer.id)}
              pollPercentage={pollPercentage}
            />
          )
        })}
      </form>

      {props.brainItemState < BrainItemState.FEEDBACK || !answerData ? (
        <div className={styles.buttonContainer}>
          <PrimaryButton
            disabled={
              selectedAnswers.length === 0 ||
              props.brainItemState === BrainItemState.CHECKING
            }
            onClick={onButtonClicked}
            onKeyPress={handleEnterKey(onButtonClicked)}
          >
            {props.brainItemState === BrainItemState.READY &&
              (item.questionType === 'KNOWLEDGE'
                ? t('common.CheckAnswer')
                : t('common.Answer'))}
            {props.brainItemState === BrainItemState.CHECKING &&
              t('common.Checking')}
            {props.brainItemState === BrainItemState.FEEDBACK &&
              t('common.Close')}
          </PrimaryButton>
        </div>
      ) : (
        <FeedbackDrawer
          brainItem={item}
          brainItemState={props.brainItemState}
          feedback={answerData}
          heading={
            item.questionType === 'POLL'
              ? t('streamItem.poll.resultinfo')
              : item.questionType === 'AWARENESS'
              ? t('streamItem.poll.doneText')
              : answerData.correct
              ? t('streamItem.brainItem.correct')
              : props.inOnboarding
              ? t('streamItem.brainItem.almostCorrect')
              : t('streamItem.brainItem.incorrect')
          }
          inDuel={!!props.inDuel}
          inOnboarding={!!props.inOnboarding}
          onBoardingItemsLeft={!!props.onboardingItemsLeft}
          onClose={props.onClose}
        />
      )}
    </div>
  )
}

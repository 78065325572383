import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { faTilde } from '@fortawesome/pro-solid-svg-icons/faTilde'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  Ref,
} from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'

import { AnswerOption_answer$key } from '../../../../generated/AnswerOption_answer.graphql'
import { classNames } from '../../../../utils/classNames'
import { handleEnterKey } from '../../../../utils/handleEnterKey'
import { ProgressBar } from '../../../common/ProgressBar'

import styles from './AnswerOption.scss'

export const enum AnswerOptionFeedback {
  CORRECT,
  INCORRECT,
  WOULD_HAVE_BEEN_CORRECT,
  POLL,
  SUBMITTED,
}

export const enum AnswerOptionViewType {
  DRAGGABLE,
  MULTIPLE_CHOICE,
  MULTIPLE_SELECT,
  POLL,
}

export type AnswerOptionProps = {
  answer: AnswerOption_answer$key
  checking: boolean
  disabled?: boolean
  feedback?: AnswerOptionFeedback
  onClick?: () => void
  pollPercentage?: number
  selected: boolean
  type: AnswerOptionViewType
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const AnswerOption = forwardRef(function AnswerOption(
  props: AnswerOptionProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const {
    answer: answerId,
    checking,
    className,
    disabled,
    feedback,
    onClick,
    pollPercentage,
    selected,
    type,
    ...divAttributes
  } = props
  // const { t } = useTranslation()

  const answer = useFragment(
    graphql`
      fragment AnswerOption_answer on AnswerOption {
        id
        text(language: $language)
      }
    `,
    answerId
  )
  const multipleAllowed = type === AnswerOptionViewType.MULTIPLE_SELECT

  return (
    <div
      className={classNames(
        styles.answerOption,
        {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.correct]: feedback === AnswerOptionFeedback.CORRECT,
          [styles.incorrect]: feedback === AnswerOptionFeedback.INCORRECT,
          [styles.wouldHaveBeenCorrect]:
            feedback === AnswerOptionFeedback.WOULD_HAVE_BEEN_CORRECT,
          [styles.submitted]: feedback === AnswerOptionFeedback.SUBMITTED,
        },
        className
      )}
      onClick={onClick}
      onKeyPress={handleEnterKey(onClick)}
      ref={ref}
      role='option'
      tabIndex={checking || disabled || feedback ? -1 : 0}
      aria-selected={selected}
      {...divAttributes}
    >
      {checking && selected && (
        <div className={styles.loadingIndicator}>
          <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
        </div>
      )}
      {feedback === AnswerOptionFeedback.CORRECT && (
        <div className={styles.correctIndicator}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      {feedback === AnswerOptionFeedback.SUBMITTED && (
        <div className={styles.submittedIndicator}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      {feedback === AnswerOptionFeedback.INCORRECT && (
        <div className={styles.incorrectIndicator}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {feedback === AnswerOptionFeedback.WOULD_HAVE_BEEN_CORRECT && (
        <div className={styles.wouldHaveBeenCorrectIndicator}>
          <FontAwesomeIcon icon={faTilde} />
        </div>
      )}

      <input
        checked={selected}
        disabled={checking || !!feedback}
        readOnly={true}
        tabIndex={-1}
        type={multipleAllowed ? 'checkbox' : 'radio'}
        value={answer.id}
        aria-hidden={true}
      />

      <div
        className={styles.answerText}
        dangerouslySetInnerHTML={{
          __html: answer.text ?? '',
        }}
      />

      {type === AnswerOptionViewType.POLL && pollPercentage && (
        <ProgressBar percentage={pollPercentage} />
      )}

      {type === AnswerOptionViewType.DRAGGABLE && !disabled && (
        <div className={styles.draggableIndicator}>
          <FontAwesomeIcon icon={faSort} />
        </div>
      )}
    </div>
  )
})

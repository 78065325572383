/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelPage_duelInfo = {
    readonly rank: number | null;
    readonly rating: number;
    readonly " $refType": "DuelPage_duelInfo";
};
export type DuelPage_duelInfo$data = DuelPage_duelInfo;
export type DuelPage_duelInfo$key = {
    readonly " $data"?: DuelPage_duelInfo$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelPage_duelInfo">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuelPage_duelInfo",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
        }
    ],
    "type": "UserDuelInfo",
    "abstractKey": null
} as any;
(node as any).hash = '7260584e7f42b0eb338f6d602481d2b9';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoginType = "LTI" | "SAML" | "WEB";
export type RoutingUserQueryVariables = {};
export type RoutingUserQueryResponse = {
    readonly me: {
        readonly firstLogin: boolean;
        readonly idp: string | null;
        readonly isOnboarding: boolean;
        readonly loginType: LoginType;
        readonly " $fragmentRefs": FragmentRefs<"NavigationBar_user" | "RoutingGuestRoute_user" | "RoutingPrivateRoute_user">;
    } | null;
};
export type RoutingUserQuery = {
    readonly response: RoutingUserQueryResponse;
    readonly variables: RoutingUserQueryVariables;
};



/*
query RoutingUserQuery {
  me {
    firstLogin
    idp
    isOnboarding
    loginType
    ...NavigationBar_user
    ...RoutingGuestRoute_user
    ...RoutingPrivateRoute_user
    id
  }
}

fragment NavigationBar_user on AuthenticatedUser {
  hasDuellingAvailable
  hasDuellingEnabled
  isOnboarding
}

fragment RoutingGuestRoute_user on AuthenticatedUser {
  loginType
}

fragment RoutingPrivateRoute_user on AuthenticatedUser {
  isOnboarding
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstLogin",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idp",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isOnboarding",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loginType",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RoutingUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "NavigationBar_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RoutingGuestRoute_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RoutingPrivateRoute_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RoutingUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasDuellingAvailable",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasDuellingEnabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "0050a2f7f5a7f1ca3227c985cffb197f",
            "id": null,
            "metadata": {},
            "name": "RoutingUserQuery",
            "operationKind": "query",
            "text": "query RoutingUserQuery {\n  me {\n    firstLogin\n    idp\n    isOnboarding\n    loginType\n    ...NavigationBar_user\n    ...RoutingGuestRoute_user\n    ...RoutingPrivateRoute_user\n    id\n  }\n}\n\nfragment NavigationBar_user on AuthenticatedUser {\n  hasDuellingAvailable\n  hasDuellingEnabled\n  isOnboarding\n}\n\nfragment RoutingGuestRoute_user on AuthenticatedUser {\n  loginType\n}\n\nfragment RoutingPrivateRoute_user on AuthenticatedUser {\n  isOnboarding\n}\n"
        }
    } as any;
})();
(node as any).hash = '64ee633f9f71dee3ff7a89dee9885a26';
export default node;

import { observer } from 'mobx-react'
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { graphql, useQuery, useSubscription } from 'relay-hooks'
import { GraphQLSubscriptionConfig } from 'relay-runtime'
import { DuelPage } from '../../../containers/duels/DuelPage'
import { OnlineMenu } from '../../../containers/OnlineMenu'
import { ActiveDuelsDuelUpdatedSubscription } from '../../../generated/ActiveDuelsDuelUpdatedSubscription.graphql'
import { ActiveDuelSlot_duel } from '../../../generated/ActiveDuelSlot_duel.graphql'
import { ActiveDuelsNewDuelSubscription } from '../../../generated/ActiveDuelsNewDuelSubscription.graphql'
import { ActiveDuelsQuery } from '../../../generated/ActiveDuelsQuery.graphql'
import { PlayDuelRound_duel$key } from '../../../generated/PlayDuelRound_duel.graphql'
import {
  ConfirmYesNoPopup,
  DuelRequestPopup,
  FinalizingDuelPopup,
  NewDuelPopup,
  PlayDuelRound,
} from '../../../stores/commonStore'
import { never } from '../../../utils/assert'
import { useEnterKeyHandler } from '../../../utils/handleEnterKey'
import { useDuelConnections } from '../../../utils/hooks/useDuelConnections'
import { EmptyList } from '../../common/EmptyList'
import { GraphQlError } from '../../common/GraphQlError'

import { LoadingIndicator } from '../../common/LoadingIndicator'
import { Popup } from '../../common/Popup'
import { SecondaryButton } from '../../common/SecondaryButton'
import { ActiveDuelRequestsList } from '../../duels/ActiveDuelRequestsList'
import { ActiveDuelsList } from '../../duels/ActiveDuelsList'
import { ActiveDuelSlot } from '../../duels/ActiveDuelSlot'
import { Duel } from '../../duels/Duel'

import styles from './ActiveDuels.scss'

export const FINALIZING_STATUSES: Array<ActiveDuelSlot_duel['status']> = [
  'WON',
  'LOST',
  'DRAW',
]

// These popups are rendered locally rather than through the commonStore, so they
// disappear automatically when navigating to a different page.
export type ActiveDuelsPopup = (
  | ConfirmYesNoPopup
  | DuelRequestPopup
  | FinalizingDuelPopup
  | NewDuelPopup
  | PlayDuelRound
) & { onClose(): void }
export type ActiveDuelsPopupAction =
  | { type: 'push'; popup: ActiveDuelsPopup }
  | { type: 'filter'; filter: (popup: ActiveDuelsPopup) => boolean }
  | { type: 'pop' }

export const ActiveDuels = observer(function ActiveDuels(
  props: RouteComponentProps
): ReactElement {
  const [maxDuels, setMaxDuels] = useState(5)
  const [slotsUsed, setSlotsUsed] = useState(0)
  const [showDuel, setShowDuel] = useState<string | undefined>(undefined)
  const {
    duelConnections,
    duelRequestConnections,
    updateDuelConnections,
    updateDuelRequestConnections,
  } = useDuelConnections()
  const [localPopups, updateLocalPopups] = useReducer(
    (
      previous: ActiveDuelsPopup[],
      action: ActiveDuelsPopupAction
    ): ActiveDuelsPopup[] => {
      switch (action.type) {
        case 'push':
          return [...previous, action.popup]
        case 'pop':
          return previous.slice(1)
        case 'filter':
          return previous.filter(action.filter)
        default:
          never(action, 'Invalid local popup action')
      }
    },
    []
  )
  const closeCurrentPopup = useCallback((): void => {
    updateLocalPopups({ type: 'pop' })
  }, [])

  const { t } = useTranslation()

  const duelInfo = useQuery<ActiveDuelsQuery>(
    graphql`
      query ActiveDuelsQuery($count: Int!, $cursor: String) {
        duelInfo {
          rank
          ...DuelPage_duelInfo
        }
        maxDuels
        myDuels(statusFilter: ONLY_ACTIVE, first: $count, after: $cursor) {
          __id
          pageInfo {
            total
          }
          edges {
            node {
              status
            }
          }
        }
        myDuelRequests(first: $count, after: $cursor) {
          __id
          edges {
            __typename
          }
          pageInfo {
            total
          }
        }
        ...ActiveDuelsList_duels
        ...ActiveDuelRequestsList_duelRequests
      }
    `,
    { count: 5 },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  useEffect(() => {
    if (!duelInfo.data?.myDuels) {
      return
    }

    const connection = duelInfo.data.myDuels.__id
    updateDuelConnections({ type: 'add', connection })
  }, [duelInfo.data?.myDuels, updateDuelConnections])
  useEffect(() => {
    if (!duelInfo.data?.myDuelRequests) {
      return
    }

    const connection = duelInfo.data.myDuelRequests.__id
    updateDuelRequestConnections({ type: 'add', connection })
  }, [duelInfo.data?.myDuelRequests, updateDuelRequestConnections])

  const newDuelRequestSubscriptionConfig = useMemo(
    (): GraphQLSubscriptionConfig<ActiveDuelsNewDuelSubscription> => ({
      subscription: graphql`
        subscription ActiveDuelsNewDuelSubscription(
          $duelConnections: [ID!]!
          $duelRequestConnections: [ID!]!
        ) {
          duelCreated {
            duelRequest {
              id @deleteEdge(connections: $duelRequestConnections)
            }
            duel
              @appendNode(
                connections: $duelConnections
                edgeTypeName: "DuelEdge"
              ) {
              id
              status
              ...ActiveDuelSlot_duel
              ...DuelFinalizingPopup_item
              ...PlayDuelRound_duel
            }
          }
        }
      `,
      variables: {
        duelConnections,
        duelRequestConnections,
      },
      onNext(subscription) {
        if (!subscription?.duelCreated) {
          return
        }

        if (!subscription.duelCreated.duelRequest) {
          setSlotsUsed((previous) => previous + 1)
        }
      },
    }),
    [duelConnections, duelRequestConnections]
  )
  useSubscription(newDuelRequestSubscriptionConfig)

  const duelUpdatedSubscription = useMemo(
    (): GraphQLSubscriptionConfig<ActiveDuelsDuelUpdatedSubscription> => ({
      subscription: graphql`
        subscription ActiveDuelsDuelUpdatedSubscription {
          duelUpdated {
            id
            status
            ...ActiveDuelSlot_duel
            ...DuelFinalizingPopup_item
            ...PlayDuelRound_duel
          }
        }
      `,
      variables: {},
    }),
    []
  )
  useSubscription(duelUpdatedSubscription)

  const newDuelClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (!duelInfo.data) {
        return
      }

      updateLocalPopups({
        type: 'push',
        popup: {
          type: 'new-duel',
          duelsConnectionIds: duelConnections,
          duelRequestsConnectionIds: duelRequestConnections,
          onClose: closeCurrentPopup,
          slotsAvailable: maxDuels - slotsUsed,
        },
      })
    },
    [
      closeCurrentPopup,
      duelConnections,
      duelInfo.data,
      duelRequestConnections,
      maxDuels,
      slotsUsed,
    ]
  )
  const newDuelEnterHandler = useEnterKeyHandler(newDuelClicked)

  useEffect(() => {
    if (
      duelInfo.data &&
      duelInfo.data.myDuels &&
      duelInfo.data.myDuelRequests
    ) {
      setSlotsUsed(
        duelInfo.data.myDuelRequests.pageInfo.total +
          duelInfo.data.myDuels.pageInfo.total
      )
      setMaxDuels(duelInfo.data.maxDuels)
    }
  }, [duelInfo.data, closeCurrentPopup, localPopups])

  const backFromDuel = useCallback((): void => {
    setShowDuel(undefined)
  }, [])

  const toDuel = useCallback(
    (duel: PlayDuelRound_duel$key & { id: string }): void => {
      updateLocalPopups({
        type: 'push',
        popup: {
          type: 'play-duel-round',
          onClose: closeCurrentPopup,
          duel,
          onPlay: () => setShowDuel(duel.id),
        },
      })
    },
    [closeCurrentPopup]
  )

  const renderPlaceholder = useCallback(
    (index: number) => (
      <ActiveDuelSlot key={index} duel={null} request={null} />
    ),
    []
  )

  const loading = !duelInfo.data && !duelInfo.error
  const showEmptyListPlaceholder =
    duelInfo.data &&
    (duelInfo.data.myDuels?.edges.filter(
      (duel) => !FINALIZING_STATUSES.includes(duel.node.status)
    ).length ?? 0) +
      (duelInfo.data.myDuelRequests?.edges.length ?? 0) ===
      0

  return showDuel ? (
    <Duel duel={showDuel} onBackClicked={backFromDuel} />
  ) : (
    <DuelPage {...props} duelInfo={duelInfo.data?.duelInfo}>
      <OnlineMenu>
        {localPopups.length > 0 && (
          <Popup
            {...localPopups[0]}
            key={
              'popupKey' in localPopups[0] ? localPopups[0].popupKey : undefined
            }
          />
        )}

        <div className={styles.activeDuels}>
          <div className={styles.intro}>
            {showEmptyListPlaceholder
              ? duelInfo?.data?.duelInfo?.rank
                ? t('activeDuels.emptyListCompleted')
                : t('activeDuels.emptyListNew')
              : t('activeDuels.title')}
          </div>

          <div className={styles.list} role='list'>
            {loading && !duelInfo.data && <LoadingIndicator />}

            {showEmptyListPlaceholder && (
              <EmptyList renderItem={renderPlaceholder} />
            )}

            {duelInfo.data && (
              <ActiveDuelsList
                localPopups={localPopups}
                duelsConnectionIds={duelConnections}
                duels={duelInfo.data}
                toDuel={toDuel}
                updateDuelConnections={updateDuelConnections}
                updateLocalPopups={updateLocalPopups}
              />
            )}

            {duelInfo.data && (
              <ActiveDuelRequestsList
                duelConnections={duelConnections}
                duelRequestConnections={duelRequestConnections}
                duelRequests={duelInfo.data}
                localPopups={localPopups}
                setSlotsUsed={setSlotsUsed}
                updateDuelRequestConnections={updateDuelRequestConnections}
                updateLocalPopups={updateLocalPopups}
              />
            )}
          </div>

          {duelInfo.error && (
            <GraphQlError error={duelInfo.error} retry={duelInfo.retry} />
          )}

          {duelInfo.data && slotsUsed < maxDuels && (
            <div className={styles.button}>
              <SecondaryButton
                disabled={loading}
                onClick={newDuelClicked}
                onKeyPress={newDuelEnterHandler}
                tabIndex={0}
              >
                {t('menu.newDuel')}
              </SecondaryButton>
            </div>
          )}
        </div>
      </OnlineMenu>
    </DuelPage>
  )
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TextItemView_pages = ReadonlyArray<{
    readonly " $fragmentRefs": FragmentRefs<"TextItemPage_page">;
    readonly " $refType": "TextItemView_pages";
}>;
export type TextItemView_pages$data = TextItemView_pages;
export type TextItemView_pages$key = ReadonlyArray<{
    readonly " $data"?: TextItemView_pages$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TextItemView_pages">;
}>;



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "plural": true
    },
    "name": "TextItemView_pages",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TextItemPage_page"
        }
    ],
    "type": "BrainSnackPage",
    "abstractKey": null
} as any;
(node as any).hash = '4d79e91e99289fe14082206297d8ec65';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MainMenuChangeLanguageMutationVariables = {
    language: string;
};
export type MainMenuChangeLanguageMutationResponse = {
    readonly changeLanguage: {
        readonly __typename: string;
    };
};
export type MainMenuChangeLanguageMutation = {
    readonly response: MainMenuChangeLanguageMutationResponse;
    readonly variables: MainMenuChangeLanguageMutationVariables;
};



/*
mutation MainMenuChangeLanguageMutation(
  $language: String!
) {
  changeLanguage(language: $language) {
    __typename
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MainMenuChangeLanguageMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "changeLanguage",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MainMenuChangeLanguageMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "changeLanguage",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "da8fb6a1b2d72037c2a97c202424b5c4",
            "id": null,
            "metadata": {},
            "name": "MainMenuChangeLanguageMutation",
            "operationKind": "mutation",
            "text": "mutation MainMenuChangeLanguageMutation(\n  $language: String!\n) {\n  changeLanguage(language: $language) {\n    __typename\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a21689dd2e3783fafacc8235808b287f';
export default node;

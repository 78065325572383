import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import { ToggleSwitch } from '../common/ToggleSwitch'

import styles from './MainMenuItem.scss'

type MainMenuItemProps = (
  | {
      to: string
      type: 'link'
    }
  | {
      type: 'section'
    }
  | {
      type: 'popup'
    }
  | {
      handleChange(newValue: boolean): void
      currentValue: boolean
      disabled?: boolean
      type: 'checkbox'
    }
) & {
  icon: IconProp
  title: string
} & DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >

export function MainMenuItem(
  props: PropsWithChildren<MainMenuItemProps>
): ReactElement {
  const element = useRef<HTMLDivElement>(null)

  const [opened, setOpened] = useState(false)
  const toggleOpened = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      setOpened(!opened)
    },
    [opened]
  )
  const toggleOpenedEnterHandler = useEnterKeyHandler(toggleOpened)

  const toggleSwitch = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (props.type === 'checkbox' && !props.disabled) {
        props.handleChange(!props.currentValue)
      }
    },
    [props]
  )
  const toggleSwitchEnterHandler = useEnterKeyHandler(toggleSwitch)

  useEffect(() => {
    if (props.type !== 'popup') {
      return
    }

    const eventListener = (event: MouseEvent) => {
      if (!element.current) {
        return
      }

      if (
        !(event.target instanceof Node) ||
        element.current.contains(event.target)
      ) {
        return
      }

      setOpened(false)
    }

    document.addEventListener('click', eventListener)

    return (): void => {
      document.removeEventListener('click', eventListener)
    }
  }, [props.type])

  const { className, title, to, type, icon, children, ...rest } = {
    to: undefined,
    ...props,
  }

  return (
    <div className={classNames(className, styles.menuItemOuter)} ref={element}>
      {props.type !== 'checkbox' ? (
        <a
          className={styles.menuItem}
          href={type === 'link' ? to : '#'}
          onClick={type !== 'link' ? toggleOpened : undefined}
          onKeyPress={type !== 'link' ? toggleOpenedEnterHandler : undefined}
          tabIndex={0}
          {...rest}
        >
          <FontAwesomeIcon className={styles.icon} icon={icon} />

          <span className={styles.title}>{title}</span>

          <FontAwesomeIcon
            className={styles.iconRight}
            icon={
              type !== 'link'
                ? opened
                  ? faChevronUp
                  : faChevronDown
                : faExternalLinkAlt
            }
          />
        </a>
      ) : (
        <div
          className={classNames(styles.menuItem, {
            [styles.disabled]: props.disabled,
          })}
        >
          <a
            className={styles.checkboxHeader}
            href={props.disabled ? undefined : '#'}
            onClick={toggleSwitch}
            onKeyPress={toggleSwitchEnterHandler}
            tabIndex={props.disabled ? undefined : 0}
          >
            <FontAwesomeIcon className={styles.icon} icon={icon} />

            <span className={styles.title}>{title}</span>
          </a>

          <ToggleSwitch
            disabled={props.disabled}
            onSwitchChanged={props.handleChange}
            currentValue={props.currentValue}
          />
        </div>
      )}

      {opened && children}
    </div>
  )
}

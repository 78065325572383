import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { classNames } from '../../utils/classNames'

import styles from './AvatarContainer.scss'

export type AvatarContainerProps = PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>

export function AvatarContainer(props: AvatarContainerProps): ReactElement {
  const { className, children, ...rest } = props

  return (
    <div className={classNames(styles.avatar, className)} {...rest}>
      {children}
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type ActiveDuelSlot_duel = {
    readonly players: ReadonlyArray<{
        readonly rating: number;
        readonly user: {
            readonly id: string;
            readonly nameForDuelling: string;
            readonly isMe: boolean;
            readonly profileImageForDuelling: string | null;
            readonly hasAnonymousDuellingEnabled: boolean;
        };
    }>;
    readonly status: DuelStatus;
    readonly time: string | null;
    readonly " $refType": "ActiveDuelSlot_duel";
};
export type ActiveDuelSlot_duel$data = ActiveDuelSlot_duel;
export type ActiveDuelSlot_duel$key = {
    readonly " $data"?: ActiveDuelSlot_duel$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_duel">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveDuelSlot_duel",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "DuelPlayer",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rating",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isMe",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageForDuelling",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasAnonymousDuellingEnabled",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
        }
    ],
    "type": "Duel",
    "abstractKey": null
} as any;
(node as any).hash = 'b6214d74862865474bec3dc383d271c7';
export default node;

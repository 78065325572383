/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SelfActivatePageMutationVariables = {
    email: string;
    companyUserId?: string | null | undefined;
};
export type SelfActivatePageMutationResponse = {
    readonly requestSelfActivation: {
        readonly message: string;
        readonly success: boolean;
    };
};
export type SelfActivatePageMutation = {
    readonly response: SelfActivatePageMutationResponse;
    readonly variables: SelfActivatePageMutationVariables;
};



/*
mutation SelfActivatePageMutation(
  $email: String!
  $companyUserId: String
) {
  requestSelfActivation(email: $email, companyUserId: $companyUserId) {
    message
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "companyUserId"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "email"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "companyUserId",
                    "variableName": "companyUserId"
                },
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                }
            ],
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "requestSelfActivation",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "SelfActivatePageMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "SelfActivatePageMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "3827292e658e241d7ae46a0b87301db4",
            "id": null,
            "metadata": {},
            "name": "SelfActivatePageMutation",
            "operationKind": "mutation",
            "text": "mutation SelfActivatePageMutation(\n  $email: String!\n  $companyUserId: String\n) {\n  requestSelfActivation(email: $email, companyUserId: $companyUserId) {\n    message\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0b4fdf27a933bce37b79dfeb6b3fef35';
export default node;

import { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { OrderView_feedback$key } from '../../../generated/OrderView_feedback.graphql'
import {
  OrderView_item,
  OrderView_item$key,
} from '../../../generated/OrderView_item.graphql'
import { assert } from '../../../utils/assert'
import {
  handleEnterKey,
  useEnterKeyHandler,
} from '../../../utils/handleEnterKey'
import {
  BrainItemProps,
  BrainItemState,
} from '../../../utils/hooks/useBrainItems'

import { PrimaryButton } from '../../common/PrimaryButton'
import { FeedbackDrawer } from '../FeedbackDrawer'

import {
  AnswerOption,
  AnswerOptionFeedback,
  AnswerOptionViewType,
} from './elements/AnswerOption'
import { DraggableAnswerOption } from './elements/DraggableAnswerOption'
import { Question } from './elements/Question'
import { QuestionInstruction } from './elements/QuestionInstruction'
import styles from './OrderView.scss'
import { QuestionHandlers } from './QuestionView'

interface OrderViewProps {
  feedback: OrderView_feedback$key | null
  item: OrderView_item$key
}

export function OrderView(
  props: OrderViewProps & BrainItemProps & QuestionHandlers
): ReactElement {
  const { t, i18n } = useTranslation()

  const item = useFragment(
    graphql`
      fragment OrderView_item on OrderQuestion {
        id
        answerOptions {
          id
          ...AnswerOption_answer
        }
        questionType
        text(language: $language)
        ...FeedbackDrawer_brainItem
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment OrderView_feedback on BrainItemCompletionData {
        __typename
        ...FeedbackDrawer_feedback
        ... on GenericQuestionCompletionData {
          correct
          correctAnswers {
            id
          }
          userAnswers {
            id
            ...AnswerOption_answer
          }
        }
      }
    `,
    props.feedback
  )

  const [order, setOrder] = useState(
    feedback?.__typename === 'GenericQuestionCompletionData' &&
      feedback.userAnswers
      ? [...feedback.userAnswers]
      : [...item.answerOptions]
  )
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false)

  const onButtonClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (props.brainItemState === BrainItemState.READY) {
        props.onSubmit({
          answerOptions: order.map((option) => option.id),
        })
      }
    },
    [order, props]
  )

  const toggleShowCorrectAnswer = useCallback(
    (event: SyntheticEvent): void => {
      setShowCorrectAnswers(!showCorrectAnswers)

      event.preventDefault()
    },
    [showCorrectAnswers]
  )
  const toggleShowCorrectAnswerKeyPress = useEnterKeyHandler(
    toggleShowCorrectAnswer
  )

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }

      const newOrder = Array.from(order)
      const [removed] = newOrder.splice(result.source.index, 1)
      newOrder.splice(result.destination.index, 0, removed)

      setOrder(newOrder)
    },
    [order]
  )

  const answerData =
    props.brainItemState > BrainItemState.CHECKING ? feedback : undefined

  if (answerData) {
    if (answerData.__typename !== 'GenericQuestionCompletionData') {
      throw new Error(
        'Invalid answer data for order question: ' + JSON.stringify(answerData)
      )
    }

    if (
      item.questionType === 'KNOWLEDGE' &&
      (answerData.correct === null || answerData.correctAnswers === null)
    ) {
      throw new Error(
        'Invalid answer data for non-poll order question: ' +
          JSON.stringify(answerData)
      )
    }
  }

  const show =
    showCorrectAnswers && answerData?.correctAnswers
      ? answerData.correctAnswers
          .map((answer) =>
            item.answerOptions.find((option) => option.id == answer.id)
          )
          .filter(
            (answer): answer is OrderView_item['answerOptions'][0] =>
              typeof answer !== 'undefined'
          )
      : order

  let answersCorrect = 0
  const answers = show.map((answer, index) => {
    let answerFeedback: AnswerOptionFeedback | undefined
    if (answerData) {
      if (item.questionType !== 'KNOWLEDGE') {
        answerFeedback = AnswerOptionFeedback.POLL
      } else {
        assert(answerData.correctAnswers !== null)

        const correctIndex = answerData.correctAnswers
          .map(({ id }) => id)
          .indexOf(answer.id)

        answerFeedback =
          correctIndex === index
            ? AnswerOptionFeedback.CORRECT
            : AnswerOptionFeedback.INCORRECT
        if (answerFeedback === AnswerOptionFeedback.CORRECT) {
          answersCorrect += 1
        }
      }
    }

    const checking = props.brainItemState === BrainItemState.CHECKING
    const isSubmitted = props.brainItemState >= BrainItemState.CHECKING
    return isSubmitted ? (
      <AnswerOption
        answer={answer}
        checking={checking}
        disabled={isSubmitted}
        feedback={answerFeedback}
        key={answer.id}
        selected={false}
        type={
          !isSubmitted || item.questionType === 'KNOWLEDGE'
            ? AnswerOptionViewType.DRAGGABLE
            : AnswerOptionViewType.POLL
        }
      />
    ) : (
      <DraggableAnswerOption
        answer={answer}
        answerId={answer.id}
        checking={checking}
        disabled={isSubmitted}
        feedback={answerFeedback}
        index={index}
        key={answer.id}
        selected={false}
      />
    )
  })

  const numberFormat = new Intl.NumberFormat(i18n.language)

  return (
    <div className={styles.question}>
      <Question text={item.text} />
      <QuestionInstruction
        text={
          answerData && item.questionType === 'KNOWLEDGE'
            ? showCorrectAnswers
              ? t('streamItem.match.showingCorrectAnswer')
              : t('streamItem.order.correct', {
                  num: numberFormat.format(answersCorrect),
                  max: numberFormat.format(
                    answerData.correctAnswers?.length ?? 0
                  ),
                })
            : t('streamItem.order.helpText')
        }
      />

      <form
        role='listbox'
        aria-label={t('streamItem.order.helpText')}
        aria-readonly={props.brainItemState !== BrainItemState.READY}
      >
        {props.brainItemState >= BrainItemState.CHECKING ? (
          answers
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={item.id} direction={'vertical'}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {answers}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </form>

      {props.brainItemState < BrainItemState.FEEDBACK || !answerData ? (
        <div className={styles.buttonContainer}>
          <PrimaryButton
            disabled={props.brainItemState === BrainItemState.CHECKING}
            onClick={onButtonClicked}
            onKeyPress={handleEnterKey(onButtonClicked)}
          >
            {props.brainItemState === BrainItemState.READY &&
              (item.questionType === 'KNOWLEDGE'
                ? t('common.CheckAnswer')
                : t('common.Answers'))}
            {props.brainItemState === BrainItemState.CHECKING &&
              t('common.Checking')}
            {props.brainItemState === BrainItemState.FEEDBACK &&
              t('common.Close')}
          </PrimaryButton>
        </div>
      ) : (
        <FeedbackDrawer
          brainItem={item}
          brainItemState={props.brainItemState}
          feedback={answerData}
          heading={
            item.questionType !== 'KNOWLEDGE'
              ? t('streamItem.order.pollSubmittedFeedback')
              : answerData.correct
              ? t('streamItem.brainItem.correct')
              : props.inOnboarding
              ? t('streamItem.brainItem.almostCorrect')
              : t('streamItem.brainItem.incorrect')
          }
          inDuel={!!props.inDuel}
          inOnboarding={!!props.inOnboarding}
          onBoardingItemsLeft={!!props.onboardingItemsLeft}
          onClose={props.onClose}
        >
          {item.questionType === 'KNOWLEDGE' && !answerData.correct && (
            <a
              className={styles.toggleCorrectAnswersLink}
              href='#'
              onClick={toggleShowCorrectAnswer}
              onKeyPress={toggleShowCorrectAnswerKeyPress}
              tabIndex={0}
            >
              {showCorrectAnswers
                ? t('streamItem.match.showMyAnswer')
                : t('streamItem.match.showCorrectAnswer')}
            </a>
          )}
        </FeedbackDrawer>
      )}
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DuelPreference = "ANONYMOUS_DUELLING" | "AUTO_DUELLING" | "DUELLING_ENABLED";
export type MainMenuToggleDuelPreferenceMutationVariables = {
    preference: DuelPreference;
    enabled: boolean;
};
export type MainMenuToggleDuelPreferenceMutationResponse = {
    readonly toggleDuelPreference: {
        readonly id: string;
        readonly hasDuellingEnabled: boolean;
        readonly hasAutoDuellingEnabled: boolean;
        readonly hasAnonymousDuellingEnabled: boolean;
    };
};
export type MainMenuToggleDuelPreferenceMutationRawResponse = {
    readonly toggleDuelPreference: {
        readonly id: string;
        readonly hasDuellingEnabled: boolean;
        readonly hasAutoDuellingEnabled: boolean;
        readonly hasAnonymousDuellingEnabled: boolean;
    };
};
export type MainMenuToggleDuelPreferenceMutation = {
    readonly response: MainMenuToggleDuelPreferenceMutationResponse;
    readonly variables: MainMenuToggleDuelPreferenceMutationVariables;
    readonly rawResponse: MainMenuToggleDuelPreferenceMutationRawResponse;
};



/*
mutation MainMenuToggleDuelPreferenceMutation(
  $preference: DuelPreference!
  $enabled: Boolean!
) {
  toggleDuelPreference(preference: $preference, enabled: $enabled) {
    id
    hasDuellingEnabled
    hasAutoDuellingEnabled
    hasAnonymousDuellingEnabled
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "enabled"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "preference"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "enabled",
                    "variableName": "enabled"
                },
                {
                    "kind": "Variable",
                    "name": "preference",
                    "variableName": "preference"
                }
            ],
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "toggleDuelPreference",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAutoDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAnonymousDuellingEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "MainMenuToggleDuelPreferenceMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "MainMenuToggleDuelPreferenceMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "3f068e282b3e120539e30f4cfaf8cd26",
            "id": null,
            "metadata": {},
            "name": "MainMenuToggleDuelPreferenceMutation",
            "operationKind": "mutation",
            "text": "mutation MainMenuToggleDuelPreferenceMutation(\n  $preference: DuelPreference!\n  $enabled: Boolean!\n) {\n  toggleDuelPreference(preference: $preference, enabled: $enabled) {\n    id\n    hasDuellingEnabled\n    hasAutoDuellingEnabled\n    hasAnonymousDuellingEnabled\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'f2a6073343dda439081dcb1382be3c3f';
export default node;

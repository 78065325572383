import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Header } from '../../containers/Header'

import styles from './InternetRequiredPopup.scss'

export function InternetRequiredPopup(): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles.popup}>
      <Header duel wide>
        <h1 id='popup-label'>{t('error.unavailableOffline.title')}</h1>
        <p>{t('error.unavailableOffline.subtitle')}</p>

        <div className={styles.circle}>
          <FontAwesomeIcon icon={faWifiSlash} />
        </div>
      </Header>

      <div className={styles.explanation}>
        {t('error.unavailableOffline.explanation')}
      </div>
    </div>
  )
}

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { SelectableTopic_topic$key } from '../../generated/SelectableTopic_topic.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import styles from './SelectableTopic.scss'
import { Topic } from './Topic'

interface SelectableTopicProps {
  selected: boolean
  onClick: () => void
  topic: SelectableTopic_topic$key
  ariaLabelledby: string
  themeTopic?: boolean
}

export function SelectableTopic(props: SelectableTopicProps): ReactElement {
  const keyHandler = useEnterKeyHandler(props.onClick)

  const topic = useFragment(
    graphql`
      fragment SelectableTopic_topic on TopicWithScore {
        name(language: $language)
        percentage
        topicIcon
      }
    `,
    props.topic
  )

  return (
    <div
      className={classNames(styles.topicOuter, {
        [styles.topicOuterSelected]: props.selected,
      })}
      onClick={props.onClick}
      onKeyPress={keyHandler}
      role='checkbox'
      tabIndex={0}
      aria-checked={props.selected}
      aria-label={topic.name}
      aria-labelledby={props.ariaLabelledby}
    >
      <div
        className={classNames(
          props.selected
            ? styles.topicSelectedIndicator
            : styles.topicUnselectedIndicator,
          {
            [styles.themeTopic]: props.themeTopic,
          }
        )}
      >
        <FontAwesomeIcon icon={faCheck} />
      </div>

      <Topic
        className={styles.topic}
        labelId={props.ariaLabelledby}
        name={topic.name}
        percentage={topic.percentage}
        withCutout={true}
        themeTopic={props.themeTopic}
        topicIcon={topic.topicIcon}
      />
    </div>
  )
}

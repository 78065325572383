import { useCallback, useState } from 'react'
import { graphql } from 'react-relay'
import { readInlineData } from 'relay-runtime'
import { useAnswerCategories_feedback$key } from '../../generated/useAnswerCategories_feedback.graphql'
import { useAnswerCategories_question$key } from '../../generated/useAnswerCategories_question.graphql'

type ID = string
type CategoryMap = { [category: string]: ReadonlyArray<ID> }
type MoveToCategoryAction = (categoryId: ID, answerId: ID) => void

export const MATCHVIEW_LISTID = '-1'

export function useAnswerCategories(
  question: useAnswerCategories_question$key,
  initialState: useAnswerCategories_feedback$key | null
): [CategoryMap, MoveToCategoryAction] {
  const [matchCategoryAnswers, setMatchCategoryAnswers] = useState<CategoryMap>(
    () => {
      const questionData = readInlineData(
        graphql`
          fragment useAnswerCategories_question on MatchQuestion @inline {
            answerCategories {
              id
            }
            answerOptions {
              id
            }
          }
        `,
        question
      )

      const feedbackData = readInlineData(
        graphql`
          fragment useAnswerCategories_feedback on MatchQuestionCompletionData
          @inline {
            userAnswers {
              category {
                id
              }
              answers {
                id
              }
            }
          }
        `,
        initialState
      )

      const categoryMap: CategoryMap = {
        [MATCHVIEW_LISTID]: feedbackData?.userAnswers
          ? []
          : questionData.answerOptions.map((answer) => answer.id),
      }
      questionData.answerCategories.forEach((category) => {
        categoryMap[category.id] =
          feedbackData?.userAnswers
            ?.find((userAnswer) => userAnswer.category.id === category.id)
            ?.answers?.map((answer) => answer.id) ?? []
      })

      return categoryMap
    }
  )

  const moveAnswerToCategory = useCallback(
    (categoryId: ID, answerId: ID): void => {
      setMatchCategoryAnswers(
        Object.fromEntries([
          // Create a new category entry, just in case.
          ...[[categoryId, [answerId]]],
          ...Object.entries(matchCategoryAnswers).map(([category, answers]) => {
            // Add it to the new category.
            if (category === categoryId) {
              return answers.includes(answerId)
                ? [category, answers]
                : [category, [...answers, answerId]]
            }

            // Remove it from any other category
            return [category, answers.filter((answer) => answer !== answerId)]
          }),
        ])
      )
    },
    [matchCategoryAnswers]
  )

  return [matchCategoryAnswers, moveAnswerToCategory]
}

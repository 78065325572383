import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Lightbox from 'react-image-lightbox'
import { classNames } from '../../utils/classNames'

import styles from './ItemHeader.scss'

interface ItemHeaderProps {
  children?(onImageOpened: (url: string) => void): ReactElement
  isVideo?: boolean
}

export function ItemHeader(props: ItemHeaderProps): ReactElement {
  const [showLightBox, setShowLightbox] = useState<string | undefined>(
    undefined
  )
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)

  const onImageOpened = useCallback((url: string) => {
    setShowLightbox(url)
  }, [])
  const closeLightbox = useCallback(() => {
    setShowLightbox(undefined)
  }, [])

  useEffect(() => {
    let alive = true
    setTimeout(() => {
      alive && setShowLoadingIndicator(true)
    }, 750)

    return () => {
      alive = false
    }
  }, [])

  return (
    <>
      <div
        className={classNames(styles.assetContainer, {
          [styles.image]: !props.isVideo,
        })}
      >
        {props.children ? (
          props.children(onImageOpened)
        ) : (
          <div
            className={styles.loadingIndicator}
            style={{ opacity: showLoadingIndicator ? 1 : 0 }}
          >
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        )}
      </div>

      {showLightBox && (
        <Lightbox
          mainSrc={showLightBox}
          onCloseRequest={closeLightbox}
          reactModalStyle={{
            overlay: {
              zIndex: 1041,
            } as CSSProperties,
          }}
        />
      )}
    </>
  )
}

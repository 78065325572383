import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'

import defaultBrainSnackImage from '../../../../../images/default-stream-items/brainsnack.jpg'
import defaultMatchImage from '../../../../../images/default-stream-items/match.jpg'
import defaultQuestionImage from '../../../../../images/default-stream-items/question.jpg'
import defaultSliderImage from '../../../../../images/default-stream-items/slider.jpg'
import { Asset_asset$key } from '../../generated/Asset_asset.graphql'
import { BrainItemHeader_brainItem$key } from '../../generated/BrainItemHeader_brainItem.graphql'
import { Asset } from '../common/Asset'
import { ItemHeader } from './ItemHeader'
import styles from './BrainItemHeader.scss'
import { TopicIndicator } from './TopicIndicator'

interface BrainItemHeaderProps {
  brainItem: BrainItemHeader_brainItem$key
}

export function BrainItemHeader(props: BrainItemHeaderProps): ReactElement {
  const { t } = useTranslation()

  const item = useFragment(
    graphql`
      fragment BrainItemHeader_brainItem on BrainItem {
        __typename
        ...TopicIndicator_item
        ... on BrainSnack {
          pages {
            asset {
              translation(language: $language) {
                mime
              }
              ...Asset_asset
            }
          }
        }
        ... on HasAsset {
          asset {
            translation(language: $language) {
              mime
            }
            ...Asset_asset
          }
        }
      }
    `,
    props.brainItem
  )

  let asset: (Asset_asset$key & { translation: { mime: string } }) | undefined
  if ('asset' in item && item.asset) {
    asset = item.asset
  } else if (
    item.__typename === 'BrainSnack' &&
    item.pages.length > 0 &&
    item.pages[0].asset
  ) {
    asset = item.pages[0].asset
  }

  if (asset) {
    return (
      <ItemHeader isVideo={asset.translation.mime.startsWith('video/')}>
        {(onImageClicked) => (
          <>
            <Asset
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              asset={asset!}
              className={styles.image}
              onImageClicked={onImageClicked}
            />
            <TopicIndicator item={item} />
          </>
        )}
      </ItemHeader>
    )
  }

  return (
    <ItemHeader>
      {() => (
        <>
          {(item.__typename === 'MultipleChoiceQuestion' ||
            item.__typename === 'MultipleSelectQuestion') && (
            <img
              alt={t(
                `streamItem.${
                  item.__typename === 'MultipleChoiceQuestion'
                    ? 'multiplechoice'
                    : 'multipleselect'
                }.questionTitle`
              )}
              src={defaultQuestionImage}
            />
          )}
          {item.__typename === 'OrderQuestion' && (
            <img
              alt={t('streamItem.order.questionTitle')}
              src={defaultQuestionImage}
            />
          )}
          {item.__typename === 'MatchQuestion' && (
            <img
              alt={t('streamItem.match.questionTitle')}
              src={defaultMatchImage}
            />
          )}
          {item.__typename === 'SliderQuestion' && (
            <img
              alt={t('streamItem.slider.questionTitle')}
              src={defaultSliderImage}
            />
          )}
          {item.__typename === 'BrainSnack' && (
            <img
              alt={t('streamItem.brainSnack.defaultTitle')}
              src={defaultBrainSnackImage}
            />
          )}

          <TopicIndicator item={item} />
        </>
      )}
    </ItemHeader>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LoginType = "LTI" | "SAML" | "WEB";
export type MainMenuUserQueryVariables = {};
export type MainMenuUserQueryResponse = {
    readonly me: {
        readonly companyUserId: string | null;
        readonly companyUserIdString: string;
        readonly email: string;
        readonly emailNotificationsEnabled: boolean;
        readonly firstName: string;
        readonly fullName: string;
        readonly hasAutoDuellingEnabled: boolean;
        readonly hasDuellingAvailable: boolean;
        readonly hasDuellingEnabled: boolean;
        readonly hasAnonymousDuellingEnabled: boolean;
        readonly id: string;
        readonly isOnboarding: boolean;
        readonly loginType: LoginType;
        readonly profileImage: string | null;
        readonly pushNotificationsEnabled: boolean;
    } | null;
};
export type MainMenuUserQuery = {
    readonly response: MainMenuUserQueryResponse;
    readonly variables: MainMenuUserQueryVariables;
};



/*
query MainMenuUserQuery {
  me {
    companyUserId
    companyUserIdString
    email
    emailNotificationsEnabled
    firstName
    fullName
    hasAutoDuellingEnabled
    hasDuellingAvailable
    hasDuellingEnabled
    hasAnonymousDuellingEnabled
    id
    isOnboarding
    loginType
    profileImage
    pushNotificationsEnabled
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyUserId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyUserIdString",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailNotificationsEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAutoDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingAvailable",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAnonymousDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOnboarding",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loginType",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImage",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pushNotificationsEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "MainMenuUserQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "MainMenuUserQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "479325bb7bc2e41c989a275669494a70",
            "id": null,
            "metadata": {},
            "name": "MainMenuUserQuery",
            "operationKind": "query",
            "text": "query MainMenuUserQuery {\n  me {\n    companyUserId\n    companyUserIdString\n    email\n    emailNotificationsEnabled\n    firstName\n    fullName\n    hasAutoDuellingEnabled\n    hasDuellingAvailable\n    hasDuellingEnabled\n    hasAnonymousDuellingEnabled\n    id\n    isOnboarding\n    loginType\n    profileImage\n    pushNotificationsEnabled\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0621cadbd3850ed864f85fb0946be6ab';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelRequestStatus = "INVITED" | "WAITING";
export type ActiveDuelRequestsListNewSubscriptionVariables = {
    duelRequestConnections: Array<string>;
};
export type ActiveDuelRequestsListNewSubscriptionResponse = {
    readonly duelRequestCreated: {
        readonly id: string;
        readonly status: DuelRequestStatus;
        readonly " $fragmentRefs": FragmentRefs<"ActiveDuelSlot_request" | "DuelRequestPopup_item">;
    } | null;
};
export type ActiveDuelRequestsListNewSubscription = {
    readonly response: ActiveDuelRequestsListNewSubscriptionResponse;
    readonly variables: ActiveDuelRequestsListNewSubscriptionVariables;
};



/*
subscription ActiveDuelRequestsListNewSubscription {
  duelRequestCreated {
    id
    status
    ...ActiveDuelSlot_request
    ...DuelRequestPopup_item
  }
}

fragment ActiveDuelSlot_request on DuelRequest {
  opponent {
    __typename
    rating
    user {
      __typename
      id
      nameForDuelling
      profileImageForDuelling
      hasAnonymousDuellingEnabled
    }
    id
  }
  status
  time
}

fragment DuelRequestPopup_item on DuelRequest {
  id
  challenger {
    __typename
    ...DuelVersusHeader_right
    id
  }
  opponent {
    __typename
    ...DuelVersusHeader_left
    id
  }
}

fragment DuelVersusHeader_left on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}

fragment DuelVersusHeader_right on Player {
  __isPlayer: __typename
  rank
  user {
    __typename
    id
    nameForDuelling
    isMe
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
  ... on DuelPlayer {
    timedOut
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "duelRequestConnections"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameForDuelling",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImageForDuelling",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAnonymousDuellingEnabled",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMe",
        "storageKey": null
    } as any, v8 = {
        "kind": "TypeDiscriminator",
        "abstractKey": "__isPlayer"
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
    } as any, v10 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timedOut",
                "storageKey": null
            }
        ],
        "type": "DuelPlayer",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActiveDuelRequestsListNewSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelRequest",
                    "kind": "LinkedField",
                    "name": "duelRequestCreated",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ActiveDuelSlot_request"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRequestPopup_item"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActiveDuelRequestsListNewSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelRequest",
                    "kind": "LinkedField",
                    "name": "duelRequestCreated",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "opponent",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rating",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "time",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "challenger",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        (v7 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                (v10 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendNode",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "duelRequestCreated",
                    "handleArgs": [
                        {
                            "kind": "Variable",
                            "name": "connections",
                            "variableName": "duelRequestConnections"
                        },
                        {
                            "kind": "Literal",
                            "name": "edgeTypeName",
                            "value": "DuelRequestEdge"
                        }
                    ]
                }
            ]
        },
        "params": {
            "cacheID": "f618352ff70b9b41ecb27e43c33e98a3",
            "id": null,
            "metadata": {},
            "name": "ActiveDuelRequestsListNewSubscription",
            "operationKind": "subscription",
            "text": "subscription ActiveDuelRequestsListNewSubscription {\n  duelRequestCreated {\n    id\n    status\n    ...ActiveDuelSlot_request\n    ...DuelRequestPopup_item\n  }\n}\n\nfragment ActiveDuelSlot_request on DuelRequest {\n  opponent {\n    __typename\n    rating\n    user {\n      __typename\n      id\n      nameForDuelling\n      profileImageForDuelling\n      hasAnonymousDuellingEnabled\n    }\n    id\n  }\n  status\n  time\n}\n\nfragment DuelRequestPopup_item on DuelRequest {\n  id\n  challenger {\n    __typename\n    ...DuelVersusHeader_right\n    id\n  }\n  opponent {\n    __typename\n    ...DuelVersusHeader_left\n    id\n  }\n}\n\nfragment DuelVersusHeader_left on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n\nfragment DuelVersusHeader_right on Player {\n  __isPlayer: __typename\n  rank\n  user {\n    __typename\n    id\n    nameForDuelling\n    isMe\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n  ... on DuelPlayer {\n    timedOut\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd8b0832b471a8108428a56e24bcbc790';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TopicCategoriesQueryVariables = {
    language: string;
};
export type TopicCategoriesQueryResponse = {
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"LearnHeader_user">;
    } | null;
    readonly topicCategories: ReadonlyArray<{
        readonly id: string;
        readonly unlocked: boolean;
        readonly progress: number;
        readonly startedAt: string | null;
        readonly " $fragmentRefs": FragmentRefs<"TopicCategory_item">;
    }>;
};
export type TopicCategoriesQuery = {
    readonly response: TopicCategoriesQueryResponse;
    readonly variables: TopicCategoriesQueryVariables;
};



/*
query TopicCategoriesQuery(
  $language: String!
) {
  me {
    ...LearnHeader_user
    id
  }
  topicCategories {
    id
    unlocked
    progress
    startedAt
    ...TopicCategory_item
  }
}

fragment LearnHeader_user on AuthenticatedUser {
  id
  firstLogin
  firstName
  fullName
  profileImage
}

fragment TopicCategory_item on TopicCategory {
  availableFrom
  completedAt
  description(language: $language)
  icon
  progress
  startedAt
  unlocked
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "language"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unlocked",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progress",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startedAt",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TopicCategoriesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LearnHeader_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicCategory",
                    "kind": "LinkedField",
                    "name": "topicCategories",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TopicCategory_item"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TopicCategoriesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstLogin",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicCategory",
                    "kind": "LinkedField",
                    "name": "topicCategories",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableFrom",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "language",
                                    "variableName": "language"
                                }
                            ],
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "icon",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "15282bf59849562a3704b83097ad1b81",
            "id": null,
            "metadata": {},
            "name": "TopicCategoriesQuery",
            "operationKind": "query",
            "text": "query TopicCategoriesQuery(\n  $language: String!\n) {\n  me {\n    ...LearnHeader_user\n    id\n  }\n  topicCategories {\n    id\n    unlocked\n    progress\n    startedAt\n    ...TopicCategory_item\n  }\n}\n\nfragment LearnHeader_user on AuthenticatedUser {\n  id\n  firstLogin\n  firstName\n  fullName\n  profileImage\n}\n\nfragment TopicCategory_item on TopicCategory {\n  availableFrom\n  completedAt\n  description(language: $language)\n  icon\n  progress\n  startedAt\n  unlocked\n}\n"
        }
    } as any;
})();
(node as any).hash = '933b7b157febe075934ddcc1908f7e02';
export default node;

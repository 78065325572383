/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RankingItem_item = {
    readonly rank: number | null;
    readonly rating: number;
    readonly user: {
        readonly id: string;
        readonly isMe: boolean;
        readonly fullNameForDuelling: string;
        readonly profileImageForDuelling: string | null;
        readonly hasAnonymousDuellingEnabled: boolean;
    };
    readonly " $refType": "RankingItem_item";
};
export type RankingItem_item$data = RankingItem_item;
export type RankingItem_item$key = {
    readonly " $data"?: RankingItem_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RankingItem_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingItem_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMe",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullNameForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAnonymousDuellingEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "RankingPlayer",
    "abstractKey": null
} as any;
(node as any).hash = 'ec8127c5831bab64523669f454f4fcff';
export default node;

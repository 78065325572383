import { useCallback, useEffect, useRef, useState } from 'react'
import { BrainItemState } from './useBrainItems'

export function useStream<T>(
  fetcher: () => Promise<T>,
  state: BrainItemState,
  setState: (newState: BrainItemState) => void
): [T | null] {
  const loading = useRef(false)
  const [loadedOnce, setLoadedOnce] = useState(false)

  const [currentItem, setCurrentItem] = useState<T | null>(null)
  const [pendingItem, setPendingItem] = useState<T | null>(null)

  const fetchItem = useCallback((): void => {
    fetcher()
      .then((item) => {
        currentItem ? setPendingItem(item) : setCurrentItem(item)
      })
      .finally(() => {
        loading.current = false
        setLoadedOnce(true)
      })
  }, [currentItem, fetcher])

  // Fetch the first item right away when mounting.
  useEffect(() => {
    if (!loadedOnce && !currentItem) {
      fetchItem()
    }
  }, [currentItem, fetchItem, loadedOnce])

  // When the status changes, we might need to fetch a new item as well.
  useEffect(() => {
    if (
      loadedOnce &&
      !loading.current &&
      !pendingItem &&
      state > BrainItemState.CHECKING
    ) {
      fetchItem()
    }
  }, [fetchItem, loadedOnce, pendingItem, state])

  // When one item is completed, move the pending one to the current position.
  useEffect(() => {
    if (state === BrainItemState.COMPLETED && pendingItem) {
      setState(BrainItemState.READY)
      setCurrentItem(pendingItem)
      setPendingItem(null)
    }
  }, [pendingItem, setState, state])

  return [currentItem]
}

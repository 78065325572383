/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AuthenticationForgotPasswordPageMutationVariables = {
    email: string;
};
export type AuthenticationForgotPasswordPageMutationResponse = {
    readonly requestNewPassword: string;
};
export type AuthenticationForgotPasswordPageMutation = {
    readonly response: AuthenticationForgotPasswordPageMutationResponse;
    readonly variables: AuthenticationForgotPasswordPageMutationVariables;
};



/*
mutation AuthenticationForgotPasswordPageMutation(
  $email: String!
) {
  requestNewPassword(email: $email)
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "email"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                }
            ],
            "kind": "ScalarField",
            "name": "requestNewPassword",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthenticationForgotPasswordPageMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AuthenticationForgotPasswordPageMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "e51e643317fd2a8be98bd136b1da3fbc",
            "id": null,
            "metadata": {},
            "name": "AuthenticationForgotPasswordPageMutation",
            "operationKind": "mutation",
            "text": "mutation AuthenticationForgotPasswordPageMutation(\n  $email: String!\n) {\n  requestNewPassword(email: $email)\n}\n"
        }
    } as any;
})();
(node as any).hash = '928cc8c23c9b82b2996cf46a8b02c195';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NotificationBrainSnackType = "NOTIFICATION" | "POLICY";
export type NotificationBrainSnackView_item = {
    readonly label: string;
    readonly brainSnack: {
        readonly pages: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"TextItemView_pages">;
        }>;
    };
    readonly notificationBrainSnackType: NotificationBrainSnackType;
    readonly " $refType": "NotificationBrainSnackView_item";
};
export type NotificationBrainSnackView_item$data = NotificationBrainSnackView_item;
export type NotificationBrainSnackView_item$key = {
    readonly " $data"?: NotificationBrainSnackView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NotificationBrainSnackView_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationBrainSnackView_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "BrainSnack",
            "kind": "LinkedField",
            "name": "brainSnack",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainSnackPage",
                    "kind": "LinkedField",
                    "name": "pages",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TextItemView_pages"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notificationBrainSnackType",
            "storageKey": null
        }
    ],
    "type": "NotificationBrainSnackStreamItem",
    "abstractKey": null
} as any;
(node as any).hash = '2b62aa53daa3181c8a4bfc9deaaea8fb';
export default node;

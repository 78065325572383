/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StreamItemHeader_streamItem = ({
    readonly __typename: "NotificationBrainSnackStreamItem";
    readonly brainSnack: {
        readonly " $fragmentRefs": FragmentRefs<"BrainItemHeader_brainItem">;
    };
} | {
    readonly __typename: "NotificationStreamItem";
} | {
    readonly __typename: "BrainItemStreamItem";
    readonly brainItem: {
        readonly " $fragmentRefs": FragmentRefs<"BrainItemHeader_brainItem">;
    };
}) & {
    readonly " $refType": "StreamItemHeader_streamItem";
};
export type StreamItemHeader_streamItem$data = StreamItemHeader_streamItem;
export type StreamItemHeader_streamItem$key = {
    readonly " $data"?: StreamItemHeader_streamItem$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StreamItemHeader_streamItem">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BrainItemHeader_brainItem"
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "StreamItemHeader_streamItem",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BrainSnack",
                        "kind": "LinkedField",
                        "name": "brainSnack",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "NotificationBrainSnackStreamItem",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "brainItem",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "BrainItemStreamItem",
                "abstractKey": null
            }
        ],
        "type": "StreamItem",
        "abstractKey": "__isStreamItem"
    } as any;
})();
(node as any).hash = '744a320303768ea5b6f744e9185e56ca';
export default node;

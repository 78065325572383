import { ReactElement, SyntheticEvent, useCallback } from 'react'
import { graphql } from 'react-relay'
import { useFragment, useMutation } from 'relay-hooks'
import { NotificationView_item$key } from '../../../generated/NotificationView_item.graphql'
import { NotificationViewContinueLearningMutation } from '../../../generated/NotificationViewContinueLearningMutation.graphql'
import { BrainItemProps } from '../../../utils/hooks/useBrainItems'
import styles from '../questions/Question.scss'
import { BrainItemCompletionInput } from '../StreamItem'
import { TextItemButton } from './TextItemButton'
import { ButtonType } from './TextItemView'

export interface NotificationViewProps {
  item: NotificationView_item$key
  onSubmit(data: BrainItemCompletionInput): void
}

export function NotificationView(
  props: BrainItemProps & NotificationViewProps
): ReactElement {
  const item = useFragment(
    graphql`
      fragment NotificationView_item on NotificationStreamItem {
        label
        message
        notificationType
      }
    `,
    props.item
  )

  const [continueLearning] =
    useMutation<NotificationViewContinueLearningMutation>(
      graphql`
        mutation NotificationViewContinueLearningMutation {
          continueLearning {
            __typename
          }
        }
      `,
      { variables: {} }
    )

  const onClose = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (
        item.notificationType === 'NO_CONTENT' ||
        item.notificationType === 'UP_TO_DATE'
      ) {
        continueLearning()
      }

      props.onSubmit({})
    },
    [item.notificationType, props, continueLearning]
  )

  return (
    <article>
      {item.label}

      <div>
        <div dangerouslySetInnerHTML={{ __html: item.message }} />
      </div>

      <div className={styles.buttonContainer}>
        <TextItemButton
          closeDisabled={false}
          brainItemState={props.brainItemState}
          onClick={onClose}
          buttonType={ButtonType.Close}
        />
      </div>
    </article>
  )
}

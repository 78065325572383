export function scrollToTop(): void {
  try {
    // Jump to the top of the page.
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error) {
    // On Chrome Mobile this may result in an error:
    // TypeError: Failed to execute 'scrollTo' on 'Window': No function was found that matched the signature provided.
    if (error instanceof TypeError) {
      window.scroll(0, 0)
    } else {
      throw error
    }
  }
}

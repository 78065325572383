import { observer } from 'mobx-react'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaginationFragment } from 'react-relay'
import { RouteComponentProps } from 'react-router'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { BottomDrawer } from '../../../containers/BottomDrawer'
import { DuelPage } from '../../../containers/duels/DuelPage'
import { OnlineMenu } from '../../../containers/OnlineMenu'
import { RankingMenu_rankings$key } from '../../../generated/RankingMenu_rankings.graphql'
import { RankingMenuPaginationQuery } from '../../../generated/RankingMenuPaginationQuery.graphql'
import { RankingMenuQuery } from '../../../generated/RankingMenuQuery.graphql'
import { useEnterKeyHandler } from '../../../utils/handleEnterKey'
import { EmptyList } from '../../common/EmptyList'
import { GraphQlError } from '../../common/GraphQlError'
import { LoadingIndicator } from '../../common/LoadingIndicator'

import { SecondaryButton } from '../../common/SecondaryButton'
import { RankingItem } from '../../duels/RankingItem'

import styles from './RankingMenu.scss'

const PAGE_SIZE = 10

export const RankingMenu = observer(function RankingMenu(
  props: RouteComponentProps
) {
  const { t } = useTranslation()

  const rankingProps = useQuery<RankingMenuQuery>(
    graphql`
      query RankingMenuQuery($count: Int!, $cursor: String) {
        duelInfo {
          rank
          ...DuelPage_duelInfo
          ...RankingItem_duelInfo
        }
        me {
          ...RankingItem_user
        }
        ...RankingMenu_rankings @arguments(count: $count, cursor: $cursor)
      }
    `,
    { count: PAGE_SIZE },
    { fetchPolicy: 'store-and-network' }
  )
  const {
    data: rankings,
    hasNext,
    isLoadingNext,
    loadNext,
  } = usePaginationFragment<
    RankingMenuPaginationQuery,
    RankingMenu_rankings$key
  >(
    graphql`
      fragment RankingMenu_rankings on Query
      @refetchable(queryName: "RankingMenuPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String" }
      ) {
        duelRankings(first: $count, after: $cursor)
          @connection(key: "RankingMenu_duelRankings") {
          pageInfo {
            total
          }
          edges {
            node {
              id
              ...RankingItem_item
            }
          }
        }
      }
    `,
    rankingProps.data ?? null
  )

  const loadMore = useCallback((): void => {
    loadNext(PAGE_SIZE)
  }, [loadNext])
  const loadMoreEnterHandler = useEnterKeyHandler(loadMore)

  const renderPlaceHolder = useCallback(
    (index: number) => (
      <RankingItem item={null} user={null} key={index} rank={index + 1} />
    ),
    []
  )

  const emptyList =
    rankings?.duelRankings && rankings.duelRankings.pageInfo.total === 0

  return (
    <DuelPage {...props} duelInfo={rankingProps.data?.duelInfo || null}>
      <OnlineMenu>
        <div className={styles.rankingMenu}>
          <div className={styles.intro}>
            {emptyList
              ? t('ranking.empty')
              : (rankingProps.data?.duelInfo?.rank || 0) > 0
              ? t('ranking.subtitle')
              : t('ranking.notRanked')}
          </div>

          {!rankingProps.data && !rankingProps.error && <LoadingIndicator />}
          {!rankingProps.data && rankingProps.error && (
            <GraphQlError
              error={rankingProps.error}
              retry={rankingProps.retry}
            />
          )}

          <div className={styles.list} role='list'>
            {emptyList && <EmptyList renderItem={renderPlaceHolder} />}

            {rankings &&
              rankings.duelRankings?.edges.map(
                (item, index): ReactElement => (
                  <RankingItem
                    item={item.node}
                    user={null}
                    key={item.node.id}
                    rank={index + 1}
                  />
                )
              )}
          </div>

          {hasNext && (
            <div className={styles.button}>
              <SecondaryButton
                disabled={isLoadingNext}
                onClick={loadMore}
                onKeyPress={loadMoreEnterHandler}
                tabIndex={0}
              >
                {t('ranking.loadMore')}
              </SecondaryButton>
            </div>
          )}

          {rankingProps.data?.duelInfo?.rank && rankingProps.data.me && (
            <BottomDrawer className={styles.bottomDrawer}>
              <RankingItem
                bare
                duelInfo={rankingProps.data.duelInfo}
                item={null}
                rank={rankingProps.data.duelInfo.rank}
                user={rankingProps.data.me}
              />
            </BottomDrawer>
          )}
        </div>
      </OnlineMenu>
    </DuelPage>
  )
})

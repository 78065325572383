const translations = {
  popup: {
    confirm: {
      delete: 'Cancella',
      keep: 'Disdire',
      cancelDuelInviteTitle: 'Cancella?',
      cancelDuelInviteMessage: 'Procedere veramente?',
    },
  },
  header: {
    'welcome-message': 'Ciao {{name}}!',
    welcome: 'Benvenuto.',
    welcomeBack: 'Bentornato.',
  },
  learn: {
    instruction: 'Seleziona gli argomenti su cui giocare.',
    practiceSelection: 'Esercitati su questa selezione',
    selectAll: 'Seleziona tutto',
    deselectAll: 'Rimuovi la selezione',
    wellDone: 'Ottimo lavoro!',
  },
  duels: {
    title: 'Punteggio duello: {{score}}',
    readyToDuel: 'Pronto per il duello?',
    roundsOverview: 'Panoramica dei round del duello',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Pagina precedente',
      doneText: 'Brainsnack completato',
      browseNext: 'Pagina successiva',
    },
    brainItem: {
      correct: 'Corretto',
      incorrect: 'Non corretto',
      completed: 'Hai completato una domanda',
      doneText: 'Fatto',
      openItem: 'Inizio',
      closeItem: 'Chiusura',
      topics: 'Temi',
      nextItem: 'Continua a esercitarti',
      showProgress: 'Dimostra i tuoi progressi',
    },
    order: {
      questionTitle: 'Ordina',
      helpText: "Trascina le risposte nell'ordine corretto",
      correct: '{{num}} di {{max}} corretto',
    },
    match: {
      questionTitle: 'Abbina',
      showingCorrectAnswer: 'Visualizza le risposte corrette.',
      helpText: 'Trascina le risposte nel punto corretto',
      correct: '{{num}} di {{max}} corretto',
      showCorrectAnswer: 'Mostra risposta corretta',
      showMyAnswer: 'Mostra la mia risposta',
    },
    slider: {
      doneText: 'Fatto',
      questionTitle: 'Dispositivo di scorrimento',
      helpText: 'Trascina il cursore nella posizione corretta',
    },
    poll: {
      questionTitle: 'Sondaggio',
      resultinfo: 'Questo è ciò che gli altri hanno risposto',
      doneText: 'Hai risposto ad una domanda di sondaggio',
      helpText: 'Scegli una risposta',
    },
    notification: {
      title: 'Notifica',
      pauze: 'Posso continuare?',
      continueanyway: 'Contunua comunque',
      doneText: 'Notifica letta',
      continueLearningText:
        'Ti stai esercitando con domande aggiuntive. Riceverai meno punti per questo, perche sei già aggiornato.',
      panelbuttonlabel: "Arresto dell' esercitazione aggiuntiva",
    },
    multipleselect: {
      correct: '{{num}} di {{max}} risposte corrette',
      incorrect: ', {{incorrect}} risposte non corrette',
      doneText: 'Domanda completata',
      questionTitle: 'Domanda',
      helpText: 'Scegli una o più risposte',
    },
    multiplechoice: {
      doneText: 'Domanda completata',
      questionTitle: 'Domanda',
      helpText: 'Scegli una risposta',
    },
    duelItem: {
      duel: 'Duello',
      draw: 'Disegna ',
      invite: 'Invito a duello',
      acceptDuel: 'Accetta il duello',
      declineDuel: 'Rifiuta il duello',
      yourTurn: "E' il tuo turno",
      opponentTurn: "E' il turno dell'avversario",
      youWon: 'Hai vinto!',
      youLost: 'Hai perso',
      cancelled: 'Il duello è cancellato',
      closeItem: 'Chiusura',
      showDetails: 'Dettagli del round',
      playItem: 'Partecipa al round {{roundnumber}}',
      duelAccepted: 'Hai accettato un invito a duello da {{name}}.',
      duelDeclined: 'Hai rifiutato un invito a duello da {{name}}.',
      duelStarted: 'Lascia che il duello con {{name}} inizi. Buona fortuna!',
      duelInfoInvitation: 'Sei stato sfidato a duello.',
      duelInfoMain:
        "Breve battaglia di conoscenza in 5 round. Vinci rispondendo velocemente e correttamente. Vinci il maggior numero di round e vincerai il duello e aumenterai la tua valutazione. Attenzione: c'è un limite di 72 ore per giocare un round.",
      viewInvitation: "Vedi l'invito",
      versusLabel: 'Contro',
      started: 'Via!',
      doneText: 'Duello completato',
      tooLate: 'Fuori tempo!',
      practiceWhileWaiting: 'Esercitati mentre aspetti',
      practice: 'Continuare a praticare',
      subheading: {
        waitForOpponent: 'Aspetta che il tuo avversario risponda',
        yourTurn: 'Sei pronto?',
        timeOutOpponent:
          'Il tuo avversario ha impiegato troppo tempo per rispondere',
        timeOutPlayer: 'Hai impiegato troppo tempo per rispondere',
        timeOutBoth: 'Entrambi avete impiegato troppo tempo per rispondere',
        wellDone: 'Ben fatto, continuate così!',
        betterNextTime: 'La prossima volta sarai più fortunato',
      },
      roundNumber: 'Round {{number}}',
      duelRequest: 'Nuova sfida a duello!',
      duelRequestText: '{{name}} ti sfida a duello',
      tooLateTurn: 'Troppo tardi',
    },
    topicPin: {
      title: 'Blocca argomento',
      pinenabled:
        "Hai bloccato {{- topiclabel}} e vedi solo gli articoli associati a questo argomento. Gli articoli associati a questo argomento vengono ripetuti più spesso in modo da poterli esercitare. Fare di nuovo clic sull'argomento aggiunto per includere domande relative ad altri argomenti. ",
      pindisabled:
        "L'argomento non è più bloccato. D'ora in poi, vedrai di nuovo gli oggetti per tutti gli argomenti",
      continue: 'Continua',
      dontshowagain: 'Non mostrare più',
      selectTopics: 'Seleziona argomenti',
      selectTopicsPractice: 'Seleziona gli argomenti su cui esercitarti.',
    },
    topicInfoHeader: 'Argomenti',
  },
  login: {
    succesful: 'Accesso riuscito.',
  },
  menu: {
    menu: 'Menu',
    duel: 'Duello',
    duels: 'Duelli',
    newDuel: 'Nuovo duello',
    activeDuels: 'Duelli attivi',
    duelVersus: 'VS',
    ranking: 'Classifica',
    rating: 'Punteggio Duel',
    profile: 'Profilo',
    autoDuelLabel: 'Duelli automatici',
    'help-and-settings': 'Guida e impostazioni',
    challengeplayer: 'Sfida un collega',
    pickplayer: 'Scegli un collega',
    randomplayer: 'Mi sento fortunato',
    duelWaiting: 'In cerca di avversario...',
    quickDuelFailed: 'Hai già inviti in sospeso',
    duelsNotAvailable: 'Non hai ancora accesso ai duelli.',
    duelsNotAvailableExplanation:
      'Vuoi sfidare a duello i tuoi colleghi? Chiedilo al tuo supervisore.',
    duelsNotEnabled:
      "Vuoi sfidare a duello i tuoi colleghi? Abilita l'opzione duello di seguito.",
    duelsNotEnabledDisclaimer:
      'I tuoi colleghi potranno vedere la tua foto e il tuo nome.',
    enableDuelling: 'Abilita i duelli con i colleghi!',
    duellingEnabledLabel: 'Partecipa ai duelli',
    completedDuels: 'Completato',
    select: 'Seleziona',
    settings: 'Impostazioni',
    practice: 'Esercitati',
    aboutDuels: {
      heading: "Cos'è un invito a duello?",
      explanation1: 'Sei stato sfidato a duello.',
      explanation2:
        'Un duello è una sfida sulle conoscenze contro un collega suddivisa in 5 round. Vince il round chi risponde correttamente alla domanda nel minor tempo. Chi vince più round si aggiudica il duello e scala la classifica.',
      attention: 'Nota:',
      explanation3: 'gioca ogni round entro 7 giorni!',
    },
    leaveAlert: {
      title: 'Procedere?',
      text: "<p>Interrompere l'esercitazione?</p><p>I tuoi progressi sono stati salvati.</p>",
      confirm: 'Stop',
      continue: 'Continua a esercitarti',
    },
  },
  ranking: {
    title: 'Classifica Duel',
    subtitle:
      'Questa valutazione mostrala tua posizione in classifica nei duelli in relazione ai tuoi colleghi.',
    yourPosition: 'La tua posizione ({{rank}})',
    topPositions: 'Prime posizioni',
    loadMore: 'Carica altre',
    empty: 'Non sei ancora entrato in classifica, inizia un duello!',
    notRanked: 'Completa un duello per entrare in classifica!',
  },
  searchFeedback: {
    title: 'Cerca un collega',
    oneResult: '{{amount}} collega trovato.',
    searchMessage: '{{amount}} colleghi trovati.',
    overflowMessage:
      'Vengono visualizzati solo i {{amount}} risultati di ricerca più adatti.',
  },
  searchDuel: {
    title: 'Cerca avversario',
    rankingposition: 'Posizione in classifica {{rank}}',
    noduelpossible: 'Duello impossibile',
    duelstillactive: 'Duello già attivo',
    invitationsentondate: 'Invito inviato il {{date}}',
    searchPlaceholder: 'Cerca un nome',
    randomOpponent:
      'Stiamo cercando il tuo prossimo avversario. Puoi chiudere questa schermata, ti informeremo quando il duello sarà pronto.',
    emptyListSearch: 'La ricerca non ha prodotto risultati. ',
    emptyList: 'Purtroppo non ce ne sono.<br>Riprova più tardi. ',
  },
  activeDuels: {
    title: 'Partecipa a un duello disponibile.',
    subtitle: '{{num}}/{{max}} duelli sono attivi',
    status: {
      draw: 'Disegnare',
      lost: 'Perduto',
      won: 'Ha vinto',
      expired: 'Scaduto',
      invited: 'Invitato',
      waiting: 'In attesa',
      yourturn: 'Gioca',
    },
    emptyListNew: 'Non ci sono duelli, iniziane uno!',
    emptyListCompleted:
      'Ottimo lavoro, hai completato tutti i duelli!<br>Ti sei proprio meritato un buon caffè. ',
  },
  completedDuels: {
    title: 'Visualizza i duelli completati.',
    empty: 'Non hai ancora completato nessun duello.<br>Iniziane uno!',
  },
  error: {
    internetdown: 'Connessione a Internet persa!',
    versionConflict: {
      title: 'Nuova versione',
      text: "C’è una nuova versione di questa applicazione. L'applicazione sta per essere aggiornata: è importante che tutti utilizzino la versione più recente.",
      refresh: 'Vai alla versione più recente ',
    },
    refreshButton: 'Clicca per aggiornare',
    internetup: 'Connesso a Internet.',
    timeoutajax: 'La richiesta del server è scaduta.',
    404: 'Server non trovato (HTTP 404).',
    500: 'Errore interno del server (HTTP 500).',
    unknown: 'Errore sconosciuto',
    tryReconnect: 'Tentativo di riconnettersi ...',
    alreadyAnswered: 'Elemento già risposto',
    unavailableOffline: {
      title: 'Connessione assente',
      subtitle: "Attualmente non c'è connessione",
      explanation:
        'Per utilizzare l’applicazione è necessaria una connessione Internet. Connessione momentaneamente assente. Prova a riconnetterti a Internet o ritenta più tardi. ',
    },
  },
  common: {
    CheckAnswer: 'Verifica risposta',
    CheckAnswers: 'Verifica risposte',
    Answer: 'Salva le mie risposte',
    Hello: 'Ciao',
    back: 'Indietro',
    Emailaddress: 'Indirizzo e-mail',
    Name: 'Nome',
    Checking: 'Controllo...',
    Close: 'Avanti',
    browsePrevious: 'Precedente',
    browseNext: 'Il prossimo',
    Accept: 'Accetta',
    'Log out': 'Disconnettersi',
    Help: 'Aiuto',
    Correct: 'Corretto',
    Incorrect: 'Non corretto',
    CorrectNotChosen: 'Sarebbe stato corretto',
    Round: 'Round',
    Version: 'Versione',
    selected: 'Selezionato',
    'No Rating': 'Non ancora valutato',
    'No Rank': 'Nessuna classifica',
    NoUserSelection: 'Nessun utente trovato',
    monthNames: 'Gen Feb Mar Apr Mag Giu Lug Ago Set Ott Nov Dic',
    or: 'o',
    on: 'on',
    off: 'off',
  },
  profile: {
    title: 'Profilo',
    subtitle: 'Modifica il tuo profilo qui.',
    pictureEdit: 'Visualizza o modifica la tua foto.',
    'picture-submit': 'Utilizza questo ritaglio',
    pictureConfirmDelete: 'Eliminare la foto profilo?',
    profilePageTitle: 'Gestisci il tuo profilo.',
    noEditDataText: 'Non puoi modificare questi dati.',
    'file-submit': 'Caricare',
    pictureCancel: 'Annulla',
    'picture-nofileselected': 'Non hai selezionato un file.',
    'picture-nofile': "Non c'è file.",
    'picture-invalidsize':
      'Questa immagine è troppo grande. La larghezza o altezza massima non può superare i 2600 pixel.',
    'picture-notimage': "Il file selezionato non sembra essere un'immagine.",
    passwordEdit: 'Cambia la password',
    'picture-saved': 'La tua immagine del profilo è stata salvata!',
    passwordTitle: 'Cambia la password',
    passwordText:
      'Cambia la tua password inserendo la tua password attuale e 2 volte la tua nuova password. La nuova password deve contenere almeno 6 caratteri.',
    'password-old': 'Password attuale',
    'password-new': 'Nuova password',
    'password-repeat': 'Conferma la nuova password',
    'password-submit': 'Salva nuova password',
    'password-oldpasswordmismatch': 'La tua password corrente era sbagliata',
    'password-newpasswordmismatch': 'La password confermata non corrisponde.',
    'password-passwordtooshort':
      'La nuova password deve contenere almeno 6 caratteri.',
    'password-passwordsaved': 'La tua password è stata salvata!',
    profilePictureTitle: 'Immagine del profilo',
    profilePictureText: 'Questa foto è visibile per i tuoi colleghi.',
    generalDataTitle: 'Informazione personale',
    generalDataText:
      'Non puoi modificare autonomamente tutti i dati personali.',
    profileLanguagePreference: 'Lingua preferita',
    profileLanguageChoice: 'Scelta della lingua',
  },
  auth: {
    header: {
      login: 'Accesso',
      expired: 'Link scaduto',
      remind: 'Hai dimenticato la password?',
      create: 'Attiva il tuo account',
      reset: 'Aggiorna la tua password',
      welcome: 'Benvenuto!',
    },
    field: {
      email: 'Indirizzo e-mail',
      password: 'Password',
      oldPassword: 'Password attuale',
      newPassword: 'Nuova password',
      newRepeatPassword: 'Ripeti la nuova password',
      rememberMe: 'Ricordami',
    },
    action: {
      itemcompleted: 'Hai completato un elemento',
      updateprofile: 'Aggiorna profilo',
      sendLink: 'Inviami un link per reimpostare la password',
      backToLogin: 'Voglio accedere',
      sendActivateLink: 'Attiva account',
      alreadyActivatedLogin: "Ho già attivato l'account e voglio accedere",
      savePassword: 'Salva la mia nuova password',
      newPassword: 'La tua nuova password',
      repeatNewPassword: 'Ripeti la nuova password',
      newPasswordPolicyIntro: 'Sicurezza della password',
    },
    selfActivate: {
      header: 'Attiva il tuo account',
    },
    logintext: 'Accesso',
    remindText:
      "Nessun problema. Ti invieremo un'e-mail per poter impostare una nuova password.",
    resetText: 'Puoi impostare la tua nuova password qui.',
    createText:
      'Benvenuto! Crea una nuova password e sarai pronto ad accedere.',
    passwords: {
      user: 'Spiacenti, nessun utente associato a questo indirizzo e-mail.',
      token: 'Questo token di reimpostazione della password non è valido.',
      sent: "È stata inviata un'e-mail con un link di reimpostazione.",
      reset: 'La password è stata reimpostata!',
      forgot: 'Non ricordo la password',
      passwordExpiredTitle: 'Password scaduta',
      submitPasswordExpiredForm: 'Salva nuova password',
      passwordExpiredText:
        'La tua password ha più di {{days}} giorni ed è scaduta. Dovrai impostare una nuova password utilizzando il modulo sottostante, dopodiché potrai continuare la tua formazione con PowerApp',
    },
  },
  faq: {
    header: 'Domande frequenti',
    q1: "PowerApp è un'app di micro-learning, ma cosa significa?",
    a1: "Il micro-learning è un sistema di apprendimento secondo il quale le nozioni apprese nell'ambito di sessioni brevi avrebbero una maggiore persistenza nel tempo.",
    q2: 'Perché a volte il punteggio è inferiore alle mie aspettative?',
    a2: 'Il punteggio diminuisce perché con il passare del tempo si tende a dimenticare quanto appreso.',
    q3: 'Quando diminuisce il punteggio?',
    a3: 'Quando si risponde in modo errato alle domande o in caso di inattività per un certo periodo.',
    q4: 'Perché vengono ripetuti gli stessi elementi?',
    a4: 'Perché le ripetizioni intervallate sono il metodo di apprendimento più efficace.',
    q5: "Cos'è un duello?",
    a5: 'Un quiz sulla conoscenza contro un collega costituito da 5 domande.',
    q6: 'Perché durante il duello devo aspettare il mio turno?',
    a6: 'Devi aspettare che il collega termini il proprio turno.',
    q7: 'Dove posso reperire maggiori informazioni e risposte alle mie domande?',
    a7: 'Il pulsante di seguito ti reindirizzerà al nostro sito web.',
    toTheFaq: 'Vai alle FAQ',
    toTheWebsite: 'Vai al sito web',
  },
}

export default translations

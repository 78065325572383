/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TopicCategoriesMarkStartedMutationVariables = {
    id: string;
};
export type TopicCategoriesMarkStartedMutationResponse = {
    readonly startTopicCategory: {
        readonly id: string;
        readonly startedAt: string | null;
    };
};
export type TopicCategoriesMarkStartedMutation = {
    readonly response: TopicCategoriesMarkStartedMutationResponse;
    readonly variables: TopicCategoriesMarkStartedMutationVariables;
};



/*
mutation TopicCategoriesMarkStartedMutation(
  $id: ID!
) {
  startTopicCategory(topicCategoryId: $id) {
    id
    startedAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "topicCategoryId",
                    "variableName": "id"
                }
            ],
            "concreteType": "TopicCategory",
            "kind": "LinkedField",
            "name": "startTopicCategory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startedAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TopicCategoriesMarkStartedMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TopicCategoriesMarkStartedMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a924ecd5e1f666513e783d39e6c7e7c1",
            "id": null,
            "metadata": {},
            "name": "TopicCategoriesMarkStartedMutation",
            "operationKind": "mutation",
            "text": "mutation TopicCategoriesMarkStartedMutation(\n  $id: ID!\n) {\n  startTopicCategory(topicCategoryId: $id) {\n    id\n    startedAt\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c2ea142fe9dc71dd0045d3730d6879f7';
export default node;

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import { RouteComponentProps } from 'react-router-dom'
import { useMutation, useQuery } from 'relay-hooks/lib'
import { Page } from '../../../containers/Page'
import { TopicCategoriesMarkStartedMutation } from '../../../generated/TopicCategoriesMarkStartedMutation.graphql'
//import { SwitchPowerapp } from '../../onboarding/SwitchPowerapp'
import { TopicCategoriesQuery } from '../../../generated/TopicCategoriesQuery.graphql'
import { GraphQlError } from '../../common/GraphQlError'
import { LearnHeader } from '../../learn/LearnHeader'
import { TopicCategory } from '../../onboarding/TopicCategory'

import styles from './TopicCategories.scss'

export function TopicCategories(props: RouteComponentProps): ReactElement {
  const { i18n, t } = useTranslation()

  const topicCategories = useQuery<TopicCategoriesQuery>(
    graphql`
      query TopicCategoriesQuery($language: String!) {
        me {
          ...LearnHeader_user
        }
        topicCategories {
          id
          unlocked
          progress
          startedAt
          ...TopicCategory_item
        }
      }
    `,
    { language: i18n.language },
    { fetchPolicy: 'store-and-network' }
  )
  const [markAsStarted] =
    useMutation<TopicCategoriesMarkStartedMutation>(graphql`
      mutation TopicCategoriesMarkStartedMutation($id: ID!) {
        startTopicCategory(topicCategoryId: $id) {
          id
          startedAt
        }
      }
    `)

  const click = (topicCategoryId: string) => {
    const topicCategory = topicCategories.data?.topicCategories.find(
      (item) => item.id === topicCategoryId
    )
    if (topicCategory && !topicCategory.startedAt) {
      markAsStarted({ variables: { id: topicCategoryId } })
    }

    props.history.push('/onboarding/' + encodeURIComponent(topicCategoryId))
  }
  //
  // const switchToPowerApp = () => {
  //   // TODO: Switch user to PowerApp
  //   props.history.push('/')
  // }

  return (
    <>
      <LearnHeader
        score={
          topicCategories.data && topicCategories.data.topicCategories.length
            ? topicCategories.data.topicCategories.reduce(
                (acc, topicCategory) => acc + topicCategory.progress,
                0
              ) / topicCategories.data.topicCategories.length
            : 0
        }
        user={topicCategories.data?.me ?? null}
      />

      <Page narrow>
        <div className={styles.topicCategories}>
          <div className={styles.instruction}>
            {t('learn.instructionLinear')}
          </div>

          {topicCategories.data && (
            <div className={styles.list}>
              {topicCategories.data.topicCategories.map((category) => (
                <TopicCategory
                  key={category.id}
                  item={category}
                  click={
                    category.unlocked ? () => click(category.id) : undefined
                  }
                />
              ))}

              {/* TODO: conditional progress to PowerApp */}

              {/* <SwitchPowerapp click={switchToPowerApp} /> */}
            </div>
          )}

          {!topicCategories.data && topicCategories.error && (
            <GraphQlError
              error={topicCategories.error}
              retry={topicCategories.retry}
            />
          )}
        </div>
      </Page>
    </>
  )
}

import { ReactElement } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { AnswerOption_answer$key } from '../../../../generated/AnswerOption_answer.graphql'

import { classNames } from '../../../../utils/classNames'

import {
  AnswerOption,
  AnswerOptionProps,
  AnswerOptionViewType,
} from './AnswerOption'
import styles from './DraggableAnswerOption.scss'

type DraggableAnswerOptionProps = Omit<AnswerOptionProps, 'answer' | 'type'> & {
  answer: AnswerOption_answer$key
  answerId: string
  index: number
}

export function DraggableAnswerOption(
  props: DraggableAnswerOptionProps
): ReactElement {
  const { answerId, index, ...rest } = props

  return (
    <Draggable
      draggableId={answerId}
      index={index}
      isDragDisabled={props.disabled}
    >
      {(provided, snapshot) => (
        <AnswerOption
          {...rest}
          className={classNames(styles.draggable, props.className, {
            [styles.dragging]: snapshot.isDragging,
          })}
          ref={provided.innerRef}
          selected={snapshot.isDragging}
          type={AnswerOptionViewType.DRAGGABLE}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        />
      )}
    </Draggable>
  )
}

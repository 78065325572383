/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NotificationViewContinueLearningMutationVariables = {};
export type NotificationViewContinueLearningMutationResponse = {
    readonly continueLearning: {
        readonly __typename: string;
    };
};
export type NotificationViewContinueLearningMutation = {
    readonly response: NotificationViewContinueLearningMutationResponse;
    readonly variables: NotificationViewContinueLearningMutationVariables;
};



/*
mutation NotificationViewContinueLearningMutation {
  continueLearning {
    __typename
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "NotificationViewContinueLearningMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "continueLearning",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "NotificationViewContinueLearningMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "continueLearning",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f2ff865d78cd9b8bde91999faf2a394c",
            "id": null,
            "metadata": {},
            "name": "NotificationViewContinueLearningMutation",
            "operationKind": "mutation",
            "text": "mutation NotificationViewContinueLearningMutation {\n  continueLearning {\n    __typename\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0648d2a62f5eaf6caf658abb5790b282';
export default node;

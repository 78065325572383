import { ReactElement, useCallback, useMemo } from 'react'
import Identicon from 'identicon.js'
import JsSHA from 'jssha'

import defaultAvatar from '../../../../../images/default-avatar.svg'
import { classNames } from '../../utils/classNames'

import { avatarUrl } from '../../utils/url'

import styles from './Avatar.scss'
import { useEnvironment } from '../../App'

export enum AvatarContext {
  default,
  duelling,
}

interface AvatarProps {
  className?: string
  filename: string | null | undefined
  userId: string
  name: string
  hasAnonymousDuellingEnabled?: boolean
  context: AvatarContext
}

export function Avatar(props: AvatarProps): ReactElement {
  const environmentSettings = useEnvironment()

  const getHash = useCallback((seed: string) => {
    const shaObj = new JsSHA('SHA-1', 'TEXT', { encoding: 'UTF8' })
    shaObj.update(seed)
    return shaObj.getHash('HEX')
  }, [])

  const getIdenticon = useCallback(
    (seed: string) => {
      return (
        'data:image/png;base64,' + new Identicon(getHash(seed), 150).toString()
      )
    },
    [getHash]
  )

  const avatar = useMemo(() => {
    return props.context === AvatarContext.duelling &&
      (environmentSettings.anonymousDuelling === 'ALL' ||
        (environmentSettings.anonymousDuelling === 'USER' &&
          props.hasAnonymousDuellingEnabled))
      ? getIdenticon(props.userId)
      : defaultAvatar
  }, [
    environmentSettings.anonymousDuelling,
    getIdenticon,
    props.context,
    props.hasAnonymousDuellingEnabled,
    props.userId,
  ])

  const src = props.filename ? avatarUrl(props.filename) : avatar

  return (
    <img
      alt={props.name}
      className={classNames(styles.avatar, props.className)}
      src={src}
    />
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnswerOption_answer = {
    readonly id: string;
    readonly text: string | null;
    readonly " $refType": "AnswerOption_answer";
};
export type AnswerOption_answer$data = AnswerOption_answer;
export type AnswerOption_answer$key = {
    readonly " $data"?: AnswerOption_answer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AnswerOption_answer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnswerOption_answer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ],
    "type": "AnswerOption",
    "abstractKey": null
} as any;
(node as any).hash = '5cc2344abf82afc4094b4b00be2d577b';
export default node;

import { ReactElement } from 'react'
import { Redirect } from 'react-router'

import { restartAppAfterLogout } from '../../../../js/jsBridge'

export function AuthenticationLoggedOutPage(): ReactElement {
  // This only does something on mobile. It will go back to the start screen so
  // the user can choose a different workspace.
  restartAppAfterLogout()

  return <Redirect to='/login' />
}

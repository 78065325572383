import { ReactElement, SyntheticEvent, useCallback } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { NotificationBrainSnackView_item$key } from '../../../generated/NotificationBrainSnackView_item.graphql'
import { BrainItemProps } from '../../../utils/hooks/useBrainItems'
import { BrainItemCompletionInput } from '../StreamItem'
import { ButtonType, TextItemView } from './TextItemView'

export interface NotificationBrainSnackViewProps {
  item: NotificationBrainSnackView_item$key
  onSubmit(data: BrainItemCompletionInput): void
}

export function NotificationBrainSnackView(
  props: BrainItemProps & NotificationBrainSnackViewProps
): ReactElement {
  const item = useFragment(
    graphql`
      fragment NotificationBrainSnackView_item on NotificationBrainSnackStreamItem {
        label
        brainSnack {
          pages {
            ...TextItemView_pages
          }
        }
        notificationBrainSnackType
      }
    `,
    props.item
  )

  const onClose = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      props.onSubmit({})
    },
    [props]
  )

  return (
    <TextItemView
      brainItemState={props.brainItemState}
      buttonType={
        item.notificationBrainSnackType === 'POLICY'
          ? ButtonType.Accept
          : ButtonType.Close
      }
      heading={item.label}
      onClose={onClose}
      pages={item.brainSnack.pages}
      setBrainItemState={props.setBrainItemState}
    />
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppNotificationType = "EMAIL" | "PUSH_NOTIFICATIONS";
export type MainMenuToggleNotificationsMutationVariables = {
    type: AppNotificationType;
    enabled: boolean;
};
export type MainMenuToggleNotificationsMutationResponse = {
    readonly toggleNotifications: {
        readonly id: string;
        readonly emailNotificationsEnabled: boolean;
        readonly pushNotificationsEnabled: boolean;
    };
};
export type MainMenuToggleNotificationsMutationRawResponse = {
    readonly toggleNotifications: {
        readonly id: string;
        readonly emailNotificationsEnabled: boolean;
        readonly pushNotificationsEnabled: boolean;
    };
};
export type MainMenuToggleNotificationsMutation = {
    readonly response: MainMenuToggleNotificationsMutationResponse;
    readonly variables: MainMenuToggleNotificationsMutationVariables;
    readonly rawResponse: MainMenuToggleNotificationsMutationRawResponse;
};



/*
mutation MainMenuToggleNotificationsMutation(
  $type: AppNotificationType!
  $enabled: Boolean!
) {
  toggleNotifications(type: $type, enabled: $enabled) {
    id
    emailNotificationsEnabled
    pushNotificationsEnabled
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "enabled"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "type"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "enabled",
                    "variableName": "enabled"
                },
                {
                    "kind": "Variable",
                    "name": "type",
                    "variableName": "type"
                }
            ],
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "toggleNotifications",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailNotificationsEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pushNotificationsEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "MainMenuToggleNotificationsMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "MainMenuToggleNotificationsMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "0b4d1d6fad96dbd341ac263d72509d00",
            "id": null,
            "metadata": {},
            "name": "MainMenuToggleNotificationsMutation",
            "operationKind": "mutation",
            "text": "mutation MainMenuToggleNotificationsMutation(\n  $type: AppNotificationType!\n  $enabled: Boolean!\n) {\n  toggleNotifications(type: $type, enabled: $enabled) {\n    id\n    emailNotificationsEnabled\n    pushNotificationsEnabled\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'e83dccb729345a8ec90284dd05ed3c82';
export default node;

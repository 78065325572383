import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons/faIdBadge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { ChangeEvent, ReactElement, SyntheticEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { Link } from 'react-router-dom'
import { useMutation } from 'relay-hooks/lib'
import { useEnvironment } from '../../../App'
import { Page } from '../../../containers/Page'
import { SelfActivatePageMutation } from '../../../generated/SelfActivatePageMutation.graphql'
import { useStores } from '../../../stores'
import { AuthAlert } from '../../auth/AuthAlert'
import { AuthFooter } from '../../auth/AuthFooter'
import { AuthHeader } from '../../auth/AuthHeader'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './SelfActivatePage.scss'

export const SelfActivatePage = observer(
  function SelfActivatePage(): ReactElement {
    const environment = useEnvironment()
    const { authStore, commonStore } = useStores()
    const { t } = useTranslation()

    const getEditableTranslation = (key: string): string => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return (
        (
          environment.editableTranslations.find(
            (translation) => translation.language === commonStore.language
          ) ||
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          environment.editableTranslations.find(
            (translation) => translation.language === 'en-US'
          )!
        ).translations?.find((translation) => translation.key === key)?.value ||
        ''
      )
    }

    const [requestSelfActivation] =
      useMutation<SelfActivatePageMutation>(graphql`
        mutation SelfActivatePageMutation(
          $email: String!
          $companyUserId: String
        ) {
          requestSelfActivation(email: $email, companyUserId: $companyUserId) {
            message
            success
          }
        }
      `)

    const onEmailChanged = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        authStore.setAuthUsername(event.target.value)
      },
      [authStore]
    )
    const onCorporateIdChanged = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        authStore.setAuthCorporateId(event.target.value)
      },
      [authStore]
    )
    const onSubmit = useCallback(
      (event: SyntheticEvent): void => {
        event.preventDefault()

        authStore.setAuthLoading(true)
        requestSelfActivation({
          variables: {
            email: authStore.authUsername,
            companyUserId: authStore.authCorporateId,
          },
        }).then((response) =>
          authStore.requestSelfActivationSuccess(response.requestSelfActivation)
        )
      },
      [authStore, requestSelfActivation]
    )

    return (
      <>
        <AuthHeader />

        <Page hideWatermark narrow>
          <div className={styles.formHeader}>
            <div className={styles.head}>{t('auth.selfActivate.header')}</div>
            <div className={styles.sub}>
              {getEditableTranslation('client_self-activateText')}
            </div>
          </div>

          <form
            action='#'
            method='post'
            className={styles.formContainer}
            onSubmit={onSubmit}
          >
            <AuthAlert />

            <div className={styles.formGroup + ' ' + styles.prepend}>
              <div className={styles.prependIcon}>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <input
                autoComplete='email'
                className={styles.formControl + ' ' + styles.prependPadding}
                placeholder={t('auth.field.email')}
                name='email'
                onChange={onEmailChanged}
                required
                type='email'
                value={authStore.authUsername}
              />
            </div>

            {environment.selfActivateWithCorporateId && (
              <div className={styles.formGroup + ' ' + styles.prepend}>
                <div className={styles.prependIcon}>
                  <FontAwesomeIcon icon={faIdBadge} />
                </div>
                <input
                  autoComplete='email'
                  className={styles.formControl + ' ' + styles.prependPadding}
                  placeholder={getEditableTranslation('field_companyuserid')}
                  name='corporateid'
                  onChange={onCorporateIdChanged}
                  required
                  type='text'
                  value={authStore.authCorporateId}
                />
              </div>
            )}

            <div className={styles.formFooter}>
              <PrimaryButton
                className={styles.primaryButton}
                disabled={
                  authStore.authLoading ||
                  authStore.authUsername.trim().length === 0 ||
                  (environment.selfActivateWithCorporateId &&
                    authStore.authCorporateId.trim().length === 0)
                }
                tabIndex={0}
                type='submit'
              >
                {t('auth.action.sendActivateLink')}
              </PrimaryButton>

              <div>
                <Link className={styles.formFooterLink} to='/login'>
                  {t('auth.action.alreadyActivatedLogin')}
                </Link>
              </div>
            </div>
          </form>
        </Page>

        <AuthFooter />
      </>
    )
  }
)

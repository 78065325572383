/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionView_feedback = {
    readonly " $fragmentRefs": FragmentRefs<"MatchView_feedback" | "MultipleChoiceView_feedback" | "MultipleSelectView_feedback" | "OrderView_feedback" | "SliderView_feedback">;
    readonly " $refType": "QuestionView_feedback";
};
export type QuestionView_feedback$data = QuestionView_feedback;
export type QuestionView_feedback$key = {
    readonly " $data"?: QuestionView_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"QuestionView_feedback">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionView_feedback",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchView_feedback"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultipleChoiceView_feedback"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultipleSelectView_feedback"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrderView_feedback"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SliderView_feedback"
        }
    ],
    "type": "BrainItemCompletionData",
    "abstractKey": "__isBrainItemCompletionData"
} as any;
(node as any).hash = 'a1ecc25683cef7466bb6dcbbbdd0d379';
export default node;

import { ReactElement, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useQuery, commitLocalUpdate } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { AuthenticationLogoutPageUserQuery } from '../../../generated/AuthenticationLogoutPageUserQuery.graphql'

import { useStores } from '../../../stores'
import { environment } from '../../../environment'

export function AuthenticationLogoutPage(): ReactElement {
  const { authStore } = useStores()
  const [doingSingleLogout, setDoingSingleLogout] = useState(false)

  const user = useQuery<AuthenticationLogoutPageUserQuery>(graphql`
    query AuthenticationLogoutPageUserQuery {
      me {
        loginType
      }
    }
  `)

  useEffect(() => {
    if (!doingSingleLogout && user.data) {
      const singleLogout = authStore.logout(user.data.me?.loginType)
      if (singleLogout) {
        setDoingSingleLogout(true)
      }
    }
  }, [authStore, user, doingSingleLogout])

  /**
   * Invalidate users scoreInfo data in the Relay store.
   */
  useEffect(() => {
    commitLocalUpdate(environment, (store) => {
      const ScoreInfo = store.getRoot().getLinkedRecord('scoreInfo')
      if (ScoreInfo != null) {
        ScoreInfo.invalidateRecord()
      }
    })
  })

  return doingSingleLogout ? <Redirect to='/login' /> : <></>
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type OnboardingItemPageStartItemMutationVariables = {
    id: string;
};
export type OnboardingItemPageStartItemMutationResponse = {
    readonly startOnboardingItem: {
        readonly id: string;
        readonly started: boolean;
    };
};
export type OnboardingItemPageStartItemMutationRawResponse = {
    readonly startOnboardingItem: {
        readonly id: string;
        readonly started: boolean;
    };
};
export type OnboardingItemPageStartItemMutation = {
    readonly response: OnboardingItemPageStartItemMutationResponse;
    readonly variables: OnboardingItemPageStartItemMutationVariables;
    readonly rawResponse: OnboardingItemPageStartItemMutationRawResponse;
};



/*
mutation OnboardingItemPageStartItemMutation(
  $id: ID!
) {
  startOnboardingItem(id: $id) {
    id
    started
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "OnboardingItem",
            "kind": "LinkedField",
            "name": "startOnboardingItem",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "started",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "OnboardingItemPageStartItemMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "OnboardingItemPageStartItemMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1ef675b15b1fc29b87fdb43fafc8b470",
            "id": null,
            "metadata": {},
            "name": "OnboardingItemPageStartItemMutation",
            "operationKind": "mutation",
            "text": "mutation OnboardingItemPageStartItemMutation(\n  $id: ID!\n) {\n  startOnboardingItem(id: $id) {\n    id\n    started\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '210190d545d84659a563d9c08e8705dd';
export default node;

import {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import { RouteComponentProps } from 'react-router-dom'
import { useMutation, useQuery } from 'relay-hooks'
import { Page } from '../../../containers/Page'
import { TimeLineFavoriteMutation } from '../../../generated/TimeLineFavoriteMutation.graphql'
import { TimeLineQuery } from '../../../generated/TimeLineQuery.graphql'
import { GraphQlError } from '../../common/GraphQlError'
import { LearnHeader } from '../../learn/LearnHeader'
import { DateIndicator } from '../../onboarding/DateIndicator'
import { TimeLineItem } from '../../onboarding/TimeLineItem'

import styles from './TimeLine.scss'

export interface TimeLineProps {
  refId?: string
}

export function TimeLine(
  props: RouteComponentProps<TimeLineProps>
): ReactElement {
  const { i18n, t } = useTranslation()
  const scrollTarget = useRef<HTMLDivElement>(null)
  const [scrolled, setScrolled] = useState(false)

  const topicCategoryItems = useQuery<TimeLineQuery>(
    graphql`
      query TimeLineQuery($language: String!) {
        me {
          ...LearnHeader_user
        }
        topicCategories {
          id
          description(language: $language)
          availableFrom
          progress
          ...TimeLineItem_topicCategory
          items {
            id
            favorited
            completedAt
            ...TimeLineItem_item
          }
        }
      }
    `,
    { language: i18n.language },
    { fetchPolicy: 'store-and-network' }
  )

  const [toggleFavorite] = useMutation<TimeLineFavoriteMutation>(graphql`
    mutation TimeLineFavoriteMutation($id: ID!, $favorited: Boolean!)
    @raw_response_type {
      favoriteOnboardingItem(id: $id, favorite: $favorited) {
        id
        favorited
      }
    }
  `)

  useLayoutEffect(() => {
    if (!scrolled && topicCategoryItems.data && scrollTarget.current) {
      scrollTarget.current.scrollIntoView({ behavior: 'smooth' })
      setScrolled(true)
    }
  }, [topicCategoryItems.data, scrolled])

  let currentDate = ''
  let currentCategoryId = ''
  let show = true

  const itemsRendered: string[] = []
  let scrollTargetFound = false
  const scrollRefComponent = () => {
    scrollTargetFound = true
    return <div ref={scrollTarget} />
  }
  return (
    <>
      <LearnHeader
        score={
          topicCategoryItems.data &&
          topicCategoryItems.data.topicCategories.length
            ? topicCategoryItems.data.topicCategories.reduce(
                (acc, topicCategory) => acc + topicCategory.progress,
                0
              ) / topicCategoryItems.data.topicCategories.length
            : 0
        }
        user={topicCategoryItems.data?.me ?? null}
      />

      <Page narrow>
        <div className={styles.instruction}>
          {t('learn.instructionTimeline')}
        </div>

        <div className={styles.timeLine}>
          {topicCategoryItems.data &&
            topicCategoryItems.data.topicCategories.flatMap((category) =>
              category.items.map((item) => {
                if (itemsRendered.includes(item.id)) {
                  return <Fragment key={category.id + item.id} />
                }

                itemsRendered.push(item.id)

                if (
                  category.availableFrom != currentDate &&
                  category.id != currentCategoryId
                ) {
                  show = true
                  currentDate = category.availableFrom
                  currentCategoryId = category.id
                } else {
                  show = false
                }

                return (
                  <Fragment key={category.id + item.id}>
                    {!scrollTargetFound &&
                      (props.match.params.refId
                        ? [category.id, item.id].includes(
                            props.match.params.refId
                          ) && scrollRefComponent()
                        : !item.completedAt && scrollRefComponent())}

                    {show && (
                      <DateIndicator
                        description={
                          category.availableFrom + ' - ' + category.description
                        }
                      />
                    )}

                    <TimeLineItem
                      topicCategory={category}
                      routeComponentProps={props}
                      item={item}
                      toggleFavorite={(event: SyntheticEvent) => {
                        event.stopPropagation()

                        toggleFavorite({
                          variables: {
                            id: item.id,
                            favorited: !item.favorited,
                          },
                          optimisticResponse: {
                            favoriteOnboardingItem: {
                              id: item.id,
                              favorited: !item.favorited,
                            },
                          },
                        })
                      }}
                    />
                  </Fragment>
                )
              })
            )}

          {!topicCategoryItems.data && topicCategoryItems.error && (
            <GraphQlError
              error={topicCategoryItems.error}
              retry={topicCategoryItems.retry}
            />
          )}

          {topicCategoryItems.data && (
            <DateIndicator description={t('learn.endOfTimeline')} />
          )}
        </div>
      </Page>
    </>
  )
}

import { ReactElement } from 'react'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

import { classNames } from '../../utils/classNames'

import styles from './Tab.scss'

interface TabProps {
  label: string
  to: string
}

export function Tab(props: TabProps): ReactElement {
  const match = useRouteMatch({ path: props.to, exact: true })

  return (
    <Link
      className={classNames(styles.tab, { [styles.active]: match !== null })}
      to={props.to}
    >
      {props.label}
    </Link>
  )
}

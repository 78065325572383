/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionType = "AWARENESS" | "KNOWLEDGE" | "POLL";
export type MatchView_item = {
    readonly id: string;
    readonly answerCategories: ReadonlyArray<{
        readonly id: string;
        readonly text: string;
        readonly " $fragmentRefs": FragmentRefs<"AnswerCategory_category">;
    }>;
    readonly answerOptions: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"AnswerOption_answer">;
    }>;
    readonly questionType: QuestionType;
    readonly text: string;
    readonly " $fragmentRefs": FragmentRefs<"useAnswerCategories_question" | "FeedbackDrawer_brainItem">;
    readonly " $refType": "MatchView_item";
};
export type MatchView_item$data = MatchView_item;
export type MatchView_item$key = {
    readonly " $data"?: MatchView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MatchView_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "language",
                "variableName": "language"
            }
        ],
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    } as any, v2 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "MatchView_item",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "AnswerCategory",
                "kind": "LinkedField",
                "name": "answerCategories",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AnswerCategory_category"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "AnswerOption",
                "kind": "LinkedField",
                "name": "answerOptions",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AnswerOption_answer"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "questionType",
                "storageKey": null
            },
            (v1 /*: any*/),
            {
                "kind": "InlineDataFragmentSpread",
                "name": "useAnswerCategories_question",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerCategory",
                        "kind": "LinkedField",
                        "name": "answerCategories",
                        "plural": true,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "answerOptions",
                        "plural": true,
                        "selections": (v2 /*: any*/),
                        "storageKey": null
                    }
                ]
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_brainItem"
            }
        ],
        "type": "MatchQuestion",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'cf598dbbdd7606951bf92213c027a803';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RankingMenuQueryVariables = {
    count: number;
    cursor?: string | null | undefined;
};
export type RankingMenuQueryResponse = {
    readonly duelInfo: {
        readonly rank: number | null;
        readonly " $fragmentRefs": FragmentRefs<"DuelPage_duelInfo" | "RankingItem_duelInfo">;
    };
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"RankingItem_user">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"RankingMenu_rankings">;
};
export type RankingMenuQuery = {
    readonly response: RankingMenuQueryResponse;
    readonly variables: RankingMenuQueryVariables;
};



/*
query RankingMenuQuery(
  $count: Int!
  $cursor: String
) {
  duelInfo {
    rank
    ...DuelPage_duelInfo
    ...RankingItem_duelInfo
  }
  me {
    ...RankingItem_user
    id
  }
  ...RankingMenu_rankings_1G22uz
}

fragment DuelPage_duelInfo on UserDuelInfo {
  rank
  rating
}

fragment RankingItem_duelInfo on UserDuelInfo {
  rank
  rating
}

fragment RankingItem_item on RankingPlayer {
  rank
  rating
  user {
    __typename
    id
    isMe
    fullNameForDuelling
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
}

fragment RankingItem_user on User {
  __isUser: __typename
  id
  isMe
  fullNameForDuelling
  profileImageForDuelling
  hasAnonymousDuellingEnabled
}

fragment RankingMenu_rankings_1G22uz on Query {
  duelRankings(first: $count, after: $cursor) {
    pageInfo {
      total
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        ...RankingItem_item
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMe",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullNameForDuelling",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImageForDuelling",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAnonymousDuellingEnabled",
        "storageKey": null
    } as any, v8 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RankingMenuQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserDuelInfo",
                    "kind": "LinkedField",
                    "name": "duelInfo",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelPage_duelInfo"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RankingItem_duelInfo"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RankingItem_user"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "RankingMenu_rankings"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RankingMenuQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserDuelInfo",
                    "kind": "LinkedField",
                    "name": "duelInfo",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/)
                            ],
                            "type": "User",
                            "abstractKey": "__isUser"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "RankingPlayerConnection",
                    "kind": "LinkedField",
                    "name": "duelRankings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "total",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RankingPlayerEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RankingPlayer",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/),
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "RankingMenu_duelRankings",
                    "kind": "LinkedHandle",
                    "name": "duelRankings"
                }
            ]
        },
        "params": {
            "cacheID": "ea2957716c1aa689f4c523825a5a00c4",
            "id": null,
            "metadata": {},
            "name": "RankingMenuQuery",
            "operationKind": "query",
            "text": "query RankingMenuQuery(\n  $count: Int!\n  $cursor: String\n) {\n  duelInfo {\n    rank\n    ...DuelPage_duelInfo\n    ...RankingItem_duelInfo\n  }\n  me {\n    ...RankingItem_user\n    id\n  }\n  ...RankingMenu_rankings_1G22uz\n}\n\nfragment DuelPage_duelInfo on UserDuelInfo {\n  rank\n  rating\n}\n\nfragment RankingItem_duelInfo on UserDuelInfo {\n  rank\n  rating\n}\n\nfragment RankingItem_item on RankingPlayer {\n  rank\n  rating\n  user {\n    __typename\n    id\n    isMe\n    fullNameForDuelling\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n}\n\nfragment RankingItem_user on User {\n  __isUser: __typename\n  id\n  isMe\n  fullNameForDuelling\n  profileImageForDuelling\n  hasAnonymousDuellingEnabled\n}\n\nfragment RankingMenu_rankings_1G22uz on Query {\n  duelRankings(first: $count, after: $cursor) {\n    pageInfo {\n      total\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...RankingItem_item\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4fbbf25523559b57edb18c0d4c83aa4e';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BrainSnackView_item = {
    readonly pages: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"TextItemView_pages">;
    }>;
    readonly " $refType": "BrainSnackView_item";
};
export type BrainSnackView_item$data = BrainSnackView_item;
export type BrainSnackView_item$key = {
    readonly " $data"?: BrainSnackView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BrainSnackView_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSnackView_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "BrainSnackPage",
            "kind": "LinkedField",
            "name": "pages",
            "plural": true,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TextItemView_pages"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "BrainSnack",
    "abstractKey": null
} as any;
(node as any).hash = '0d8615750e148495255f884c3dee4155';
export default node;

import { ReactElement } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { TextItemPage_page$key } from '../../../generated/TextItemPage_page.graphql'

import { Asset } from '../../common/Asset'

import styles from './TextItemPage.scss'

export interface TextItemPageProps {
  index: number
  page: TextItemPage_page$key
}

export function TextItemPage(props: TextItemPageProps): ReactElement {
  const page = useFragment(
    graphql`
      fragment TextItemPage_page on BrainSnackPage {
        text(language: $language)
        asset {
          ...Asset_asset
        }
      }
    `,
    props.page
  )

  return (
    <div>
      {/* The first page's asset is rendered by the header */}
      {props.index > 0 && page.asset && (
        <div className={styles.asset}>
          <Asset asset={page.asset} />
        </div>
      )}

      <div dangerouslySetInnerHTML={{ __html: page.text }} />
    </div>
  )
}

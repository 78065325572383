import EasyPieChart from 'easy-pie-chart'
import { ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../utils/classNames'

import styles from './ScoreChart.scss'

// A 100% full chart keeps a gap of 20% at the bottom
const FULL_SIZE = 80

interface ScoreChartProps {
  animateAtStart: boolean
  className?: string
  trackWidth: number
  chartWidth: number
  size: number
  value: number
}

export function ScoreChart(props: ScoreChartProps): ReactElement {
  const { i18n } = useTranslation()

  const trackElement = useRef<HTMLDivElement>(null)
  const chartElement = useRef<HTMLDivElement>(null)

  const trackChart = useRef<EasyPieChart | null>(null)
  const chartChart = useRef<EasyPieChart | null>(null)

  useEffect(() => {
    if (!trackElement.current || !chartElement.current) {
      return
    }

    if (trackChart.current || chartChart.current) {
      return
    }

    trackChart.current = new EasyPieChart(trackElement.current, {
      animate: false,
      lineCap: 'round',
      lineWidth: props.trackWidth,
      rotate: 180 + 3.6 * ((100 - FULL_SIZE) / 2),
      scaleColor: false,
      barColor: getComputedStyle(trackElement.current).getPropertyValue(
        'color'
      ),
      // Note: this assumes chartWidth > trackWidth
      size: props.size - (props.chartWidth - props.trackWidth),
      trackColor: false,
    })
    trackChart.current.update(FULL_SIZE)

    chartChart.current = new EasyPieChart(chartElement.current, {
      barColor: getComputedStyle(chartElement.current).getPropertyValue(
        'color'
      ),
      lineCap: 'round',
      lineWidth: props.chartWidth,
      rotate: 180 + 3.6 * ((100 - FULL_SIZE) / 2),
      scaleColor: false,
      size: props.size,
      trackColor: false,
    })
    if (!props.animateAtStart) {
      chartChart.current.update((props.value / 100) * FULL_SIZE)
    }
  }, [
    props.animateAtStart,
    props.chartWidth,
    props.size,
    props.trackWidth,
    props.value,
  ])

  useEffect(() => {
    if (!chartChart.current) {
      return
    }

    chartChart.current.update((props.value / 100) * FULL_SIZE)
  }, [props.value])

  return (
    <div className={classNames(styles.scoreChart, props.className)}>
      <div
        className={styles.track}
        ref={trackElement}
        style={{
          [i18n.dir(i18n.language) === 'ltr' ? 'left' : 'right']:
            (props.chartWidth - props.trackWidth) / 2,
          top: (props.chartWidth - props.trackWidth) / 2,
        }}
      />
      <div className={styles.chart} ref={chartElement} />
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RankingItem_duelInfo = {
    readonly rank: number | null;
    readonly rating: number;
    readonly " $refType": "RankingItem_duelInfo";
};
export type RankingItem_duelInfo$data = RankingItem_duelInfo;
export type RankingItem_duelInfo$key = {
    readonly " $data"?: RankingItem_duelInfo$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RankingItem_duelInfo">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingItem_duelInfo",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
        }
    ],
    "type": "UserDuelInfo",
    "abstractKey": null
} as any;
(node as any).hash = '00981f0e5e7818dfc569a72f70b927bb';
export default node;

import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { RankingItem_duelInfo$key } from '../../generated/RankingItem_duelInfo.graphql'
import { RankingItem_item$key } from '../../generated/RankingItem_item.graphql'
import { RankingItem_user$key } from '../../generated/RankingItem_user.graphql'
import { classNames } from '../../utils/classNames'
import { Avatar, AvatarContext } from '../common/Avatar'
import { NumberBubble } from '../common/NumberBubble'

import styles from './RankingItem.scss'
import { useEnvironment } from '../../App'

export interface RankingItemProps {
  bare?: boolean
  duelInfo?: RankingItem_duelInfo$key
  item: RankingItem_item$key | null
  rank: number
  user: RankingItem_user$key | null
}

export function RankingItem(props: RankingItemProps): ReactElement {
  const { t, i18n } = useTranslation()
  const environmentSettings = useEnvironment()

  const item = useFragment(
    graphql`
      fragment RankingItem_item on RankingPlayer {
        rank
        rating
        user {
          id
          isMe
          fullNameForDuelling
          profileImageForDuelling
          hasAnonymousDuellingEnabled
        }
      }
    `,
    props.item
  )
  const duelInfo = useFragment(
    graphql`
      fragment RankingItem_duelInfo on UserDuelInfo {
        rank
        rating
      }
    `,
    props.duelInfo || null
  )
  const user = useFragment(
    graphql`
      fragment RankingItem_user on User {
        id
        isMe
        fullNameForDuelling
        profileImageForDuelling
        hasAnonymousDuellingEnabled
      }
    `,
    props.user
  )

  const userToShow = item?.user || (duelInfo && user ? user : null)

  const playerName = userToShow
    ? userToShow.isMe &&
      (environmentSettings.anonymousDuelling === 'ALL' ||
        (environmentSettings.anonymousDuelling === 'USER' &&
          userToShow.hasAnonymousDuellingEnabled))
      ? t('streamItem.duelItem.yourName', {
          name: userToShow.fullNameForDuelling,
        })
      : userToShow?.fullNameForDuelling
    : t('common.Name')

  return (
    <div
      className={classNames(styles.rankingItem, {
        [styles.bare]: props.bare === true,
        [styles.placeholder]: !item,
      })}
      role='listitem'
    >
      <NumberBubble className={styles.rank} text={props.rank} scaling />

      {userToShow ? (
        <Avatar
          className={styles.avatar}
          filename={userToShow.profileImageForDuelling}
          name={playerName}
          userId={userToShow.id}
          context={AvatarContext.duelling}
          hasAnonymousDuellingEnabled={userToShow.hasAnonymousDuellingEnabled}
        />
      ) : (
        <span className={styles.avatar} />
      )}

      <span className={styles.name}>{playerName}</span>
      <span className={styles.rating}>
        <FontAwesomeIcon icon={faStar} />{' '}
        {Intl.NumberFormat(i18n.language).format(
          item?.rating || duelInfo?.rating || 0
        )}
      </span>
    </div>
  )
}

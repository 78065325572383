import { observer } from 'mobx-react'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { useQuery } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import { DuelPage } from '../../../containers/duels/DuelPage'
import { OnlineMenu } from '../../../containers/OnlineMenu'
import { CompletedDuelsQuery } from '../../../generated/CompletedDuelsQuery.graphql'
import { EmptyList } from '../../common/EmptyList'
import { GraphQlError } from '../../common/GraphQlError'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { CompletedDuelSlot } from '../../duels/CompletedDuelSlot'

import styles from './CompletedDuels.scss'

export const CompletedDuels = observer(function CompletedDuels(
  props: RouteComponentProps
): ReactElement {
  const { t } = useTranslation()

  const completedDuels = useQuery<CompletedDuelsQuery>(
    graphql`
      query CompletedDuelsQuery {
        duelInfo {
          ...DuelPage_duelInfo
        }
        duels(statusFilter: ONLY_COMPLETED) {
          id
          ...CompletedDuelSlot_item
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const renderPlaceholder = useCallback(
    (index: number) => <CompletedDuelSlot key={index} item={null} />,
    []
  )

  const loading = !completedDuels.data && !completedDuels.error
  const emptyList =
    completedDuels.data && completedDuels.data.duels.length === 0

  return (
    <DuelPage {...props} duelInfo={completedDuels.data?.duelInfo}>
      <OnlineMenu>
        <div className={styles.completedDuels}>
          <div className={styles.intro}>
            {emptyList ? t('completedDuels.empty') : t('completedDuels.title')}
          </div>

          <div className={styles.list} role='list'>
            {loading && <LoadingIndicator />}

            {emptyList && <EmptyList renderItem={renderPlaceholder} />}

            {completedDuels.data &&
              completedDuels.data.duels.map(
                (item): ReactElement => (
                  <CompletedDuelSlot key={item.id} item={item} />
                )
              )}

            {!completedDuels.data && completedDuels.error && (
              <GraphQlError
                error={completedDuels.error}
                retry={completedDuels.retry}
              />
            )}
          </div>
        </div>
      </OnlineMenu>
    </DuelPage>
  )
})

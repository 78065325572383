/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionType = "AWARENESS" | "KNOWLEDGE" | "POLL";
export type SliderView_item = {
    readonly questionType: QuestionType;
    readonly sliderType: {
        readonly __typename: "NumericalSlider";
        readonly maxValue: number;
        readonly minValue: number;
        readonly postfix: string;
        readonly prefix: string;
        readonly step: number;
    } | {
        readonly __typename: "TextualSlider";
        readonly values: ReadonlyArray<string>;
    };
    readonly text: string;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_brainItem">;
    readonly " $refType": "SliderView_item";
};
export type SliderView_item$data = SliderView_item;
export type SliderView_item$key = {
    readonly " $data"?: SliderView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SliderView_item">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "language",
            "variableName": "language"
        } as any
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "SliderView_item",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "questionType",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sliderType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxValue",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minValue",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": (v0 /*: any*/),
                                "kind": "ScalarField",
                                "name": "postfix",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": (v0 /*: any*/),
                                "kind": "ScalarField",
                                "name": "prefix",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "step",
                                "storageKey": null
                            }
                        ],
                        "type": "NumericalSlider",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "alias": null,
                                "args": (v0 /*: any*/),
                                "kind": "ScalarField",
                                "name": "values",
                                "storageKey": null
                            }
                        ],
                        "type": "TextualSlider",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_brainItem"
            }
        ],
        "type": "SliderQuestion",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '8a13323e4def261cc503a7f9edbd82c4';
export default node;

import { forwardRef, PropsWithChildren, ReactElement, Ref } from 'react'

import { useStores } from '../stores'
import { classNames } from '../utils/classNames'

import styles from './BottomDrawer.scss'

interface BottomDrawerProps {
  className?: string
}

export const BottomDrawer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<BottomDrawerProps>
>(function BottomDrawer(
  props: PropsWithChildren<BottomDrawerProps>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const { commonStore } = useStores()

  return (
    <div
      className={classNames(styles.positioning, {
        [styles.withoutNavBar]: commonStore.navBarHidden,
        [styles.withItemNavigationBar]: commonStore.itemNavigationBarShown,
      })}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={`${styles.col12} ${styles.colMd10} ${styles.colLg8}`}>
            <div className={props.className} ref={ref}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionType = "AWARENESS" | "KNOWLEDGE" | "POLL";
export type WellDoneOverlay_feedback = {
    readonly scoreChange: {
        readonly from: number;
        readonly to: number;
    } | null;
    readonly brainItem: {
        readonly questionType?: QuestionType | undefined;
    } | null;
    readonly " $refType": "WellDoneOverlay_feedback";
};
export type WellDoneOverlay_feedback$data = WellDoneOverlay_feedback;
export type WellDoneOverlay_feedback$key = {
    readonly " $data"?: WellDoneOverlay_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WellDoneOverlay_feedback">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WellDoneOverlay_feedback",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScoreChange",
            "kind": "LinkedField",
            "name": "scoreChange",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "from",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "to",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brainItem",
            "plural": false,
            "selections": [
                {
                    "kind": "InlineFragment",
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "questionType",
                            "storageKey": null
                        }
                    ],
                    "type": "HasQuestionType",
                    "abstractKey": "__isHasQuestionType"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "BrainItemCompletionData",
    "abstractKey": "__isBrainItemCompletionData"
} as any;
(node as any).hash = 'fdc9fbcefa62d2d2ee0539d256a35323';
export default node;

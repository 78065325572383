import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { TopicCategorySelector_topicCategories$key } from '../../generated/TopicCategorySelector_topicCategories.graphql'
import { useStores } from '../../stores'
import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { PrimaryButton } from '../common/PrimaryButton'
import { ProgressBar } from '../common/ProgressBar'

import styles from './TopicCategorySelector.scss'

interface TopicCategorySelectorProps {
  topicCategories: TopicCategorySelector_topicCategories$key
  changeCurrentTopicCategoryIndex(index: number): void
}

export function TopicCategorySelector(
  props: TopicCategorySelectorProps
): ReactElement {
  const { i18n, t } = useTranslation()
  const { learnStore } = useStores()
  const numberFormatter = new Intl.NumberFormat(i18n.language)
  const percentageFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'percent',
  })
  const isLtr = i18n.dir() === 'ltr'

  const [focusOnCategory, setFocusOnCategory] = useState<number | undefined>(
    undefined
  )

  const topicCategories = useFragment(
    graphql`
      fragment TopicCategorySelector_topicCategories on TopicCategoryWithScore
      @relay(plural: true) {
        id
        description(language: $language)
        percentage
        topics {
          id
        }
      }
    `,
    props.topicCategories
  )

  const nextSlide = useCallback(() => {
    if (learnStore.selectedTopicCategoryIndex < topicCategories.length - 1) {
      props.changeCurrentTopicCategoryIndex(
        learnStore.selectedTopicCategoryIndex + 1
      )
    }
  }, [learnStore.selectedTopicCategoryIndex, props, topicCategories.length])
  const nextSlideKeyPress = useEnterKeyHandler(nextSlide)

  const previousSlide = useCallback(() => {
    if (learnStore.selectedTopicCategoryIndex > 0) {
      props.changeCurrentTopicCategoryIndex(
        learnStore.selectedTopicCategoryIndex - 1
      )
    }
  }, [learnStore.selectedTopicCategoryIndex, props])
  const previousSlideKeyPress = useEnterKeyHandler(previousSlide)

  const focusCameFromKeyboard = useRef<boolean>(false)
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        focusCameFromKeyboard.current = true
      }
    }
    const keyUpHandler = () => (focusCameFromKeyboard.current = false)

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [])

  // Note that we are guaranteed to receive blur before the next focus.
  const onBlurCategory = useCallback(() => {
    setFocusOnCategory(undefined)
  }, [])
  const onFocusOnCategory = useCallback((index: number) => {
    if (!focusCameFromKeyboard.current) {
      // Note: This fixes an issue where clicking one of the outer topic categories
      // moving the focus to it, but the click not registering because by the
      // time the mouse up (and thus the click event) is fired, the element has
      // started to move to the center, and a click even is only triggered when
      // the mouse down and mouse up are both within the boundaries of the element.
      return
    }

    setFocusOnCategory(index)
  }, [])

  const categoryIndexToShow =
    focusOnCategory ?? learnStore.selectedTopicCategoryIndex
  const afterVisibleIndex =
    categoryIndexToShow === 0 ? 3 : categoryIndexToShow + 2
  const beforeVisibleIndex =
    categoryIndexToShow === topicCategories.length - 1
      ? topicCategories.length - 4
      : categoryIndexToShow - 2

  const showCarouselNavigationButtons = topicCategories.length > 3
  return (
    <div className={styles.wrapper}>
      {showCarouselNavigationButtons && (
        <div className={styles.navigation}>
          <PrimaryButton
            className={styles.primaryButton}
            disabled={learnStore.selectedTopicCategoryIndex === 0}
            onClick={previousSlide}
            onKeyPress={previousSlideKeyPress}
            tabIndex={0}
          >
            <FontAwesomeIcon icon={isLtr ? faArrowLeft : faArrowRight} />
          </PrimaryButton>
        </div>
      )}

      <div className={styles.carousel}>
        <div
          className={classNames(styles.carouselInner, {
            [styles.center]: topicCategories.length < 3,
          })}
          style={{
            transform: `translateX(${
              (categoryIndexToShow < 2
                ? 0
                : categoryIndexToShow >= topicCategories.length - 1
                ? topicCategories.length - 3
                : categoryIndexToShow - 1) *
              (1 / 3) *
              100 *
              (isLtr ? -1 : 1)
            }%)`,
          }}
        >
          {topicCategories.map((topicCategory, index) => (
            <div
              className={classNames(styles.categoryWrapper, {
                [styles.next]: index >= afterVisibleIndex,
                [styles.previous]: index <= beforeVisibleIndex,
              })}
              key={topicCategory.id + index}
            >
              <div
                className={classNames(styles.category, {
                  [styles.active]:
                    index === learnStore.selectedTopicCategoryIndex,
                })}
                onBlur={onBlurCategory}
                onClick={() => props.changeCurrentTopicCategoryIndex(index)}
                onFocus={() => onFocusOnCategory(index)}
                onKeyPress={(event) =>
                  event.key === 'Enter' &&
                  props.changeCurrentTopicCategoryIndex(index)
                }
                tabIndex={0}
              >
                <div className={styles.counter}>
                  {numberFormatter.format(index + 1)}/
                  {numberFormatter.format(topicCategories.length)}
                </div>
                <div className={styles.description}>
                  {topicCategory.description}
                </div>
                <div className={styles.percentage}>
                  {percentageFormatter.format(topicCategory.percentage / 100)}
                </div>

                <ProgressBar
                  hideText={true}
                  percentage={topicCategory.percentage}
                />

                <div className={styles.topicCount}>
                  {t('learn.topicCount', {
                    count: topicCategory.topics.length,
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showCarouselNavigationButtons && (
        <div className={styles.navigation}>
          <PrimaryButton
            className={styles.primaryButton}
            disabled={
              learnStore.selectedTopicCategoryIndex ===
              topicCategories.length - 1
            }
            onClick={nextSlide}
            onKeyPress={nextSlideKeyPress}
            tabIndex={0}
          >
            <FontAwesomeIcon icon={isLtr ? faArrowRight : faArrowLeft} />
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

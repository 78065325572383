/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionView_item = ({
    readonly __typename: "MatchQuestion";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"MatchView_item">;
} | {
    readonly __typename: "MultipleChoiceQuestion";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"MultipleChoiceView_item">;
} | {
    readonly __typename: "MultipleSelectQuestion";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"MultipleSelectView_item">;
} | {
    readonly __typename: "OrderQuestion";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"OrderView_item">;
} | {
    readonly __typename: "SliderQuestion";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"SliderView_item">;
}) & {
    readonly " $refType": "QuestionView_item";
};
export type QuestionView_item$data = QuestionView_item;
export type QuestionView_item$key = {
    readonly " $data"?: QuestionView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"QuestionView_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "QuestionView_item",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MatchView_item"
                    }
                ],
                "type": "MatchQuestion",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MultipleChoiceView_item"
                    }
                ],
                "type": "MultipleChoiceQuestion",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MultipleSelectView_item"
                    }
                ],
                "type": "MultipleSelectQuestion",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OrderView_item"
                    }
                ],
                "type": "OrderQuestion",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SliderView_item"
                    }
                ],
                "type": "SliderQuestion",
                "abstractKey": null
            }
        ],
        "type": "BrainItemQuestion",
        "abstractKey": "__isBrainItemQuestion"
    } as any;
})();
(node as any).hash = '30c4abff6600477d380886c51c0f47d7';
export default node;

import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { TopicCategory_item$key } from '../../generated/TopicCategory_item.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { ForwardIcon } from '../common/ForwardIcon'
import { ProgressBar } from '../common/ProgressBar'

import styles from './TopicCategory.scss'

interface TopicCategoryProps {
  click?: () => void
  item: TopicCategory_item$key
}

export function TopicCategory(props: TopicCategoryProps): ReactElement {
  const { t } = useTranslation()

  const topicCategory = useFragment(
    graphql`
      fragment TopicCategory_item on TopicCategory {
        availableFrom
        completedAt
        description(language: $language)
        icon
        progress
        startedAt
        unlocked
      }
    `,
    props.item
  )

  const dividerStyle = classNames(styles.divider, {
    [styles.active]: topicCategory.unlocked,
    [styles.disabled]: !topicCategory.unlocked,
  })

  const topicCategoryStyle = classNames(styles.topicCategoryOuter, {
    [styles.active]: topicCategory.unlocked,
    [styles.disabled]: !topicCategory.unlocked,
    [styles.withIcon]: !!topicCategory.icon,
  })

  const categoryStatus = topicCategory.unlocked
    ? topicCategory.completedAt
      ? t('learn.completedAt')
      : topicCategory.startedAt
      ? t('learn.startedAt')
      : t('learn.availableFrom')
    : t('learn.available')

  return (
    <>
      <div className={dividerStyle}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      <div className={topicCategoryStyle}>
        <div
          className={styles.topicCategory}
          onClick={props.click}
          onKeyPress={useEnterKeyHandler(props.click)}
          role='listitem'
          style={
            topicCategory.icon
              ? { backgroundImage: `url("${topicCategory.icon}")` }
              : {}
          }
          tabIndex={0}
        >
          <div className={styles.name}>{topicCategory.description}</div>
          <div className={styles.status}>
            {categoryStatus}{' '}
            {(topicCategory.unlocked &&
              (topicCategory.completedAt ?? topicCategory.startedAt)) ??
              topicCategory.availableFrom}
          </div>
          <ProgressBar
            percentage={topicCategory.progress}
            alternativeTextStyle={styles.name}
          />
        </div>

        <div className={styles.attachment} onClick={props.click}>
          {topicCategory.unlocked ? (
            <ForwardIcon />
          ) : (
            <FontAwesomeIcon icon={faLock} />
          )}
        </div>
      </div>
    </>
  )
}

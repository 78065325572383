import { observer } from 'mobx-react'
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useFragment, useMutation, useQuery } from 'relay-hooks'

import { Header } from '../../../containers/Header'
import { Page } from '../../../containers/Page'
import { OnboardingItemPageContinueToPowerAppMutation } from '../../../generated/OnboardingItemPageContinueToPowerAppMutation.graphql'
import { OnboardingItemPageItemView_item$key } from '../../../generated/OnboardingItemPageItemView_item.graphql'
import { OnboardingItemPageQuery } from '../../../generated/OnboardingItemPageQuery.graphql'
import { OnboardingItemPageStartItemMutation } from '../../../generated/OnboardingItemPageStartItemMutation.graphql'
import {
  OnboardingItemPageSubmitMutation,
  OnboardingItemPageSubmitMutationResponse,
} from '../../../generated/OnboardingItemPageSubmitMutation.graphql'
import { QuestionView_feedback$key } from '../../../generated/QuestionView_feedback.graphql'
import { useStores } from '../../../stores'
import { never } from '../../../utils/assert'
import {
  BrainItemProps,
  BrainItemState,
  useBrainItems,
} from '../../../utils/hooks/useBrainItems'
import { scrollToTop } from '../../../utils/scrollToTop'
import { useTimeline } from '../../../utils/hooks/useTimeline'
import { BackIcon } from '../../common/BackIcon'
import { Fullscreen } from '../../common/Fullscreen'
import { GraphQlError } from '../../common/GraphQlError'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { PrimaryButton } from '../../common/PrimaryButton'
import { BrainItemHeader } from '../../learn/BrainItemHeader'
import { ItemHeader } from '../../learn/ItemHeader'
import { QuestionView } from '../../learn/questions/QuestionView'
import { BrainSnackView } from '../../learn/snacks/BrainSnackView'
import { BrainItemCompletionInput } from '../../learn/StreamItem'
import { OnboardingItemNavigationBar } from '../../onboarding/OnboardingItemNavigationBar'
import styles from '../learn/Stream.scss'

export interface OnboardingItemPageProps {
  itemId?: string
}

export const OnboardingItemPage = observer(function OnboardingItemPage(
  props: RouteComponentProps<OnboardingItemPageProps>
): ReactElement {
  const { commonStore } = useStores()
  const { i18n } = useTranslation()

  const [brainItemState, setBrainItemState] = useBrainItems()
  const [brainItemFeedback, setBrainItemFeedback] =
    useState<QuestionView_feedback$key | null>(null)

  // Query for retrieving the complete timeline, with the item data.
  const timeline = useQuery<OnboardingItemPageQuery>(
    graphql`
      query OnboardingItemPageQuery($language: String!) {
        me {
          id
          hasPowerAppGroups
        }
        topicCategories {
          ...useTimeline_timeline
          id
          # eslint-disable-next-line relay/unused-fields
          items {
            id
            started
            brainItem {
              __typename
              ... on Node {
                id
              }
              ...BrainItemHeader_brainItem
              ...OnboardingItemPageItemView_item
            }
            feedback {
              ...QuestionView_feedback
            }
          }
        }
      }
    `,
    {
      language: i18n.language,
    }
  )
  const {
    item,
    canNavigateBackwards,
    canNavigateForwards,
    navigateBackwards,
    navigateForwards,
    nextLockedTopicCategoryId,
    nextLockedItemAvailableFrom,
    startTime,
  } = useTimeline(timeline?.data?.topicCategories, props.match.params.itemId)

  // Mutation for marking an item as started.
  const [markItemAsStarted] =
    useMutation<OnboardingItemPageStartItemMutation>(graphql`
      mutation OnboardingItemPageStartItemMutation($id: ID!)
      @raw_response_type {
        startOnboardingItem(id: $id) {
          id
          started
        }
      }
    `)

  // Mutation for marking an item as completed, and retrieving the feedback data.
  const [submitAnswer] = useMutation<OnboardingItemPageSubmitMutation>(graphql`
    mutation OnboardingItemPageSubmitMutation(
      $id: ID!
      $data: BrainItemCompletionInput!
      $language: String!
    ) {
      completeOnboardingItem(id: $id, data: $data) {
        onboardingItem {
          id
          started
          completed
          brainItem {
            __typename
          }
          feedback {
            ...QuestionView_feedback
          }
        }
        brainItemCompletionData {
          __typename
          ...QuestionView_feedback
        }
      }
    }
  `)

  const [continueToPowerApp] =
    useMutation<OnboardingItemPageContinueToPowerAppMutation>(graphql`
      mutation OnboardingItemPageContinueToPowerAppMutation @raw_response_type {
        continueToPowerApp {
          id
          isOnboarding
        }
      }
    `)

  const resetBrainItemState = useCallback((): void => {
    setBrainItemFeedback(null)
    setBrainItemState(
      item && item.feedback && item.brainItem.__typename !== 'BrainSnack'
        ? BrainItemState.FEEDBACK
        : BrainItemState.READY
    )
  }, [item, setBrainItemState])

  // Navigate to the timeline after completing all items, optionally being told to come back later.
  const navigateToTimeline = useCallback(() => {
    props.history.push(
      nextLockedTopicCategoryId
        ? '/onboarding/' + encodeURIComponent(nextLockedTopicCategoryId)
        : '/onboarding'
    )
  }, [nextLockedTopicCategoryId, props.history])

  // Mark item as started when it is opened.
  useEffect(() => {
    if (item && !item.started) {
      markItemAsStarted({
        variables: { id: item.id },
        optimisticResponse: {
          startOnboardingItem: {
            id: item.id,
            started: true,
          },
        },
      })
    }
  }, [item, markItemAsStarted])

  useEffect(resetBrainItemState, [item, resetBrainItemState])

  // Handlers for navigating backwards and forwards from the navigation bar.
  // Navigating forwards is also used when closing an item.
  const onNavigateBackwards = useCallback((): void => {
    if (canNavigateBackwards) {
      resetBrainItemState()
      navigateBackwards()
    }
  }, [canNavigateBackwards, navigateBackwards, resetBrainItemState])
  const onNavigateForwards = useCallback((): void => {
    resetBrainItemState()
    if (canNavigateForwards) {
      navigateForwards()
    } else if (nextLockedItemAvailableFrom !== '') {
      commonStore.openPopup(
        {
          type: 'come-back-later',
          comeBackDate: nextLockedItemAvailableFrom,
          onConfirm: () => {
            navigateToTimeline()
            return Promise.resolve()
          },
        },
        true
      )
    } else if (!canNavigateBackwards && !canNavigateForwards) {
      commonStore.openPopup(
        {
          type: 'finished-onboarding',
          allowContinueToPowerApp: timeline.data?.me
            ? timeline.data.me.hasPowerAppGroups
            : false,
          continueToPowerApp: (event: SyntheticEvent) => {
            event.stopPropagation()

            continueToPowerApp({
              variables: {},
              optimisticResponse: {
                continueToPowerApp: {
                  id: String(timeline.data?.me?.id),
                  isOnboarding: false,
                },
              },
            }).then(() => {
              props.history.push('/')
              commonStore.closePopup()
            })
          },
          stayInOnfire: () => {
            props.history.push('/')
            commonStore.closePopup()
          },
        },
        true
      )
    } else {
      navigateToTimeline()
    }
  }, [
    resetBrainItemState,
    canNavigateForwards,
    nextLockedItemAvailableFrom,
    canNavigateBackwards,
    navigateForwards,
    commonStore,
    navigateToTimeline,
    timeline.data?.me,
    continueToPowerApp,
    props.history,
  ])

  // Handler for after an item is marked as completed, to update the brain item state correctly.
  const onSubmitCompleted = useCallback(
    (result: OnboardingItemPageSubmitMutationResponse): void => {
      const feedback = result.completeOnboardingItem

      setBrainItemFeedback(feedback.brainItemCompletionData)
      setBrainItemState(
        feedback.brainItemCompletionData.__typename ===
          'BrainSnackCompletionData'
          ? BrainItemState.COMPLETED
          : BrainItemState.FEEDBACK
      )
    },
    [setBrainItemState]
  )

  // Handlers for closing a brain snack, closing a question, and submitting
  // a question.
  const closeBrainSnack = useCallback(
    (data: BrainItemCompletionInput): void => {
      setBrainItemState(BrainItemState.CHECKING)

      submitAnswer({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: item!.brainItem.id!,
          data: {
            duration: new Date().getTime() - startTime,
            ...data,
          },
          language: i18n.language,
        },
        onCompleted: onSubmitCompleted,
      })
    },
    [
      i18n.language,
      item,
      onSubmitCompleted,
      setBrainItemState,
      startTime,
      submitAnswer,
    ]
  )
  const closeQuestion = useCallback(() => {
    setBrainItemState(BrainItemState.COMPLETED)

    scrollToTop()
  }, [setBrainItemState])
  const submitQuestion = useCallback(
    (data: BrainItemCompletionInput): void => {
      setBrainItemState(BrainItemState.CHECKING)

      submitAnswer({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: item!.brainItem.id!,
          data: {
            duration: new Date().getTime() - startTime,
            ...data,
          },
          language: i18n.language,
        },
        onCompleted: onSubmitCompleted,
      })
    },
    [
      i18n.language,
      item,
      onSubmitCompleted,
      setBrainItemState,
      startTime,
      submitAnswer,
    ]
  )

  // When the brain item state gets set to complete, automatically navigate
  // forwards to the next item.
  useEffect((): void => {
    if (brainItemState === BrainItemState.COMPLETED) {
      onNavigateForwards()
    }
  }, [brainItemState, onNavigateForwards])

  return (
    <Fullscreen>
      <Header>
        <div className={styles.row}>
          <div className={styles.col}>
            <PrimaryButton
              className={styles.backButton}
              component={Link}
              icon
              onClick={resetBrainItemState}
              to={`/onboarding/${item?.id}`}
            >
              <BackIcon />
            </PrimaryButton>
          </div>

          <div className={styles.col}>{!item && <LoadingIndicator />}</div>
        </div>
      </Header>

      <Page>
        {item ? <BrainItemHeader brainItem={item.brainItem} /> : <ItemHeader />}

        <ItemView
          brainItemState={brainItemState}
          canNavigateForwards={canNavigateForwards}
          closeBrainSnack={closeBrainSnack}
          closeQuestion={closeQuestion}
          feedback={item?.feedback ?? brainItemFeedback}
          item={item?.brainItem}
          setBrainItemState={setBrainItemState}
          submitQuestion={submitQuestion}
        />

        {!timeline.data && timeline.error && (
          <GraphQlError error={timeline.error} retry={timeline.retry} />
        )}
      </Page>

      <OnboardingItemNavigationBar
        canNavigateForwards={canNavigateForwards}
        canNavigateBackwards={canNavigateBackwards}
        onBack={onNavigateBackwards}
        onForward={onNavigateForwards}
      />
    </Fullscreen>
  )
})

interface ItemViewProps {
  feedback: QuestionView_feedback$key | null
  canNavigateForwards: boolean

  closeBrainSnack(data: BrainItemCompletionInput): void

  closeQuestion(): void

  item: OnboardingItemPageItemView_item$key | undefined

  submitQuestion(data: BrainItemCompletionInput): void
}

function ItemView(props: BrainItemProps & ItemViewProps): ReactElement {
  const item = useFragment(
    graphql`
      fragment OnboardingItemPageItemView_item on BrainItem {
        __typename
        ...BrainSnackView_item
        ...QuestionView_item
      }
    `,
    props.item || null
  )

  if (!item) {
    return <></>
  }

  switch (item.__typename) {
    case 'BrainSnack':
      return (
        <BrainSnackView
          brainItemState={props.brainItemState}
          inOnboarding={true}
          item={item}
          onClose={props.closeBrainSnack}
          onboardingItemsLeft={props.canNavigateForwards}
          setBrainItemState={props.setBrainItemState}
        />
      )

    case 'MatchQuestion':
    case 'MultipleChoiceQuestion':
    case 'MultipleSelectQuestion':
    case 'OrderQuestion':
    case 'SliderQuestion':
      return (
        <QuestionView
          brainItemState={props.brainItemState}
          feedback={props.feedback}
          inOnboarding={true}
          item={item}
          onClose={props.closeQuestion}
          onSubmit={props.submitQuestion}
          onboardingItemsLeft={props.canNavigateForwards}
          setBrainItemState={props.setBrainItemState}
        />
      )

    default:
      never(item.__typename, `Invalid brain item type: ${JSON.stringify(item)}`)
  }
}

import { ReactElement, SyntheticEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { MultipleSelectView_feedback$key } from '../../../generated/MultipleSelectView_feedback.graphql'
import { MultipleSelectView_item$key } from '../../../generated/MultipleSelectView_item.graphql'
import { assert, never } from '../../../utils/assert'
import { handleEnterKey } from '../../../utils/handleEnterKey'
import {
  BrainItemProps,
  BrainItemState,
} from '../../../utils/hooks/useBrainItems'
import { useSelectedAnswers } from '../../../utils/hooks/useSelectedAnswers'

import { PrimaryButton } from '../../common/PrimaryButton'
import { FeedbackDrawer } from '../FeedbackDrawer'

import {
  AnswerOption,
  AnswerOptionFeedback,
  AnswerOptionViewType,
} from './elements/AnswerOption'
import { Question } from './elements/Question'
import { QuestionInstruction } from './elements/QuestionInstruction'
import styles from './Question.scss'
import { QuestionHandlers } from './QuestionView'

export interface MultipleSelectViewProps {
  feedback: MultipleSelectView_feedback$key | null
  item: MultipleSelectView_item$key
}

export function MultipleSelectView(
  props: MultipleSelectViewProps & BrainItemProps & QuestionHandlers
): ReactElement {
  const { t, i18n } = useTranslation()

  const item = useFragment(
    graphql`
      fragment MultipleSelectView_item on MultipleSelectQuestion {
        id
        answerOptions {
          id
          ...AnswerOption_answer
        }
        questionType
        text(language: $language)
        ...FeedbackDrawer_brainItem
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment MultipleSelectView_feedback on BrainItemCompletionData {
        __typename
        ...FeedbackDrawer_feedback
        ... on GenericQuestionCompletionData {
          correctAnswers {
            id
          }
          responseCount
          responses {
            timesChosen
            answerOption {
              id
            }
          }
          userAnswers {
            id
          }
        }
      }
    `,
    props.feedback
  )

  const [selectedAnswers, addSelectedAnswer, removeSelectedAnswer] =
    useSelectedAnswers(
      feedback?.__typename === 'GenericQuestionCompletionData'
        ? feedback.userAnswers.map((answerOption) => answerOption.id)
        : []
    )

  const onAnswerClicked = useCallback(
    (answerId: string): void => {
      if (props.brainItemState >= BrainItemState.CHECKING) {
        return
      }

      if (selectedAnswers.includes(answerId)) {
        removeSelectedAnswer(answerId)
      } else {
        addSelectedAnswer(answerId)
      }
    },
    [
      addSelectedAnswer,
      props.brainItemState,
      removeSelectedAnswer,
      selectedAnswers,
    ]
  )

  const onButtonClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (props.brainItemState === BrainItemState.READY) {
        props.onSubmit({
          answerOptions: selectedAnswers.slice(),
        })
      }
    },
    [props, selectedAnswers]
  )

  const answerData =
    props.brainItemState > BrainItemState.CHECKING ? feedback : undefined

  if (answerData) {
    if (answerData.__typename !== 'GenericQuestionCompletionData') {
      throw new Error(
        'Invalid answer data for multiple select question: ' +
          JSON.stringify(answerData)
      )
    }

    if (item.questionType === 'KNOWLEDGE') {
      if (answerData.correctAnswers === null) {
        throw new Error(
          'Invalid answer data for non-poll multiple select question: ' +
            JSON.stringify(answerData)
        )
      }
    } else if (
      item.questionType === 'POLL' &&
      (answerData.responseCount === null || answerData.responses === null)
    ) {
      throw new Error(
        'Invalid answer data for poll multiple select question: ' +
          JSON.stringify(answerData)
      )
    }
  }

  let resultText = undefined
  if (answerData) {
    if (item.questionType === 'POLL') {
      resultText = t('streamItem.poll.resultinfo')
    } else if (item.questionType === 'AWARENESS') {
      resultText = t('streamItem.poll.doneText')
    } else {
      assert(answerData.correctAnswers !== null)

      const numberFormat = new Intl.NumberFormat(i18n.language)
      resultText = t('streamItem.multipleselect.correct', {
        num: numberFormat.format(
          selectedAnswers.filter((answer) =>
            answerData.correctAnswers?.find(
              (correctAnswer) => correctAnswer.id === answer
            )
          ).length
        ),
        max: numberFormat.format(answerData.correctAnswers.length),
      })

      if (!props.inOnboarding) {
        const incorrect = selectedAnswers.filter(
          (answer) =>
            !answerData.correctAnswers?.find(
              (correctAnswer) => correctAnswer.id === answer
            )
        ).length
        if (incorrect > 0) {
          resultText += t('streamItem.multipleselect.incorrect', {
            incorrect: numberFormat.format(incorrect),
          })
        }
      }
    }
  }

  return (
    <div className={styles.question}>
      <Question text={item.text} />
      <QuestionInstruction text={t('streamItem.multipleselect.helpText')} />

      <form
        role='listbox'
        aria-multiselectable={true}
        aria-readonly={props.brainItemState !== BrainItemState.READY}
      >
        {item.answerOptions.map((answer) => {
          const selected = selectedAnswers.includes(answer.id)
          let answerFeedback: AnswerOptionFeedback | undefined
          let pollPercentage: number | undefined
          if (answerData) {
            switch (item.questionType) {
              case 'KNOWLEDGE':
                assert(answerData.correctAnswers !== null)

                if (selected) {
                  answerFeedback = answerData.correctAnswers.find(
                    (correctAnswer) => correctAnswer.id === answer.id
                  )
                    ? AnswerOptionFeedback.CORRECT
                    : AnswerOptionFeedback.INCORRECT
                } else if (
                  answerData.correctAnswers.find(
                    (correctAnswer) => correctAnswer.id === answer.id
                  )
                ) {
                  answerFeedback = AnswerOptionFeedback.WOULD_HAVE_BEEN_CORRECT
                }
                break

              case 'AWARENESS':
                answerFeedback = selected
                  ? AnswerOptionFeedback.SUBMITTED
                  : undefined
                break

              case 'POLL':
                assert(
                  answerData.responses !== null &&
                    answerData.responseCount !== null
                )

                {
                  answerFeedback = AnswerOptionFeedback.POLL
                  const response = answerData.responses.find(
                    (r) => r.answerOption.id === answer.id
                  )
                  if (response) {
                    pollPercentage = Math.round(
                      (response.timesChosen / answerData.responseCount) * 100
                    )
                  }
                }

                break
              default:
                never(
                  item.questionType,
                  `Invalid question type: ${item.questionType}`
                )
            }
          }

          return (
            <AnswerOption
              answer={answer}
              checking={props.brainItemState === BrainItemState.CHECKING}
              disabled={props.brainItemState >= BrainItemState.CHECKING}
              feedback={answerFeedback}
              key={answer.id}
              selected={selected}
              type={
                item.questionType === 'POLL'
                  ? AnswerOptionViewType.POLL
                  : AnswerOptionViewType.MULTIPLE_SELECT
              }
              onClick={(): void => onAnswerClicked(answer.id)}
              pollPercentage={pollPercentage}
            />
          )
        })}
      </form>

      {props.brainItemState < BrainItemState.FEEDBACK || !answerData ? (
        <div className={styles.buttonContainer}>
          <PrimaryButton
            disabled={
              selectedAnswers.length === 0 ||
              props.brainItemState === BrainItemState.CHECKING
            }
            onClick={onButtonClicked}
            onKeyPress={handleEnterKey(onButtonClicked)}
          >
            {props.brainItemState === BrainItemState.READY &&
              (item.questionType === 'KNOWLEDGE'
                ? selectedAnswers.length === 1
                  ? t('common.CheckAnswer')
                  : t('common.CheckAnswers')
                : selectedAnswers.length === 1
                ? t('common.Answer')
                : t('common.Answers'))}
            {props.brainItemState === BrainItemState.CHECKING &&
              t('common.Checking')}
            {props.brainItemState === BrainItemState.FEEDBACK &&
              t('common.Close')}
          </PrimaryButton>
        </div>
      ) : (
        <FeedbackDrawer
          brainItem={item}
          brainItemState={props.brainItemState}
          feedback={answerData}
          heading={
            resultText! /* eslint-disable-line @typescript-eslint/no-non-null-assertion */
          }
          inDuel={!!props.inDuel}
          inOnboarding={!!props.inOnboarding}
          onBoardingItemsLeft={!!props.onboardingItemsLeft}
          onClose={props.onClose}
        />
      )}
    </div>
  )
}

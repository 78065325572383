import { ReactElement } from 'react'

import styles from './QuestionInstruction.scss'

interface QuestionInstructionProps {
  text: string
}

export function QuestionInstruction(
  props: QuestionInstructionProps
): ReactElement {
  return (
    <div className={styles.instruction} role='status'>
      {props.text}
    </div>
  )
}

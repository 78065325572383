import { observer } from 'mobx-react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'

import { Header } from '../../containers/Header'
import { LearnHeader_user$key } from '../../generated/LearnHeader_user.graphql'
import { Avatar, AvatarContext } from '../common/Avatar'
import { EditableAvatar } from '../common/EditableAvatar'
import { NumberBubble } from '../common/NumberBubble'

import { ScoreChart } from '../common/ScoreChart'

import styles from './LearnHeader.scss'

interface LearnHeaderProps {
  score: number
  user: LearnHeader_user$key | null
}

export const LearnHeader = observer(function LearnHeader(
  props: LearnHeaderProps
): ReactElement {
  const { t, i18n } = useTranslation()

  const currentUser = useFragment(
    graphql`
      fragment LearnHeader_user on AuthenticatedUser {
        id
        firstLogin
        firstName
        fullName
        profileImage
      }
    `,
    props.user
  )

  return (
    <>
      <Header wide>
        <div className={styles.header}>
          <div className={styles.welcome}>
            <div className={styles.welcomeName}>
              {currentUser &&
                t('header.welcome-message', {
                  name: currentUser.firstName,
                })}
            </div>
            <div className={styles.welcomeBack}>
              {currentUser &&
                (currentUser.firstLogin
                  ? t('header.welcome')
                  : t('header.welcomeBack'))}
            </div>
          </div>
          <EditableAvatar>
            <ScoreChart
              animateAtStart={true}
              className={styles.avatarChart}
              trackWidth={5}
              chartWidth={11}
              size={112 + 2 * 11}
              value={props.score}
            />

            <Avatar
              filename={currentUser?.profileImage}
              name={currentUser?.fullName || ''}
              userId={currentUser?.id || ''}
              context={AvatarContext.default}
            />

            <NumberBubble
              className={styles.avatarAttachment}
              scaling
              text={Intl.NumberFormat(i18n.language, {
                style: 'percent',
              }).format(props.score / 100)}
            />
          </EditableAvatar>
        </div>
      </Header>
    </>
  )
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ActiveDuelRequestsListCancelMutationVariables = {
    id: string;
    duelRequestConnections: Array<string>;
};
export type ActiveDuelRequestsListCancelMutationResponse = {
    readonly respondToDuelRequest: {
        readonly result: {
            readonly request: {
                readonly id: string;
            } | null;
        } | null;
    };
};
export type ActiveDuelRequestsListCancelMutation = {
    readonly response: ActiveDuelRequestsListCancelMutationResponse;
    readonly variables: ActiveDuelRequestsListCancelMutationVariables;
};



/*
mutation ActiveDuelRequestsListCancelMutation(
  $id: ID!
) {
  respondToDuelRequest(id: $id, to: DELETE) {
    result {
      request {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "duelRequestConnections"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any,
        {
            "kind": "Literal",
            "name": "to",
            "value": "DELETE"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ActiveDuelRequestsListCancelMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "RespondToDuelRequestResultResult",
                    "kind": "LinkedField",
                    "name": "respondToDuelRequest",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RespondToDuelRequestResult",
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DuelRequest",
                                    "kind": "LinkedField",
                                    "name": "request",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ActiveDuelRequestsListCancelMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "RespondToDuelRequestResultResult",
                    "kind": "LinkedField",
                    "name": "respondToDuelRequest",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RespondToDuelRequestResult",
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DuelRequest",
                                    "kind": "LinkedField",
                                    "name": "request",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "filters": null,
                                            "handle": "deleteEdge",
                                            "key": "",
                                            "kind": "ScalarHandle",
                                            "name": "id",
                                            "handleArgs": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "connections",
                                                    "variableName": "duelRequestConnections"
                                                }
                                            ]
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4367414f05453978122e11c72248a98c",
            "id": null,
            "metadata": {},
            "name": "ActiveDuelRequestsListCancelMutation",
            "operationKind": "mutation",
            "text": "mutation ActiveDuelRequestsListCancelMutation(\n  $id: ID!\n) {\n  respondToDuelRequest(id: $id, to: DELETE) {\n    result {\n      request {\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '06e22f623c7c5750f1dcf206666f6060';
export default node;

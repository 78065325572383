import { CSSProperties, ReactElement } from 'react'

import { library, IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { classNames } from '../../utils/classNames'

import { ProgressBar } from '../common/ProgressBar'
import defaultTopicImage from '../../../../../images/default-topic.png'
import styles from './Topic.scss'

library.add(fas, far, fab)

export const enum ChangeType {
  POSITIVE,
  NEGATIVE,
}

export interface TopicProps {
  change?: ChangeType
  className?: string
  labelId?: string
  name: string
  percentage: number
  percentageLabel?: string
  style?: CSSProperties
  withCutout?: boolean
  themeTopic?: boolean
  topicIcon?: ReadonlyArray<string>
}

export function Topic(props: TopicProps): ReactElement {
  const faIconProps: ReadonlyArray<string> =
    props.topicIcon || ([] as ReadonlyArray<string>)

  return (
    <div
      className={classNames(
        styles.topic,
        {
          [props.themeTopic ? styles.themeCutout : styles.cutout]:
            props.withCutout,
        },
        props.className
      )}
      style={props.style}
    >
      {faIconProps.length > 0 && (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faIconProps as IconProp} />
        </div>
      )}
      {props.themeTopic && <img alt='topic image' src={defaultTopicImage} />}
      <label
        className={props.themeTopic ? styles.themeTopic : ''}
        id={props.labelId}
        dangerouslySetInnerHTML={{ __html: props.name }}
      />

      <div className={props.themeTopic ? styles.progressBarThemeTopic : ''}>
        <ProgressBar
          alternativeText={props.percentageLabel}
          alternativeTextStyle={
            props.change === undefined
              ? undefined
              : props.change === ChangeType.POSITIVE
              ? styles.positiveChange
              : styles.negativeChange
          }
          percentage={props.percentage}
          hideText={props.themeTopic}
        />
      </div>
    </div>
  )
}

import { observer } from 'mobx-react'
import { ReactElement, useCallback, useRef } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { DuelCompleteRoundMutationVariables } from '../../generated/DuelCompleteRoundMutation.graphql'
import { OnboardingItemPageSubmitMutationVariables } from '../../generated/OnboardingItemPageSubmitMutation.graphql'
import { StreamItem_feedback$key } from '../../generated/StreamItem_feedback.graphql'

import { StreamItem_item$key } from '../../generated/StreamItem_item.graphql'
import { useStores } from '../../stores'
import { never } from '../../utils/assert'
import { BrainItemProps } from '../../utils/hooks/useBrainItems'
import {
  CheckAnswerMode,
  StreamSubmissionVariables,
} from '../pages/learn/Stream'

import { QuestionView } from './questions/QuestionView'
import { BrainSnackView } from './snacks/BrainSnackView'
import { NotificationBrainSnackView } from './snacks/NotificationBrainSnackView'
import { NotificationView } from './snacks/NotificationView'

export type BrainItemCompletionInput = Omit<
  StreamSubmissionVariables['data'] &
    DuelCompleteRoundMutationVariables['data'] &
    OnboardingItemPageSubmitMutationVariables['data'],
  'duration'
>

export type StreamItemProps = BrainItemProps & {
  closeQuestion(): void
  feedback: StreamItem_feedback$key | null
  item: StreamItem_item$key
  submitStreamItem(data: StreamSubmissionVariables, mode: CheckAnswerMode): void
}

export const StreamItem = observer(function StreamItem(
  props: StreamItemProps
): ReactElement {
  const { learnStore } = useStores()
  const startTime = useRef(new Date().getTime())

  const item = useFragment(
    graphql`
      fragment StreamItem_item on StreamItem {
        __typename
        ... on BrainItemStreamItem {
          id
          brainItem {
            __typename
            ... on Node {
              id
            }
            ...BrainSnackView_item
            ...QuestionView_item
          }
        }
        ... on NotificationBrainSnackStreamItem {
          id
          ...NotificationBrainSnackView_item
        }
        ... on NotificationStreamItem {
          id
          ...NotificationView_item
        }
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment StreamItem_feedback on BrainItemCompletionData {
        ...QuestionView_feedback
      }
    `,
    props.feedback
  )

  const brainItemId =
    item.__typename === 'BrainItemStreamItem' ? item.brainItem.id : null
  const submitItem = useCallback(
    (data: BrainItemCompletionInput, mode: CheckAnswerMode): void => {
      props.submitStreamItem(
        {
          id: item.id,
          topicPin: learnStore.topicPin.slice(),
          data: {
            duration: new Date().getTime() - startTime.current,
            ...data,
          },
          ...(brainItemId ? { brainItemId } : {}),
        },
        mode
      )
    },
    [props, item.id, brainItemId, learnStore.topicPin]
  )

  const closeBrainSnack = useCallback(
    (data: BrainItemCompletionInput): void => {
      submitItem(data, CheckAnswerMode.AUTO_CLOSE)
    },
    [submitItem]
  )
  const submitQuestion = useCallback(
    (data: BrainItemCompletionInput): void => {
      submitItem(data, CheckAnswerMode.SHOW_FEEDBACK)
    },
    [submitItem]
  )

  let itemView
  switch (item.__typename) {
    case 'BrainItemStreamItem':
      switch (item.brainItem.__typename) {
        case 'BrainSnack':
          itemView = (
            <BrainSnackView
              brainItemState={props.brainItemState}
              item={item.brainItem}
              onClose={closeBrainSnack}
              setBrainItemState={props.setBrainItemState}
            />
          )
          break

        case 'MatchQuestion':
        case 'MultipleChoiceQuestion':
        case 'MultipleSelectQuestion':
        case 'OrderQuestion':
        case 'SliderQuestion':
          itemView = (
            <QuestionView
              brainItemState={props.brainItemState}
              feedback={feedback}
              item={item.brainItem}
              onClose={props.closeQuestion}
              onSubmit={submitQuestion}
              setBrainItemState={props.setBrainItemState}
            />
          )
          break

        default:
          never(item.brainItem.__typename, '')
      }
      break

    case 'NotificationStreamItem':
      itemView = (
        <NotificationView
          brainItemState={props.brainItemState}
          item={item}
          onSubmit={closeBrainSnack}
          setBrainItemState={props.setBrainItemState}
        />
      )
      break

    case 'NotificationBrainSnackStreamItem': {
      itemView = (
        <NotificationBrainSnackView
          brainItemState={props.brainItemState}
          item={item}
          onSubmit={closeBrainSnack}
          setBrainItemState={props.setBrainItemState}
        />
      )
      break
    }

    // Allow fall through if neither if above matches.
    default:
      // This will produce a compile error if a new itemType is added, clever Typescript!
      never(
        item,
        `Invalid stream item type in StreamItemView: ${JSON.stringify(item)}`
      )
  }

  return <>{itemView}</>
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useTimeline_timeline = {
    readonly availableFrom: string;
    readonly id: string;
    readonly unlocked: boolean;
    readonly items: ReadonlyArray<{
        readonly brainItem: {
            readonly id?: string | undefined;
        };
    }>;
    readonly " $refType": "useTimeline_timeline";
};
export type useTimeline_timeline$data = useTimeline_timeline;
export type useTimeline_timeline$key = {
    readonly " $data"?: useTimeline_timeline$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useTimeline_timeline">;
};



const node: ReaderInlineDataFragment = {
    "kind": "InlineDataFragment",
    "name": "useTimeline_timeline"
} as any;
(node as any).hash = 'eec31a4c2ab312aa72f834b067b53482';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type StreamItem_item = ({
    readonly __typename: "BrainItemStreamItem";
    readonly id: string;
    readonly brainItem: {
        readonly __typename: "BrainSnack" | "MatchQuestion" | "MultipleChoiceQuestion" | "MultipleSelectQuestion" | "OrderQuestion" | "SliderQuestion";
        readonly id?: string | undefined;
        readonly " $fragmentRefs": FragmentRefs<"BrainSnackView_item" | "QuestionView_item">;
    };
} | {
    readonly __typename: "NotificationBrainSnackStreamItem";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NotificationBrainSnackView_item">;
} | {
    readonly __typename: "NotificationStreamItem";
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NotificationView_item">;
}) & {
    readonly " $refType": "StreamItem_item";
};
export type StreamItem_item$data = StreamItem_item;
export type StreamItem_item$key = {
    readonly " $data"?: StreamItem_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StreamItem_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "StreamItem_item",
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "brainItem",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v1 /*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                            },
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "BrainSnackView_item"
                            },
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "QuestionView_item"
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "type": "BrainItemStreamItem",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NotificationBrainSnackView_item"
                    }
                ],
                "type": "NotificationBrainSnackStreamItem",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NotificationView_item"
                    }
                ],
                "type": "NotificationStreamItem",
                "abstractKey": null
            }
        ],
        "type": "StreamItem",
        "abstractKey": "__isStreamItem"
    } as any;
})();
(node as any).hash = '3c8e5c9ba1300eb812565005da8f63a0';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RoutingPrivateRoute_user = {
    readonly isOnboarding: boolean;
    readonly " $refType": "RoutingPrivateRoute_user";
};
export type RoutingPrivateRoute_user$data = RoutingPrivateRoute_user;
export type RoutingPrivateRoute_user$key = {
    readonly " $data"?: RoutingPrivateRoute_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RoutingPrivateRoute_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutingPrivateRoute_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarding",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '124ab4a116097888e7afe0835aa0bc37';
export default node;

import {
  CacheConfig,
  Environment,
  GraphQLResponse,
  Network,
  PayloadError,
  QueryResponseCache,
  RecordSource,
  RequestParameters,
  Observable,
  Store,
  UploadableMap,
  Variables,
  PayloadData,
  GraphQLSingularResponse,
} from 'relay-runtime'
import { WebService } from './services/WebService'

import { stores } from './stores'
import { handleThrottling } from './utils/handleThrottling'
import Pusher from 'pusher-js'

type PayloadErrorWithExtensions = PayloadError & {
  extensions?: {
    category?: string
  }
}

interface ChannelMessage {
  result?: {
    errors?: Error
    data: PayloadData
  }
  more: boolean
}

const fifteenMinutes = 15 * 60 * 1000
const cache = new QueryResponseCache({ size: 250, ttl: fifteenMinutes })

async function fetchQuery(
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
): Promise<GraphQLResponse> {
  const queryId = operation.text
  const isMutation = operation.operationKind === 'mutation'
  const isQuery = operation.operationKind === 'query'
  const forceFetch = cacheConfig && cacheConfig.force
  // Try to get data from cache on queries
  if (queryId) {
    const fromCache = cache.get(queryId, variables)
    if (isQuery && fromCache !== null && !forceFetch) {
      return Promise.resolve(fromCache)
    }
  }

  const token = stores.authStore.token

  let data:
    | FormData
    | { query: string | null | undefined; variables: Variables }
  const headers: Record<string, string> = {
    'X-Apollo-Client-Name': 'web-' + location.hostname,
    'X-Apollo-Client-Version': `${process.env.VERSION} (${process.env.SENTRY_RELEASE})`,
    'Accept-Language': stores.commonStore.language,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  }
  if (!uploadables) {
    data = { query: operation.text, variables }
    headers['Content-Type'] = 'application/json'
  } else {
    data = new FormData()
    data.append(
      'operations',
      JSON.stringify({ query: operation.text, variables })
    )

    // Mapping from files to where in variables they should end up.
    const mapping: Record<string, string[]> = {}
    for (const key in uploadables) {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        if (key in variables) {
          mapping[key] = ['variables.' + key]
        }

        data.append(key, uploadables[key])
      }
    }

    data.append('map', JSON.stringify(mapping))
  }

  const response = await WebService.getInstance().sendRequest<GraphQLResponse>({
    url: process.env.APP_URL + '/graphql',
    method: 'POST',
    withCredentials: true,
    headers,
    data,
  })
  if ('errors' in response.data && response.data.errors?.length) {
    for (const error of response.data.errors) {
      const errorWithExtensions = error as PayloadErrorWithExtensions
      if (errorWithExtensions.extensions?.category === 'throttling') {
        // Means we exceeded the limit of request throttling, retry when
        // we get credits again.
        return handleThrottling(response).then(() =>
          fetchQuery(operation, variables, cacheConfig, uploadables)
        )
      }
    }
  }
  // Update cache on queries
  if (isQuery && queryId && response.data) {
    cache.set(queryId, variables, response.data)
  }
  // Clear cache on mutations
  if (isMutation) {
    cache.clear()
  }
  return response.data
}

const createHandler = () => {
  let pusher: Pusher

  return (
    operation: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig
  ) => {
    let channelName: string
    let pusherAppKey: string
    return Observable.create<GraphQLSingularResponse>((sink) => {
      fetchQuery(operation, variables, cacheConfig).then((json) => {
        // Is it a (read-only) array?
        if ('length' in json) {
          sink.complete()
          return
        }

        channelName = json?.extensions?.lighthouse_subscriptions.channel ?? null
        pusherAppKey =
          json?.extensions?.lighthouse_subscriptions.pusher_app_key ?? null

        if (!channelName || !pusherAppKey) {
          sink.complete()
          return
        }

        if (!pusher) {
          const token = stores.authStore.token

          pusher = new Pusher(pusherAppKey, {
            ...(token
              ? {
                  auth: {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                }
              : {}),
            authEndpoint: process.env.APP_URL + `/graphql/subscriptions/auth`,
            enabledTransports: ['ws', 'wss'],
            wsHost: process.env.PUSHER_HOST,
          })
        }

        const channel = pusher.subscribe(channelName)

        channel.bind(`lighthouse-subscription`, (payload: ChannelMessage) => {
          const result = payload.result

          if (result && result.errors) {
            sink.error(result.errors)
          } else if (result) {
            sink.next({
              data: result.data,
            })
          }

          if (!payload.more) {
            sink.complete()
          }
        })
      })
    }).finally(() => {
      pusher?.unsubscribe(channelName)
    })
  }
}

const subscriptionHandler = createHandler()

const network = Network.create(fetchQuery, subscriptionHandler)

export const environment = new Environment({
  configName: 'PowerApp',
  network,
  store: new Store(new RecordSource()),
})

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LearnHeader_user = {
    readonly id: string;
    readonly firstLogin: boolean;
    readonly firstName: string;
    readonly fullName: string;
    readonly profileImage: string | null;
    readonly " $refType": "LearnHeader_user";
};
export type LearnHeader_user$data = LearnHeader_user;
export type LearnHeader_user$key = {
    readonly " $data"?: LearnHeader_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LearnHeader_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LearnHeader_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstLogin",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImage",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = 'b2669eab6b5e8ebc8ad7ea5b5d4fe13b';
export default node;

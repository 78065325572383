/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrderView_feedback = ({
    readonly __typename: "GenericQuestionCompletionData";
    readonly correct: boolean | null;
    readonly correctAnswers: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly userAnswers: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"AnswerOption_answer">;
    }>;
} | {
    readonly __typename: "BrainSnackCompletionData" | "MatchQuestionCompletionData" | "PollQuestionCompletionData" | "SliderQuestionCompletionData";
}) & {
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_feedback">;
    readonly " $refType": "OrderView_feedback";
};
export type OrderView_feedback$data = OrderView_feedback;
export type OrderView_feedback$key = {
    readonly " $data"?: OrderView_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrderView_feedback">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "OrderView_feedback",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_feedback"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "correct",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "correctAnswers",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerOption",
                        "kind": "LinkedField",
                        "name": "userAnswers",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "AnswerOption_answer"
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "type": "GenericQuestionCompletionData",
                "abstractKey": null
            }
        ],
        "type": "BrainItemCompletionData",
        "abstractKey": "__isBrainItemCompletionData"
    } as any;
})();
(node as any).hash = '919adaf86c46a1230c45cd7970e97ee6';
export default node;

import { ReactElement, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { handleEnterKey } from '../../../utils/handleEnterKey'
import { BrainItemState } from '../../../utils/hooks/useBrainItems'
import { PrimaryButton } from '../../common/PrimaryButton'
import { ButtonType } from './TextItemView'

interface TextItemButtonProps {
  closeDisabled: boolean
  brainItemState: BrainItemState
  onClick: (event: SyntheticEvent) => void
  buttonType: ButtonType
  inOnboarding?: boolean
  onboardingItemsLeft?: boolean
}

export function TextItemButton(props: TextItemButtonProps): ReactElement {
  const { t } = useTranslation()

  return (
    <PrimaryButton
      disabled={
        props.closeDisabled || props.brainItemState > BrainItemState.READY
      }
      onClick={props.onClick}
      onKeyPress={handleEnterKey(props.onClick)}
    >
      {props.buttonType === ButtonType.Accept && t('common.Accept')}
      {props.buttonType === ButtonType.Close &&
        (!props.inOnboarding
          ? t('streamItem.brainItem.nextItem')
          : props.onboardingItemsLeft
          ? t('streamItem.brainItem.nextOnboardingItem')
          : t('streamItem.brainItem.closeItem'))}
      {props.buttonType === ButtonType.ContinueAnyway &&
        t('streamItem.notification.continueanyway')}
    </PrimaryButton>
  )
}

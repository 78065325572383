import { observer } from 'mobx-react'
import { ReactElement, SyntheticEvent, useCallback } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'

import { BrainSnackView_item$key } from '../../../generated/BrainSnackView_item.graphql'
import { BrainItemProps } from '../../../utils/hooks/useBrainItems'
import { BrainItemCompletionInput } from '../StreamItem'

import { ButtonType, TextItemView } from './TextItemView'

export interface BrainSnackHandlers {
  inOnboarding?: boolean
  onboardingItemsLeft?: boolean

  onClose(data: BrainItemCompletionInput): void
}

export interface BrainSnackViewProps {
  item: BrainSnackView_item$key
}

export const BrainSnackView = observer(function BrainSnackView(
  props: BrainItemProps & BrainSnackViewProps & BrainSnackHandlers
): ReactElement {
  const item = useFragment(
    graphql`
      fragment BrainSnackView_item on BrainSnack {
        pages {
          ...TextItemView_pages
        }
      }
    `,
    props.item
  )

  const { onClose } = props
  const closeQuestion = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      onClose({})
    },
    [onClose]
  )

  return (
    <TextItemView
      brainItemState={props.brainItemState}
      buttonType={ButtonType.Close}
      inOnboarding={props.inOnboarding}
      onClose={closeQuestion}
      onboardingItemsLeft={props.onboardingItemsLeft}
      pages={item.pages}
      setBrainItemState={props.setBrainItemState}
    />
  )
})

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { TopicIndicator_item$key } from '../../generated/TopicIndicator_item.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import styles from './TopicIndicator.scss'

interface TopicIndicatorProps {
  item: TopicIndicator_item$key
}

export const TopicIndicator = (props: TopicIndicatorProps): ReactElement => {
  const element = useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState(false)
  const toggleOpened = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      setOpened(!opened)
    },
    [opened]
  )
  const toggleOpenedEnterHandler = useEnterKeyHandler(toggleOpened)
  const { t } = useTranslation()

  const item = useFragment(
    graphql`
      fragment TopicIndicator_item on BrainItem {
        ... on HasTopics {
          topics {
            id
            name(language: $language)
          }
        }
      }
    `,
    props.item
  )

  useLayoutEffect(() => {
    if (
      !element.current ||
      !window.MSInputMethodContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(document as any).documentMode
    ) {
      return
    }

    const size = element.current.getBoundingClientRect()
    element.current.style.bottom = `-${opened ? 0 : size.height - 22}px`
  }, [opened])

  if (!item.topics || !item.topics.length) {
    return <></>
  }

  return (
    <div
      className={classNames(styles.assetInfoContainer, {
        [styles.expanded]: opened,
      })}
      ref={element}
    >
      <div
        className={styles.handle}
        onClick={toggleOpened}
        onKeyPress={toggleOpenedEnterHandler}
      >
        <FontAwesomeIcon
          className={styles.chevron}
          icon={opened ? faChevronDown : faChevronUp}
        />
      </div>

      <div className={styles.topicInfo}>
        <div className={styles.topicInfoHeader}>
          {t('streamItem.topicInfoHeader')}:
        </div>
        {item.topics.map((topic) => (
          <div
            key={topic.id}
            dangerouslySetInnerHTML={{ __html: topic.name }}
          />
        ))}
      </div>
    </div>
  )
}

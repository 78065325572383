import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { RouteComponentProps } from 'react-router-dom'
import { useMutation } from 'relay-hooks/lib'

import { Page } from '../../../containers/Page'
import { SetPasswordMutation } from '../../../generated/SetPasswordMutation.graphql'
import { useStores } from '../../../stores'
import { Severity } from '../../../stores/authStore'
import { never } from '../../../utils/assert'
import { classNames } from '../../../utils/classNames'
import {
  Language,
  withLanguages,
  WithLanguages,
} from '../../../utils/hocs/withLanguages'
import { AuthAlert } from '../../auth/AuthAlert'
import { AuthHeader } from '../../auth/AuthHeader'
import { AcceptPrivacyPolicy } from '../../common/AcceptPrivacyPolicy'
import { PasswordInput } from '../../common/PasswordInput'
import { PrimaryButton } from '../../common/PrimaryButton'
import { SelectSingle } from '../../common/SelectSingle'

import styles from '../SetPassword.scss'

interface SetPasswordParams {
  email?: string
  language?: string
  token: string
}

type SetPasswordPageProps = WithLanguages &
  RouteComponentProps<SetPasswordParams> & {
    action: SetPasswordAction
  }

export const enum SetPasswordAction {
  Activation,
  PasswordReset,
}

export const SetPassword = withLanguages(function SetPassword(
  props: SetPasswordPageProps
): ReactElement {
  const { action, history, match } = props
  const [email, setEmail] = useState(() =>
    decodeURIComponent(match.params.email || '')
  )
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordsValidated, setPasswordsValidated] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)

  const canSubmit =
    props.action === SetPasswordAction.Activation
      ? passwordsValidated && privacyPolicyAccepted
      : passwordsValidated

  const { authStore, commonStore } = useStores()
  const { t, i18n } = useTranslation()

  const emailExists = !!match.params.email

  useEffect(() => {
    if (match.params.language) {
      commonStore.setLanguage(match.params.language).catch()
    }
  }, [commonStore, match.params.language])

  const [submit] = useMutation<SetPasswordMutation>(graphql`
    mutation SetPasswordMutation(
      $email: String!
      $token: String!
      $password: String!
      $passwordConfirmation: String!
    ) {
      setPassword(
        email: $email
        token: $token
        password: $password
        passwordConfirmation: $passwordConfirmation
      ) {
        message
        success
      }
    }
  `)

  const emailChanged = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
  }, [])
  const setLanguage = useCallback(
    (language: Language): void => {
      commonStore.setLanguage(language.code)
    },
    [commonStore]
  )
  const submitActivation = useCallback(
    (event: SyntheticEvent): void => {
      if (!canSubmit) {
        event.preventDefault()
        return
      }

      event.preventDefault()

      submit({
        variables: {
          email: email,
          password: password,
          passwordConfirmation: passwordConfirmation,
          token: match.params.token,
        },
      })
        .then((response) => {
          authStore.setAuthMessage(
            response.setPassword.message,
            response.setPassword.success ? Severity.SUCCESS : Severity.ERROR
          )
          if (response.setPassword.success) {
            history.push('/login')
          }
        })
        .catch((error) => {
          if (error.response) {
            authStore.setAuthMessage(
              error.response.data.errorCode,
              Severity.ERROR
            )
          } else {
            authStore.setAuthMessage(error.message, Severity.ERROR)
          }
        })
    },
    [
      authStore,
      canSubmit,
      email,
      history,
      match.params.token,
      password,
      passwordConfirmation,
      submit,
    ]
  )

  let header, description: string
  switch (action) {
    case SetPasswordAction.Activation:
      header = t('auth.header.create')
      description = t('auth.createText')
      break

    case SetPasswordAction.PasswordReset:
      header = t('auth.header.reset')
      description = t('auth.resetText')
      break

    default:
      never(action, 'Invalid activation action')
  }

  return (
    <>
      <AuthHeader />

      <Page narrow>
        <form
          action='#'
          method='post'
          className={styles.formContainer}
          onSubmit={submitActivation}
        >
          {/* <!--Form Name--> */}
          <div className={styles.formGroup + ' ' + styles.formHeader}>
            <div className={styles.head}>{header}</div>
            <div className={styles.sub}>{description}</div>
          </div>

          <AuthAlert />

          <SelectSingle
            icon={faGlobe}
            inside
            title={t('profile.language_' + i18n.language)}
            items={props.languages}
            onSelect={setLanguage}
            renderItem={(language, onSelect) => (
              <li
                className={classNames({
                  [styles.selected]: language.code === i18n.language,
                })}
                key={language.code}
                onClick={() => onSelect(language)}
                onKeyPress={(event) =>
                  event.key === 'Enter' && onSelect(language)
                }
                tabIndex={0}
              >
                {language.name}
              </li>
            )}
          />

          <div className={styles.formGroup}>
            <div className={styles.formGroup + ' ' + styles.prepend}>
              <div className={styles.prependIcon}>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <input
                autoComplete='username'
                className={styles.formControl + ' ' + styles.prependPadding}
                name='email'
                placeholder={t('common.Emailaddress')}
                value={email ? email : ''}
                readOnly={emailExists}
                onChange={emailChanged}
                type='email'
              />
            </div>
          </div>

          <PasswordInput
            email={email}
            onPasswordChanged={setPassword}
            onPasswordConfirmationChanged={setPasswordConfirmation}
            onPasswordsValidated={setPasswordsValidated}
            token={match.params.token}
          />

          {action === SetPasswordAction.Activation && (
            <AcceptPrivacyPolicy
              privacyPolicyAccepted={privacyPolicyAccepted}
              change={setPrivacyPolicyAccepted}
            />
          )}

          <div className={styles.formFooter}>
            <PrimaryButton
              className={styles.primaryButton}
              disabled={!canSubmit}
              type='submit'
            >
              {t('auth.action.savePassword')}
            </PrimaryButton>
          </div>
        </form>
      </Page>
    </>
  )
})

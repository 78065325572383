/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SliderView_feedback = ({
    readonly __typename: "SliderQuestionCompletionData";
    readonly averageAnswerValue: number | null;
    readonly correct: boolean | null;
    readonly correctValue: number | null;
    readonly userAnswerValue: number;
} | {
    readonly __typename: "BrainSnackCompletionData" | "GenericQuestionCompletionData" | "MatchQuestionCompletionData" | "PollQuestionCompletionData";
}) & {
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_feedback">;
    readonly " $refType": "SliderView_feedback";
};
export type SliderView_feedback$data = SliderView_feedback;
export type SliderView_feedback$key = {
    readonly " $data"?: SliderView_feedback$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SliderView_feedback">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SliderView_feedback",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FeedbackDrawer_feedback"
        },
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "averageAnswerValue",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "correct",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "correctValue",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userAnswerValue",
                    "storageKey": null
                }
            ],
            "type": "SliderQuestionCompletionData",
            "abstractKey": null
        }
    ],
    "type": "BrainItemCompletionData",
    "abstractKey": "__isBrainItemCompletionData"
} as any;
(node as any).hash = '6f492fc04e6b21c85024d31d0b3ee777';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SelectableTopic_topic = {
    readonly name: string;
    readonly percentage: number;
    readonly topicIcon: ReadonlyArray<string>;
    readonly " $refType": "SelectableTopic_topic";
};
export type SelectableTopic_topic$data = SelectableTopic_topic;
export type SelectableTopic_topic$key = {
    readonly " $data"?: SelectableTopic_topic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SelectableTopic_topic">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectableTopic_topic",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percentage",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "topicIcon",
            "storageKey": null
        }
    ],
    "type": "TopicWithScore",
    "abstractKey": null
} as any;
(node as any).hash = '94fc7b40e8224a81ed81f5f9285f7b70';
export default node;

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, usePaginationFragment, useSubscription } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { GraphQLSubscriptionConfig } from 'relay-runtime'
import { ActiveDuelRequestsList_duelRequests$key } from '../../generated/ActiveDuelRequestsList_duelRequests.graphql'
import { ActiveDuelRequestsListCancelMutation } from '../../generated/ActiveDuelRequestsListCancelMutation.graphql'
import { ActiveDuelRequestsListDeletedSubscription } from '../../generated/ActiveDuelRequestsListDeletedSubscription.graphql'
import { ActiveDuelRequestsListNewSubscription } from '../../generated/ActiveDuelRequestsListNewSubscription.graphql'
import { ActiveDuelRequestsListPaginationQuery } from '../../generated/ActiveDuelRequestsListPaginationQuery.graphql'
import { ConnectionAction } from '../../utils/hooks/useDuelConnections'
import {
  ActiveDuelsPopup,
  ActiveDuelsPopupAction,
} from '../pages/duels/ActiveDuels'
import { ActiveDuelSlot } from './ActiveDuelSlot'

interface ActiveDuelRequestsListProps {
  duelRequestConnections: string[]
  duelRequests: ActiveDuelRequestsList_duelRequests$key
  duelConnections: string[]
  localPopups: ActiveDuelsPopup[]
  setSlotsUsed: Dispatch<SetStateAction<number>>
  updateDuelRequestConnections: Dispatch<ConnectionAction>
  updateLocalPopups: Dispatch<ActiveDuelsPopupAction>
}

export function ActiveDuelRequestsList(
  props: ActiveDuelRequestsListProps
): ReactElement {
  const {
    duelRequestConnections,
    duelConnections,
    localPopups,
    setSlotsUsed,
    updateDuelRequestConnections,
    updateLocalPopups,
  } = props
  const { t } = useTranslation()
  const { data } = usePaginationFragment<
    ActiveDuelRequestsListPaginationQuery,
    ActiveDuelRequestsList_duelRequests$key
  >(
    graphql`
      fragment ActiveDuelRequestsList_duelRequests on Query
      @refetchable(queryName: "ActiveDuelRequestsListPaginationQuery") {
        myDuelRequests(first: $count, after: $cursor)
          @connection(key: "ActiveDuelRequestsList_myDuelRequests") {
          __id
          edges {
            node {
              id
              status
              ...ActiveDuelSlot_request
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...DuelRequestPopup_item
            }
          }
        }
      }
    `,
    props.duelRequests
  )

  useEffect(() => {
    if (!data.myDuelRequests?.__id) {
      return
    }

    const connection = data.myDuelRequests.__id
    updateDuelRequestConnections({ type: 'add', connection })
  }, [data.myDuelRequests?.__id, updateDuelRequestConnections])

  useEffect(() => {
    if (data?.myDuelRequests) {
      data.myDuelRequests.edges
        .map((edge) => edge.node)
        .filter((item) => item.status === 'INVITED')
        .filter(
          (item) =>
            !localPopups.find(
              (popup) =>
                popup.type === 'duel-request' && item.id === popup.popupKey
            )
        )
        .forEach((item) => {
          updateLocalPopups({
            type: 'push',
            popup: {
              type: 'duel-request',
              duelsConnectionIds: duelConnections,
              duelRequestsConnectionIds: duelRequestConnections,
              onClose: () => updateLocalPopups({ type: 'pop' }),
              item,
              popupKey: item.id,
            },
          })
        })
    }
  }, [
    localPopups,
    data.myDuelRequests,
    updateLocalPopups,
    duelConnections,
    duelRequestConnections,
  ])

  const [cancelDuelRequest] =
    useMutation<ActiveDuelRequestsListCancelMutation>(graphql`
      mutation ActiveDuelRequestsListCancelMutation(
        $id: ID!
        $duelRequestConnections: [ID!]!
      ) {
        respondToDuelRequest(id: $id, to: DELETE) {
          result {
            request {
              id @deleteEdge(connections: $duelRequestConnections)
            }
          }
        }
      }
    `)

  const cancelDuelInvite = useCallback(
    (item: string) =>
      cancelDuelRequest({
        variables: {
          id: item,
          duelRequestConnections,
        },
      }).then(() => {
        setSlotsUsed((previous) => previous - 1)
      }),
    [cancelDuelRequest, duelRequestConnections, setSlotsUsed]
  )

  const confirmCancelInvite = useCallback(
    (item: string): void => {
      updateLocalPopups({
        type: 'push',
        popup: {
          type: 'confirm-yes-no',
          onClose: () => updateLocalPopups({ type: 'pop' }),
          onConfirm: () => cancelDuelInvite(item),
          title: t('popup.confirm.cancelDuelInviteTitle'),
          message: t('popup.confirm.cancelDuelInviteMessage'),
          confirmButtonText: t('popup.confirm.delete'),
          cancelButtonText: t('popup.confirm.keep'),
        },
      })
    },
    [updateLocalPopups, t, cancelDuelInvite]
  )

  const newDuelRequestSubscriptionConfig = useMemo(
    (): GraphQLSubscriptionConfig<ActiveDuelRequestsListNewSubscription> => ({
      subscription: graphql`
        subscription ActiveDuelRequestsListNewSubscription(
          $duelRequestConnections: [ID!]!
        ) {
          duelRequestCreated
            @appendNode(
              connections: $duelRequestConnections
              edgeTypeName: "DuelRequestEdge"
            ) {
            id
            status
            ...ActiveDuelSlot_request
            ...DuelRequestPopup_item
          }
        }
      `,
      variables: {
        duelRequestConnections,
      },
      onNext(subscription) {
        if (!subscription?.duelRequestCreated) {
          return
        }

        setSlotsUsed((previous) => previous + 1)
      },
    }),
    [duelRequestConnections, setSlotsUsed]
  )
  useSubscription(newDuelRequestSubscriptionConfig)

  const deletedDuelRequestSubscriptionConfig = useMemo(
    (): GraphQLSubscriptionConfig<ActiveDuelRequestsListDeletedSubscription> => ({
      subscription: graphql`
        subscription ActiveDuelRequestsListDeletedSubscription(
          $duelRequestConnections: [ID!]!
        ) {
          duelRequestDeleted {
            id @deleteEdge(connections: $duelRequestConnections)
          }
        }
      `,
      variables: {
        duelRequestConnections,
      },
      onNext(subscription) {
        const deletedRequest = subscription?.duelRequestDeleted
        if (!deletedRequest) {
          return
        }

        setSlotsUsed((previous) => previous + 1)

        // Remove any open popup for this request.
        updateLocalPopups({
          type: 'filter',
          filter: (popup) =>
            popup.type !== 'duel-request' ||
            popup.popupKey !== deletedRequest.id,
        })
      },
    }),
    [duelRequestConnections, setSlotsUsed, updateLocalPopups]
  )
  useSubscription(deletedDuelRequestSubscriptionConfig)

  return (
    <>
      {data.myDuelRequests &&
        data.myDuelRequests?.edges
          .map((edge) => edge.node)
          .map(
            (request): ReactElement => (
              <ActiveDuelSlot
                key={request.id}
                duel={null}
                request={request}
                onClick={(event) => {
                  event.preventDefault()

                  if (request.status === 'WAITING') {
                    confirmCancelInvite(request.id)
                  }
                }}
              />
            )
          )}
    </>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ScoreIndicator_scoreInfo = {
    readonly totalScore: number;
    readonly " $refType": "ScoreIndicator_scoreInfo";
};
export type ScoreIndicator_scoreInfo$data = ScoreIndicator_scoreInfo;
export type ScoreIndicator_scoreInfo$key = {
    readonly " $data"?: ScoreIndicator_scoreInfo$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ScoreIndicator_scoreInfo">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScoreIndicator_scoreInfo",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalScore",
            "storageKey": null
        }
    ],
    "type": "ScoreInfoResponse",
    "abstractKey": null
} as any;
(node as any).hash = '43957b0a117d4f83e62252073720da4a';
export default node;

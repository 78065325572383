import { ReactElement } from 'react'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { QuestionView_feedback$key } from '../../../generated/QuestionView_feedback.graphql'
import { QuestionView_item$key } from '../../../generated/QuestionView_item.graphql'

import { never } from '../../../utils/assert'
import { BrainItemProps } from '../../../utils/hooks/useBrainItems'
import { BrainItemCompletionInput } from '../StreamItem'

import { MatchView } from './MatchView'
import { MultipleChoiceView } from './MultipleChoiceView'
import { MultipleSelectView } from './MultipleSelectView'
import { OrderView } from './OrderView'
import { SliderView } from './SliderView'

export interface QuestionHandlers {
  inDuel?: boolean
  inOnboarding?: boolean
  onboardingItemsLeft?: boolean

  onClose(): void

  onSubmit(data: BrainItemCompletionInput): void
}

export interface QuestionItem {
  feedback: QuestionView_feedback$key | null
  item: QuestionView_item$key
}

export function QuestionView(
  props: QuestionItem & BrainItemProps & QuestionHandlers
): ReactElement {
  const item = useFragment(
    graphql`
      fragment QuestionView_item on BrainItemQuestion {
        __typename
        ... on MatchQuestion {
          id
          ...MatchView_item
        }
        ... on MultipleChoiceQuestion {
          id
          ...MultipleChoiceView_item
        }
        ... on MultipleSelectQuestion {
          id
          ...MultipleSelectView_item
        }
        ... on OrderQuestion {
          id
          ...OrderView_item
        }
        ... on SliderQuestion {
          id
          ...SliderView_item
        }
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment QuestionView_feedback on BrainItemCompletionData {
        ...MatchView_feedback
        ...MultipleChoiceView_feedback
        ...MultipleSelectView_feedback
        ...OrderView_feedback
        ...SliderView_feedback
      }
    `,
    props.feedback
  )

  switch (item.__typename) {
    case 'MultipleChoiceQuestion':
      return (
        <MultipleChoiceView
          key={item.id}
          {...props}
          item={item}
          feedback={feedback}
        />
      )

    case 'MultipleSelectQuestion':
      return (
        <MultipleSelectView
          key={item.id}
          {...props}
          item={item}
          feedback={feedback}
        />
      )

    case 'OrderQuestion':
      return (
        <OrderView key={item.id} {...props} item={item} feedback={feedback} />
      )

    case 'SliderQuestion':
      return (
        <SliderView key={item.id} {...props} item={item} feedback={feedback} />
      )

    case 'MatchQuestion':
      return (
        <MatchView key={item.id} {...props} item={item} feedback={feedback} />
      )

    default:
      never(item, 'Unknown question type in stream item view')
  }
}

import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventHandler, ReactElement, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import {
  ActiveDuelSlot_duel,
  ActiveDuelSlot_duel$key,
} from '../../generated/ActiveDuelSlot_duel.graphql'
import {
  ActiveDuelSlot_request,
  ActiveDuelSlot_request$key,
} from '../../generated/ActiveDuelSlot_request.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { Avatar, AvatarContext } from '../common/Avatar'
import { ForwardIcon } from '../common/ForwardIcon'

import styles from './ActiveDuelSlot.scss'

const ACTIVE_STATUSES: Array<
  (ActiveDuelSlot_duel | ActiveDuelSlot_request)['status']
> = ['YOURTURN']

type ActiveDuelSlotProps = {
  onClick?: EventHandler<SyntheticEvent<HTMLElement>>
} & (
  | {
      duel: ActiveDuelSlot_duel$key
      request: null
    }
  | {
      duel: null
      request: ActiveDuelSlot_request$key
    }
  | {
      duel: null
      request: null
    }
)

export function ActiveDuelSlot(props: ActiveDuelSlotProps): ReactElement {
  const { t, i18n } = useTranslation()
  const enterKeyHandler = useEnterKeyHandler(props.onClick)

  const duel = useFragment(
    graphql`
      fragment ActiveDuelSlot_duel on Duel {
        players {
          rating
          user {
            id
            nameForDuelling
            isMe
            profileImageForDuelling
            hasAnonymousDuellingEnabled
          }
        }
        status
        time
      }
    `,
    props.duel
  )
  const request = useFragment(
    graphql`
      fragment ActiveDuelSlot_request on DuelRequest {
        opponent {
          rating
          user {
            id
            nameForDuelling
            profileImageForDuelling
            hasAnonymousDuellingEnabled
          }
        }
        status
        time
      }
    `,
    props.request
  )

  const opponent = duel
    ? duel.players.find((player) => !player.user.isMe)
    : request
    ? request.opponent
    : null

  const item = duel || request
  const placeholder = !item
  const active = item && ACTIVE_STATUSES.includes(item.status)

  return (
    <div
      className={classNames(styles.activeDuelSlotOuter, {
        [styles.active]: active,
        [styles.waiting]: !active,
        [styles.placeholder]: placeholder,
      })}
    >
      <div
        className={styles.activeDuelSlot}
        onClick={props.onClick}
        onKeyPress={enterKeyHandler}
        role='listitem'
        tabIndex={props.onClick ? 0 : undefined}
      >
        {opponent ? (
          <Avatar
            filename={opponent.user.profileImageForDuelling}
            name={opponent.user.nameForDuelling}
            className={styles.avatar}
            userId={opponent.user.id}
            context={AvatarContext.duelling}
            hasAnonymousDuellingEnabled={
              opponent.user.hasAnonymousDuellingEnabled
            }
          />
        ) : (
          <span className={styles.avatar} />
        )}

        <div className={styles.horizontal}>
          <div className={styles.responsive}>
            {opponent || placeholder ? (
              <>
                <span className={styles.name}>
                  {opponent?.user.nameForDuelling || t('common.Name')}
                </span>
                <span className={styles.rating}>
                  <FontAwesomeIcon icon={faStar} />{' '}
                  {Intl.NumberFormat(i18n.language).format(
                    opponent?.rating || 0
                  )}
                </span>
              </>
            ) : (
              <span className={styles.name}>{t('menu.duelWaiting')}</span>
            )}
          </div>

          <div className={styles.responsive + ' ' + styles.reverse}>
            <div className={styles.status}>
              {t(
                'activeDuels.status.' +
                  (item ? item.status.toLowerCase() : 'waiting')
              )}{' '}
            </div>

            <div className={styles.timer}>
              <FontAwesomeIcon icon={faStopwatch} /> {item ? item.time : 0}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.attachment} onClick={props.onClick}>
        {item && (
          <>
            {item.status === 'YOURTURN' && <ForwardIcon />}
            {item.status === 'WAITING' && <FontAwesomeIcon icon={faTimes} />}
          </>
        )}
      </div>
    </div>
  )
}

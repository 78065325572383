/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuestionType = "AWARENESS" | "KNOWLEDGE" | "POLL";
export type MultipleSelectView_item = {
    readonly id: string;
    readonly answerOptions: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"AnswerOption_answer">;
    }>;
    readonly questionType: QuestionType;
    readonly text: string;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackDrawer_brainItem">;
    readonly " $refType": "MultipleSelectView_item";
};
export type MultipleSelectView_item$data = MultipleSelectView_item;
export type MultipleSelectView_item$key = {
    readonly " $data"?: MultipleSelectView_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MultipleSelectView_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "MultipleSelectView_item",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "AnswerOption",
                "kind": "LinkedField",
                "name": "answerOptions",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AnswerOption_answer"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "questionType",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "language",
                        "variableName": "language"
                    }
                ],
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackDrawer_brainItem"
            }
        ],
        "type": "MultipleSelectQuestion",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'c93a7a03506836132040386e2925454e';
export default node;

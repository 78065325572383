import { observer } from 'mobx-react'
import { SyntheticEvent, useCallback } from 'react'
import { useStores } from '../../stores'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { AvatarContainer, AvatarContainerProps } from './AvatarContainer'

export const EditableAvatar = observer(function EditableAvatar(
  props: Omit<AvatarContainerProps, 'onClick' | 'onKeyPress' | 'tabIndex'>
) {
  const { commonStore } = useStores()

  const openAvatarPopup = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      commonStore.openPopup({ type: 'avatar' })
    },
    [commonStore]
  )

  return (
    <AvatarContainer
      className={props.className}
      onClick={openAvatarPopup}
      onKeyPress={useEnterKeyHandler(openAvatarPopup)}
      tabIndex={0}
    >
      {props.children}
    </AvatarContainer>
  )
})

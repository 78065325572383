const translations = {
  popup: {
    confirm: {
      delete: 'Entfernen',
      keep: 'Abbrechen',
      cancelDuelInviteTitle: 'Entfernen?',
      cancelDuelInviteMessage: 'Sind Sie sicher?',
    },
  },
  header: {
    'welcome-message': 'Hallo {{name}}!',
    welcome: 'Willkommen.',
    welcomeBack: 'Willkommen zurück.',
  },
  learn: {
    instruction: 'Wählen Sie die Themen aus, die Sie spielen möchten.',
    practiceSelection: 'Üben Sie diese Auswahl',
    selectAll: 'Alle auswählen',
    deselectAll: 'Auswahl entfernen',
    wellDone: 'Gut gemacht!',
  },
  duels: {
    title: 'Duell-Bewertung: {{score}}',
    readyToDuel: 'Sind Sie bereit für das Duell?',
    roundsOverview: 'Übersicht über die Duell-Runden',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Vorherige Seite',
      doneText: 'Brainsnack abgeschlossen',
      browseNext: 'Nächste Seite',
    },
    brainItem: {
      correct: 'Richtig',
      incorrect: 'Falsch',
      completed: 'Sie haben eine Frage abgeschlossen',
      doneText: 'Fertig',
      openItem: 'Starten',
      closeItem: 'Schließen',
      topics: 'Themen',
      nextItem: 'Weiter üben',
      showProgress: 'Ihre Fortschritte anzeigen',
    },
    order: {
      questionTitle: 'Reihenfolge',
      helpText: 'Ziehen Sie die Antworten in die richtige Reihenfolge',
      correct: '{{num}} von {{max}} korrekt',
    },
    match: {
      questionTitle: 'Match',
      showingCorrectAnswer: 'Die richtige Antwort anzeigen.',
      helpText: 'Ziehen Sie die Antworten an die richtige Stelle',
      correct: '{{num}} von {{max}} korrekt',
      showCorrectAnswer: 'Richtige Antwort anzeigen',
      showMyAnswer: 'Zeige meine Antwort',
    },
    slider: {
      doneText: 'Erledigt',
      questionTitle: 'Schieber',
      helpText: 'Ziehen Sie den Schieberegler an die richtige Position',
    },
    poll: {
      questionTitle: 'Umfrage',
      resultinfo: 'This is what others have answered',
      doneText: 'Umfrage abgeschlossen',
      helpText: 'Wählen Sie eine Antwort',
    },
    notification: {
      doneText: 'Benachrichtigung gelesen',
      title: 'Benachrichtigung',
      pauze: 'Kann ich fortfahren?',
      continueanyway: 'Trotzdem fortsetzen',
      continueLearningText:
        'Sie üben mit zusätzlichen Fragen. Sie erhalten weniger Punkte dafür, da Sie bereits auf dem neuesten Stand sind.',
      panelbuttonlabel: 'Zusätzliche Übung stoppen',
    },
    multipleselect: {
      correct: '{{num}} von {{max}} richtig',
      incorrect: ', {{incorrect}} falsch',
      doneText: 'Frage abgeschlossen',
      questionTitle: 'Frage',
      helpText: 'Wählen Sie eine oder mehrere Antworten',
    },
    multiplechoice: {
      doneText: 'Frage abgeschlossen',
      questionTitle: 'Frage',
      helpText: 'Wählen Sie eine Antwort',
    },
    duelItem: {
      duel: 'Duell',
      draw: 'Gleichstand',
      invite: 'Herausforderung zum Duell',
      acceptDuel: 'Duell akzeptieren',
      declineDuel: 'Duell ablehnen',
      yourTurn: 'Sie sind dran',
      opponentTurn: 'Gegner an der Reihe',
      youWon: 'Sie haben gewonnen!',
      youLost: 'Sie haben verloren',
      cancelled: 'Das Duell wurde storniert',
      closeItem: 'Schließen',
      showDetails: 'Rundeninformationen',
      playItem: 'Spielrunde {{roundnumber}}',
      duelAccepted:
        'Sie haben eine Herausforderung zum Duell von {{name}} akzeptiert.',
      duelDeclined:
        'Sie haben eine Herausforderung zum Duell von {{name}} abgelehnt.',
      duelStarted: 'Das Duell mit {{name}} kann beginnen. Viel Glück!',
      duelInfoInvitation: 'Sie werden zu einem Duell herausgefordert.',
      duelInfoMain:
        'Eine kurze Wissensschlacht in 5 Runden. Gewinnen Sie, indem Sie schnell und richtig antworten. Gewinnen Sie die meisten Runden und Sie gewinnen das Duell und erhöhen Ihre Bewertung. Beachten Sie, dass das Zeitlimit für eine Runde 72 Stunden beträgt.',
      viewInvitation: 'Herausforderung sehen',
      versusLabel: 'gegen',
      started: 'Starten!',
      doneText: 'Duell abgeschlossen',
      tooLate: 'Zeit überschritten!',
      practiceWhileWaiting: 'Übe während du wartest',
      practice: 'Üben Sie weiter',
      subheading: {
        waitForOpponent: 'Warten Sie, bis Ihr Gegner antwortet',
        yourTurn: 'Sind Sie bereit?',
        timeOutOpponent: 'Ihr Gegner hat zu lange gebraucht, um zu antworten',
        timeOutPlayer: 'Sie haben zu lange gebraucht, um zu antworten',
        timeOutBoth: 'Sie haben beide zu lange gebraucht, um zu antworten',
        wellDone: 'Gut gemacht, weiter so!',
        betterNextTime: 'Viel Glück beim nächsten Mal!',
      },
      roundNumber: 'Runde {{number}}',
      duelRequest: 'Neue Duell-Herausforderung!',
      duelRequestText: '{{name}} fordert Sie zu einem Duell heraus!',
      tooLateTurn: 'Zu spät',
    },
    topicPin: {
      title: 'Thema anpinnen',
      pinenabled:
        'Sie haben {{- topiclabel}} angepinnt, wodurch Ihnen nur Fragen zu diesem Thema vorgelegt werden. Die Fragen zu diesem Thema werden öfter wiederholt, sodass Sie diese üben können. Wenn Sie auch wieder Fragen über andere Themen erhalten möchten, drücken Sie einfach noch einmal auf das angepinnte Thema.',
      pindisabled:
        'Das Thema ist jetzt nicht mehr angepinnt. Ab jetzt werden Ihnen wieder Fragen über alle Themen vorgelegt.',
      continue: 'Weiter',
      dontshowagain: 'Nicht mehr anzeigen',
      selectTopics: 'Themen auswählen',
      selectTopicsPractice:
        'Wählen Sie die Themen aus, mit denen Sie üben möchten.',
    },
    topicInfoHeader: 'Themen',
  },
  login: {
    succesful: 'Anmeldung erfolgreich.',
  },
  menu: {
    menu: 'Menü',
    duel: 'Duell',
    duels: 'Duelle',
    newDuel: 'Neues Duell',
    activeDuels: 'Aktive Duelle',
    duelVersus: 'VS',
    ranking: 'Duellrangliste',
    rating: 'Duellbewertung',
    profile: 'Profil',
    autoDuelLabel: 'Duell automatisch starten',
    'help-and-settings': 'Hilfe & Einstellungen',
    challengeplayer: 'Duellpartner suchen',
    pickplayer: 'Einen Kollegen auswählen',
    randomplayer: 'Beliebiger Gegner',
    duelWaiting: 'Gegner wird gesucht ...',
    quickDuelFailed: 'Sie haben bereits ausstehende Einladungen',
    duelsNotAvailable:
      'Die Duell-Funktion steht Ihnen noch nicht zur Verfügung.',
    duelsNotAvailableExplanation:
      'Möchten Sie Duelle mit Kollegen spielen? Stellen Sie eine Anfrage an Ihre(n) Vorgesetzte(n).',
    duelsNotEnabled:
      'Möchten Sie Duelle mit Kollegen spielen? Aktivieren Sie die Duell-Option unten.',
    duelsNotEnabledDisclaimer:
      'Ihr Foto und Name werden für Ihre Kollegen sichtbar sein.',
    enableDuelling: 'Duelle mit Kollegen aktivieren!',
    duellingEnabledLabel: 'An Duellen teilnehmen',
    completedDuels: 'Abgeschlossen',
    select: 'Auswählen',
    settings: 'Einstellungen',
    practice: 'Üben',
    aboutDuels: {
      heading: 'Was ist eine Duell-Einladung?',
      explanation1: 'Sie wurden zu einem Duell herausgefordert.',
      explanation2:
        'Ein Duell ist ein kurzer Wissenstest gegen einen Kollegen in 5 Runden. Sie gewinnen eine Runde, wenn Sie die Frage richtig und schneller als Ihr Kollege beantworten. Wenn Sie mehr Runden gewinnen als Ihr Gegenspieler, gewinnen Sie das Duell und klettern Sie in der Rangfolge nach oben.',
      attention: 'Hinweis:',
      explanation3: 'Spielen Sie jede Runde innerhalb von 7 Tagen!',
    },
    leaveAlert: {
      title: 'Sind Sie sicher?',
      text: '<p>Sind Sie sicher, dass Sie nicht mehr üben möchten?</p><p>Ihre Fortschritte wurden gespeichert.</p>',
      confirm: 'Beenden',
      continue: 'Weiter üben',
    },
  },
  ranking: {
    title: 'Duellrangliste',
    subtitle:
      'Diese Liste zeigt Ihre Platzierung im Vergleich zu Ihren Kollegen und Kolleginnen.',
    yourPosition: 'Ihre Platzierung ({{rank}})',
    topPositions: 'Top-Platzierungen',
    loadMore: 'Mehr laden',
    empty: 'Es gibt keine Rangfolge. Beginnen Sie ein neues Duell!',
    notRanked:
      'Beenden Sie ein Duell, um in die Rangfolge aufgenommen zu werden!',
  },
  searchFeedback: {
    title: 'Kollegensuche',
    oneResult: '{{amount}} Kollege gefunden.',
    searchMessage: '{{amount}} Kollegen gefunden.',
    overflowMessage:
      'Nur die {{amount}} treffendsten Suchergebnisse werden angezeigt.',
  },
  searchDuel: {
    title: 'Duellpartner',
    rankingposition: 'Rang {{rank}}',
    noduelpossible: 'Duell nicht möglich',
    duelstillactive: 'Bereits ein aktives Duell',
    invitationsentondate: 'Herausforderung am {{date}} gesendet',
    searchPlaceholder: 'Namensuche',
    randomOpponent:
      'Wir suchen nach Ihrem nächsten Gegner. Sie können diesen Bildschirm schließen. Wir werden Sie benachrichtigen, wenn Ihr Duell bereit ist.',
    emptyListSearch: 'Es liegen keine Suchergebnisse vor. ',
    emptyList:
      'Leider ist niemand verfügbar.<br>Versuchen Sie es zu einem späteren Zeitpunkt. ',
  },
  activeDuels: {
    title: 'Ein verfügbares Duell spielen.',
    subtitle: '{{num}}/{{max}} Duelle sind aktiv',
    status: {
      draw: 'Gleichstand',
      lost: 'Verloren',
      won: 'Gewonnen',
      expired: 'Abgelaufen',
      invited: 'Eingeladen',
      waiting: 'Wartend',
      yourturn: 'Spielen',
    },
    emptyListNew:
      'Es steht kein Duell zur Verfügung. Beginnen Sie ein neues Duell!',
    emptyListCompleted:
      'Alle Duelle beendet. Gut gemacht!<br>Gönnen Sie sich eine Tasse Kaffee als Belohnung. ',
  },
  completedDuels: {
    title: 'Betrachten Sie Ihre beendeten Duelle.',
    empty:
      'Sie haben noch keine Duelle beendet.<br>Beginnen Sie ein neues Duell!',
  },
  error: {
    internetdown: 'Die Verbindung mit dem Internet wurde unterbrochen!',
    versionConflict: {
      title: 'Neue Version',
      text: 'Es gibt eine neue Version dieser Anwendung. Um sicherzustellen, dass alle über die neueste Version verfügen, werden wir jetzt ein Update auf die neueste Version durchführen. ',
      refresh: 'Zur neuesten Version gehen ',
    },
    refreshButton: 'Zum Aktualisieren klicken',
    internetup: 'Die Verbindung mit dem Internet wurde hergestellt.',
    timeoutajax: 'Die Zeit für die Serveranfrage wurde überschritten.',
    404: 'Server nicht gefunden (HTTP 404).',
    500: 'Interner Serverfehler (HTTP 500).',
    unknown: 'Unbekannter Fehler',
    tryReconnect: 'Verbindung wird erneut hergestellt....',
    alreadyAnswered: 'Kapitel wurde bereits beantwortet.',
    unavailableOffline: {
      title: 'Keine Verbindung',
      subtitle: 'Es besteht derzeit keine Verbindung',
      explanation:
        'Für die Nutzung der App ist eine Verbindung zum Server erforderlich. Es besteht derzeit keine Verbindung zum Server. Versuchen Sie, eine Verbindung zum Internet herzustellen, oder versuchen Sie es zu einem späteren Zeitpunkt noch einmal. ',
    },
  },
  common: {
    CheckAnswers: 'Antworten prüfen',
    Answer: 'Meine Antwort speichern',
    Hello: 'Hallo',
    back: 'Zurück',
    CheckAnswer: 'Antwort prüfen',
    Emailaddress: 'E-Mail-Adresse',
    Name: 'Name',
    Checking: 'Überprüfung...',
    Close: 'Schließen',
    browsePrevious: 'Zurück',
    browseNext: 'Weiter',
    Accept: 'Akzeptieren',
    'Log out': 'Abmelden',
    Help: 'Hilfe',
    Correct: 'Richtig',
    Incorrect: 'Falsch',
    CorrectNotChosen: 'Wäre richtig gewesen',
    Round: 'Runde',
    Version: 'Version',
    selected: 'Ausgewählt',
    'No Rating': 'Noch nicht bewertet',
    'No Rank': 'Keine Platzierung',
    NoUserSelection: 'Keine Benutzer gefunden',
    monthNames: 'Jan Feb März Apr Mai Jun Jul Aug Sept Okt Nov Dez',
    or: 'oder',
    on: 'Ein',
    off: 'Aus',
  },
  profile: {
    title: 'Profil',
    subtitle: 'Passen Sie Ihr Profil hier an.',
    pictureEdit: 'Betrachten oder bearbeiten Sie Ihr Foto.',
    'picture-submit': 'Diesen Schnitt verwenden',
    pictureConfirmDelete: 'Möchten Sie Ihr Profilbild wirklich löschen?',
    profilePageTitle: 'Ihr Profil verwalten.',
    noEditDataText: 'Sie können diese Daten nicht ändern.',
    'file-submit': 'Hochladen',
    pictureCancel: 'Löschen',
    'picture-nofileselected': 'Sie haben keine Datei ausgewählt.',
    'picture-nofile': 'Es gibt keine Datei.',
    'picture-invalidsize':
      'Das Bild ist zu groß. Die maximale Breite oder Höhe kann 2600 Pixel nicht überschreiten.',
    'picture-notimage':
      'Die von Ihnen ausgewählte Datei scheint kein Bild zu sein.',
    passwordEdit: 'Passwort ändern',
    'picture-saved': 'Ihr Profilbild wurde gespeichert!',
    passwordTitle: 'Passwort ändern',
    passwordText:
      'Ändern Sie Ihr Passwort durch Eingabe Ihres aktuellen Passworts und zweimalige Eingabe Ihres neuen Passworts. Das neue Passwort muss mindestens 6 Zeichen lang sein.',
    'password-old': 'Aktuelles Passwort',
    'password-new': 'Neues Passwort',
    'password-repeat': 'Neues Passwort bestätigen',
    'password-submit': 'Neues Passwort speichern',
    'password-oldpasswordmismatch': 'Ihr aktuelles Passwort war nicht korrekt',
    'password-newpasswordmismatch':
      'Das bestätigte Passwort stimmte nicht überein.',
    'password-passwordtooshort':
      'Das neue Passwort muss mindestens 6 Zeichen lang sein.',
    'password-passwordsaved': 'Ihr Passwort wurde gespeichert!',
    profilePictureTitle: 'Profilbild',
    profilePictureText: 'Dieses Foto ist für Ihre Kollegen sichtbar.',
    generalDataTitle: 'Persönliche Daten',
    generalDataText:
      'Sie können nicht alle personenbezogenen Daten selbst ändern.',
    profileLanguagePreference: 'Bevorzugte Sprache',
    profileLanguageChoice: 'Sprachwahl',
  },
  auth: {
    header: {
      login: 'Anmeldung',
      expired: 'Link nicht mehr gültig',
      remind: 'Passwort vergessen?',
      create: 'Aktivieren Sie Ihr Konto',
      reset: 'Aktualisieren Sie Ihr Passwort',
      welcome: 'Willkommen!',
    },
    field: {
      email: 'E-Mail-Adresse',
      password: 'Passwort',
      oldPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      newRepeatPassword: 'Wiederholen Sie Ihr neues Passwort',
      rememberMe: 'Meine Daten merken',
    },
    action: {
      itemcompleted: 'Sie haben ein Kapitel abgeschlossen',
      updateprofile: 'Profil aktualisieren',
      sendLink: 'Link zum Zurücksetzen des Passworts senden',
      backToLogin: 'Ich möchte mich anmelden',
      sendActivateLink: 'Konto aktivieren',
      alreadyActivatedLogin:
        'Ich habe das Konto bereits aktiviert und möchte mich anmelden',
      savePassword: 'Mein neues Passwort speichern',
      newPassword: 'Ihr neues Passwort',
      repeatNewPassword: 'Wiederholen Sie Ihr neues Passwort',
      newPasswordPolicyIntro: 'Passwortstärke',
    },
    selfActivate: {
      header: 'Aktivieren Sie Ihr Konto',
    },
    logintext: 'Anmeldung',
    remindText:
      'Kein Problem. Wir senden Ihnen eine E-Mail, sodass Sie Ihr Passwort ändern können.',
    resetText: 'Sie können Ihr neues Passwort hier einrichten.',
    createText:
      'Willkommen! Bitte erstellen Sie ein neues Passwort und dann haben Sie alles, um sich anzumelden.',
    passwords: {
      user: 'Leider können wir keinen Benutzer mit dieser E-Mail-Adresse finden.',
      token: 'Dieser Token für die Passwortzurücksetzung ist ungültig.',
      sent: ' Es wurde eine E-Mail mit einem Link zur Passwortzurücksetzung versandt.',
      reset: 'Passwort wurde zurückgesetzt!',
      forgot: 'Ich kenne mein Passwort nicht',
      passwordExpiredTitle: 'Passwort ist abgelaufen',
      submitPasswordExpiredForm: 'Neues Passwort speichern',
      passwordExpiredText:
        'Ihr Passwort ist älter als {{days}} Tage und daher abgelaufen. Sie müssen mithilfe des unten stehenden Formulars ein neues Passwort einrichten. Danach können Sie mit der PowerApp weiterlernen.',
    },
  },
  faq: {
    header: 'Häufig gestellte Fragen',
    q1: 'Die PowerApp ist eine Mikrolern-App. Was bedeutet das?',
    a1: 'Mikrolernen bedeutet, dass es effizienter ist, in kurzen Sitzungen zu lernen, wenn man langfristig mehr Wissen behalten möchte.',
    q2: 'Weshalb ist meine Punktzahl manchmal niedriger als erwartet?',
    a2: 'Ihr Punktzahl nimmt ab, denn mit der Zeit vergessen Sie, was Sie gelernt haben.',
    q3: 'Wann nimmt meine Punktzahl ab?',
    a3: 'Wenn Sie Fragen falsch beantwortet haben oder über längere Zeit inaktiv sind.',
    q4: 'Weshalb wiederholen sich dieselben Lernbausteine?',
    a4: 'Weil eine gelegentliche Wiederholung der effektivste Weg ist, zu lernen.',
    q5: 'Was ist ein Duell?',
    a5: 'Ein Wissensquiz mit 5 Fragen, das Sie gegen einen Kollegen spielen.',
    q6: 'Weshalb muss ich in einem Duell warten, bis ich an der Reihe bin?',
    a6: 'Sie müssen warten, bis Ihr Kollege seine Runde beendet hat.',
    q7: 'Wo kann ich weitere Informationen und Antworten auf meine Fragen finden?',
    a7: 'Die Schaltfläche unten führt Sie zu unserer Website.',
    toTheFaq: 'Zu den FAQ',
    toTheWebsite: 'Zur Website',
  },
}

export default translations

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faPoll } from '@fortawesome/pro-solid-svg-icons/faPoll'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ChangeEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { classNames } from '../../../../utils/classNames'
import { SliderInput } from '../../../common/SliderInput'
import { AnswerOptionFeedback } from './AnswerOption'

import styles from './Slider.scss'

const CONTAINER_PADDING = 23
const LABEL_WIDTH = 150
const HALF_LABEL_WIDTH = LABEL_WIDTH / 2

export interface SliderProps {
  disabled?: boolean
  feedback?: AnswerOptionFeedback
  label: string
  min: number
  max: number
  onChange?: (value: number) => void
  sliderContainer: RefObject<HTMLElement>
  step: number
  value: number
  valueToText(value: number): string
}

export function Slider(props: SliderProps): ReactElement {
  const slider = useRef<HTMLInputElement>(null)
  const [offset, setOffset] = useState(0)

  useEffect((): void => {
    // Calculate and update the position.
    const valuePercentage = (props.value - props.min) / (props.max - props.min)
    const trackWidth = slider.current ? slider.current.offsetWidth : 0

    // All this fuckery with the label width etc is to make sure the text stays
    // within the box and doesn't overflow.
    const position =
      trackWidth * valuePercentage + CONTAINER_PADDING - HALF_LABEL_WIDTH
    const sliderContainerWidth = props.sliderContainer.current
      ? props.sliderContainer.current.offsetWidth - 2 * CONTAINER_PADDING
      : 0

    setOffset(
      Math.min(
        Math.max(position, 0),
        sliderContainerWidth + 2 * CONTAINER_PADDING - LABEL_WIDTH
      )
    )
  }, [props.value, props.min, props.max, props.sliderContainer])

  const onSliderValueChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const value = parseInt(event.target.value, 10)
      if (isNaN(value)) {
        return
      }

      props.onChange && props.onChange(value)
    },
    [props]
  )

  return (
    <div
      className={classNames(styles.sliderContainer, {
        [styles.disabled]: props.disabled,
        [styles.correct]: props.feedback === AnswerOptionFeedback.CORRECT,
        [styles.incorrect]: props.feedback === AnswerOptionFeedback.INCORRECT,
      })}
    >
      <label
        className={styles.sliderLabel}
        style={{
          transform: `translateX(${offset + HALF_LABEL_WIDTH}px)`,
        }}
        aria-hidden={true}
      >
        {props.valueToText(props.value)}
      </label>

      <div className={styles.sliderBox}>
        {props.disabled && typeof props.feedback === 'undefined' && (
          <div className={styles.loadingIndicator}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.CORRECT && (
          <div className={styles.correctIndicator}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.SUBMITTED && (
          <div className={styles.pollIndicator}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.INCORRECT && (
          <div className={styles.incorrectIndicator}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {props.feedback === AnswerOptionFeedback.POLL && (
          <div className={styles.pollIndicator}>
            <FontAwesomeIcon icon={faPoll} />
          </div>
        )}

        <SliderInput
          disabled={props.disabled}
          label={props.label}
          max={props.max}
          min={props.min}
          onChange={onSliderValueChanged}
          ref={slider}
          step={props.step}
          value={props.value}
          valueToText={props.valueToText}
        />
      </div>
    </div>
  )
}

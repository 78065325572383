/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Asset_asset = {
    readonly translation: {
        readonly mime: string;
        readonly url: string;
    };
    readonly " $refType": "Asset_asset";
};
export type Asset_asset$data = Asset_asset;
export type Asset_asset$key = {
    readonly " $data"?: Asset_asset$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Asset_asset">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Asset_asset",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "language",
                    "variableName": "language"
                }
            ],
            "concreteType": "AssetTranslation",
            "kind": "LinkedField",
            "name": "translation",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mime",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Asset",
    "abstractKey": null
} as any;
(node as any).hash = '1974440bb21f85504c770fd9fd642017';
export default node;

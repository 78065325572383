import { ReactElement } from 'react'

import styles from './DateIndicator.scss'

interface DateIndicatorProps {
  description: string
}

export function DateIndicator(props: DateIndicatorProps): ReactElement {
  return (
    <div className={styles.dateIndicatorOuter}>
      <div className={styles.dateIndicatorCircle} />

      {props.description}
    </div>
  )
}

import { faArrowCircleDown } from '@fortawesome/pro-regular-svg-icons/faArrowCircleDown'
import { faArrowCircleUp } from '@fortawesome/pro-regular-svg-icons/faArrowCircleUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'

import { BottomDrawer } from '../../containers/BottomDrawer'
import { FeedbackDrawer_brainItem$key } from '../../generated/FeedbackDrawer_brainItem.graphql'
import { FeedbackDrawer_feedback$key } from '../../generated/FeedbackDrawer_feedback.graphql'
import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { BrainItemState } from '../../utils/hooks/useBrainItems'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './FeedbackDrawer.scss'
import { BrainItemType } from './BrainItemType'

interface FeedbackDrawerProps {
  brainItem: FeedbackDrawer_brainItem$key
  brainItemState: BrainItemState
  feedback: FeedbackDrawer_feedback$key
  heading: string
  inDuel: boolean
  inOnboarding: boolean
  onBoardingItemsLeft: boolean

  onClose(): void
}

export function FeedbackDrawer(
  props: PropsWithChildren<FeedbackDrawerProps>
): ReactElement {
  const element = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const brainItem = useFragment(
    graphql`
      fragment FeedbackDrawer_brainItem on BrainItem {
        __typename
        ... on HasQuestionType {
          questionType
        }
        ...BrainItemType_item
      }
    `,
    props.brainItem
  )

  const feedback = useFragment(
    graphql`
      fragment FeedbackDrawer_feedback on BrainItemCompletionData {
        __typename
        feedbackText(language: $language)
        scoreChange {
          __typename
        }
        ... on GenericQuestionCompletionData {
          correct
        }
        ... on MatchQuestionCompletionData {
          correct
        }
        ... on SliderQuestionCompletionData {
          correct
        }
      }
    `,
    props.feedback
  )

  const hasAnyFeedbackToLookAt =
    // Knowledge questions with mistakes show you what the correct answer was.
    (brainItem.questionType === 'KNOWLEDGE' &&
      'correct' in feedback &&
      feedback.correct === false) ||
    // Poll questions for specific question types show what others answered.
    (brainItem.questionType === 'POLL' &&
      (brainItem.__typename === 'MultipleChoiceQuestion' ||
        brainItem.__typename === 'MultipleSelectQuestion' ||
        brainItem.__typename === 'SliderQuestion'))

  // number of milliseconds after which the feedback drawer automatically expands
  const feedbackDrawerExpandTime = hasAnyFeedbackToLookAt ? 5000 : 2500

  useEffect(() => {
    let alive = true

    setTimeout(() => alive && setExpanded(true), feedbackDrawerExpandTime)

    return () => {
      alive = false
    }
  }, [feedbackDrawerExpandTime])

  useLayoutEffect(() => {
    if (
      !element.current ||
      !window.MSInputMethodContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(document as any).documentMode
    ) {
      return
    }

    const size = element.current.getBoundingClientRect()
    element.current.style.bottom =
      props.brainItemState === BrainItemState.WELL_DONE
        ? '-100%'
        : expanded
        ? '0'
        : `-${size.height - 76}px`
  }, [expanded, props.brainItemState])

  const onExpandButtonClicked = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])
  const onExpandButtonKeyPress = useEnterKeyHandler(onExpandButtonClicked)
  const onCloseKeyPress = useEnterKeyHandler(props.onClose)

  if (!feedback || feedback.__typename === 'BrainSnackCompletionData') {
    return <></>
  }

  const buttonText =
    'correct' in feedback &&
    feedback.correct &&
    feedback.scoreChange &&
    !props.inOnboarding
      ? t('streamItem.brainItem.showProgress')
      : props.inDuel
      ? t('streamItem.duelItem.closeItem')
      : !props.inOnboarding
      ? t('streamItem.brainItem.nextItem')
      : props.onBoardingItemsLeft
      ? t('streamItem.brainItem.nextOnboardingItem')
      : t('streamItem.brainItem.closeItem')

  return (
    <BottomDrawer
      className={classNames(styles.drawer, {
        [styles.expanded]: expanded,
        [styles.hiding]: props.brainItemState === BrainItemState.WELL_DONE,
      })}
      ref={element}
    >
      <PrimaryButton
        className={styles.button}
        icon
        onClick={onExpandButtonClicked}
        onKeyPress={onExpandButtonKeyPress}
        tabIndex={0}
      >
        <FontAwesomeIcon
          icon={expanded ? faArrowCircleDown : faArrowCircleUp}
        />
      </PrimaryButton>

      <BrainItemType brainItem={brainItem} />

      <div className={styles.heading}>{props.heading}</div>

      {feedback.feedbackText && feedback.feedbackText.length > 0 && (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: feedback.feedbackText,
          }}
        />
      )}

      <div className={styles.buttonContainer}>
        <PrimaryButton
          onClick={props.onClose}
          onKeyPress={onCloseKeyPress}
          tabIndex={0}
        >
          {buttonText}
        </PrimaryButton>
      </div>

      {props.children && (
        <div className={styles.childrenContainer}>{props.children}</div>
      )}
    </BottomDrawer>
  )
}

import { observer } from 'mobx-react'
import { ReactElement, useEffect } from 'react'

import { useStores } from '../stores'
import { Popup } from '../stores/commonStore'
import { ChildrenProps } from '../utils/types'

const DUEL_POPUPS: ReadonlyArray<Popup['type']> = [
  'duel-finalizing',
  'duel-request',
  'new-duel',
]

export const OnlineMenu = observer(function OnlineMenu(
  props: ChildrenProps
): ReactElement {
  const { commonStore } = useStores()

  useEffect((): void => {
    if (commonStore.online) {
      if (commonStore.hasPopup('internet-required')) {
        commonStore.closePopup()
      }

      return
    }

    // We're offline, open a popup and navigate away from the duelling page.
    while (
      commonStore.popups.length > 0 &&
      DUEL_POPUPS.includes(commonStore.popups[0].type)
    ) {
      commonStore.closePopup()
    }

    commonStore.openPopup({ type: 'internet-required' }, true)
  }, [commonStore, commonStore.online])

  return <>{props.children}</>
})

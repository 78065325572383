import { ChangeEvent, ReactElement, useCallback } from 'react'

import styles from './ToggleSwitch.scss'

type ToggleSwitchProps = {
  currentValue: boolean
  disabled?: boolean
  onSwitchChanged(newValue: boolean): void
}

export function ToggleSwitch(props: ToggleSwitchProps): ReactElement {
  const { onSwitchChanged } = props

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSwitchChanged(event.target.checked)
    },
    [onSwitchChanged]
  )

  return (
    <>
      <label className={styles.toggleSwitch}>
        <input
          disabled={props.disabled}
          type='checkbox'
          onChange={handleChange}
          checked={props.currentValue}
        />

        <span className={styles.toggleSwitchSlider} />
      </label>
    </>
  )
}

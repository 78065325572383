import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './FaqItem.scss'

interface FaqItemProps {
  q: string
  a: string
}

export function FaqItem(props: FaqItemProps): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.faqContainer}>
        <div className={styles.q}>{t('faq.q')}</div>
        <div className={styles.qText}>{props.q}</div>
      </div>
      <div className={styles.faqContainer}>
        <div className={styles.aBalloon}>{props.a}</div>
        <div className={styles.a}>{t('faq.a')}</div>
      </div>
    </>
  )
}

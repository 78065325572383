import { observer } from 'mobx-react'
import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { Prompt } from 'react-router'
import { Link } from 'react-router-dom'
import { fetchQuery, useMutation } from 'relay-hooks'

import { Header } from '../../../containers/Header'
import { Page } from '../../../containers/Page'
import { environment } from '../../../environment'
import {
  StreamItemQuery,
  StreamItemQueryResponse,
} from '../../../generated/StreamItemQuery.graphql'
import {
  StreamSubmitBrainItemMutation,
  StreamSubmitBrainItemMutationResponse,
  StreamSubmitBrainItemMutationVariables,
} from '../../../generated/StreamSubmitBrainItemMutation.graphql'
import {
  StreamSubmitStreamItemMutation,
  StreamSubmitStreamItemMutationResponse,
  StreamSubmitStreamItemMutationVariables,
} from '../../../generated/StreamSubmitStreamItemMutation.graphql'
import { useStores } from '../../../stores'
import { assert } from '../../../utils/assert'
import {
  BrainItemState,
  useBrainItems,
} from '../../../utils/hooks/useBrainItems'
import { useStream } from '../../../utils/hooks/useStream'
import { scrollToTop } from '../../../utils/scrollToTop'
import { BackIcon } from '../../common/BackIcon'
import { GraphQlError } from '../../common/GraphQlError'
import { PrimaryButton } from '../../common/PrimaryButton'
import { ItemHeader } from '../../learn/ItemHeader'
import { ScoreIndicator } from '../../learn/ScoreIndicator'
import { StreamItem } from '../../learn/StreamItem'
import { StreamItemHeader } from '../../learn/StreamItemHeader'
import { WellDoneOverlay } from '../../learn/WellDoneOverlay'

import styles from './Stream.scss'
import { OnlineMenu } from '../../../containers/OnlineMenu'

export const enum CheckAnswerMode {
  SHOW_FEEDBACK,
  AUTO_CLOSE,
}

export type StreamSubmissionVariables =
  | Omit<StreamSubmitBrainItemMutationVariables, 'language'>
  | Omit<StreamSubmitStreamItemMutationVariables, 'language'>
export type StreamSubmissionVariablesWithLanguage =
  | StreamSubmitBrainItemMutationVariables
  | StreamSubmitStreamItemMutationVariables

export const Stream = observer(function Stream(): ReactElement {
  const { learnStore } = useStores()
  const { i18n } = useTranslation()

  const query = graphql`
    query StreamItemQuery($topics: [ID!]!, $language: String!) {
      streamItem(topics: $topics) {
        ... on Node {
          id
        }
        ...StreamItem_item
        ...StreamItemHeader_streamItem
      }
      scoreInfo {
        ...ScoreIndicator_scoreInfo
      }
    }
  `
  const fetcher = useCallback((): Promise<StreamItemQueryResponse> => {
    return fetchQuery<StreamItemQuery>(environment, query, {
      topics: learnStore.topicPin.slice(),
      language: i18n.language,
    })
      .toPromise()
      .then((item) => {
        assert(typeof item !== 'undefined')

        return item
      })
  }, [i18n.language, learnStore.topicPin, query])

  const [brainItemState, setBrainItemState] = useBrainItems()
  const [currentItemResult] = useStream(
    fetcher,
    brainItemState,
    setBrainItemState
  )
  const [feedback, setFeedback] = useState<
    | (StreamSubmitBrainItemMutationResponse &
        StreamSubmitStreamItemMutationResponse)
    | null
  >(null)

  // The two mutations here should be combined into one with a nullable $brainItemId
  // input, but they're a workaround for a bug in Lighthouse:
  // https://github.com/nuwave/lighthouse/issues/1861
  const [submitBrainItem, submitBrainItemResult] =
    useMutation<StreamSubmitBrainItemMutation>(graphql`
      mutation StreamSubmitBrainItemMutation(
        $id: ID!
        $brainItemId: ID!
        $topicPin: [ID!]!
        $data: BrainItemCompletionInput!
        $language: String!
      ) {
        feedback: completeStreamItem(
          id: $id
          brainItemId: $brainItemId
          topicPin: $topicPin
          data: $data
        ) {
          ...StreamItem_feedback
          ...WellDoneOverlay_feedback
          ... on GenericQuestionCompletionData {
            correct
          }
          ... on MatchQuestionCompletionData {
            correct
          }
          ... on SliderQuestionCompletionData {
            correct
          }
          brainItem {
            ... on MatchQuestion {
              questionType
            }
            ... on MultipleChoiceQuestion {
              questionType
            }
            ... on MultipleSelectQuestion {
              questionType
            }
            ... on OrderQuestion {
              questionType
            }
            ... on SliderQuestion {
              questionType
            }
          }
        }
      }
    `)
  const [submitStreamItem, submitStreamItemResult] =
    useMutation<StreamSubmitStreamItemMutation>(graphql`
      mutation StreamSubmitStreamItemMutation(
        $id: ID!
        $topicPin: [ID!]!
        $data: BrainItemCompletionInput!
        $language: String!
      ) {
        feedback: completeStreamItem(
          id: $id
          topicPin: $topicPin
          data: $data
        ) {
          ...StreamItem_feedback
          ...WellDoneOverlay_feedback
          ... on GenericQuestionCompletionData {
            correct
          }
          ... on MatchQuestionCompletionData {
            correct
          }
          ... on SliderQuestionCompletionData {
            correct
          }
          brainItem {
            ... on MatchQuestion {
              questionType
            }
            ... on MultipleChoiceQuestion {
              questionType
            }
            ... on MultipleSelectQuestion {
              questionType
            }
            ... on OrderQuestion {
              questionType
            }
            ... on SliderQuestion {
              questionType
            }
          }
        }
      }
    `)

  const [submissionData, setSubmissionData] = useState<
    [StreamSubmissionVariables, CheckAnswerMode] | null
  >(null)

  const submitItem = useCallback(
    (data: StreamSubmissionVariables, mode: CheckAnswerMode): void => {
      const dataWithLanguage: StreamSubmissionVariablesWithLanguage = {
        ...data,
        language: i18n.language,
      }
      setSubmissionData([dataWithLanguage, mode])
      setBrainItemState(BrainItemState.CHECKING)

      const promise =
        'brainItemId' in dataWithLanguage && dataWithLanguage.brainItemId
          ? submitBrainItem({ variables: dataWithLanguage })
          : submitStreamItem({ variables: dataWithLanguage })
      promise
        .then((response) => {
          if (mode === CheckAnswerMode.SHOW_FEEDBACK && response.feedback) {
            setBrainItemState(BrainItemState.FEEDBACK)
            setFeedback(response)
          } else {
            setBrainItemState(BrainItemState.COMPLETED)
          }
        })
        .catch(() => setBrainItemState(BrainItemState.READY))
    },
    [i18n.language, setBrainItemState, submitBrainItem, submitStreamItem]
  )

  const retrySubmission = useCallback((): void => {
    if (submissionData) {
      submitItem(...submissionData)
    }
  }, [submitItem, submissionData])

  const closeStreamItem = useCallback((): void => {
    setBrainItemState(BrainItemState.COMPLETED)
    setFeedback(null)
  }, [setBrainItemState])

  const submissionResult = submitBrainItemResult ?? submitStreamItemResult
  const closeQuestion = useCallback((): void => {
    if (
      // Knowledge type questions
      (submissionResult.data?.feedback &&
        'correct' in submissionResult.data.feedback &&
        submissionResult.data.feedback.correct) ||
      // Subjective type questions
      (submissionResult.data?.feedback &&
        submissionResult.data?.feedback?.brainItem &&
        submissionResult.data?.feedback?.brainItem?.questionType ===
          'AWARENESS')
    ) {
      setBrainItemState(BrainItemState.WELL_DONE)
    } else {
      closeStreamItem()
    }

    scrollToTop()
  }, [closeStreamItem, setBrainItemState, submissionResult.data])

  return (
    <>
      <Prompt when={true} message='' />

      <Header>
        <div className={styles.row}>
          <div className={styles.col}>
            <PrimaryButton
              component={Link}
              to='/'
              icon
              className={styles.backButton}
            >
              <BackIcon className={styles.chevronSpacing} />
            </PrimaryButton>
          </div>

          <div className={styles.col}>
            {currentItemResult && (
              <ScoreIndicator scoreInfo={currentItemResult.scoreInfo} />
            )}
          </div>
        </div>
      </Header>

      <OnlineMenu>
        <Page>
          {currentItemResult ? (
            <StreamItemHeader streamItem={currentItemResult.streamItem} />
          ) : (
            <ItemHeader />
          )}

          {currentItemResult && (
            <StreamItem
              brainItemState={brainItemState}
              closeQuestion={closeQuestion}
              feedback={feedback?.feedback ?? null}
              key={currentItemResult.streamItem.id}
              item={currentItemResult.streamItem}
              setBrainItemState={setBrainItemState}
              submitStreamItem={submitItem}
            />
          )}

          {submissionResult.error && (
            <GraphQlError
              error={submissionResult.error}
              retry={retrySubmission}
            />
          )}

          {brainItemState === BrainItemState.WELL_DONE &&
            feedback?.feedback && (
              <WellDoneOverlay
                feedback={feedback.feedback}
                onClose={closeStreamItem}
              />
            )}
        </Page>
      </OnlineMenu>
    </>
  )
})

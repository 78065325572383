import { Dispatch, Reducer, useMemo, useReducer } from 'react'
import { never } from '../assert'

export type ConnectionAction =
  | { type: 'add'; connection: string }
  | { type: 'remove'; connection: string }

interface DuelConnections {
  duelConnections: string[]
  duelRequestConnections: string[]
  updateDuelConnections: Dispatch<ConnectionAction>
  updateDuelRequestConnections: Dispatch<ConnectionAction>
}

export function useDuelConnections(): DuelConnections {
  const [duelConnections, updateDuelConnections] = useConnections()
  const [duelRequestConnections, updateDuelRequestConnections] =
    useConnections()

  return useMemo(
    () => ({
      duelConnections,
      duelRequestConnections,
      updateDuelConnections,
      updateDuelRequestConnections,
    }),
    [
      duelConnections,
      duelRequestConnections,
      updateDuelConnections,
      updateDuelRequestConnections,
    ]
  )
}

function useConnections(): [string[], Dispatch<ConnectionAction>] {
  return useReducer<Reducer<string[], ConnectionAction>>((previous, action) => {
    switch (action.type) {
      case 'add':
        return [...previous, action.connection]
      case 'remove':
        return previous.filter((connection) => connection !== action.connection)
      default:
        never(action, `Invalid connection action: ${JSON.stringify(action)}`)
    }
  }, [])
}

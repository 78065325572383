/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TopicCategorySelector_topicCategories = ReadonlyArray<{
    readonly id: string;
    readonly description: string;
    readonly percentage: number;
    readonly topics: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "TopicCategorySelector_topicCategories";
}>;
export type TopicCategorySelector_topicCategories$data = TopicCategorySelector_topicCategories;
export type TopicCategorySelector_topicCategories$key = ReadonlyArray<{
    readonly " $data"?: TopicCategorySelector_topicCategories$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TopicCategorySelector_topicCategories">;
}>;



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "plural": true
        },
        "name": "TopicCategorySelector_topicCategories",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "language",
                        "variableName": "language"
                    }
                ],
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentage",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "TopicWithScore",
                "kind": "LinkedField",
                "name": "topics",
                "plural": true,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "TopicCategoryWithScore",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'e38b7e0e01226c24a5c8a101718c6a52';
export default node;

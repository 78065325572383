import { observer } from 'mobx-react'
import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import { RouteComponentProps } from 'react-router-dom'
import { useQuery } from 'relay-hooks'
import { Page } from '../../../containers/Page'
import { SelectTopicScoreInfoQuery } from '../../../generated/SelectTopicScoreInfoQuery.graphql'
import { useStores } from '../../../stores'
import { GraphQlError } from '../../common/GraphQlError'

import { PrimaryButton } from '../../common/PrimaryButton'
import { SecondaryButton } from '../../common/SecondaryButton'
import { LearnHeader } from '../../learn/LearnHeader'
import { SelectableTopic } from '../../learn/SelectableTopic'
import { TopicCategorySelector } from '../../learn/TopicCategorySelector'

import styles from './SelectTopic.scss'
import { OnlineMenu } from '../../../containers/OnlineMenu'

export const SelectTopic = observer(function SelectTopic(
  props: RouteComponentProps
): ReactElement {
  const { learnStore } = useStores()
  const { i18n, t } = useTranslation()

  const scoreInfo = useQuery<SelectTopicScoreInfoQuery>(
    graphql`
      query SelectTopicScoreInfoQuery($language: String!) {
        me {
          ...LearnHeader_user
        }
        scoreInfo {
          totalScore
          topicCategories {
            ...TopicCategorySelector_topicCategories
            topics {
              id
              ...SelectableTopic_topic
            }
          }
          topics {
            id
            ...SelectableTopic_topic
          }
        }
      }
    `,
    { language: i18n.language },
    { fetchPolicy: 'store-and-network' }
  )

  const onCheckBoxClicked = (topic: string): void => {
    learnStore.topicPin.some((item) => item == topic)
      ? learnStore.unpinTopic(topic)
      : learnStore.pinTopic(topic)
  }

  const onClick = useCallback(() => {
    props.history.push('/practice')
  }, [props.history])

  const hasTopicCategories =
    scoreInfo.data && scoreInfo.data.scoreInfo.topicCategories.length > 0
  const showTopicsPerCategory =
    (scoreInfo.data && scoreInfo.data?.scoreInfo?.topicCategories.length > 1) ??
    false

  const topicsToShow = useMemo(
    () =>
      (showTopicsPerCategory
        ? scoreInfo.data?.scoreInfo?.topicCategories[
            learnStore.selectedTopicCategoryIndex
          ].topics
        : hasTopicCategories
        ? scoreInfo.data?.scoreInfo.topicCategories[0].topics
        : scoreInfo.data?.scoreInfo.topics) ?? [],
    [
      showTopicsPerCategory,
      scoreInfo.data?.scoreInfo.topicCategories,
      scoreInfo.data?.scoreInfo.topics,
      learnStore.selectedTopicCategoryIndex,
      hasTopicCategories,
    ]
  )

  const onSelectAll = useCallback(() => {
    if (!scoreInfo.data) {
      return
    }

    if (showTopicsPerCategory) {
      scoreInfo.data?.scoreInfo?.topicCategories.forEach((topicCategory) => {
        topicCategory.topics
          .filter(
            (topic) => !learnStore.topicPin.some((pin) => topic.id === pin)
          )
          .forEach((topic) => learnStore.pinTopic(topic.id))
      })
    } else {
      topicsToShow
        .filter((topic) => !learnStore.topicPin.some((pin) => topic.id === pin))
        .forEach((topic) => learnStore.pinTopic(topic.id))
    }
  }, [scoreInfo.data, showTopicsPerCategory, learnStore, topicsToShow])

  const startPracticeAllTopics = () => {
    onSelectAll()
    onClick()
  }

  const onDeselectAll = useCallback(() => {
    if (!scoreInfo.data) {
      return
    }

    learnStore.setTopicPin([])
  }, [learnStore, scoreInfo.data])

  const changeTopicCategory = useCallback(
    (index: number) => {
      learnStore.setTopicCategory(index)
      learnStore.setTopicPin(
        scoreInfo.data?.scoreInfo?.topicCategories?.[index].topics.map(
          (topic) => topic.id
        ) ?? []
      )
    },
    [learnStore, scoreInfo.data?.scoreInfo?.topicCategories]
  )

  let anyNotSelected = false

  useEffect(() => {
    if (scoreInfo.data) {
      learnStore.initializeTopicPin(topicsToShow.map((topic) => topic.id))
    }
  }, [learnStore, scoreInfo.data, topicsToShow])

  return (
    <>
      <LearnHeader
        score={scoreInfo.data?.scoreInfo?.totalScore || 0}
        user={scoreInfo.data?.me ?? null}
      />
      <OnlineMenu>
        <Page wide>
          <div className={styles.topicPage}>
            {scoreInfo.data &&
              (showTopicsPerCategory ? (
                <TopicCategorySelector
                  topicCategories={scoreInfo.data?.scoreInfo?.topicCategories}
                  changeCurrentTopicCategoryIndex={changeTopicCategory}
                />
              ) : (
                <p className={styles.instruction}>{t('learn.instruction')}</p>
              ))}

            {scoreInfo.data && (
              <>
                <ul
                  className={
                    showTopicsPerCategory
                      ? styles.themeTopicList
                      : styles.topicList
                  }
                >
                  {topicsToShow.map((topic) => {
                    const selected = learnStore.topicPin.some(
                      (pin) => pin === topic.id
                    )
                    const topicLabelElement = `label${topic.id}`
                    if (!selected) {
                      anyNotSelected = true
                    }

                    return (
                      <li className={styles.topic} key={topic.id}>
                        <SelectableTopic
                          key={topic.id}
                          selected={selected}
                          onClick={() => onCheckBoxClicked(topic.id)}
                          topic={topic}
                          ariaLabelledby={topicLabelElement}
                          themeTopic={showTopicsPerCategory}
                        />
                      </li>
                    )
                  })}
                </ul>

                <PrimaryButton
                  className={styles.button}
                  disabled={learnStore.topicPin.length === 0}
                  onClick={onClick}
                  tabIndex={0}
                >
                  {t('learn.practiceSelection')}
                </PrimaryButton>

                <SecondaryButton
                  className={styles.button}
                  onClick={
                    showTopicsPerCategory
                      ? startPracticeAllTopics
                      : anyNotSelected
                      ? onSelectAll
                      : onDeselectAll
                  }
                  tabIndex={0}
                >
                  {t(
                    showTopicsPerCategory
                      ? 'learn.practiceAll'
                      : anyNotSelected
                      ? 'learn.selectAll'
                      : 'learn.deselectAll'
                  )}
                </SecondaryButton>
              </>
            )}

            {!scoreInfo.data && scoreInfo.error && (
              <GraphQlError error={scoreInfo.error} retry={scoreInfo.retry} />
            )}
          </div>
        </Page>
      </OnlineMenu>
    </>
  )
})

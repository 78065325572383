/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TopicIndicator_item = {
    readonly topics?: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }> | undefined;
    readonly " $refType": "TopicIndicator_item";
};
export type TopicIndicator_item$data = TopicIndicator_item;
export type TopicIndicator_item$key = {
    readonly " $data"?: TopicIndicator_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TopicIndicator_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "language"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicIndicator_item",
    "selections": [
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Topic",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "language",
                                    "variableName": "language"
                                }
                            ],
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "HasTopics",
            "abstractKey": "__isHasTopics"
        }
    ],
    "type": "BrainItem",
    "abstractKey": "__isBrainItem"
} as any;
(node as any).hash = '27d6fba8946afb9dae7208a7b3e600c2';
export default node;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TimeLineItem_item = {
    readonly id: string;
    readonly brainItem: {
        readonly __typename: "BrainSnack" | "MatchQuestion" | "MultipleChoiceQuestion" | "MultipleSelectQuestion" | "OrderQuestion" | "SliderQuestion";
        readonly id?: string | undefined;
    };
    readonly completedAt: string | null;
    readonly favorited: boolean;
    readonly introAsset: {
        readonly " $fragmentRefs": FragmentRefs<"Asset_asset">;
    } | null;
    readonly startedAt: string | null;
    readonly summaryText: string | null;
    readonly " $refType": "TimeLineItem_item";
};
export type TimeLineItem_item$data = TimeLineItem_item;
export type TimeLineItem_item$key = {
    readonly " $data"?: TimeLineItem_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TimeLineItem_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "language"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "TimeLineItem_item",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brainItem",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v0 /*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "favorited",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "introAsset",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Asset_asset"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "language",
                        "variableName": "language"
                    }
                ],
                "kind": "ScalarField",
                "name": "summaryText",
                "storageKey": null
            }
        ],
        "type": "OnboardingItem",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '60d5363d18068536f084c833afb793c5';
export default node;

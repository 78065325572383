import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { SliderView_feedback$key } from '../../../generated/SliderView_feedback.graphql'
import { SliderView_item$key } from '../../../generated/SliderView_item.graphql'

import { handleEnterKey } from '../../../utils/handleEnterKey'
import {
  BrainItemProps,
  BrainItemState,
} from '../../../utils/hooks/useBrainItems'
import { PrimaryButton } from '../../common/PrimaryButton'
import { FeedbackDrawer } from '../FeedbackDrawer'

import { AnswerOptionFeedback } from './elements/AnswerOption'
import { Question } from './elements/Question'
import { QuestionInstruction } from './elements/QuestionInstruction'
import { Slider, SliderProps } from './elements/Slider'
import { TextualSlider } from './elements/TextualSlider'
import styles from './Question.scss'
import { QuestionHandlers } from './QuestionView'

export interface SliderViewProps {
  feedback: SliderView_feedback$key | null
  item: SliderView_item$key
}

export function SliderView(
  props: SliderViewProps & BrainItemProps & QuestionHandlers
): ReactElement {
  const { t, i18n } = useTranslation()
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.language),
    [i18n.language]
  )

  const item = useFragment(
    graphql`
      fragment SliderView_item on SliderQuestion {
        questionType
        sliderType {
          __typename
          ... on NumericalSlider {
            maxValue
            minValue
            postfix(language: $language)
            prefix(language: $language)
            step
          }
          ... on TextualSlider {
            values(language: $language)
          }
        }
        text(language: $language)
        ...FeedbackDrawer_brainItem
      }
    `,
    props.item
  )
  const feedback = useFragment(
    graphql`
      fragment SliderView_feedback on BrainItemCompletionData {
        __typename
        ...FeedbackDrawer_feedback
        ... on SliderQuestionCompletionData {
          averageAnswerValue
          correct
          correctValue
          userAnswerValue
        }
      }
    `,
    props.feedback
  )

  const { sliderType } = item
  const min =
    sliderType.__typename === 'NumericalSlider' ? sliderType.minValue : 0
  const max =
    sliderType.__typename === 'NumericalSlider'
      ? sliderType.maxValue
      : sliderType.values.length - 1
  const step = sliderType.__typename === 'NumericalSlider' ? sliderType.step : 1
  const startValue =
    sliderType.__typename === 'NumericalSlider'
      ? min + Math.floor((max - min) / step / 2) * step
      : Math.round(sliderType.values.length / 2) % 2 === 0
      ? Math.round(sliderType.values.length / 2)
      : Math.floor(sliderType.values.length / 2)

  const sliderValueToText = useCallback(
    (value: number): string => {
      if (sliderType.__typename === 'NumericalSlider') {
        return `${sliderType.prefix}${numberFormatter.format(value)}${
          sliderType.postfix
        }`
      }

      return sliderType.values[value]
    },
    [numberFormatter, sliderType]
  )

  const [sliderValue, setSliderValue] = useState(
    feedback?.__typename === 'SliderQuestionCompletionData' &&
      feedback.userAnswerValue
      ? feedback.userAnswerValue
      : startValue
  )
  const sliderContainer = useRef<HTMLDivElement>(null)

  const onButtonClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      if (props.brainItemState === BrainItemState.READY) {
        props.onSubmit({
          sliderValue,
        })

        return
      }

      if (props.brainItemState === BrainItemState.FEEDBACK) {
        props.onClose()
      }
    },
    [props, sliderValue]
  )

  const answerData =
    props.brainItemState > BrainItemState.CHECKING ? feedback : undefined

  if (answerData) {
    if (answerData.__typename !== 'SliderQuestionCompletionData') {
      throw new Error(
        'Invalid answer data for slider question: ' + JSON.stringify(answerData)
      )
    }

    if (item.questionType === 'KNOWLEDGE') {
      if (answerData.correct === null || answerData.correctValue === null) {
        throw new Error(
          'Invalid answer data for non-poll slider question: ' +
            JSON.stringify(answerData)
        )
      }
    } else if (
      item.questionType === 'POLL' &&
      answerData.averageAnswerValue === null
    ) {
      throw new Error(
        'Invalid answer data for poll slider question: ' +
          JSON.stringify(answerData)
      )
    }
  }

  const ShowSlider = (sliderProps: SliderProps) => {
    return sliderType.__typename === 'TextualSlider' ? (
      <TextualSlider {...sliderProps} values={sliderType.values} />
    ) : (
      <Slider {...sliderProps} />
    )
  }

  return (
    <div className={styles.question}>
      <Question text={item.text} />
      <QuestionInstruction text={t('streamItem.slider.helpText')} />

      <form
        aria-label={t('streamItem.slider.helpText')}
        aria-readonly={props.brainItemState !== BrainItemState.READY}
      >
        <div ref={sliderContainer}>
          {sliderType.__typename === 'TextualSlider' ? (
            <TextualSlider
              disabled={props.brainItemState !== BrainItemState.READY}
              feedback={
                answerData
                  ? item.questionType === 'KNOWLEDGE'
                    ? answerData.correct
                      ? AnswerOptionFeedback.CORRECT
                      : AnswerOptionFeedback.INCORRECT
                    : AnswerOptionFeedback.SUBMITTED
                  : undefined
              }
              label={t(`streamItem.slider.helpText`)}
              max={max}
              min={min}
              onChange={setSliderValue}
              sliderContainer={sliderContainer}
              step={step}
              value={sliderValue}
              values={sliderType.values}
              valueToText={sliderValueToText}
            />
          ) : (
            <Slider
              disabled={props.brainItemState !== BrainItemState.READY}
              feedback={
                answerData
                  ? item.questionType === 'KNOWLEDGE'
                    ? answerData.correct
                      ? AnswerOptionFeedback.CORRECT
                      : AnswerOptionFeedback.INCORRECT
                    : AnswerOptionFeedback.SUBMITTED
                  : undefined
              }
              label={t(`streamItem.slider.helpText`)}
              max={max}
              min={min}
              onChange={setSliderValue}
              sliderContainer={sliderContainer}
              step={step}
              value={sliderValue}
              valueToText={sliderValueToText}
            />
          )}

          {answerData &&
            answerData.correct === false &&
            answerData.correctValue !== null && (
              <ShowSlider
                disabled={true}
                feedback={AnswerOptionFeedback.CORRECT}
                label={t(`streamItem.slider.helpText`)}
                max={max}
                min={min}
                sliderContainer={sliderContainer}
                step={step}
                value={answerData.correctValue}
                valueToText={sliderValueToText}
              />
            )}
          {answerData && answerData.averageAnswerValue !== null && (
            <ShowSlider
              disabled={true}
              feedback={AnswerOptionFeedback.POLL}
              label={t(`streamItem.slider.helpText`)}
              max={max}
              min={min}
              sliderContainer={sliderContainer}
              step={step}
              value={answerData.averageAnswerValue}
              valueToText={sliderValueToText}
            />
          )}
        </div>
      </form>

      {props.brainItemState < BrainItemState.FEEDBACK || !answerData ? (
        <div className={styles.buttonContainer}>
          <PrimaryButton
            disabled={props.brainItemState === BrainItemState.CHECKING}
            onClick={onButtonClicked}
            onKeyPress={handleEnterKey(onButtonClicked)}
          >
            {props.brainItemState === BrainItemState.READY &&
              (item.questionType === 'KNOWLEDGE'
                ? t('common.CheckAnswer')
                : t('common.Answer'))}
            {props.brainItemState === BrainItemState.CHECKING &&
              t('common.Checking')}
            {props.brainItemState === BrainItemState.FEEDBACK &&
              t('common.Close')}
          </PrimaryButton>
        </div>
      ) : (
        <FeedbackDrawer
          brainItem={item}
          brainItemState={props.brainItemState}
          feedback={answerData}
          heading={
            item.questionType === 'POLL'
              ? t('streamItem.slider.averageAnswer')
              : item.questionType === 'AWARENESS'
              ? t('streamItem.slider.doneText')
              : answerData.correct
              ? t('streamItem.brainItem.correct')
              : props.inOnboarding
              ? t('streamItem.brainItem.almostCorrect')
              : t('streamItem.brainItem.incorrect')
          }
          inDuel={!!props.inDuel}
          inOnboarding={!!props.inOnboarding}
          onBoardingItemsLeft={!!props.onboardingItemsLeft}
          onClose={props.onClose}
        />
      )}
    </div>
  )
}

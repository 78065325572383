/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MainMenuChangePasswordMutationVariables = {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
};
export type MainMenuChangePasswordMutationResponse = {
    readonly changePassword: {
        readonly message: string;
        readonly success: boolean;
    };
};
export type MainMenuChangePasswordMutation = {
    readonly response: MainMenuChangePasswordMutationResponse;
    readonly variables: MainMenuChangePasswordMutationVariables;
};



/*
mutation MainMenuChangePasswordMutation(
  $oldPassword: String!
  $password: String!
  $passwordConfirmation: String!
) {
  changePassword(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
    message
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "oldPassword"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "password"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "passwordConfirmation"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "oldPassword",
                    "variableName": "oldPassword"
                },
                {
                    "kind": "Variable",
                    "name": "password",
                    "variableName": "password"
                },
                {
                    "kind": "Variable",
                    "name": "passwordConfirmation",
                    "variableName": "passwordConfirmation"
                }
            ],
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "changePassword",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MainMenuChangePasswordMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MainMenuChangePasswordMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c4f8ffa3bedb04f5036709494792a0dc",
            "id": null,
            "metadata": {},
            "name": "MainMenuChangePasswordMutation",
            "operationKind": "mutation",
            "text": "mutation MainMenuChangePasswordMutation(\n  $oldPassword: String!\n  $password: String!\n  $passwordConfirmation: String!\n) {\n  changePassword(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {\n    message\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'acac8cddeffda9f7c61a88e125fa0fae';
export default node;

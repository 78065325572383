const translations = {
  popup: {
    confirm: {
      delete: 'Șterge',
      keep: 'Păstrează',
      cancelDuelInviteTitle: 'Șterge provocarea la duel?',
      cancelDuelInviteMessage: 'Vreți să stergeți provocarea Dvs. la duel?',
    },
  },
  header: {
    'welcome-message': 'Bună, {{name}}!',
    welcome: 'Bine ați venit.',
    welcomeBack: 'Bine ați revenit.',
  },
  learn: {
    instruction: 'Alege subiectele pe care le dorești să le joci.',
    practiceSelection: 'Exersează această selecție',
    selectAll: 'Selectează tot',
    deselectAll: 'Sterge selecția',
    wellDone: 'Bravo!',
  },
  duels: {
    title: 'Calificativ duel: {{score}}',
    readyToDuel: 'Sunteți gata pentru duel?',
    roundsOverview: 'Prezentarea rundelor de duel',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Pagina anterioară',
      doneText: 'Brainsnack finisat',
      browseNext: 'Pagina următoare',
    },
    brainItem: {
      correct: 'Corect',
      incorrect: 'Incorect ',
      completed: 'Răspundeți la întrebare ',
      doneText: 'Finalizat ',
      openItem: 'Început',
      closeItem: 'Închidere ',
      topics: 'Teme',
      nextItem: 'Continuă să exersezi',
      showProgress: 'Afișează-ți evoluția',
    },
    order: {
      questionTitle: 'Ordine',
      helpText: 'Mutați răspunsurile în secvența corectă',
      correct: 'Corect {{num}} la {{max}} ',
    },
    match: {
      questionTitle: 'Întrebare',
      showingCorrectAnswer: 'Vezi răspunsurile corecte.',
      helpText: 'Mutați răspunsurile la locul corect',
      correct: 'Corect {{num}} la {{max}}',
      showCorrectAnswer: 'Arată răspunsul corect',
      showMyAnswer: 'Arată răspunsul meu ',
    },
    slider: {
      doneText: 'Finalizat',
      questionTitle: 'Glisor',
      helpText: 'Mutați cursorul în poziția corectă',
    },
    poll: {
      questionTitle: 'Sondaj',
      resultinfo: 'Acestea sunt răspunsurile altora',
      doneText: 'Ați răspuns la o întrebare din sondaj',
      helpText: 'Alegeți un răspuns ',
    },
    notification: {
      title: 'Notificare',
      pauze: 'Pot continua?',
      continueanyway: 'Continuare oricum',
      doneText: 'Notificare citită',
      continueLearningText:
        'Exercitați cu întrebări suplimentare. Veți primi mai puține puncte pentru ele, deoarece ați ajuns deja la numărul necesar.',
      panelbuttonlabel: 'Încetarea exercițiilor suplimentare',
    },
    multipleselect: {
      correct: 'Corect {{num}} la {{max}}',
      incorrect: ', incorect {{incorrect}}',
      doneText: 'Întrebarea e finalizată',
      questionTitle: 'Întrebare',
      helpText: 'Alegeți unul sau mai multe răspunsuri',
    },
    multiplechoice: {
      doneText: 'Întrebarea e finalizată',
      questionTitle: 'Întrebare',
      helpText: 'Alegeți un răspuns',
    },
    duelItem: {
      duel: 'Duel',
      draw: 'Egalitate ',
      invite: 'Provocare duel',
      acceptDuel: 'Acceptare duel ',
      declineDuel: 'Respingere duel ',
      yourTurn: 'Este rândul Dvs.',
      opponentTurn: 'Rândul oponentului',
      youWon: 'Ați câștigat! ',
      youLost: 'Ați pierdut ',
      cancelled: 'Duelul e amânat ',
      closeItem: 'Închidere',
      showDetails: 'Detalii privind rundă',
      playItem: 'Jucați runda {{roundnumber}}',
      duelAccepted: 'Ați acceptat o invitație de duel de la {{name}}.',
      duelDeclined: 'Ați respins o invitație de duel de la {{name}}.',
      duelStarted: 'Duelul cu {{name}} să înceapă. Mult noroc!',
      duelInfoInvitation: 'Sunteți provocat la un duel. ',
      duelInfoMain:
        'O luptă scurtă pentru cunoaștere în 5 runde.  Câștigați, răspunzând rapid și corect. Câștigați cât mai multe runde, câștigați duelul și creșteți-vă scorul. Rețineți, că aveți limită de 72 ore pe rundă.',
      viewInvitation: 'Vizualizează invitația',
      versusLabel: 'împotriva',
      started: 'Start! ',
      doneText: 'Duelul e finalizat ',
      tooLate: 'Timpul a expirat! ',
      practiceWhileWaiting: 'Exercitați în timp ce așteptați',
      practice: 'Continuați să exercitați ',
      subheading: {
        waitForOpponent: 'Așteptați răspunsul adversarului ',
        yourTurn: 'Sunteți gata?',
        timeOutOpponent:
          'Adversarul Dvs a avut nevoie de prea mult timp pentru a răspunde',
        timeOutPlayer: 'Ați avut nevoie de prea mult timp pentru a răspunde',
        timeOutBoth:
          'Ambii ați avut nevoie de prea mult timp pentru a răspunde',
        wellDone: 'Bun lucru, continuați în acest spirit!',
        betterNextTime: 'Mult noroc data viitoare!',
      },
      roundNumber: 'Runda {{number}}',
      duelRequest: 'O nouă provocare la duel!',
      duelRequestText: '{{name}} te provoacă la duel',
      tooLateTurn: 'Prea târziu',
    },
    topicPin: {
      title: 'Atașați subiectul',
      pinenabled:
        'Ați atașat {{- topiclabel}} și vedeți doar elemente legate de acest subiect. Elementele legate de acest subiect sunt repetate mai des pentru a vă permite să vă exercitați. Faceți clic din nou pe subiectul atașat pentru a include întrebări legate de alte subiecte.',
      pindisabled:
        'Tema deja nu e atașată. De acum înainte veți vedea din nou elemente pentru toate subiectele.',
      continue: 'Continuare',
      dontshowagain: 'Nu mai arătați asta',
      selectTopics: 'Alege subiectele',
      selectTopicsPractice: 'Alege subiectele la care dorești să exersezi.',
    },
    topicInfoHeader: 'Subiecte',
  },
  login: { succesful: 'Intrare cu succes' },
  menu: {
    menu: 'Meniu',
    duel: 'Duel',
    duels: 'Dueluri ',
    newDuel: 'Duel nou ',
    activeDuels: 'Dueluri active ',
    duelVersus: 'ÎMPOTRIVA ',
    ranking: 'Clasificare',
    rating: 'Evaluarea duelului ',
    profile: 'Profil (cont) ',
    autoDuelLabel: 'Dueluri automate',
    'help-and-settings': 'Ajutor și setări ',
    challengeplayer: 'Provocați un coleg',
    pickplayer: 'Alegeți un coleg',
    randomplayer: 'Mă simt norocos ',
    duelWaiting: 'Căutare adversar...',
    quickDuelFailed: 'Aveți deja invitații în așteptare',
    duelsNotAvailable: 'Duelul nu este încă disponibil pentru tine.',
    duelsNotAvailableExplanation:
      'Dorești să te duelezi cu colegii? Solicici acest lucru administratorului tău de {{appName}}.',
    duelsNotEnabled:
      'Dorești să te duelezi cu colegii? Activează opțiunea de duel mai jos.',
    duelsNotEnabledDisclaimer:
      'Fotografia și numele tău vor fi vizibile pentru colegii tăi.',
    enableDuelling: 'Activează duelul cu colegii!',
    duellingEnabledLabel: 'Participă la dueluri',
    completedDuels: 'Finalizat',
    select: 'Selectează',
    settings: 'Setări',
    practice: 'Exersează',
    aboutDuels: {
      heading: 'Ce este o invitație la duel?',
      explanation1: 'Ai fost provocat la un duel.',
      explanation2:
        'Un duel este o scurtă confruntare a cunoștințelor cu un coleg, în 5 runde. Câștigi o rundă dacă răspunzi corect la întrebare mai repede decât colegul tău. În cazul în care câștigi mai multe runde decât adversarul, câștigi duelul și urci în clasament.',
      attention: 'Notă:',
      explanation3: 'Joacă câte o rundă timp de 7 zile!',
    },
    leaveAlert: {
      title: 'Sigur?',
      text: '<p>Sigur dorești să oprești exersarea?</p><p>Evoluția ta a fost salvată.</p>',
      confirm: 'Oprește',
      continue: 'Continuă să exersezi',
    },
  },
  ranking: {
    title: 'Clasificare dueluri ',
    subtitle:
      'Această evaluare arată rangul dvs. în duel față de cele ale colegilor.',
    loadMore: 'Încărcați mai mult',
    yourPosition: 'Poziția dvs. ({{rank}})',
    topPositions: 'Poziții top',
    empty: 'Nu există clasament, începe un duel!',
    notRanked: 'Încheie un duel pentru un loc în clasament!',
  },
  searchFeedback: {
    title: 'Căutare coleg',
    oneResult: 'Este găsit {{amount}} coleg.',
    searchMessage: 'Sunt găsiți {{amount}} colegi.',
    overflowMessage:
      'Sunt arătate {{amount}} cele mai bune rezultate din căutare.',
  },
  searchDuel: {
    title: 'Căutare adversar ',
    rankingposition: 'Poziția în clasificare {{rank}}',
    noduelpossible: 'Duelul nu e posibil',
    duelstillactive: 'Duelul deja e activ.',
    invitationsentondate: 'Invitația e trimisă către {{date}}',
    searchPlaceholder: 'Căutare după nume ',
    randomOpponent:
      'Îți căutăm noul adversar. Poți închide acest ecran, te vom anunța când este gata duelul pentru tine.',
    emptyListSearch: 'Nu există rezultate ale căutării. ',
    emptyList:
      'Din păcate, nu există niciunul disponibil.<br>Încearcă din nou mai târziu. ',
  },
  activeDuels: {
    title: 'Participă la un duel disponibil.',
    subtitle: '{{num}}/{{max}} dueluri sunt active',
    status: {
      draw: 'Egalitate',
      lost: 'Pierdute ',
      won: 'Câștigate ',
      expired: 'Expirat',
      invited: 'Invitat',
      waiting: 'În așteptare',
      yourturn: 'Joacă',
    },
    emptyListNew: 'Nu există niciun duel, începe un duel nou!',
    emptyListCompleted:
      'Toate duelurile sunt încheiate, bravo!<br>Bea o ceașcă de cafea drept recompensă. ',
  },
  completedDuels: {
    title: 'Vizualizează duelurile finalizate.',
    empty: 'Nu ai încheiat niciun duel.<br>Începe un nou duel!',
  },
  error: {
    404: 'Serverul nu a fost găsit (HTTP 404).',
    500: 'Eroare internă la server (HTTP 500).',
    internetdown: 'Conexiune la Internet pierdută!',
    versionConflict: {
      title: 'Versiune nouă',
      text: 'Există o versiune nouă a acestei aplicații. Pentru a fi siguri că toată lumea folosește cea mai recentă versiune, acum îți vom prezenta noutățile privind cea mai recentă versiune. ',
      refresh: 'Accesează cea mai recentă versiune ',
    },
    refreshButton: 'Faceți clic pentru a actualiza ',
    internetup: 'Există o conexiune la internet.',
    timeoutajax: 'Timp de așteptare a expirat.',
    unknown: 'Eroare necunoscută ',
    tryReconnect: 'Încercare de reconectare...',
    alreadyAnswered: 'La acest element a fost deja răspuns. ',
    unavailableOffline: {
      title: 'Lipsă conexiune',
      subtitle: 'Nu există conexiune în prezent',
      explanation:
        'Este necesară o conexiune la internet pentru a utiliza aplicația. În acest moment nu există conexiune. Verifică dacă te poți reconecta la internet sau încearcă din nou mai târziu. ',
    },
  },
  common: {
    CheckAnswer: 'Verifică răspunsul',
    CheckAnswers: 'Verifică răspunsurile',
    Answer: 'Doresc să mi se salveze răspunsul',
    Answers: 'Doresc să mi se salveze răspunsurile',
    Hello: 'Bună',
    back: 'Înapoi',
    Emailaddress: 'Poștă electronică',
    Name: 'Nume',
    Checking: 'Verificare...',
    Close: 'Închidere',
    browsePrevious: 'Precedent',
    browseNext: 'Următor',
    Accept: 'Acceptare ',
    'Log out': 'Ieșire ',
    Help: 'Asistență',
    Correct: 'Corect',
    Incorrect: 'Incorect',
    CorrectNotChosen: 'Ar fi fost corect',
    Round: 'Rundă ',
    Version: 'Versiune',
    selected: 'Alese ',
    'No Rating': 'Nu este încă nici o evaluare ',
    'No Rank': 'Nu este clasificare ',
    NoUserSelection: 'Nu a fost găsi nici un utilizator',
    monthNames:
      'Ianuarie Februarie Martie Aprilie Mai Iunie Iulie August Septembrie Octombrie Noiembrie Decembrie',
    or: 'sau',
    on: 'conectare',
    off: 'deconectare',
  },
  profile: {
    title: 'Profil (cont)',
    subtitle: 'Modifică-ți profilul aici.',
    pictureEdit: 'Vizualizează-ți sau editează-ți fotografia.',
    'picture-submit': 'Folosește această selecție',
    pictureConfirmDelete:
      'Sigur dorești să-ți ștergi această fotografie de profil?',
    profilePageTitle: 'Gestionează-ți profilul.',
    noEditDataText: 'Nu poți modifica aceste date.',
    'file-submit': 'Încărcare ',
    pictureCancel: 'Anulare (abrogare)',
    'picture-nofileselected': 'Nu ați ales fișier. ',
    'picture-nofile': 'Nu este fișier. ',
    'picture-invalidsize':
      'Imaginea este prea mare. Lățimea sau înălțimea maximă nu pot depăși 2600 pixeli.',
    'picture-notimage': 'Fișierul selectat nu este o imagine.',
    passwordEdit: 'Schimbarea parolei ',
    'picture-saved': 'Fotografia Dvs de profil a fost salvată!',
    passwordTitle: 'Schimbarea parolei',
    passwordText:
      'Schimbați parola introducând parola curentă și de două ori parolă nouă. Parola nouă trebuie să aibă cel puțin 6 caractere.',
    'password-old': 'Parola curentă ',
    'password-new': 'Parola nouă',
    'password-repeat': 'Confirmare parolă nouă',
    'password-submit': 'Salvare parolă nouă',
    'password-oldpasswordmismatch': 'Parola dvs. curentă este incorectă',
    'password-newpasswordmismatch': 'Parola confirmată nu coincide. ',
    'password-passwordtooshort':
      'Parola nouă trebuie să conțină cel puțin 6 caractere.',
    'password-passwordsaved': 'Parola Dvs este salvată! ',
    profilePictureTitle: 'Fotografie de profil ',
    profilePictureText: 'Colegii Dvs pot vedea această imagine.',
    generalDataTitle: 'Informații personale ',
    generalDataText: 'Nu îți poți modifica singur toate datele personale.',
    profileLanguagePreference: 'Preferințe de limbă',
    profileLanguageChoice: 'Alegere limbă',
  },
  auth: {
    header: {
      login: 'Autentificare',
      expired: 'Linkul a expirat',
      remind: 'Ți-ai uitat parola?',
      create: 'Activează-ți contul',
      reset: 'Actualizează-ți parola',
      welcome: 'Bine ai venit!',
    },
    field: {
      email: 'Adresă de e-mail',
      password: 'Parolă',
      oldPassword: 'Parolă curentă',
      newPassword: 'Parolă nouă',
      newRepeatPassword: 'Repetă parola nouă',
      rememberMe: 'Memorare utilizator',
    },
    action: {
      itemcompleted: 'Ai finalizat un articol',
      updateprofile: 'Actualizează-ți profilul',
      sendLink: 'Doresc să primesc un link pentru resetarea parolei',
      backToLogin: 'Doresc să mă autentific',
      sendActivateLink: 'Activează contul',
      alreadyActivatedLogin:
        'Mi-am activat deja contul și doresc să mă autentific',
      savePassword: 'Doresc salvarea noii parole',
      newPassword: 'Noua ta parolă',
      repeatNewPassword: 'Repetă noua parolă',
      newPasswordPolicyIntro: 'Puterea parolei',
    },
    selfActivate: {
      header: 'Activați profilul său',
    },
    logintext: 'Autentificare',
    remindText:
      'Nicio problemă. Îți vom trimite un e-mail ca să îți poți seta o parolă nouă.',
    resetText: 'Îți poți seta noua parolă aici.',
    createText:
      'Bine ai venit! Creează-ți o parolă nouă și apoi ești gata de autentificare.',
    passwords: {
      user: 'Ne pare rău. Nu s-a găsit un utilizator cu această adresă de e-mail.',
      token: 'Acest token pentru resetarea parolei nu este valid.',
      sent: ' S-a trimis un e-mai cu un link de reinițializare.',
      reset: 'Parola a fost resetată!',
      forgot: 'Nu îmi cunosc parola',
      passwordExpiredTitle: 'Parola a expirat',
      submitPasswordExpiredForm: 'Salvează noua parola',
      passwordExpiredText:
        'Parola este mai veche de {{days}} zile și a expirat. Va trebui să setezi o nouă parolă folosind formularul de mai jos, după care poți continua să înveți folosind PowerApp',
    },
  },
  faq: {
    header: 'Întrebări frecvente',
    q1: 'PowerApp este o aplicație de micro-învățare, ce înseamnă aceasta?',
    a1: 'Micro-învățarea înseamnă că este mai eficient să înveți în ședințe scurte, în cazul în care scopul tău este să reții mai multe cunoștințe în timp.',
    q2: 'De ce scorul meu este uneori mai mic decât mă așteptam?',
    a2: 'Scorul tău scade deoarece în timp ai uitat ce ai învățat.',
    q3: 'Când îmi scade scorul?',
    a3: 'Când ai răspuns incorect la întrebări sau ești inactiv un anumit timp.',
    q4: 'De ce se repetă aceleași elemente?',
    a4: 'Deoarece repetarea la anumite intervale este cel mai eficient mod de a învăța.',
    q5: 'Ce este un duel?',
    a5: 'Un test de cunoștințe cu 5 întrebări în care concurezi cu un coleg.',
    q6: 'De ce trebuie să-mi aștept rândul când particip la un duel?',
    a6: 'Trebuie să aștepți să își termine mai întâi colegul rândul',
    q7: 'Unde pot găsi informații suplimentare și răspunsuri la întrebările mele?',
    a7: 'Butonul de mai jos te va duce la site-ul nostru web.',
    toTheFaq: 'La Întrebări frecvente',
    toTheWebsite: 'La site-ul web',
  },
}

export default translations

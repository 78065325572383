/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AuthenticationPasswordCompleteSsoPageSetPasswordMutationVariables = {
    email: string;
    token: string;
    password: string;
    passwordConfirmation: string;
    deviceName: string;
    requestAccessToken: boolean;
};
export type AuthenticationPasswordCompleteSsoPageSetPasswordMutationResponse = {
    readonly completeHybridSso: {
        readonly message: string | null;
        readonly success: boolean;
        readonly result: {
            readonly accessToken?: string | undefined;
            readonly user: {
                readonly __typename: string;
                readonly id: string;
                readonly email: string;
                readonly language: string;
            };
        } | null;
    };
};
export type AuthenticationPasswordCompleteSsoPageSetPasswordMutation = {
    readonly response: AuthenticationPasswordCompleteSsoPageSetPasswordMutationResponse;
    readonly variables: AuthenticationPasswordCompleteSsoPageSetPasswordMutationVariables;
};



/*
mutation AuthenticationPasswordCompleteSsoPageSetPasswordMutation(
  $email: String!
  $token: String!
  $password: String!
  $passwordConfirmation: String!
  $deviceName: String!
  $requestAccessToken: Boolean!
) {
  completeHybridSso(email: $email, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
    message
    success
    result {
      accessToken(deviceName: $deviceName) @include(if: $requestAccessToken)
      user {
        __typename
        id
        email
        language
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "deviceName"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "email"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "password"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "passwordConfirmation"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "requestAccessToken"
    } as any, v5 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "token"
    } as any, v6 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Variable",
                    "name": "password",
                    "variableName": "password"
                },
                {
                    "kind": "Variable",
                    "name": "passwordConfirmation",
                    "variableName": "passwordConfirmation"
                },
                {
                    "kind": "Variable",
                    "name": "token",
                    "variableName": "token"
                }
            ],
            "concreteType": "LoggedInResult",
            "kind": "LinkedField",
            "name": "completeHybridSso",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LoggedIn",
                    "kind": "LinkedField",
                    "name": "result",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticatedUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "language",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "condition": "requestAccessToken",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "deviceName",
                                            "variableName": "deviceName"
                                        }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "accessToken",
                                    "storageKey": null
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthenticationPasswordCompleteSsoPageSetPasswordMutation",
            "selections": (v6 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v5 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v0 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AuthenticationPasswordCompleteSsoPageSetPasswordMutation",
            "selections": (v6 /*: any*/)
        },
        "params": {
            "cacheID": "e120aa1db9d7c7696d3c1c605938d39f",
            "id": null,
            "metadata": {},
            "name": "AuthenticationPasswordCompleteSsoPageSetPasswordMutation",
            "operationKind": "mutation",
            "text": "mutation AuthenticationPasswordCompleteSsoPageSetPasswordMutation(\n  $email: String!\n  $token: String!\n  $password: String!\n  $passwordConfirmation: String!\n  $deviceName: String!\n  $requestAccessToken: Boolean!\n) {\n  completeHybridSso(email: $email, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {\n    message\n    success\n    result {\n      accessToken(deviceName: $deviceName) @include(if: $requestAccessToken)\n      user {\n        __typename\n        id\n        email\n        language\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '6cd99f4b329f1999be4be31f8c881ca5';
export default node;

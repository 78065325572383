import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faHeart as faHeartOutline } from '@fortawesome/pro-regular-svg-icons/faHeart'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useFragment } from 'relay-hooks'

import { TimeLineItem_item$key } from '../../generated/TimeLineItem_item.graphql'
import { TimeLineItem_topicCategory$key } from '../../generated/TimeLineItem_topicCategory.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { Asset } from '../common/Asset'
import { ForwardIcon } from '../common/ForwardIcon'

import styles from './TimeLineItem.scss'

interface TimeLineItemProps {
  item: TimeLineItem_item$key
  routeComponentProps: RouteComponentProps
  toggleFavorite: (event: SyntheticEvent) => void
  topicCategory: TimeLineItem_topicCategory$key
}

export function TimeLineItem(props: TimeLineItemProps): ReactElement {
  const { t } = useTranslation()
  const [assetExpanded, setAssetExpanded] = useState(false)

  const topicCategory = useFragment(
    graphql`
      fragment TimeLineItem_topicCategory on TopicCategory {
        availableFrom
        unlocked
      }
    `,
    props.topicCategory
  )
  const timeLineItem = useFragment(
    graphql`
      fragment TimeLineItem_item on OnboardingItem {
        id
        brainItem {
          __typename
          ... on Node {
            id
          }
        }
        completedAt
        favorited
        introAsset {
          ...Asset_asset
        }
        startedAt
        summaryText(language: $language)
      }
    `,
    props.item
  )

  const available = topicCategory.unlocked

  const timeLineItemStyle = classNames(styles.timeLineItemOuter, {
    [styles.active]: topicCategory.unlocked,
    [styles.available]: available,
    [styles.disabled]: !topicCategory.unlocked,
  })

  const timeLineItemIndicatorStyle = classNames(styles.timeLineItemIndicator, {
    [styles.active]: topicCategory.unlocked,
    [styles.disabled]: !topicCategory.unlocked,
  })

  const timeLineItemStatus =
    timeLineItem.completedAt !== null
      ? t('learn.completedAt')
      : timeLineItem.startedAt !== null
      ? t('learn.startedAt')
      : topicCategory.unlocked
      ? t('learn.availableFrom')
      : t('learn.available')

  const timeLineItemStatusDate =
    timeLineItem.completedAt !== null
      ? timeLineItem.completedAt
      : timeLineItem.startedAt !== null
      ? timeLineItem.startedAt
      : topicCategory.availableFrom

  const favorite = timeLineItem.favorited ? faHeartSolid : faHeartOutline

  let brainItemTypeString = timeLineItem.brainItem.__typename.toString()
  brainItemTypeString =
    brainItemTypeString !== 'BrainSnack'
      ? brainItemTypeString.toLowerCase().replace('question', '')
      : 'brainSnack'

  const assetClicked = useCallback(() => {
    setAssetExpanded(!assetExpanded)
  }, [assetExpanded])

  const onClick = useCallback((): void => {
    props.routeComponentProps.history.push(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      '/onboarding/item/' + encodeURIComponent(timeLineItem.brainItem.id!)
    )
  }, [props.routeComponentProps.history, timeLineItem.brainItem.id])
  const onKeyPress = useEnterKeyHandler(onClick)

  return (
    <>
      {timeLineItem.introAsset && (
        <div
          className={classNames(styles.assetContainer, {
            [styles.assetExpanded]: assetExpanded,
          })}
          onClick={assetClicked}
        >
          <Asset asset={timeLineItem.introAsset} />
        </div>
      )}

      <div className={styles.timeLineItemContainer}>
        <div className={timeLineItemIndicatorStyle}>
          <div
            className={classNames(styles.attachment, {
              [styles.completed]: timeLineItem.completedAt !== null,
            })}
          >
            {timeLineItem.completedAt !== null ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : null}
          </div>
        </div>

        <div className={timeLineItemStyle}>
          <div
            className={styles.timeLineItem}
            onClick={available ? onClick : undefined}
            onKeyPress={available ? onKeyPress : undefined}
            tabIndex={0}
          >
            <div className={styles.name}>{timeLineItem.summaryText}</div>

            {topicCategory.unlocked && (
              <div className={styles.favorite} onClick={props.toggleFavorite}>
                <FontAwesomeIcon icon={favorite} />
              </div>
            )}

            <div className={styles.statusRow}>
              <div
                className={classNames(styles.status, styles.statusItemRight)}
              >
                {timeLineItemStatus} {timeLineItemStatusDate}
              </div>
            </div>

            {topicCategory.unlocked && (
              <>
                <hr />

                {timeLineItem.startedAt === null && (
                  <div className={styles.motivate}>
                    {t(
                      'streamItem.' + brainItemTypeString + '.challengingText'
                    )}
                  </div>
                )}
                {timeLineItem.startedAt !== null &&
                  timeLineItem.completedAt === null && (
                    <div className={styles.motivate}>
                      {t('streamItem.challengingTextStarted')}
                    </div>
                  )}
                {timeLineItem.completedAt !== null && (
                  <div className={styles.motivate}>
                    {t('streamItem.' + brainItemTypeString + '.completedText')}
                  </div>
                )}
              </>
            )}
          </div>

          {available && (
            <Link
              to={
                '/onboarding/item/' +
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                encodeURIComponent(timeLineItem.brainItem.id!)
              }
              className={styles.attachment}
            >
              {topicCategory.unlocked ? (
                <ForwardIcon />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </Link>
          )}
        </div>
      </div>
    </>
  )
}
